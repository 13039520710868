import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Gallery from "../../Reusable/Gallery";

import AboutGreeceImage1 from "../../../static/images/About_Country/About_Greece/Holidays/about_greece_holidays_1.png";

class AboutGreeceHolidays extends Component {
    render() {
        return (
            <div className="about_greece_vacation">
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="description">
                                Verski praznici koji se ujedno praznuju i kao državni su: Božić, Bogojavljenje, Uskrs, Uspenje Svete Bogorodice, Sveti
                                Dimitrije, Sveta Trojica i grčki dan nezavisnosti "OHI". Od 1924. u Grčkoj se praznuje po julijanskom kalendaru-n.pr. Božić 25.
                                decembra. Mali procenat praznuje po gregorijanskom kaledaru. Takođe, svaki grad slavi svog sveca-zaštitnika, tako u Solunu na
                                primer slave Svetog Dimitrija. Državni praznici u Grčkoj su:
                            </div>
                        </div>
                        <Row className="content_wrapper">
                            <Col lg={6}>
                                <ul className="country_unorder_list">
                                    <li>1 Januar-Nova godina.</li>
                                    <li>6 Januar-Bogojavljenje.</li>
                                    <li>Katara Deftera (Čisti Ponedeljak) prvi dan uskršnjeg posta.</li>
                                    <li>
                                        25 Mart-Dan nezavisnosti, najveći nacionalni praznik. Na ovaj dan 1821. god. počeo je oslobodilački rat protiv turskog
                                        jarma.
                                    </li>
                                    <li>Uskrs.</li>
                                    <li>1 Maj-Praznik rada.</li>
                                    <li>Dan Svetog Duha 50. dana posle Uskrsa.</li>
                                    <li>15 Avgust-Uspenje Presvete Bogorodice.</li>
                                    <li>28 Oktobar-nacionalni praznik "OHI".</li>
                                    <li>24 Decembar-Badnje veče (1.dan).</li>
                                    <li>25 Decembar-Božić.</li>
                                </ul>
                            </Col>
                            <Col lg={6}>
                                <img src={AboutGreeceImage1} alt="About Greece holiday 1" />
                            </Col>
                        </Row>
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <Container>
                    <Gallery />
                </Container>
            </div>
        );
    }
}

export default AboutGreeceHolidays;
