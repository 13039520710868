import React, { Component } from "react";
import { Container } from "react-bootstrap";

class AboutCountryInformations extends Component {
    render() {
        return (
            <div className="about_country_informations font-poppins">
                <div className="image_wrapper">
                    <div className="overlay"></div>
                    <img src={this.props.image} alt={`about greece informations`} />
                    <Container className="country_informations_wrapper">
                        <div className="title">{this.props.title}</div>
                        <div className="description">{this.props.description}</div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default AboutCountryInformations;
