export const srpski_mala_slova = {
    name: "srpski_mala_slova",
    months: [
        ["Januar", "Jan"],
        ["Februar", "Feb"],
        ["Mart", "Mar"],
        ["April", "Apr"],
        ["Maj", "Maj"],
        ["Jun", "Jun"],
        ["Jul", "Jul"],
        ["Avgust", "Avg"],
        ["Septembar", "Sep"],
        ["Oktobar", "Okt"],
        ["Novembar", "Nov"],
        ["Decembar", "Dec"],
    ],
    weekDays: [
        ["Subota", "Sub"],
        ["Nedelja", "Ned"],
        ["Ponedeljak", "Pon"],
        ["Utorak", "Uto"],
        ["Sreda", "Sre"],
        ["Četvrtak", "Čet"],
        ["Petak", "Pet"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
        ["AM", "am"],
        ["PM", "pm"],
    ],
};
