import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_ERROR,
    GET_SINGLE_NOTIFICATIONS,
    GET_SINGLE_NOTIFICATIONS_SUCCESS,
    GET_SINGLE_NOTIFICATIONS_ERROR,
} from "./actionTypes";

export const getNotifications = () => {
    return {
        type: GET_NOTIFICATIONS,
    };
};
export const getNotificationsSuccess = (payload) => {
    return {
        type: GET_NOTIFICATIONS_SUCCESS,
        payload,
    };
};
export const getNotificationsError = (payload) => {
    return {
        type: GET_NOTIFICATIONS_ERROR,
        payload,
    };
};

export const getSingleNotifications = (id) => {
    return {
        type: GET_SINGLE_NOTIFICATIONS,
        payload: id,
    };
};
export const getSingleNotificationsSuccess = (payload) => {
    return {
        type: GET_SINGLE_NOTIFICATIONS_SUCCESS,
        payload,
    };
};
export const getSingleNotificationsError = (payload) => {
    return {
        type: GET_SINGLE_NOTIFICATIONS_ERROR,
        payload,
    };
};
