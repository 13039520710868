import React, { Component } from "react";
import { Transition } from "react-transition-group";

import BookingFilters from "../../Reusable/BookingFilters";

// import { quickFilters } from "../../../helpers/utils/bookingUtils";

import "../../../styles/Components/BookingQuickFilters.scss";

import pdf_icon from "../../../static/images/icons/PDF_icon.png";
import filter_black_icon from "../../../static/images/icons/filter_black_icon.png";
import sort_black_icon from "../../../static/images/icons/sort_black_icon.png";

const transitionDuration = 500;
const defaultStyle = {
    transition: `opacity ${500}ms ease-in-out`,
    opacity: 0,
};
const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0, zIndex: -1 },
};

const defaultFiltersStyle = {
    transition: `left  ${transitionDuration}ms ease-in-out, opacity ${800}ms ease-in-out`,
    left: 0,
    opacity: 0,
};
const transitionFiltersStyles = {
    entering: { left: "-50%", opacity: 1 },
    entered: { left: 0, opacity: 1 },
    exiting: { left: "-100%", opacity: 1 },
    exited: { left: "-100%", zIndex: -1, opacity: 0 },
};

class BookingQuickFiltersMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenSort: false,
            isFilterOpen: false,
            priceSort: undefined,
            priceSortLabel: undefined,
        };
    }

    render() {
        return (
            <div className="type_filters no-select">
                {/* {quickFilters.map((filter, key) => (
                    <div
                        className={`type_filter ${this.props.activeTypeFilters.includes(filter.value) ? "active" : "inactive"}`}
                        onClick={() => this.props.changeActiveFilters(filter.value)}
                        key={key}
                    >
                        {filter.label}
                    </div>
                ))} */}
                <div className="type_filter">
                    <img src={pdf_icon} alt="PDF icon" />
                    Opisi hotela na Srpskom
                </div>
                <div className="type_filter position-relative">
                    <div onClick={() => this.setState({ isFilterOpen: !this.state.isFilterOpen })}>
                        <img src={filter_black_icon} alt="Filter black icon" />
                        Filteri
                    </div>
                    <Transition in={this.state.isFilterOpen} timeout={500}>
                        {(state) => (
                            <>
                                <div
                                    className="booking_filters_wrapper_mobile-overlay"
                                    style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state],
                                    }}
                                    onClick={() => {
                                        setTimeout(() => {
                                            this.setState({
                                                isFilterOpen: !this.state.isFilterOpen,
                                            });
                                        }, transitionDuration);
                                    }}
                                ></div>
                                <div
                                    className="booking_filters_wrapper_mobile"
                                    style={{
                                        ...defaultFiltersStyle,
                                        ...transitionFiltersStyles[state],
                                    }}
                                >
                                    <BookingFilters
                                        className="bookings_filter_desktop"
                                        filters={this.props.filters}
                                        changeFilters={this.props.changeFilters}
                                        deleteFilters={this.props.deleteFilters}
                                        searchByFilters={this.props.searchByFilters}
                                    />
                                </div>
                            </>
                        )}
                    </Transition>
                </div>
                <div className="type_filter position-relative">
                    <div onClick={() => this.setState({ isOpenSort: !this.state.isOpenSort })}>
                        <img src={sort_black_icon} alt="Sort black icon" />
                        {this.state.priceSortLabel ?? "Sortiraj cenu"}
                    </div>
                    <Transition in={this.state.isOpenSort} timeout={500}>
                        {(state) => (
                            <div
                                className="sort_price_wrapper"
                                style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state],
                                }}
                            >
                                {[
                                    { value: "popusti", label: "Popusti" },
                                    { value: "skuplje", label: "Od jeftinijeg" },
                                    { value: "jeftinije", label: "Od skupljeg" },
                                ].map((item, key) => (
                                    <div
                                        className="sort_price_item_wrapper"
                                        onClick={() => this.setState({ priceSort: item.value, priceSortLabel: item.label })}
                                        key={key}
                                    >
                                        <div className="label">{item.label}</div>
                                        <div className="separator"></div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Transition>
                </div>
            </div>
        );
    }
}

export default BookingQuickFiltersMobile;
