import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";

import "../styles/Blog.scss";

import BlogItem from "../components/Reusable/BlogItem";

import { getNotifications } from "../store/actions";

class Notifications extends Component {
    componentDidMount() {
        this.props.getNotifications();
    }

    render() {
        return (
            <div className="blog-wrapper">
                <Container className="blog-content-wrapper font-poppins">
                    <div className="content-wrapper">
                        <div className="blog-title">Najnovija obaveštenja</div>
                        <div className="content-items">
                            {this.props.notifications?.map((item, key) => (
                                <BlogItem isNotifications={true} item={item} index={key} />
                            ))}
                        </div>
                    </div>
                    <div className="pagination"></div>
                </Container>
                <div className="fake_background"></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.Notifications.notifications,
    };
};

export default connect(mapStateToProps, { getNotifications })(Notifications);
