import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";

// import PublicRoute from "./PublicRoute";
import AuthRoute from "./AuthRoute";

import Header from "./components/Header";
import Footer from "./components/Footer/Footer";

import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import Home from "./routes/Home";
import Booking from "./routes/Booking";
import SpecialOffers from "./routes/SpecialOffers";
import Blog from "./routes/Blog";
import SingleBlog from "./routes/SingleBlog";
import AboutHotel from "./routes/AboutHotel";
import AboutCountry from "./routes/AboutCountry";
import Contact from "./routes/Contact";
import Destinations from "./routes/Destinations";
import DestinationDetails from "./routes/DestinationDetails";
import Reservation from "./routes/Reservation";
import Login from "./routes/Login";
import Registration from "./routes/Registration";
import MyReservation from "./routes/MyReservation";
// import MyReservationInformations from "./routes/MyReservationInformations";
import FavoritesAccommodations from "./routes/FavoritesAccommodations";
import Notifications from "./routes/Notifications";
import SingleNotifications from "./routes/SingleNotifications";
import CustomPage from "./routes/CustomPage";

import MyProfile from "./routes/MyProfile";
import NotFound from "./routes/NotFound";
import CoomingSoon from "./routes/CoomingSoon";
import ResetPassword from "./routes/ResetPassword";
import ForgotPassword from "./routes/ForgotPassword";

import ScrollToTop from "./components/Reusable/ScrollToTop";

axios.defaults.withCredentials = true;

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route exact path="/reservation" element={<AuthRoute />}>
                    <Route exact path="/reservation" element={<Reservation />} />
                </Route>
                <Route exact path="/my-profile" element={<AuthRoute />}>
                    <Route exact path="/my-profile" element={<MyProfile />} />
                </Route>
                <Route exact path="/my-reservations" element={<AuthRoute />}>
                    <Route exact path="/my-reservations" element={<MyReservation />} />
                </Route>
                <Route exact path="/favorites-accommodations" element={<AuthRoute />}>
                    <Route exact path="/favorites-accommodations" element={<FavoritesAccommodations />} />
                </Route>
                <Route exact path="/change-password" element={<AuthRoute />}>
                    <Route exact path="/change-password" element={<ResetPassword />} />
                </Route>

                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/special-offers/:type" element={<SpecialOffers />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:id" element={<SingleBlog />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/notifications/:id" element={<SingleNotifications />} />
                <Route path="/about-hotel/:destId/:id" element={<AboutHotel />} />
                <Route path="/about-country/:country" element={<AboutCountry />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/destinations" element={<Destinations />} />
                <Route path="/destination/details/:region" element={<DestinationDetails />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/page/:page" element={<CustomPage />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="/cooming-soon" element={<CoomingSoon />} />
                <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    </Provider>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
