import React, { Component } from "react";
import { Container } from "react-bootstrap";

import AboutCountryDictionary from "../../Reusable/AboutCountryDictionary";

class AboutGreeceDictionary extends Component {
    render() {
        return (
            <div className="about_greece_dictionary">
                <Container>
                    <div className="content_wrapper">
                        <div className="title">Fraze na Grčkom</div>
                        <AboutCountryDictionary data={this.props.dictionary} />
                    </div>
                </Container>
            </div>
        );
    }
}

export default AboutGreeceDictionary;
