import React, { Component } from "react";

import "../../styles/Components/RegistrationProfiles.scss";

import PersonInactiveIcon from "../../static/images/icons/person_inactive_icon.png";
import PersonActiveIcon from "../../static/images/icons/person_active_icon.png";

class RegistrationProfiles extends Component {
    render() {
        return (
            <div className="registration_profiles_wrapper font-poppins">
                {[{ title: "Za turiste" }, { title: "Za agente" }].map((profile, key) => (
                    <div
                        className={`registration_profiles_item_wrapper ${this.props.active === key ? "active" : ""}`}
                        onClick={() => this.props.setActiveProfile(key)}
                        key={key}
                    >
                        {this.props.active === key ? (
                            <img src={PersonActiveIcon} alt={`Person icon ${key}`} />
                        ) : (
                            <img src={PersonInactiveIcon} alt={`Person icon ${key}`} />
                        )}
                        {profile.title}
                    </div>
                ))}
            </div>
        );
    }
}

export default RegistrationProfiles;
