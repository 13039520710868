import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";

import { withRouter } from "../helpers/utils/globalUtils";

import SpecialOffersList from "../components/Reusable/SpecialOffersList";

import { getSpecialOffersHotels, getSpecialOffersApartments, getFirstLastMinutesOffers } from "../store/actions";

import "../styles/Booking.scss";

class SpecialOffers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOffers: undefined,
        };
    }

    getSpecialOffers = () => {
        if (this.props.router.params.type) {
            const type = this.props.router.params.type.toUpperCase();
            switch (type) {
                case "HOTELS": {
                    this.props.getSpecialOffersHotels();
                    this.setState({ currentOffers: this.props.hotels });
                    break;
                }
                case "APARTMENTS": {
                    this.props.getSpecialOffersApartments();
                    this.setState({ currentOffers: this.props.apartments });
                    break;
                }
                case "FIRST-LAST-MINUTES": {
                    this.props.getFirstLastMinutesOffers();
                    this.setState({ currentOffers: this.props.firstLastMinutes });
                    break;
                }
                default:
                    this.props.router.navigate("/404");
            }
        } else {
            this.props.router.navigate("/404");
        }
    };

    componentDidMount() {
        this.getSpecialOffers();
    }

    componentDidUpdate(prevProps) {
        if (this.props.router.params.type !== prevProps.router.params.type) {
            this.getSpecialOffers();
        }

        if (JSON.stringify(this.props.hotels) !== JSON.stringify(prevProps.hotels)) {
            if (this.props.router.params.type.toUpperCase() === "HOTELS") {
                this.setState({ currentOffers: this.props.hotels });
            }
        }

        if (JSON.stringify(this.props.apartments) !== JSON.stringify(prevProps.apartments)) {
            if (this.props.router.params.type.toUpperCase() === "APARTMENTS") {
                this.setState({ currentOffers: this.props.apartments });
            }
        }

        if (JSON.stringify(this.props.firstLastMinutes) !== JSON.stringify(prevProps.firstLastMinutes)) {
            if (this.props.router.params.type.toUpperCase() === "FIRST-LAST-MINUTES") {
                this.setState({ currentOffers: this.props.firstLastMinutes });
            }
        }
    }

    render() {
        return (
            <Container fluid className="booking_main_wrapper_desktop font-poppins">
                <Container>
                    <SpecialOffersList loading={this.props.loading} accommodation={this.state.currentOffers} />
                </Container>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.SpecialOffers.loading,
        hotels: state.SpecialOffers.hotels,
        apartments: state.SpecialOffers.apartments,
        firstLastMinutes: state.SpecialOffers.firstLastMinutes,
    };
};

export default connect(mapStateToProps, { getSpecialOffersHotels, getSpecialOffersApartments, getFirstLastMinutesOffers })(withRouter(SpecialOffers));
