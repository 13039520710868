// export const NAME_NAME = 'NAME_NAME';
// export const NAME_NAME_SUCCESS = 'NAME_NAME_SUCCESS';
// export const NAME_NAME_ERROR = 'NAME_NAME_ERROR';

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR";

export const GET_SINGLE_NOTIFICATIONS = "GET_SINGLE_NOTIFICATIONS";
export const GET_SINGLE_NOTIFICATIONS_SUCCESS = "GET_SINGLE_NOTIFICATIONS_SUCCESS";
export const GET_SINGLE_NOTIFICATIONS_ERROR = "GET_SINGLE_NOTIFICATIONS_ERROR";
