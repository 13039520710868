import {
    GET_DESTINATIONS,
    GET_DESTINATIONS_SUCCESS,
    GET_DESTINATIONS_ERROR,
    SEARCH_ACCOMMODATION,
    SEARCH_ACCOMMODATION_SUCCESS,
    SEARCH_ACCOMMODATION_ERROR,
    SORT_ACCOMMODATION,
    GET_ABOUT_HOTEL,
    GET_ABOUT_HOTEL_SUCCESS,
    GET_ABOUT_HOTEL_ERROR,
    MAKE_RESERVATION,
    MAKE_RESERVATION_SUCCESS,
    MAKE_RESERVATION_ERROR,
    MY_RESERVATIONS,
    MY_RESERVATIONS_SUCCESS,
    MY_RESERVATIONS_ERROR,
    CANCEL_RESERVATION,
    CANCEL_RESERVATION_SUCCESS,
    CANCEL_RESERVATION_ERROR,
    SEARCH_RESERVATIONS,
    RESET,
} from "./actionTypes";

const initialState = {
    // variable: value
    destinations: undefined,
    accommodation: undefined,
    aboutHotel: undefined,
    myReservations: [],
    searchedReservations: [],
    loading: false,
    loadingSearch: false,
    reservationStatus: undefined,
    success: undefined,
    error: undefined,
};

const sortAccommodation = (arr, sort) => {
    arr?.sort((a, b) => {
        if (a?.roomData?.mealPlanPrice < b?.roomData?.mealPlanPrice) {
            return -1;
        }
        if (a?.roomData?.mealPlanPrice > b?.roomData?.mealPlanPrice) {
            return 1;
        }

        return 0;
    });
    if (sort === "jeftinije") {
        arr.reverse();
    }

    return arr;
};

const Booking = (state = initialState, action) => {
    switch (action.type) {
        case GET_DESTINATIONS:
            state = { ...state, loading: true };
            break;
        case GET_DESTINATIONS_SUCCESS:
            state = { ...state, loading: false, destinations: action.payload?.destinations };
            break;
        case GET_DESTINATIONS_ERROR:
            state = { ...state, loading: false };
            break;
        case SEARCH_ACCOMMODATION:
            state = { ...state, loadingSearch: true };
            break;
        case SEARCH_ACCOMMODATION_SUCCESS: {
            let accommodation = action.payload?.data;
            if (action.payload?.sort && accommodation) {
                accommodation = sortAccommodation(accommodation, action.payload?.sort);
            }

            state = { ...state, loadingSearch: false, accommodation };
            break;
        }
        case SEARCH_ACCOMMODATION_ERROR:
            state = { ...state, loadingSearch: false };
            break;
        case GET_ABOUT_HOTEL:
            state = { ...state, loading: true };
            break;
        case GET_ABOUT_HOTEL_SUCCESS:
            state = { ...state, loading: false, aboutHotel: action.payload };
            break;
        case GET_ABOUT_HOTEL_ERROR:
            state = { ...state, loading: false, error: action.payload };
            break;
        case SORT_ACCOMMODATION: {
            state = {
                ...state,
                accommodation: sortAccommodation(state.accommodation, action.payload),
            };
            break;
        }
        case MAKE_RESERVATION: {
            state = { ...state, loading: true };
            break;
        }
        case MAKE_RESERVATION_SUCCESS: {
            state = {
                ...state,
                loading: false,
                success: true,
                reservationStatus: {
                    title: "Uspešna Rezervacija!",
                    description: "Uskoro ćete dobiti e-poruku sa obaveštenjem sa podacima o rezervaciji.",
                },
            };
            break;
        }
        case MAKE_RESERVATION_ERROR: {
            state = {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
                reservationStatus: {
                    title: "Neuspešna Rezervacija!",
                    description: "Nažalost niste uspeli da rezervišete smeštaj.",
                },
            };
            break;
        }
        case MY_RESERVATIONS: {
            state = {
                ...state,
                loading: true,
            };
            break;
        }
        case MY_RESERVATIONS_SUCCESS: {
            state = {
                ...state,
                loading: false,
                myReservations: action.payload,
            };
            break;
        }
        case MY_RESERVATIONS_ERROR: {
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;
        }
        case CANCEL_RESERVATION: {
            state = {
                ...state,
                loading: true,
            };
            break;
        }
        case CANCEL_RESERVATION_SUCCESS: {
            let myReservations = state.myReservations;
            if (action.payload) {
                let reservations = [];
                myReservations.forEach((reservation) => {
                    if (reservation.bookID === action.payload) {
                        reservations.push({ ...reservation, status: "cancelled" });
                    } else {
                        reservations.push(reservation);
                    }
                });
                myReservations = reservations;
            }

            state = {
                ...state,
                loading: false,
                myReservations,
            };
            break;
        }
        case CANCEL_RESERVATION_ERROR: {
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;
        }
        case SEARCH_RESERVATIONS: {
            let searchedReservations = state.myReservations;
            if (action.payload && action.payload !== "") {
                searchedReservations = state.myReservations?.filter((reservation) => reservation.bookID === action.payload);
            }

            state = {
                ...state,
                searchedReservations,
            };
            break;
        }
        case RESET: {
            state = {
                ...state,
                success: false,
                error: undefined,
                reservationStatus: undefined,
            };
            break;
        }
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default Booking;
