import React, { Component } from "react";
import { NavLink } from "react-bootstrap";

import LoggedUser from "../../LoggedUser";

class LoginDesktop extends Component {
    render() {
        if (this.props.isLogged) {
            return <LoggedUser userId={this.props.userId} userName={this.props.userFirstName} userType={this.props.userType} />;
        }

        return (
            <NavLink className="login-button" href="/login">
                Uloguj se
            </NavLink>
        );
    }
}

export default LoginDesktop;
