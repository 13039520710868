import {
    GET_ALL_REGIONS,
    GET_ALL_REGIONS_SUCCESS,
    GET_ALL_REGIONS_ERROR,
    GET_SINGLE_REGION,
    GET_SINGLE_REGION_SUCCESS,
    GET_SINGLE_REGION_ERROR,
    GET_PROGRAM_LIST,
    GET_PROGRAM_LIST_SUCCESS,
    GET_PROGRAM_LIST_ERROR,
    CONTACT_US_SUCCESS,
    CONTACT_US_ERROR,
    GET_EXCHANGE_RATE_SUCCESS,
    GET_EXCHANGE_RATE_ERROR,
    SUBSCRIBE_NEWSLETTERS,
    SUBSCRIBE_NEWSLETTERS_SUCCESS,
    SUBSCRIBE_NEWSLETTERS_ERROR,
} from "./actionTypes";

const initialState = {
    // variable: value
    regions: undefined,
    singleRegion: undefined,
    programs: undefined,
    loading: false,
    error: undefined,
    exchangeRate: undefined,
    newsletters: undefined,
    contactUs: {
        success: undefined,
        error: undefined,
    },
};

const General = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_REGIONS:
            state = { ...state, loading: true };
            break;
        case GET_ALL_REGIONS_SUCCESS:
            state = { ...state, loading: false, regions: action.payload };
            break;
        case GET_ALL_REGIONS_ERROR:
            state = { ...state, loading: false };
            break;
        case GET_SINGLE_REGION:
            state = { ...state, loading: true };
            break;
        case GET_SINGLE_REGION_SUCCESS:
            state = { ...state, loading: false, singleRegion: action.payload };
            break;
        case GET_SINGLE_REGION_ERROR:
            state = { ...state, loading: false };
            break;
        case GET_PROGRAM_LIST:
            state = { ...state, loading: true };
            break;
        case GET_PROGRAM_LIST_SUCCESS:
            state = { ...state, loading: false, programs: action.payload };
            break;
        case GET_PROGRAM_LIST_ERROR:
            state = { ...state, loading: false };
            break;
        case CONTACT_US_SUCCESS: {
            state = {
                ...state,
                contactUs: {
                    ...state.contactUs,
                    success: true,
                },
            };
            break;
        }
        case CONTACT_US_ERROR: {
            state = {
                ...state,
                contactUs: {
                    ...state.contactUs,
                    error: true,
                },
            };
            break;
        }
        case GET_EXCHANGE_RATE_SUCCESS: {
            state = {
                ...state,
                exchangeRate: action.payload,
            };
            break;
        }
        case GET_EXCHANGE_RATE_ERROR:
            state = {
                ...state,
                error: action.payload,
            };
            break;
        case SUBSCRIBE_NEWSLETTERS: {
            state = {
                ...state,
                newsletters: "sending",
            };
            break;
        }
        case SUBSCRIBE_NEWSLETTERS_SUCCESS: {
            state = {
                ...state,
                newsletters: "success",
            };
            break;
        }
        case SUBSCRIBE_NEWSLETTERS_ERROR: {
            state = {
                ...state,
                newsletters: "error",
            };
            break;
        }
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default General;
