import {
    GET_ALL_REGIONS,
    GET_ALL_REGIONS_SUCCESS,
    GET_ALL_REGIONS_ERROR,
    GET_SINGLE_REGION,
    GET_SINGLE_REGION_SUCCESS,
    GET_SINGLE_REGION_ERROR,
    GET_PROGRAM_LIST,
    GET_PROGRAM_LIST_SUCCESS,
    GET_PROGRAM_LIST_ERROR,
    SUBSCRIBE_NEWSLETTERS,
    SUBSCRIBE_NEWSLETTERS_SUCCESS,
    SUBSCRIBE_NEWSLETTERS_ERROR,
    CONTACT_US,
    CONTACT_US_SUCCESS,
    CONTACT_US_ERROR,
    GET_EXCHANGE_RATE,
    GET_EXCHANGE_RATE_SUCCESS,
    GET_EXCHANGE_RATE_ERROR,
} from "./actionTypes";

export const getAllRegions = () => {
    return {
        type: GET_ALL_REGIONS,
    };
};

export const getAllRegionsSuccess = (payload) => {
    return {
        type: GET_ALL_REGIONS_SUCCESS,
        payload,
    };
};

export const getAllRegionsError = (payload) => {
    return {
        type: GET_ALL_REGIONS_ERROR,
        payload,
    };
};

export const getSingleRegion = (slug, history) => {
    return {
        type: GET_SINGLE_REGION,
        payload: { slug, history },
    };
};

export const getSingleRegionSuccess = (payload) => {
    return {
        type: GET_SINGLE_REGION_SUCCESS,
        payload,
    };
};

export const getSingleRegionError = (payload) => {
    return {
        type: GET_SINGLE_REGION_ERROR,
        payload,
    };
};

export const getProgramList = () => {
    return {
        type: GET_PROGRAM_LIST,
    };
};

export const getProgramListSuccess = (payload) => {
    return {
        type: GET_PROGRAM_LIST_SUCCESS,
        payload,
    };
};

export const getProgramListError = (payload) => {
    return {
        type: GET_PROGRAM_LIST_ERROR,
        payload,
    };
};

export const subscribeNewsletters = (payload) => {
    return {
        type: SUBSCRIBE_NEWSLETTERS,
        payload,
    };
};
export const subscribeNewslettersSuccess = () => {
    return {
        type: SUBSCRIBE_NEWSLETTERS_SUCCESS,
    };
};
export const subscribeNewslettersError = () => {
    return {
        type: SUBSCRIBE_NEWSLETTERS_ERROR,
    };
};

export const contactUs = (payload) => {
    return {
        type: CONTACT_US,
        payload,
    };
};

export const contactUsSuccess = () => {
    return {
        type: CONTACT_US_SUCCESS,
    };
};

export const contactUsError = (payload) => {
    return {
        type: CONTACT_US_ERROR,
        payload,
    };
};

export const getExchangeRate = () => {
    return {
        type: GET_EXCHANGE_RATE,
    };
};

export const getExchangeRateSuccess = (payload) => {
    return {
        type: GET_EXCHANGE_RATE_SUCCESS,
        payload,
    };
};

export const getExchangeRateError = (payload) => {
    return {
        type: GET_EXCHANGE_RATE_ERROR,
        payload,
    };
};
