import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_ERROR,
    GET_SINGLE_NOTIFICATIONS,
    GET_SINGLE_NOTIFICATIONS_SUCCESS,
    GET_SINGLE_NOTIFICATIONS_ERROR,
} from "./actionTypes";

const initialState = {
    // variable: value
    notifications: undefined,
    singleNotification: undefined,
    loading: false,
    error: undefined,
};

const Notifications = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            state = { ...state, loading: true };
            break;
        case GET_NOTIFICATIONS_SUCCESS:
            state = { ...state, loading: false, notifications: action.payload };
            break;
        case GET_NOTIFICATIONS_ERROR:
            state = { ...state, loading: false };
            break;
        case GET_SINGLE_NOTIFICATIONS:
            state = { ...state, loading: true };
            break;
        case GET_SINGLE_NOTIFICATIONS_SUCCESS:
            state = { ...state, loading: false, singleNotification: action.payload };
            break;
        case GET_SINGLE_NOTIFICATIONS_ERROR:
            state = { ...state, loading: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default Notifications;
