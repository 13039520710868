import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Transition } from "react-transition-group";

import "../../styles/Components/AboutCountryDictionary.scss";

import ArrowDownIcon from "../../static/images/icons/arrow_down_icon.png";

const transitionDuration = 300;
const defaultStyle = {
    transition: `opacity ${transitionDuration}ms ease-in-out`,
    opacity: 0,
};
const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1, zIndex: 999 },
    exiting: { opacity: 0 },
    exited: { opacity: 0, zIndex: -1 },
};

class AboutCountryDictionary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dictionaryOpen: Array.from({ length: props.data ? props.data.length : 1 }, () => false),
        };
    }

    componentDidMount() {
        this.setState({ dictionaryOpen: Array.from({ length: this.props.data ? this.props.data?.length : 1 }, () => false) });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
            this.setState({ dictionaryOpen: Array.from({ length: this.props.data ? this.props.data?.length : 1 }, () => false) });
        }
    }

    render() {
        return (
            <Row className="about_country_dictinary_wrapper">
                {this.props.data &&
                    this.props.data?.map((item, key) => (
                        <Col lg={6} key={key} className="about_country_item_wrapper font-poppins">
                            <div
                                className="about_country_title_wrapper"
                                onClick={() => {
                                    let dictionaryOpen = this.state.dictionaryOpen;
                                    dictionaryOpen[key] = !dictionaryOpen[key];
                                    this.setState({ dictionaryOpen });
                                }}
                            >
                                <div>{item.title}</div>
                                <div className="icons_wrapper">
                                    <img src={item?.icon} alt={`Dictionary icon ${key}`} />
                                    <img src={ArrowDownIcon} alt={`Dictionary arrow down icon ${key}`} />
                                </div>
                            </div>
                            <Transition in={this.state.dictionaryOpen[key]} timeout={transitionDuration}>
                                {(state) => (
                                    <WordsList
                                        data={item?.words}
                                        style={{
                                            ...defaultStyle,
                                            ...transitionStyles[state],
                                        }}
                                    />
                                )}
                            </Transition>
                        </Col>
                    ))}
            </Row>
        );
    }
}

export default AboutCountryDictionary;

class WordsList extends Component {
    render() {
        return (
            <Row className="words_list_wrapper  scrollbar" style={this.props.style}>
                <Col className="words_list_language_wrapper">
                    {this.props.data
                        ?.map((item) => item.language1)
                        ?.map((item, key) => (
                            <Col className="words_list_language_item" key={key}>
                                {item}
                            </Col>
                        ))}
                </Col>
                <div className="words_list_separator"></div>
                <Col className="words_list_language_wrapper">
                    {this.props.data
                        ?.map((item) => item.language2)
                        ?.map((item, key) => (
                            <Col className="words_list_language_item" key={key}>
                                {item}
                            </Col>
                        ))}
                </Col>
            </Row>
        );
    }
}
