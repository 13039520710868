import React, { Component } from "react";
import ReactPaginate from "react-paginate";

import SpecialOffersAccommodationItem from "../SpecialOffersAccommodationItem/index";
import BookingLoadingSkeleton from "../BookingLoadingSkeleton";

import "../../styles/Components/BookingList.scss";
import "../../styles/Components/Pagination.scss";

class SpecialOffersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentItems: null,
            pageCount: 0,
            itemsOffset: 0,
        };
    }

    doPaginate = () => {
        const endOffset = this.state.itemsOffset + 4;
        this.setState({
            currentItems: this.props.accommodation?.slice(this.state.itemsOffset, endOffset),
            pageCount: Math.ceil(this.props.accommodation?.length / 4),
        });
    };

    componentDidMount() {
        this.doPaginate();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.itemsOffset !== prevState.itemsOffset || JSON.stringify(this.props.accommodation) !== JSON.stringify(prevProps.accommodation)) {
            this.doPaginate();
        }
    }

    handlePageClick = (event) => {
        const newOffset = (event.selected * 4) % this.props.accommodation?.length;
        this.setState({ itemsOffset: newOffset });
    };

    render() {
        return (
            <div className={`bookings_list_wrapper ${this.props.loading ? "loading" : "done_loading"}`}>
                {this.props.loading && [1, 2, 3].map((e, index) => <BookingLoadingSkeleton key={index} />)}
                {!this.props.loading &&
                    (this.props.accommodation?.length ? (
                        <>
                            {this.state.currentItems?.map((e, index) => (
                                <SpecialOffersAccommodationItem key={index} accommodation={e} />
                            ))}
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={this.state.pageCount}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                                className="pagination"
                            />
                        </>
                    ) : (
                        <div className="not-available-text">Nema rezultata</div>
                    ))}
            </div>
        );
    }
}

export default SpecialOffersList;
