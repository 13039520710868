import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Transition } from "react-transition-group";
import { connect } from "react-redux";

import "../styles/Destinations.scss";

import { withRouter } from "../helpers/utils/globalUtils";

import DestinationImage from "../static/images/destinations_fake_image.png";

import { getAllRegions } from "../store/actions";

const transitionDuration = 500;
const defaultDestinationItemStyle = {
    transition: `transform ${transitionDuration}ms ease`,
    transform: "scale(1,1)",
};
const transitionDestinationItemStyles = {
    entering: { transform: "scale(1.2,1.2)", zIndex: 1 },
    entered: { transform: "scale(1.2,1.2)", zIndex: 1 },
    exiting: { transform: "scale(1,1)" },
    exited: { transform: "scale(1,1)" },
};

class Destinations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            destinationsOpen: null,
        };
        props.getAllRegions();
    }

    componentDidMount() {
        this.props.getAllRegions();
    }

    hoverDestination = (index) => {
        let destinationsOpen = index === this.state.destinationsOpen ? null : index;
        setTimeout(() => {
            this.setState({
                destinationsOpen,
            });
        }, transitionDuration);
    };

    openDetails = (region) => {
        this.props.router.navigate(`/destination/details/${region}`);
    };

    render() {
        return (
            <Container className="destinations_wrapper font-poppins ">
                <div className="destinations_items_wrapper">
                    {this.props.regions?.map((destination, key) => (
                        <Transition in={this.state.destinationsOpen === key} timeout={transitionDuration} key={key}>
                            {(state) => (
                                <div
                                    className="destinations_item_wrapper"
                                    onMouseOver={() => this.hoverDestination(key)}
                                    onMouseOut={() => this.hoverDestination(key)}
                                    onClick={() => this.openDetails(destination?.slug)}
                                    style={{
                                        backgroundImage: `url('${DestinationImage}')`,
                                        ...defaultDestinationItemStyle,
                                        ...transitionDestinationItemStyles[state],
                                    }}
                                    key={key}
                                >
                                    <div className="overlay"></div>
                                    <div className="destinations_title">{destination.title}</div>
                                </div>
                            )}
                        </Transition>
                    ))}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        regions: state.General.regions,
    };
};

export default connect(mapStateToProps, { getAllRegions })(withRouter(Destinations));
