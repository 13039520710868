// export const NAME_NAME = 'NAME_NAME';
// export const NAME_NAME_SUCCESS = 'NAME_NAME_SUCCESS';
// export const NAME_NAME_ERROR = 'NAME_NAME_ERROR';

export const GET_ALL_REGIONS = "GET_ALL_REGIONS";
export const GET_ALL_REGIONS_SUCCESS = "GET_ALL_REGIONS_SUCCESS";
export const GET_ALL_REGIONS_ERROR = "GET_ALL_REGIONS_ERROR";

export const GET_SINGLE_REGION = "GET_SINGLE_REGION";
export const GET_SINGLE_REGION_SUCCESS = "GET_SINGLE_REGION_SUCCESS";
export const GET_SINGLE_REGION_ERROR = "GET_SINGLE_REGION_ERROR";

export const GET_PROGRAM_LIST = "GET_PROGRAM_LIST";
export const GET_PROGRAM_LIST_SUCCESS = "GET_PROGRAM_LIST_SUCCESS";
export const GET_PROGRAM_LIST_ERROR = "GET_PROGRAM_LIST_ERROR";

export const CONTACT_US = "CONTACT_US";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_ERROR = "CONTACT_US_ERROR";

export const SUBSCRIBE_NEWSLETTERS = "SUBSCRIBE_NEWSLETTERS";
export const SUBSCRIBE_NEWSLETTERS_SUCCESS = "SUBSCRIBE_NEWSLETTERS_SUCCESS";
export const SUBSCRIBE_NEWSLETTERS_ERROR = "SUBSCRIBE_NEWSLETTERS_ERROR";

export const GET_EXCHANGE_RATE = "GET_EXCHANGE_RATE";
export const GET_EXCHANGE_RATE_SUCCESS = "GET_EXCHANGE_RATE_SUCCESS";
export const GET_EXCHANGE_RATE_ERROR = "GET_EXCHANGE_RATE_ERROR";
