import axios from "axios";

export const postRegisterUser = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/register`, data).catch((error) => {
        return error;
    });
};

export const postRegisterAgent = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/register_agent`, data).catch((error) => {
        return error;
    });
};

export const login = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, data, { withCredentials: true }).catch((error) => {
        return error;
    });
};

export const logout = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/logout`, { withCredentials: true }).catch((error) => {
        return error;
    });
};

export const forgotPassword = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/forgotpassword`, data).catch((error) => {
        return error;
    });
};

export const changePassword = async (data) => {
    let endpoint = "changepassword";
    if (data?.token && data?.email) {
        endpoint = `changepass?token=${data?.token}&email=${data?.email}`;
    }

    return await axios.post(`${process.env.REACT_APP_API_URL}/api/${endpoint}`, data).catch((error) => {
        return error;
    });
};
