import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AboutCountryInformations from "../../Reusable/AboutCountryInformations";
import Gallery from "../../Reusable/Gallery";

import AboutGreeceImage1 from "../../../static/images/About_Country/About_Greece/People/about_greece_people_2.png";
import AboutGreeceImage2 from "../../../static/images/About_Country/About_Greece/People/about_greece_people_3.png";

class AboutGreecePeople extends Component {
    render() {
        return (
            <div className="about_greece_history">
                <AboutCountryInformations
                    image={require("../../../static/images/About_Country/About_Greece/People/about_greece_people_1.png")}
                    title={"Grci i Grkinje"}
                    description={`Mnogi imaju tendenciju da posete zemlju-kolevku evropske civilizacije. Zemlja heroja i bogova gde se može osetiti dubina umetnosti antičke Grčke. To ima veze prvenstveno sa prirodom karaktera modernog Grka-koji se ne razlikuje baš mnogo od svog pretka. Kakvi su oni zapravo? Evropljani sa filozofskim stavom o životu, muškarci koji bezrezervno ispoljavaju nežnost prema lepšem polu i samim tim zauzimaju titulu "evropskih šampiona"`}
                />
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="description">
                                Naravno, posebno mesto u Grkoj tradiciji zauzimaju masline. One predstavljaju mnogo više od obične namirnice. One su hrana i lek
                                a nekada su na Mediteranu bile smatrane svetim drvetom. Skoro sva hrana se priprema na maslinovom ulju. Što se ostalog voća tiče
                                (a masline su zaista voće) Grci su poznati po uzgajanju lubenice, grožđa, breskvi. Glavni deo mesne ishrane je riba. Jagnjetina
                                i piletina kao i suvlaki su čest izbor dok se svinjetnina i govedina zaista retko konzumiraju. Takođe, supu nećete često naći na
                                meniju (efekat vruće klime). Grci vole ražnjiće: meso mekano, marinirano i začinjeno začinskim travama, maslinovim uljem, lukom,
                                limunovim sokom, biberom i solju. Kao i u svakoj primorskoj državi, popularna su i jela od morskih plodova ili "talassina":
                                lignje, hobotnice, rakovi, školjke i raznovrsna riba koja se služi sa sosem od maslinovog ulja i limunovog soka.
                            </div>
                        </div>
                        <Row className="content_wrapper">
                            <Col lg={6}>
                                <div className="description">
                                    Sir se u staroj grčkoj smatrao hranom Bogova a današnji Grci zauzimaju drugo mesto u Evropi po potrošnji sireva (iza
                                    Francuza naravno). Postoji nekoliko sireva karakterističnih za Grčku: Feta, polutvrd, beli sir koji se pravi od kravljeg
                                    mleka, Kasseri-tvrdi, žuti sir, Mizithra-mek, beli sir koji se pravi od kozijeg ili ovčijeg mleka. Popularna grčka salata je
                                    nezamisliva bez Feta sira! To je ujedno i najpoznatiji grčki proizvod izvan granica Grčke
                                </div>
                                <img src={AboutGreeceImage1} alt="Greece people 1" />
                            </Col>
                            <Col lg={6}>
                                <img src={AboutGreeceImage2} alt="Greece people 2" />
                            </Col>
                        </Row>
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <Container>
                    <Gallery />
                </Container>
            </div>
        );
    }
}

export default AboutGreecePeople;
