import React, { Component } from "react";
import { Container } from "react-bootstrap";

import AboutCountryInformations from "../Reusable/AboutCountryInformations";

class AboutMontenegro extends Component {
    render() {
        return (
            <div className="about_greece_general_informations">
                <AboutCountryInformations
                    image={require("../../static/images/About_Country/general_info_montenegro.png")}
                    title={"ОPŠTE INFORMACIJE"}
                    description={`Crna Gora je centar modernog eko-turizma na zapadu Balkanskog poluostrva. Ovo je najmanja slavenska država, ali u pogledu atraktivnosti i uslova rekreacije ne ustručava se da bude veća. Crna Gora je netaknuta priroda, ima moderna odmarališta, opremljene plaže i komplekse na obali, uključujući domaću kuhinju, čisto more, drevnu arhitekturu i razvijenu infrastrukturu.`}
                />
                <Container>
                    <ul className="country_unorder_list">
                        <li>Glavni grad države: Podgorica</li>
                        <li>Službeni jezik: crnogorski, srpski</li>
                        <li>Valuta: evro</li>
                        <li>Klima: na severu Crne Gore - umereno kontinentalna, na obali Jadrana - mediteranska</li>
                        <li>Stanovništvo: 622.182</li>
                        <li>Državni sistem: parlamentarna republika</li>
                        <li>Pozivni broj: +382</li>
                    </ul>
                </Container>
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="title">ZNAMENITOSTI</div>
                            <ul className="country_unorder_list">
                                <li>
                                    Ostrvo Svetog Stefana - na njemu se nalazi čuveni manastir Ostrog - vizit karta zemlje. Zgrada je uklesana u stenu. Spolja
                                    može izgledati da manastir bukvalno visi u vazduhu na nadmorskoj visini od 1 km. Ostrog ne posećuju samo pravoslavci, već i
                                    katolici i muslimani.
                                </li>
                                <li>Bokokotorski zaliv i osmatračnica</li>
                                <li>Drevni gradovi Budva, Cetinje (ranije glavni grad zemlje), Herceg Novi i Ulcinj</li>
                                <li>Skadarsko jezero je najveće i najčistije na Balkanu, dom je retkih vrsta ribe, šarana i pelikana.</li>
                                <li>Drevni manastiri XIV-XV veka smešteni su na malim ostrvima duž obale.</li>
                                <li>Mauzolej crnogorskog vladara i prosvetitelja u 19. veku, Petra Njegoša, nalazi se na vrhu Lovrene.</li>
                                <li>
                                    Nacionalni parkovi su ponos i biser zemlje. Jedno od najpoznatijih jezera je Crno jezero, prelepo u bilo koje doba godine,
                                    nalazi se u nacionalnom parku Durmitor.
                                </li>
                                <li>Kanjon reke Tare je drugi najduži na svetu.</li>
                                <li>
                                    Naselje u gradu Herceg Novom jedno je od najpoznatijih. Osnovan je u blizini Kotorskog zaliva pre više od 1500 godina i
                                    savršeno je sačuvao svoje istorijsko nasleđe: srednjovekovne arhitektonske spomenike, stare stambene zgrade, manastire
                                    (Savina 1030), tvrđave i bedemi
                                </li>
                            </ul>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Praznici</div>
                            <div className="description">
                                Crnogorci imaju mnogo praznika. Većina festivala se održava tokom leta. Krajem jula možete posetiti festival borovnica koji
                                traje nedelju dana i održava se u Plavu. Početkom avgusta u Tivtu se održava Sajam maslina, a krajem meseca možete uživati u
                                ribama i domaćim vinima u Petrovcu. Malo severnije, u mestu Berane, možete posetiti Festival meda, a u selu Njeguši možete
                                probati sir, pršut i ostale lokalne specijalitete. Leti u Crnoj Gori možete doći na Narodne izložbe, izložbu informacionih
                                tehnologija, Ribarski dan, muzičke festivale i druge nezaboravne događaje.
                            </div>
                        </div>
                    </Container>
                    <div className="fake_background"></div>
                </div>
            </div>
        );
    }
}

export default AboutMontenegro;
