import React, { Component } from "react";
import { Transition } from "react-transition-group";
import { Link } from "react-router-dom";

import "../../styles/Components/MenuDropdownList.scss";

const transitionDuration = 300;

const defaultOffersStyle = {
    transition: `max-height ${400}ms ease-in-out`,
    maxHeight: 0,
};
const defaultSidebarStyle = {
    transition: `opacity ${400}ms ease-in-out`,
    opacity: 0,
};
const transitionOffersStyles = {
    entering: { maxHeight: 500 },
    entered: { maxHeight: 500 },
    exiting: { maxHeight: 0 },
    exited: { maxHeight: 0, height: 0, zIndex: -1 },
};
const transitionOffersTextStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0, height: 0, marginBottom: 0, zIndex: -1 },
};

class MenuDropdownList extends Component {
    render() {
        return (
            <Transition in={this.props.isIn} timeout={transitionDuration}>
                {(state) => (
                    <div
                        className={`menu-country-list-offer ${this.props.className ?? ""}`}
                        style={{
                            ...defaultOffersStyle,
                            ...transitionOffersStyles[state],
                        }}
                    >
                        <div className="menu-country-list-offer-gradient" style={{ ...defaultSidebarStyle, ...transitionOffersTextStyles[state] }}></div>
                        <div className="menu-country-list-offers" style={{ ...defaultSidebarStyle, ...transitionOffersTextStyles[state] }}>
                            {this.props.offers?.map((i, index) => (
                                <Link
                                    to={i?.link ?? "/"}
                                    className="menu-country-list-offers-item font-poppins"
                                    style={{ ...defaultSidebarStyle, ...transitionOffersTextStyles[state] }}
                                    key={String(index)}
                                >
                                    <div
                                        className="menu-country-list-offers-item-text"
                                        style={{ ...defaultSidebarStyle, ...transitionOffersTextStyles[state] }}
                                    >
                                        {i?.label}
                                    </div>
                                    {index < this.props.offers?.length - 1 && (
                                        <div
                                            className="menu-country-list-offers-item-separator"
                                            style={{ ...defaultSidebarStyle, ...transitionOffersTextStyles[state] }}
                                        ></div>
                                    )}
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
            </Transition>
        );
    }
}

export default MenuDropdownList;
