const dataURItoBlob = (dataURI) => {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
};

export const openPDFdocument = (data) => {
    // data should be your response data in base64 format

    const blob = dataURItoBlob(data);
    const url = URL.createObjectURL(blob);

    // to open the PDF in a new window
    window.open(url, "_blank");
};
