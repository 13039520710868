import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { GET_CUSTOM_PAGE } from "./actionTypes";
import { getCustomPageSuccess, getCustomPageError } from "./actions";

import { getCustomPage } from "../../helpers/api/custom-page";

function* getCustomPageSaga({ payload: { slug, history } }) {
    try {
        const response = yield call(getCustomPage, slug);
        if (response && response.data.status === 200) {
            yield put(getCustomPageSuccess(response.data.data));
        } else {
            history("/404");
            yield put(getCustomPageError(response.error));
        }
    } catch (error) {
        history("/404");
        yield put(getCustomPageError(error));
    }
}

export function* watchGetCustomPageSaga() {
    yield takeEvery(GET_CUSTOM_PAGE, getCustomPageSaga);
}

function* customPageSaga() {
    yield all([fork(watchGetCustomPageSaga)]);
}

export default customPageSaga;
