import React, { Component } from "react";
import { Link } from "react-router-dom";

import LoggedUser from "../../LoggedUser";

import login from "../../../static/images/icons/login_icon.png";

class LoginMobile extends Component {
    render() {
        if (this.props.isLogged) {
            return <LoggedUser userId={this.props.userId} userName={this.props.userFirstName} userType={this.props.userType} />;
        }

        return (
            <Link to="/login">
                <img src={login} alt="Logout icon" />
            </Link>
        );
    }
}

export default LoginMobile;
