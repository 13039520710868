import { GET_CUSTOM_PAGE, GET_CUSTOM_PAGE_SUCCESS, GET_CUSTOM_PAGE_ERROR } from "./actionTypes";

const initialState = {
    // variable: value
    data: undefined,
    loading: false,
    error: undefined,
};

const CustomPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOM_PAGE:
            state = { ...state, loading: true };
            break;
        case GET_CUSTOM_PAGE_SUCCESS:
            state = { ...state, loading: false, data: action.payload };
            break;
        case GET_CUSTOM_PAGE_ERROR:
            state = { ...state, loading: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default CustomPage;
