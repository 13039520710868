import React, { Component } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { format } from "date-fns";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";

import "../styles/Reservation.scss";

import { cities } from "../static/data/cities";
import { phoneRegExp, withRouter } from "../helpers/utils/globalUtils";
import { checkFavoritesHotels, editFavoritesHotels } from "../helpers/utils/favoritesUtils";

import unlimited_logo from "../static/images/unlimited_travel_logo.png";
import people_icon from "../static/images/Reservation/people_icon.png";
import check_in_icon from "../static/images/Reservation/check_in_icon.png";
import check_out_icon from "../static/images/Reservation/check_out_icon.png";
import bed_icon from "../static/images/Reservation/bed_icon.png";
import price_icon from "../static/images/Reservation/price_icon.png";
import palm_1_icon from "../static/images/Reservation/palm_1.png";
import palm_2_icon from "../static/images/Reservation/palm_2.png";
import reservation_summer_successful from "../static/images/Reservation/reservation_summer_successful.png";
import reservation_winter_successful from "../static/images/Reservation/reservation_winter_successful.png";
import reservation_autumn_successful from "../static/images/Reservation/reservation_autumn_successful.png";
import reservation_spring_successful from "../static/images/Reservation/reservation_spring_successful.png";
import wave_icon from "../static/images/Reservation/reservation_wave.png";

import FullHeartIcon from "../static/images/icons/heart_full_icon.png";
import NotFullHeartIcon from "../static/images/icons/heart_not_full_icon.png";

import { makeReservation, reset } from "../store/actions";

const schema = yup.object().shape({
    users_data: yup.array().of(
        yup.object().shape({
            name: yup.string().required("Polje mora biti popunjeno"),
            surname: yup.string().required("Polje mora biti popunjeno"),
            passport: yup.string().required("Polje mora biti popunjeno"),
        }),
    ),
    children_data: yup.array().of(
        yup.object().shape({
            name: yup.string().required("Polje mora biti popunjeno"),
            surname: yup.string().required("Polje mora biti popunjeno"),
            age: yup.number().required("Polje mora biti popunjeno"),
        }),
    ),
    phone: yup.string().matches(phoneRegExp, "Broj telefona nije validan").required("Polje mora biti popunjeno"),
    email: yup.string().email("E-mail adresa nije validna").required("Polje mora biti popunjeno"),
    city: yup.string().required("Polje mora biti popunjeno"),
    address: yup.string().required("Polje mora biti popunjeno"),
    zip: yup.string().required("Polje mora biti popunjeno"),
    terms_privacy: yup.boolean().oneOf([true], "Polje mora biti označeno"),
});

class Reservation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            roomData: {},
        };
    }

    componentDidMount() {
        this.readSearchData();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.router?.location?.state) !== JSON.stringify(prevProps.router?.location?.state)) {
            this.readSearchData();
        }
    }

    readSearchData = () => {
        if (this.props.router?.location?.state) {
            this.setState({ roomData: this.props.router?.location?.state }, () => {
                const hotelInfo = {
                    address: this.state.roomData?.address,
                    id: this.state.roomData?.hotelId,
                    destId: this.state.roomData?.destId,
                    name: this.state.roomData?.hotelName,
                    photo: this.state.roomData?.photo,
                    rating: this.state.roomData?.hotelRatings,
                };
                this.setState({ isFavorite: checkFavoritesHotels(hotelInfo) });
            });
        } else {
            this.props.router.navigate("/");
        }
    };

    getSeasonImage = () => {
        let month = new Date().getMonth() + 1;

        if (month >= 3 && month <= 5) {
            return reservation_spring_successful;
        } else if (month >= 6 && month <= 8) {
            return reservation_summer_successful;
        } else if (month >= 9 && month <= 11) {
            return reservation_autumn_successful;
        }

        return reservation_winter_successful;
    };

    editFavoritesHotels = () => {
        const hotelInfo = {
            address: this.state.roomData?.address,
            destId: this.state.roomData?.destId,
            id: this.state.roomData?.hotelId,
            name: this.state.roomData?.hotelName,
            photo: this.state.roomData?.photo,
            rating: this.state.roomData?.hotelRatings,
        };
        editFavoritesHotels(hotelInfo);
        this.setState({ isFavorite: checkFavoritesHotels(hotelInfo) });
    };

    render() {
        const seasonImage = this.getSeasonImage();
        return (
            <>
                <Modal
                    show={Boolean(this.props.reservationStatus)}
                    onHide={() => this.props.reset()}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="reservation_successful_wrapper"
                >
                    <Modal.Body className="reservation_successful_body font-poppins">
                        <Row className="reservation_successful_body_row">
                            <Col lg={6}>
                                <img src={unlimited_logo} alt="Modal logo" />
                                <div className="title">{this.props.reservationStatus?.title}</div>
                                <div className="description">{this.props.reservationStatus?.description}</div>
                                <div className="back_to_home_button">Početna</div>
                            </Col>
                            <Col lg={6}>
                                <img src={seasonImage} alt="Reservation successful" className="w-100" />
                            </Col>
                        </Row>

                        <img src={wave_icon} alt="Reservation wave icon" id="wave_icon" />
                    </Modal.Body>
                </Modal>
                <Container className="reservation_wrapper font-poppins">
                    <div className="reservation_general_informations">
                        <Row>
                            <Col lg={6}>
                                <div className="hotel_name_stars">
                                    <div className="hotel_name_favorite">
                                        <img
                                            src={this.state.isFavorite ? FullHeartIcon : NotFullHeartIcon}
                                            alt="Favorite icon"
                                            width={25}
                                            height={23}
                                            onClick={() => this.editFavoritesHotels()}
                                        />

                                        <div className="hotel_name">{this.state.roomData?.hotelName}</div>
                                    </div>
                                    <div className="hotel_stars">
                                        {Array.from({ length: parseInt(this.state.roomData?.hotelRatings) }, (v) => v)?.map((value, key) => (
                                            <span className="fa fa-star checked" key={key}></span>
                                        ))}
                                    </div>
                                </div>
                                <div className="information_item">
                                    <img src={people_icon} alt="People number icon" />
                                    {`Odrasli: ${this.state.roomData?.adults}${
                                        this.state.roomData?.children ? `, Deca: ${this.state.roomData?.children}` : ""
                                    }`}
                                </div>
                                {this.state.roomData?.checkIn ? (
                                    <div className="information_item">
                                        <img src={check_in_icon} alt="Check in date icon" />
                                        {`Check in: ${format(new Date(this.state.roomData?.checkIn), "dd/MM/yyyy")}`}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {this.state.roomData?.checkOut ? (
                                    <div className="information_item">
                                        <img src={check_out_icon} alt="Check out date icon" />
                                        {`Check out: ${format(new Date(this.state.roomData?.checkOut), "dd/MM/yyyy")}`}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className="information_item">
                                    <img src={bed_icon} alt="Bed icon" />
                                    {`${this.state.roomData?.roomName ?? ""} - `}
                                    <span>{`${this.state.roomData?.finalPrice} €`}</span>
                                </div>
                                <div className="information_item">
                                    <img src={price_icon} alt="Price icon" />
                                    {`Final price: `}
                                    <span className="price_text">{this.state.roomData?.finalPrice} €</span>
                                </div>
                            </Col>
                            <Col lg={6} className="hotel_generale_image">
                                <img src={this.state.roomData?.photo} alt="Hotel generale" />
                            </Col>
                        </Row>
                    </div>
                    <div className="reservation_form">
                        <Formik
                            validationSchema={schema}
                            enableReinitialize
                            initialValues={{
                                users_data: Array.from({ length: this.state.roomData?.adults ? parseInt(this.state.roomData?.adults) : 2 }, () => {
                                    return { name: "", surname: "", passport: "" };
                                }),
                                children_data: Array.from({ length: this.state.roomData?.children ? parseInt(this.state.roomData?.children) : 0 }, (v, k) => {
                                    return { name: "", surname: "", age: this.state.roomData?.childrenAges[k] };
                                }),
                                phone: "",
                                email: "",
                                address: "",
                                city: "",
                                zip: "",
                                user_comment: "",
                                terms_privacy: false,
                            }}
                            onSubmit={(values, actions) => {
                                this.props.makeReservation({
                                    hotel_id: this.state.roomData?.hotelId,
                                    check_in: this.state.roomData?.checkIn,
                                    check_out: this.state.roomData?.checkOut,
                                    guests_info: [...values.users_data, ...values.children_data],
                                    room_id: this.state.roomData?.roomId,
                                    meal_plan: this.state.roomData?.mealPlan,
                                    transport: this.state.roomData?.transport,
                                    phone: values.phone,
                                    email: values.email,
                                    city: values.city,
                                    address: values.address,
                                    zip: values.zip,
                                    country: "Serbia",
                                    comment: values.user_comment,
                                });
                                actions.resetForm();
                            }}
                        >
                            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                                <Form noValidate className="reservation_form_items" onSubmit={handleSubmit}>
                                    <div className="title">Informacije o rezervaciji</div> <div className="subtitle">Informacije o rezervaciji</div>
                                    <FieldArray name="users_data">
                                        {() =>
                                            values.users_data &&
                                            values.users_data?.map((item, key) => (
                                                <div key={key}>
                                                    <div className="mb-3 main_row_wrapper">
                                                        <span className="user_serial_number">{`${key + 1}.`}</span>
                                                        <Row className="w-100" style={{ rowGap: 10 }}>
                                                            <Form.Group as={Col} lg="4">
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    placeholder="Ime"
                                                                    name={`users_data[${key}].name`}
                                                                    value={item?.name ?? ""}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={
                                                                        touched.users_data?.length &&
                                                                        touched.users_data[key] &&
                                                                        touched.users_data[key]?.name &&
                                                                        errors.users_data?.length &&
                                                                        errors.users_data[key] &&
                                                                        errors.users_data[key]?.name
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.users_data?.length && errors.users_data[key]?.name}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} lg="4">
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    placeholder="Prezime"
                                                                    name={`users_data[${key}].surname`}
                                                                    value={item?.surname ?? ""}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={
                                                                        touched.users_data?.length &&
                                                                        touched.users_data[key] &&
                                                                        touched.users_data[key]?.surname &&
                                                                        errors.users_data?.length &&
                                                                        errors.users_data[key] &&
                                                                        errors.users_data[key]?.surname
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.users_data?.length && errors.users_data[key]?.surname}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} lg="4">
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    placeholder="Broj pasoša"
                                                                    name={`users_data[${key}].passport`}
                                                                    value={item?.passport ?? ""}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={
                                                                        touched.users_data?.length &&
                                                                        touched.users_data[key] &&
                                                                        touched.users_data[key]?.passport &&
                                                                        errors.users_data?.length &&
                                                                        errors.users_data[key] &&
                                                                        errors.users_data[key]?.passport
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.users_data?.length && errors.users_data[key]?.passport}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Row>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </FieldArray>
                                    {values.children_data?.length > 0 && <div className="subtitle">Deca</div>}
                                    <FieldArray name="children_data">
                                        {() =>
                                            values.children_data &&
                                            values.children_data?.map((item, key) => (
                                                <div key={key}>
                                                    <div className="mb-3 main_row_wrapper">
                                                        <span className="user_serial_number">{`${key + 1}.`}</span>
                                                        <Row className="w-100">
                                                            <Form.Group as={Col} lg="4">
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    placeholder="Ime"
                                                                    name={`children_data[${key}].name`}
                                                                    value={item?.name ?? ""}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={
                                                                        touched.children_data?.length &&
                                                                        touched.children_data[key] &&
                                                                        touched.children_data[key]?.name &&
                                                                        errors.children_data?.length &&
                                                                        errors.children_data[key] &&
                                                                        errors.children_data[key]?.name
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.children_data?.length && errors.children_data[key]?.name}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} lg="4">
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    placeholder="Prezime"
                                                                    name={`children_data[${key}].surname`}
                                                                    value={item?.surname ?? ""}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={
                                                                        touched.children_data?.length &&
                                                                        touched.children_data[key] &&
                                                                        touched.children_data[key]?.surname &&
                                                                        errors.children_data?.length &&
                                                                        errors.children_data[key] &&
                                                                        errors.children_data[key]?.surname
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.children_data?.length && errors.children_data[key]?.surname}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} lg="4">
                                                                <Form.Control
                                                                    required
                                                                    type="number"
                                                                    placeholder="Godine"
                                                                    name={`children_data[${key}].age`}
                                                                    value={item?.age ?? ""}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={
                                                                        touched.children_data?.length &&
                                                                        touched.children_data[key] &&
                                                                        touched.children_data[key]?.age &&
                                                                        errors.children_data?.length &&
                                                                        errors.children_data[key] &&
                                                                        errors.children_data[key]?.age
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.children_data?.length && errors.children_data[key]?.age}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Row>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </FieldArray>
                                    <div className="subtitle">Kontakt infomacije</div>
                                    <Row className="main_row_wrapper row_contact_wrapper mb-3">
                                        <Form.Group as={Col} lg="6">
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Mobilni"
                                                name={`phone`}
                                                value={values?.phone ?? ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.phone && errors.phone}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} lg="6">
                                            <Form.Control
                                                required
                                                type="email"
                                                placeholder="E-mail"
                                                name={`email`}
                                                value={values?.email ?? ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.email && errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} lg="6">
                                            <Form.Select
                                                name={`city`}
                                                value={values?.city ?? ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.city && errors.city}
                                            >
                                                <option hidden>Grad</option>
                                                {cities.map((city, key) => (
                                                    <option value={`${city}`} key={key}>
                                                        {city}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} lg="6">
                                            <Form.Control
                                                type="text"
                                                placeholder="Adresa"
                                                name={`address`}
                                                value={values?.address ?? ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.address && errors.address}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} lg="6">
                                            <Form.Control
                                                type="text"
                                                placeholder="Zip"
                                                name={`zip`}
                                                value={values?.zip ?? ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.zip && errors.zip}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.zip}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <div className="subtitle">Napomene</div>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Vaš komentar"
                                        className="main_row_wrapper comment_textarea mb-3"
                                        name="user_comment"
                                        value={values.user_comment}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <Form.Group className="mb-3">
                                        <Form.Check
                                            required
                                            label="Agree to terms and conditions *"
                                            name="terms_privacy"
                                            id="terms_privacy"
                                            style={{ color: "#166FA9" }}
                                            value={values.terms_privacy}
                                            onChange={handleChange}
                                            isInvalid={touched.terms_privacy && errors.terms_privacy}
                                        />
                                    </Form.Group>
                                    <Button className="reservation_button" type="submit">
                                        Rezerviši
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                        <img src={palm_1_icon} id="palm_tree_1" alt="Palm tree icon 1" />
                        <img src={palm_2_icon} id="palm_tree_2" alt="Palm tree icon 2" />
                    </div>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        success: state.Booking.success,
        reservationStatus: state.Booking.reservationStatus,
    };
};

export default connect(mapStateToProps, { makeReservation, reset })(withRouter(Reservation));
