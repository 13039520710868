// export const NAME_NAME = 'NAME_NAME';
// export const NAME_NAME_SUCCESS = 'NAME_NAME_SUCCESS';
// export const NAME_NAME_ERROR = 'NAME_NAME_ERROR';

export const GET_BLOG = "GET_BLOG";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const GET_BLOG_ERROR = "GET_BLOG_ERROR";

export const GET_SINGLE_BLOG = "GET_SINGLE_BLOG";
export const GET_SINGLE_BLOG_SUCCESS = "GET_SINGLE_BLOG_SUCCESS";
export const GET_SINGLE_BLOG_ERROR = "GET_SINGLE_BLOG_ERROR";
