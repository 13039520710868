// export const NAME_NAME = 'NAME_NAME';
// export const NAME_NAME_SUCCESS = 'NAME_NAME_SUCCESS';
// export const NAME_NAME_ERROR = 'NAME_NAME_ERROR';

export const GET_DESTINATIONS = "GET_DESTINATIONS";
export const GET_DESTINATIONS_SUCCESS = "GET_DESTINATIONS_SUCCESS";
export const GET_DESTINATIONS_ERROR = "GET_DESTINATIONS_ERROR";

export const SEARCH_ACCOMMODATION = "SEARCH_ACCOMMODATION";
export const SEARCH_ACCOMMODATION_SUCCESS = "SEARCH_ACCOMMODATION_SUCCESS";
export const SEARCH_ACCOMMODATION_ERROR = "SEARCH_ACCOMMODATION_ERROR";

export const GET_ABOUT_HOTEL = "GET_ABOUT_HOTEL";
export const GET_ABOUT_HOTEL_SUCCESS = "GET_ABOUT_HOTEL_SUCCESS";
export const GET_ABOUT_HOTEL_ERROR = "GET_ABOUT_HOTEL_ERROR";

export const SORT_ACCOMMODATION = "SORT_ACCOMMODATION";

export const MAKE_RESERVATION = "MAKE_RESERVATION";
export const MAKE_RESERVATION_SUCCESS = "MAKE_RESERVATION_SUCCESS";
export const MAKE_RESERVATION_ERROR = "MAKE_RESERVATION_ERROR";

export const MY_RESERVATIONS = "MY_RESERVATIONS";
export const MY_RESERVATIONS_SUCCESS = "MY_RESERVATIONS_SUCCESS";
export const MY_RESERVATIONS_ERROR = "MY_RESERVATIONS_ERROR";

export const CANCEL_RESERVATION = "CANCEL_RESERVATION";
export const CANCEL_RESERVATION_SUCCESS = "CANCEL_RESERVATION_SUCCESS";
export const CANCEL_RESERVATION_ERROR = "CANCEL_RESERVATION_ERROR";

export const SEARCH_RESERVATIONS = "SEARCH_RESERVATIONS";

export const RESET = "RESET";
