import axios from "axios";

export const getAllRegions = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/getAllRegion`).catch((error) => {
        return error;
    });
};

export const getSingleRegion = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/region/${data}`).catch((error) => {
        return error;
    });
};

export const getProgramList = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/program_list`).catch((error) => {
        return error;
    });
};

export const subscribeNewsletters = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/subscribe`, data).catch((error) => {
        return error;
    });
};

export const contactUs = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, data).catch((error) => {
        return error;
    });
};

export const getExchangeRate = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/getExchangeRate`).catch((error) => {
        return error;
    });
};
