import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { POST_REGISTRATION_USER, POST_REGISTRATION_AGENT, LOGIN, LOGOUT, FORGOT_PASSWORD, CHANGE_PASSWORD } from "./actionTypes";
import {
    postRegistrationUserSuccess,
    postRegistrationUserError,
    postRegistrationAgentSuccess,
    postRegistrationAgentError,
    loginSuccess,
    loginError,
    logoutSuccess,
    logoutError,
    forgotPasswordSuccess,
    forgotPasswordError,
    changePasswordSuccess,
    changePasswordError,
} from "./actions";

import { postRegisterUser, postRegisterAgent, login, logout, forgotPassword, changePassword } from "../../helpers/api/auth";

function* postRegisterUserSaga({ payload }) {
    try {
        const response = yield call(postRegisterUser, payload);
        if (response && response.data.status === 201) {
            yield put(postRegistrationUserSuccess("Uspešno ste se registrovali"));
        } else {
            yield put(postRegistrationUserError("Nažalost niste uspeli da se registrujete"));
        }
    } catch (error) {
        yield put(postRegistrationUserError("Nažalost niste uspeli da se registrujete"));
    }
}

function* postRegisterAgentSaga({ payload }) {
    try {
        const response = yield call(postRegisterAgent, payload);
        if (response && response.data.status === 201) {
            yield put(postRegistrationAgentSuccess("Uspešno ste se registrovali"));
        } else {
            yield put(postRegistrationAgentError("Nažalost niste uspeli da se registrujete"));
        }
    } catch (error) {
        yield put(postRegistrationAgentError("Nažalost niste uspeli da se registrujete"));
    }
}

function* loginSaga({ payload: { values, navigate } }) {
    try {
        const response = yield call(login, values);
        if (response && response.data.status === 200) {
            navigate("/my-profile");
            yield put(loginSuccess(response.data.message));
        } else {
            yield put(loginError("Email ili lozinka nisu odgovarajući"));
        }
    } catch (error) {
        yield put(loginError("Email ili lozinka nisu odgovarajući"));
    }
}

function* logoutSaga({ payload: { navigate } }) {
    try {
        const response = yield call(logout);
        if (response && response.status === 200) {
            navigate("/");
            yield put(logoutSuccess(response.data.message));
        } else {
            yield put(logoutError(response.error));
        }
    } catch (error) {
        yield put(logoutError(error));
    }
}

function* forgotPasswordSaga({ payload }) {
    try {
        const response = yield call(forgotPassword, payload);
        if (response && response.status === 200) {
            yield put(forgotPasswordSuccess("Lozinku možete restovati sa linkom kojim Vam je stigao na email adresu"));
        } else {
            yield put(forgotPasswordError("Ovaj korisnik nije registrovan"));
        }
    } catch (error) {
        yield put(forgotPasswordError(error));
    }
}

function* changePasswordSaga({ payload }) {
    try {
        const response = yield call(changePassword, payload);
        if (response && response.status === 200) {
            yield put(changePasswordSuccess("Uspešno ste promenili lozinku"));
        } else {
            yield put(changePasswordError("Nažalost niste uspeli da promenite lozinku"));
        }
    } catch (error) {
        yield put(changePasswordError(error));
    }
}

export function* watchPostRegisterUserSaga() {
    yield takeEvery(POST_REGISTRATION_USER, postRegisterUserSaga);
}

export function* watchPostRegisterAgentSaga() {
    yield takeEvery(POST_REGISTRATION_AGENT, postRegisterAgentSaga);
}

export function* watchLoginSaga() {
    yield takeEvery(LOGIN, loginSaga);
}

export function* watchLogoutSaga() {
    yield takeEvery(LOGOUT, logoutSaga);
}

export function* watchForgotPasswordSaga() {
    yield takeEvery(FORGOT_PASSWORD, forgotPasswordSaga);
}

export function* watchChangePasswordSaga() {
    yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
}

function* authSaga() {
    yield all([
        fork(watchPostRegisterUserSaga),
        fork(watchPostRegisterAgentSaga),
        fork(watchLoginSaga),
        fork(watchLogoutSaga),
        fork(watchForgotPasswordSaga),
        fork(watchChangePasswordSaga),
    ]);
}

export default authSaga;
