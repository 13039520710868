import React, { Component } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import ExternalLinkIcon from "../../static/images/icons/external_link_icon.png";

class AboutCountryFilters extends Component {
    render() {
        return (
            <Container className="about_country_filters_wrapper">
                <Row className="about_country_row">
                    {this.props.data &&
                        Object.entries(this.props.data).map(([k, value], i) => {
                            if (value.link) {
                                return (
                                    <Col className={`item item_link`} lg={3} key={i}>
                                        <Link to={value.link}>
                                            <div>
                                                {value.title}
                                                <img src={ExternalLinkIcon} alt={`External link ${i}`} />
                                            </div>
                                        </Link>
                                    </Col>
                                );
                            }
                            return (
                                <Col
                                    className={`item`}
                                    lg={3}
                                    onClick={() => {
                                        this.props.onSetSelectedFilter(k);
                                    }}
                                    key={i}
                                >
                                    <div className={`${this.props.selectedFilter === k ? "active" : ""}`}>{value.title}</div>
                                </Col>
                            );
                        })}
                </Row>
            </Container>
        );
    }
}

export default AboutCountryFilters;
