import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";

import "../../styles/Mobile/Booking.scss";

import SearchBar from "../Reusable/SearchBar";
import BookingQuickFilters from "../BookingQuickFilters";
import BookingList from "../Reusable/BookingList";
import Spinner from "../../components/Loading/Spinner";

class BookingMobile extends Component {
    render() {
        return (
            <Container fluid className="booking_main_wrapper_mobile font-poppins">
                <Container className="booking_inner_wrapper">
                    {/* prettier-ignore */}
                    <SearchBar width={100} className="search_bar_external_class" route={this.props.route}
                        filters={this.props.filters}
                        changeFilters={this.props.changeFilters} />
                    <BookingQuickFilters
                        activeTypeFilters={this.props.activeTypeFilters}
                        changeActiveFilters={this.props.changeActiveFilters}
                        className="bookings_filter_desktop"
                        filters={this.props.filters}
                        changeFilters={this.props.changeFilters}
                        deleteFilters={this.props.deleteFilters}
                        searchByFilters={this.props.searchByFilters}
                    />

                    <div className="listings_area">
                        <div className="bookings_items">
                            <BookingList
                                loading={this.props.loading}
                                destination={this.props.filters?.selectedPlace}
                                params={new URLSearchParams(this.props.filters).toString()}
                            />
                        </div>
                    </div>
                    {this.props.loading && <Spinner destinationName={this.props.destinationName} filters={this.props.filters} />}
                </Container>
            </Container>
        );
    }
}

BookingMobile.propTypes = {
    activeTypeFilters: PropTypes.arrayOf(PropTypes.string),
    changeActiveFilters: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        loading: state.Booking.loading || state.Booking.loadingSearch,
    };
};

export default connect(mapStateToProps, {})(BookingMobile);
