import axios from "axios";

export const getBlog = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/getAllBlog`).catch((error) => {
        return error;
    });
};

export const getSingleBlog = async ({ id }) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/getSingleBlog/${id}`).catch((error) => {
        return error;
    });
};
