import React, { Component } from "react";
import { Row, Col, Form, FormCheck } from "react-bootstrap";
import { Transition } from "react-transition-group";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { formatDistanceStrict, differenceInDays } from "date-fns";
import { srLatn } from "date-fns/esm/locale";
import MediaQuery from "react-responsive";
import ReactPaginate from "react-paginate";

import { withRouter } from "../../helpers/utils/globalUtils";

import "../../styles/Components/AboutHotelRooms.scss";
import "../../styles/Components/Pagination.scss";

import { roomType, roomTypeName, roomTypeIcon } from "../../helpers/utils/aboutHotelUtils";

import SearchBar from "./SearchBar";
// import SearchCalendar from "../Reusable/SearchCalendar";
// import SearchPeople from "../Reusable/SearchPeople";

// import calendar_icon from "../../static/images/icons/calendar_icon.png";
// import people_icon from "../../static/images/icons/people_icon.png";
import filter_icon from "../../static/images/icons/filter_icon.png";
// import room_cancelation_icon from "../../static/images/icons/room_cancelation_icon.png";
// import room_gallery_icon from "../../static/images/icons/room_gallery_icon.png";

const transitionDuration = 300;
const defaultStyle = {
    transition: `opacity ${100}ms ease-in-out, height ${transitionDuration}ms ease-in-out`,
    opacity: 0,
};
const transitionStyles = {
    entering: { opacity: 1, height: "auto", paddingTop: 23, marginTop: 30 },
    entered: { opacity: 1, height: "auto", zIndex: 999, paddingTop: 23, marginTop: 30 },
    exiting: { opacity: 0, height: "auto" },
    exited: { opacity: 0, height: 0, zIndex: -1 },
};

class AboutHotelRooms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filtersOpen: false,
            currentNumber: 1,
            filters: {},
            currentItems: null,
            pageCount: 0,
            itemsOffset: 0,
        };
    }

    doPaginate = () => {
        const endOffset = this.state.itemsOffset + 4;
        this.setState({
            currentItems: this.props.rooms?.slice(this.state.itemsOffset, endOffset),
            pageCount: Math.ceil(this.props.rooms?.length / 4),
        });
    };

    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.router.location.search);
        let filters = {};
        for (const [key, value] of queryParams) {
            filters = { ...filters, ...{ [key]: value } };
        }
        this.setState({ filters });
        this.doPaginate();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.itemsOffset !== prevState.itemsOffset || JSON.stringify(this.props.rooms) !== JSON.stringify(prevProps.rooms)) {
            this.doPaginate();
        }
    }

    handlePageClick = (event) => {
        const newOffset = (event.selected * 4) % this.props.rooms?.length;
        this.setState({ itemsOffset: newOffset });
    };

    render() {
        console.log("this.props.filters", this.props.filters);
        return (
            <>
                <div className="about_rooms_availability font-poppins">
                    <div className="title">Dostupnost soba</div>
                    <SearchBar
                        hotelName={this.props.informations?.name}
                        className="search-bar-about-hotel"
                        filters={this.props.filters}
                        changeFilters={this.props.changeFilters}
                        route={`about-hotel/${this.props.filters?.selectedPlace ?? this.props.filters?.destId}/${this.props.informations?.id}`}
                    />
                </div>
                <div className="about_hotel_rooms_header">
                    <Row className="about_hotel_rooms_header_titles">
                        <MediaQuery minWidth={991}>
                            <Col lg={4} className="title">
                                Tip sobe
                            </Col>
                            <Col lg={2} className="title">
                                Plan obroka
                            </Col>
                            <Col lg={2} className="title">
                                Cena po danu
                            </Col>
                            <Col lg={2} className="title">
                                Konačna cena
                            </Col>
                        </MediaQuery>
                        <Col lg={2}>
                            <div
                                className="filter_button"
                                onClick={() => {
                                    this.setState({ filtersOpen: !this.state.filtersOpen });
                                }}
                            >
                                <img src={filter_icon} alt="filter icon" />
                                Filteri
                            </div>
                        </Col>
                    </Row>

                    <Transition in={this.state.filtersOpen} timeout={300}>
                        {(state) => (
                            <Row className="filters_wrapper" style={{ ...defaultStyle, ...transitionStyles[state] }}>
                                <Col xs={6} lg={3} className="filters_item_wrapper">
                                    <div className="filters_title">Meal plan</div>
                                    <div className="filters_list">
                                        <Form>
                                            {Object.entries(roomType)?.map(([key, value], index) => (
                                                <FormCheck
                                                    type={"checkbox"}
                                                    name={`mealPlan`}
                                                    id={`meal_plan_room${index}`}
                                                    label={`${value}`}
                                                    value={`${key}`}
                                                    checked={
                                                        this.props.filters && this.props.filters["mealPlan"]
                                                            ? this.props.filters["mealPlan"]?.includes(key)
                                                            : false
                                                    }
                                                    onChange={() => this.props.changeFilters("mealPlan", key, true)}
                                                    key={index}
                                                />
                                            ))}
                                        </Form>
                                    </div>
                                </Col>
                                <Col xs={6} lg={3} className="filters_item_wrapper">
                                    <div className="filters_title">Cancellation policies</div>
                                    <div className="filters_list">
                                        <Form>
                                            {/* <FormCheck
                                                type={"checkbox"}
                                                name={`cancelation_plan`}
                                                id={`cancelation_free`}
                                                label={`Free cancelation`}
                                                value={`freeCancellation`}
                                                checked={
                                                    this.props.filters && this.props.filters["cancellationPoliciesFilter"]
                                                        ? this.props.filters["cancellationPoliciesFilter"]?.includes("freeCancellation")
                                                        : false
                                                }
                                                onChange={(e) => {
                                                    this.props.changeFilters("cancellationPoliciesFilter", "freeCancellation", true);
                                                }}
                                            /> */}
                                            <FormCheck
                                                type={"checkbox"}
                                                name={`cancelation_plan`}
                                                id={`cancelation_non_refundable`}
                                                label={`Non refundable`}
                                                value={`isRefundable`}
                                                checked={
                                                    this.props.filters && this.props.filters["isRefundable"]
                                                        ? Boolean(this.props.filters["isRefundable"])
                                                        : false
                                                }
                                                onChange={(e) => {
                                                    this.props.changeFilters("isRefundable", !Boolean(this.props.filters["isRefundable"]));
                                                }}
                                            />
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Transition>
                </div>
                <div className="rooms_list font-poppins">
                    {this.state.loading && [1, 2, 3].map((e, index) => <LoadingSkeleton key={index} />)}
                    {!this.state.loading &&
                        (this.props.rooms?.length ? (
                            <>
                                {this.state.currentItems?.map((room, index) => (
                                    <Row className="room_item" key={index}>
                                        <Col lg={4} className="room_name_offers">
                                            <div className="room_name">{room.type}</div>
                                        </Col>
                                        <Col lg={2} className="room_plans_wrapper">
                                            <div className="room_type">
                                                <img src={roomTypeIcon(room?.mealPlan?.description)} alt="Room cancelation icon" width={15} height="auto" />
                                                {roomTypeName(room?.mealPlan?.description)}
                                            </div>
                                            <div style={{ color: room?.isRefundable ? "#50C85C" : "#FF3E3E" }}>{`${
                                                room?.isRefundable ? "Free cancelation" : "Non refundable"
                                            }`}</div>
                                        </Col>
                                        <Col lg={2} className="price_per_room_wrapper">
                                            <div className="price_per_room">{`${parseFloat(
                                                Math.round(parseFloat(room?.finalPrice)) /
                                                    differenceInDays(new Date(this.props.filters?.dateTo), new Date(this.props.filters?.dateFrom)),
                                            ).toFixed(2)} €`}</div>
                                            <div className="price_per_room_text">Po noći</div>
                                        </Col>
                                        <Col lg={2} className="total_price_wrapper">
                                            <div className="total_price">{`${Math.round(parseFloat(room?.finalPrice))} €`}</div>
                                            <div className="total_price_text">
                                                {this.props.filters?.dateFrom && this.props.filters?.dateTo
                                                    ? `Cena za ${formatDistanceStrict(
                                                          new Date(this.props.filters?.dateFrom),
                                                          new Date(this.props.filters?.dateTo),
                                                          {
                                                              locale: srLatn,
                                                          },
                                                      )}`
                                                    : ""}
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div
                                                className="book_button"
                                                onClick={() =>
                                                    this.props.router.navigate(`/reservation`, {
                                                        state: {
                                                            photo: this.props.informations?.photo,
                                                            hotelId: this.props.filters?.hotelId,
                                                            hotelRatings: this.props.informations?.rating,
                                                            hotelName: this.props.informations?.name,
                                                            address: this.props.informations?.address,
                                                            roomId: room?.id,
                                                            checkIn: this.props.filters?.dateFrom,
                                                            checkOut: this.props.filters?.dateTo,
                                                            finalPrice: Math.round(parseFloat(room?.finalPrice)),
                                                            roomName: room.type,
                                                            adults: this.props.filters?.adults ?? 2,
                                                            children: this.props.filters?.children ?? 0,
                                                            childrenAges: this.props.filters?.childrenAges ?? [],
                                                            mealPlan: room?.mealPlan?.description,
                                                            destId: this.props.filters?.destId,
                                                            transport: this.props.filters?.transport ?? "sopstveni_prevoz",
                                                        },
                                                    })
                                                }
                                            >
                                                Rezerviši
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={this.handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={this.state.pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    className="pagination"
                                />
                            </>
                        ) : (
                            <div className="not-available-text">Nema dostupnih soba</div>
                        ))}
                </div>
            </>
        );
    }
}

export default withRouter(AboutHotelRooms);

function LoadingSkeleton(props) {
    return (
        <Row className="item_loading_skeleton">
            <Col lg={4}>
                <Skeleton count={4} containerClassName="inside_price_part" />
            </Col>
            <Col lg={2}>
                <Skeleton count={4} containerClassName="inside_price_part" />
            </Col>
            <Col lg={2}>
                <Skeleton count={4} containerClassName="inside_price_part" />
            </Col>
            <Col lg={2}>
                <Skeleton count={4} containerClassName="inside_price_part" />
            </Col>
            <Col lg={2}>
                <Skeleton count={4} containerClassName="inside_price_part" />
            </Col>
        </Row>
    );
}
