import { GET_MY_PROFILE, GET_MY_PROFILE_SUCCESS, GET_MY_PROFILE_ERROR } from "./actionTypes";

const initialState = {
    // variable: value
    myProfile: undefined,
    loading: false,
    error: undefined,
};

const MyProfile = (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_PROFILE:
            state = { ...state, loading: true };
            break;
        case GET_MY_PROFILE_SUCCESS:
            state = { ...state, loading: false, myProfile: action.payload };
            break;
        case GET_MY_PROFILE_ERROR:
            state = { ...state, loading: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default MyProfile;
