import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import { cities } from "../../static/data/cities";
import { phoneRegExp } from "../../helpers/utils/globalUtils";

const schemaAgent = yup.object().shape({
    ag_name: yup.string().required("Polje mora biti popunjeno"),
    ag_tin: yup.string().required("Polje mora biti popunjeno"),
    ag_skype: yup.string().notRequired(),
    city: yup.string().required("Polje mora biti popunjeno"),
    ag_zip: yup.string().required("Polje mora biti popunjeno"),
    email: yup.string().email().required("Polje mora biti popunjeno"),
    contact_person: yup.string().required("Polje mora biti popunjeno"),
    address: yup.string().required("Polje mora biti popunjeno"),
    username: yup.string().required("Polje mora biti popunjeno"),
    phone_number: yup.string().matches(phoneRegExp, "Broj telefona nije validan").required("Polje mora biti popunjeno"),
});

class MyProfileFormAgents extends Component {
    render() {
        return (
            <Formik
                validationSchema={schemaAgent}
                initialValues={{
                    ag_name: this.props.values?.ag_name ?? "",
                    ag_tin: this.props.values?.ag_tin ?? "",
                    city: this.props.values?.city ?? "",
                    ag_zip: this.props.values?.ag_zip ?? "",
                    address: this.props.values?.address ?? "",
                    phone_number: this.props.values?.phone_number ?? "",
                    ag_skype: this.props.values?.ag_skype ?? "",
                    email: this.props.values?.email ?? "",
                    contact_person: this.props.values?.contact_person ?? "",
                    username: this.props.values?.username ?? "",
                    password: this.props.values?.password ?? "",
                    confirm_password: this.props.values?.confirm_password ?? "",
                }}
                onSubmit={(values, actions) => {
                    this.props.getUserData(values);
                    actions.resetForm();
                }}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                    <Form noValidate autoComplete="off" name="registration_agency_form" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Agencija"
                                name="ag_name"
                                value={values.ag_name}
                                isInvalid={touched.ag_name && errors.ag_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">{errors.ag_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Tin broj"
                                name="ag_tin"
                                value={values.ag_tin}
                                isInvalid={touched.ag_tin && errors.ag_tin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">{errors.ag_tin}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Select
                                className="mb-3"
                                name="city"
                                value={values.city}
                                isInvalid={touched.city && errors.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option hidden>Grad</option>
                                {cities.map((city, key) => (
                                    <option value={`${city}`} key={key}>
                                        {city}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Zip"
                                name="ag_zip"
                                value={values.ag_zip}
                                isInvalid={touched.ag_zip && errors.ag_zip}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">{errors.ag_zip}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Adresa"
                                name="address"
                                value={values.address}
                                isInvalid={touched.address && errors.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    className="mt-3"
                                    placeholder={`Telefon`}
                                    name={`phone_number`}
                                    value={values.phone_number}
                                    isInvalid={touched.phone_number && errors.phone_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Skype" name="ag_skype" value={values.ag_skype} onChange={handleChange} onBlur={handleBlur} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="E-mail"
                                name="email"
                                value={values.email}
                                isInvalid={touched.email && errors.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Kontakt osoba"
                                name="contact_person"
                                value={values.contact_person}
                                isInvalid={touched.contact_person && errors.contact_person}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">{errors.contact_person}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Korisničko ime"
                                name="username"
                                value={values.username}
                                isInvalid={touched.username && errors.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                        </Form.Group>
                        <Link to="/change-password" className="send_user_button">
                            Promeni lozinku
                        </Link>
                        <button type="submit" className="send_user_button">
                            {this.props.submitButton}
                        </button>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default MyProfileFormAgents;
