import React, { Component } from "react";
import MediaQuery from "react-responsive";

import BookingQuickFiltersMobile from "./Mobile";
import BookingQuickFiltersDesktop from "./Desktop";

class BookingQuickFilters extends Component {
    render() {
        return (
            <>
                <MediaQuery minWidth={991}>
                    <BookingQuickFiltersDesktop {...this.props} />
                </MediaQuery>
                <MediaQuery maxWidth={990}>
                    <BookingQuickFiltersMobile {...this.props} />
                </MediaQuery>
            </>
        );
    }
}

export default BookingQuickFilters;
