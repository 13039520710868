import {
    GET_DESTINATIONS,
    GET_DESTINATIONS_SUCCESS,
    GET_DESTINATIONS_ERROR,
    SEARCH_ACCOMMODATION,
    SEARCH_ACCOMMODATION_SUCCESS,
    SEARCH_ACCOMMODATION_ERROR,
    SORT_ACCOMMODATION,
    GET_ABOUT_HOTEL,
    GET_ABOUT_HOTEL_SUCCESS,
    GET_ABOUT_HOTEL_ERROR,
    MAKE_RESERVATION,
    MAKE_RESERVATION_SUCCESS,
    MAKE_RESERVATION_ERROR,
    RESET,
    MY_RESERVATIONS,
    MY_RESERVATIONS_SUCCESS,
    MY_RESERVATIONS_ERROR,
    CANCEL_RESERVATION,
    CANCEL_RESERVATION_SUCCESS,
    CANCEL_RESERVATION_ERROR,
    SEARCH_RESERVATIONS,
} from "./actionTypes";

export const getDestinations = () => {
    return {
        type: GET_DESTINATIONS,
    };
};

export const getDestinationsSuccess = (payload) => {
    return {
        type: GET_DESTINATIONS_SUCCESS,
        payload,
    };
};

export const getDestinationsError = (payload) => {
    return {
        type: GET_DESTINATIONS_ERROR,
        payload,
    };
};

export const searchAccommodation = (payload) => {
    return {
        type: SEARCH_ACCOMMODATION,
        payload,
    };
};

export const searchAccommodationSuccess = (payload) => {
    return {
        type: SEARCH_ACCOMMODATION_SUCCESS,
        payload,
    };
};

export const searchAccommodationError = (payload) => {
    return {
        type: SEARCH_ACCOMMODATION_ERROR,
        payload,
    };
};

export const sortAccommodation = (payload = null) => {
    return {
        type: SORT_ACCOMMODATION,
        payload,
    };
};

export const getAboutHotel = (payload) => {
    return {
        type: GET_ABOUT_HOTEL,
        payload,
    };
};

export const getAboutHotelSuccess = (payload) => {
    return {
        type: GET_ABOUT_HOTEL_SUCCESS,
        payload,
    };
};

export const getAboutHotelError = (payload) => {
    return {
        type: GET_ABOUT_HOTEL_ERROR,
        payload,
    };
};

export const makeReservation = (payload) => {
    return {
        type: MAKE_RESERVATION,
        payload,
    };
};

export const makeReservationSuccess = (payload) => {
    return {
        type: MAKE_RESERVATION_SUCCESS,
        payload,
    };
};

export const makeReservationError = (payload) => {
    return {
        type: MAKE_RESERVATION_ERROR,
        payload,
    };
};

export const myReservations = () => {
    return {
        type: MY_RESERVATIONS,
    };
};

export const myReservationsSuccess = (payload) => {
    return {
        type: MY_RESERVATIONS_SUCCESS,
        payload,
    };
};

export const myReservationsError = (payload) => {
    return {
        type: MY_RESERVATIONS_ERROR,
        payload,
    };
};

export const cancelReservation = (payload) => {
    return {
        type: CANCEL_RESERVATION,
        payload,
    };
};

export const cancelReservationSuccess = (payload) => {
    return {
        type: CANCEL_RESERVATION_SUCCESS,
        payload,
    };
};

export const cancelReservationError = (payload) => {
    return {
        type: CANCEL_RESERVATION_ERROR,
        payload,
    };
};

export const searchReservation = (payload) => {
    return {
        type: SEARCH_RESERVATIONS,
        payload,
    };
};

export const reset = () => {
    return {
        type: RESET,
    };
};
