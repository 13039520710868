import React, { Component } from "react";
import { Transition } from "react-transition-group";

import "../../../styles/Components/LoggedUser.scss";

import LoginDropdownItems from "../../../routes/LoginDropdownItems";
import LoginProfileCircle from "../../../routes/LoginProfileCircle";

import ArrowDownIcon from "../../../static/images/icons/arrow_down_full_blue_icon.png";

const defaultDropdownArrowStyle = {
    transition: `transform ${200}ms ease`,
    transform: "rotate(0deg)",
};

const transitionDropdownArrowStyles = {
    entering: { transform: "rotate(180deg)" },
    entered: { transform: "rotate(180deg)" },
    exiting: { transform: "rotate(0deg)" },
    exited: { transform: "rotate(0deg)" },
};

class LoggedUserDesktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };
    }

    render() {
        return (
            <div className="position-relative d-flex">
                <Transition in={this.state.isMenuOpen} timeout={200}>
                    {(state) => (
                        <>
                            <div className="logged_user_wrapper" onClick={() => this.setState({ isMenuOpen: !this.state.isMenuOpen })}>
                                <LoginProfileCircle userName={this.props.userName} />
                                <span className="logged_account_label">NALOG</span>
                                <img
                                    src={ArrowDownIcon}
                                    alt="Logged arrow down"
                                    style={{ ...defaultDropdownArrowStyle, ...transitionDropdownArrowStyles[state] }}
                                />
                            </div>
                            <LoginDropdownItems state={state} userId={this.props.userId} userType={this.props.userType} />
                        </>
                    )}
                </Transition>
            </div>
        );
    }
}

export default LoggedUserDesktop;
