import React, { Component } from "react";

// import { quickFilters } from "../../../helpers/utils/bookingUtils";

import "../../../styles/Components/BookingQuickFilters.scss";

// import pdf_icon from "../../../static/images/icons/PDF_icon.png";

class BookingQuickFiltersDesktop extends Component {
    render() {
        return (
            <div className="type_filters no-select">
                {/* {quickFilters.map((filter, key) => (
                    <div
                        className={`type_filter ${this.props.activeTypeFilters.includes(filter.value) ? "active" : "inactive"}`}
                        onClick={() => this.props.changeActiveFilters(filter.value)}
                        key={key}
                    >
                        {filter.label}
                    </div>
                ))} */}
                {/* <div className="type_filter">
                    <img src={pdf_icon} alt="PDF icon" />
                    Opisi hotela na Srpskom
                </div> */}
            </div>
        );
    }
}

export default BookingQuickFiltersDesktop;
