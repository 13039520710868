import React, { Component } from "react";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";

import "../../styles/Components/BlogItem.scss";

import BlogWriterIcon from "../../static/images/icons/writer_blog_icon.png";
import BlogDateIcon from "../../static/images/icons/date_blog_icon.png";

class BlogItem extends Component {
    render() {
        return (
            <div className="blog-item-wrapper">
                <div className="position-relative">
                    <div className="image-overlay"></div>
                    <img src={`data:image/png;base64,${this.props.item?.image_file}`} alt={`blog-images-${this.props.item?.id}`} />
                </div>
                <div className="blog-content-item-wrapper font-poppins">
                    <div className="title font-marck-script">{this.props.item?.title}</div>
                    <div className="description" dangerouslySetInnerHTML={{ __html: this.props.item?.description }}></div>
                    <div className="bottom_details_wrapper">
                        <div className="bottom_items">
                            {this.props.item?.author_name && !this.props.isNotifications && (
                                <div className="items">
                                    <img src={BlogWriterIcon} alt={`Blog writer icon ${this.props.iten?.id}`} />
                                    <div>{this.props.item?.author_name}</div>
                                </div>
                            )}
                            {this.props.item?.updated_at && (
                                <div className="items">
                                    <img src={BlogDateIcon} alt={`Blog date icon ${this.props.index}`} />
                                    {this.props.item?.updated_at && <div>{format(parseISO(this.props.item?.updated_at), "MM.dd.yyyy")}</div>}
                                </div>
                            )}
                        </div>
                        <Link to={`/${this.props.isNotifications ? "notifications" : "blog"}/${this.props.item?.id}`} className="read-more">
                            Pročitaj
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogItem;
