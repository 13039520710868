import {
    POST_REGISTRATION_USER,
    POST_REGISTRATION_USER_SUCCESS,
    POST_REGISTRATION_USER_ERROR,
    POST_REGISTRATION_AGENT,
    POST_REGISTRATION_AGENT_SUCCESS,
    POST_REGISTRATION_AGENT_ERROR,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
} from "./actionTypes";

export const postRegistrationUser = (payload) => {
    return {
        type: POST_REGISTRATION_USER,
        payload,
    };
};

export const postRegistrationUserSuccess = (payload) => {
    return {
        type: POST_REGISTRATION_USER_SUCCESS,
        payload,
    };
};

export const postRegistrationUserError = (payload) => {
    return {
        type: POST_REGISTRATION_USER_ERROR,
        payload,
    };
};

export const postRegistrationAgent = (payload) => {
    return {
        type: POST_REGISTRATION_AGENT,
        payload,
    };
};

export const postRegistrationAgentSuccess = (payload) => {
    return {
        type: POST_REGISTRATION_AGENT_SUCCESS,
        payload,
    };
};

export const postRegistrationAgentError = (payload) => {
    return {
        type: POST_REGISTRATION_AGENT_ERROR,
        payload,
    };
};

export const login = (payload) => {
    return {
        type: LOGIN,
        payload,
    };
};

export const loginSuccess = (payload) => {
    return {
        type: LOGIN_SUCCESS,
        payload,
    };
};

export const loginError = (payload) => {
    return {
        type: LOGIN_ERROR,
        payload,
    };
};

export const logout = (payload) => {
    return {
        type: LOGOUT,
        payload,
    };
};

export const logoutSuccess = (payload) => {
    return {
        type: LOGOUT_SUCCESS,
        payload,
    };
};

export const logoutError = (payload) => {
    return {
        type: LOGOUT_ERROR,
        payload,
    };
};

export const forgotPassword = (payload) => {
    return {
        type: FORGOT_PASSWORD,
        payload,
    };
};

export const forgotPasswordSuccess = (payload) => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload,
    };
};

export const forgotPasswordError = (payload) => {
    return {
        type: FORGOT_PASSWORD_ERROR,
        payload,
    };
};

export const changePassword = (payload) => {
    return {
        type: CHANGE_PASSWORD,
        payload,
    };
};

export const changePasswordSuccess = (payload) => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload,
    };
};

export const changePasswordError = (payload) => {
    return {
        type: CHANGE_PASSWORD_ERROR,
        payload,
    };
};
