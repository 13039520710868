import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";

import "../styles/MyProfile.scss";
import { getCookie } from "../helpers/utils/globalUtils";

import UsersForm from "../components/Reusable/UsersForm";

import { getMyProfile } from "../store/actions";

class MyProfile extends Component {
    componentDidMount() {
        this.props.getMyProfile();
    }

    render() {
        const user = getCookie("type") !== "user" ? 1 : 0;
        return (
            <Container className="my_profile_wrapper font-poppins">
                <h2 className="title">Moj profil</h2>

                {this.props.myProfile ? (
                    <UsersForm
                        formType={"myProfile"}
                        activeProfile={user}
                        submitButton={"Sačuvaj izmene"}
                        className="my_profile_form_wrapper"
                        values={this.props.myProfile}
                    />
                ) : (
                    <></>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        myProfile: state.MyProfile.myProfile,
    };
};

export default connect(mapStateToProps, { getMyProfile })(MyProfile);
