import React, { Component } from "react";
import { Container } from "react-bootstrap";

import AboutCountryInformations from "../Reusable/AboutCountryInformations";

import TurkeyGeoLocation from "../../static/images/About_Country/About_Turkey/turkey-geo-location.png";
import TurkeyFlag from "../../static/images/About_Country/About_Turkey/turkey-flag.png";

class AboutTurkey extends Component {
    render() {
        return (
            <div className="about_greece_general_informations">
                <AboutCountryInformations
                    image={require("../../static/images/About_Country/About_Turkey/general-informations-turkey.png")}
                    title={"ОPŠTE INFORMACIJE"}
                    description={`Šta može iznenaditi Tursku? Ako mislite da ne postoji ništa atraktivno osim plaža i hotela sa sveobuhvatnim sistemom, onda ne znate pravu Tursku - sa veličanstvenim Istanbulom, nekad vizantijskim Carigradom, drevnim hrišćanskim spomenicima i neverovatnim čudima prirode koja stoji definitivno vredi pogledati.`}
                />
                <Container>
                    <div className="content_wrapper">
                        <div className="title">Osnovne informacije</div>
                        <ul className="country_unorder_list">
                            <li>Službeni naziv: Turska</li>
                            <li>Površina teritorije je 783 563 km2</li>
                            <li>Stanovništvo - 82 003 882 ljudi</li>
                            <li>Glavni grad je Ankara</li>
                            <li>Službeni jezik je turski</li>
                            <li>Religija je sunitski islam</li>
                            <li>Valuta je turska lira</li>
                        </ul>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">Geografska lokacija</div>
                        <img src={TurkeyGeoLocation} alt="Turkey Geo Location" />
                    </div>
                </Container>
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="description">
                                Turska se nalazi na istočnoj hemisferi. Deo njene teritorije - 97% - nalazi se u Aziji, a 3% - u Evropi. Zemlja se nalazi na
                                raskršću važnih ruta koje su od davnina povezale Evropu sa Azijom, zemlje Crnog mora i narode - sa Sredozemljem. Danas Turska
                                saobraća autoputevima i železnicama koje povezuju Evropu sa mnogim azijskim zemljama. Maksimalna dužina turske teritorije od
                                zapada do istoka iznosi 1600 km, od severa do juga - 600 km. Sa tri strane ga ispire more: na severu Crno more, na zapadu
                                Egejsko more, na jugu Mediteran. Evropski i azijski deo Turske razdvojen jemoreuzom od Bosfora i Dardanela
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Teritorija</div>
                            <div className="description">
                                Turska je podeljena na 81 il (provincije). Svaki mulj je podeljen na okruge (mulj). Administrativni centar je smešten u njegovom
                                centralnom delu. Mnoga područja su podeljena na mesta.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Oblik vladavine</div>
                            <div className="description">
                                Turska je predsednička republika. Zakonodavna vlast ovde pripada jednoparničnom parlamentu - Velikoj nacionalnoj skupštini, koju
                                čini 600 poslanika, izabranih za petogodišnje direktno univerzalno biračko pravo.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Jezik</div>
                            <div className="description">
                                Službeni jezik je turski. Poslovni jezici su turski i engleski. Jezička barijera je pojava koja je u Turskoj stvarna samo u
                                pokrajini. Stanovništvo uključeno u turističku sferu govori ruski. Engleski se uglavnom govori u Turskoj u većim gradovima.
                            </div>
                        </div>
                        <img src={TurkeyFlag} alt="Turkey flag" />
                    </Container>
                    <div className="fake_background blue"></div>
                </div>
                <Container>
                    <div className="content_wrapper">
                        <div className="title">Etnički sastav</div>
                        <div className="description">
                            Glavno stanovništvo zemlje - Turci, takođe postoje Armenci, Asirci, Kurdi, Krimski Tatari i Kavkazanci.
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">Valuta</div>
                        <div className="description">
                            Nacionalna valuta je turska lira (TRI). Prosečan kurs za 2019. godinu: 1 TRI - 11,9 RUB. U Turskoj se devizni kurs stalno menja, pa
                            pretvaranje velikih količina nije baš profitabilno. Evro i dolari su ovde široko prihvaćeni, a ponekad je plaćanje u stranoj valuti
                            isplativije od nacionalne valute. Prihvataju se sve vrste čekova i kreditnih kartica. Strana valuta se može uvesti i izvoziti bez
                            ograničenja, izvoz turske valute dozvoljen je u roku od 1000 lira
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">Vremenska zona</div>
                        <div className="description">
                            U Turskoj, istočnoevropskom vremenu, vremenska razlika sa prosečnim Evropljanom plus jedan sat, a u Moskvi minus jedan sat.
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">Klima</div>
                        <div className="description">
                            U središnjem delu zemlje klima je umereno kontinentalna s vrućim i suvim letima i dovoljno hladnim zimama. Na egejskoj i
                            mediteranskoj obali klima je mediteranska s vrlo blagim zimama. Crnomorska obala ima morsku klimu sa obilnim kišama i hladnim zimama
                            sa retkim niskim temperaturama. Na jugoistoku je klima tropska pustinja sa veoma toplim letima.
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">Dress code</div>
                        <div className="description">
                            Uprkos činjenici da u malim gradovima lokalno stanovništvo nosi nacionalnu odeću, za turiste ne postoje posebna ograničenja. Ali ako
                            ćete gledati u džamiju, ne zaboravite na pravila: žene prekrivaju glave i navlače dugu suknju, a muškarci mijenjaju kratke hlače i
                            majice na košulju i pantalone. I žene i muškarci moraju da budu saslušani pre nego što uđu u svetilište.
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">Praznici</div>
                        <div className="subtitle">Nacionalni praznici:</div>
                        <ul className="country_unorder_list">
                            <li>Novogodišnji dan 1.januara</li>
                            <li>Dan nacionalnog suvereniteta i Dan deteta 23. aprila</li>
                            <li>Dan omladine i sporta 19. maj</li>
                            <li>Dan Pobede 30 аvgusta</li>
                        </ul>
                    </div>
                </Container>
            </div>
        );
    }
}

export default AboutTurkey;
