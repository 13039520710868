import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import "../styles/SingleBlog.scss";

import { shuffle, withRouter } from "../helpers/utils/globalUtils";

import BlogWriterIcon from "../static/images/icons/writer_blog_icon.png";
import BlogDateIcon from "../static/images/icons/date_blog_icon.png";
import OtherBlogsIcon from "../static/images/icons/other_blogs_icon.png";

import { getBlog, getSingleBlog } from "../store/actions";

class SingleBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            singleBlog: undefined,
            otherBlogs: undefined,
        };
    }

    shuffleBlogs = () => {
        this.setState({
            otherBlogs: shuffle(this.props.blogs?.filter((value) => this.props.router?.params?.id !== value?.id)),
        });
    };

    componentDidMount() {
        if (this.props.router?.params?.id) {
            this.props.getSingleBlog(this.props.router?.params?.id);
            this.props.getBlog();
            this.shuffleBlogs();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.router?.params && this.props.router?.params?.id !== prevProps.router?.params?.id) {
            this.props.getSingleBlog(this.props.router?.params?.id);
            this.props.getBlog();
            this.shuffleBlogs();
        }

        if (JSON.stringify(this.props.blogs) !== JSON.stringify(prevProps.blogs) || this.props.singleBlog !== prevProps.singleBlog) {
            this.shuffleBlogs();
        }
    }

    render() {
        return (
            <div className="single-blog-wrapper font-poppins">
                <div
                    className="single-blog-image-cover"
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_API_URL}${this.props.singleBlog?.image})`,
                    }}
                >
                    <div className="image-overlay"></div>

                    <Container className="single-blog-header-wrapper">
                        <Row className="single-blog-header-row">
                            <Col className="title font-marck-script">{this.props.singleBlog?.title}</Col>
                            <Col className="single-blog-writer-time-wrapper ">
                                {this.props.singleBlog?.author_name && (
                                    <div className="items">
                                        <img src={BlogWriterIcon} alt={`Blog writer icon`} />
                                        <div>{this.props.singleBlog?.author_name}</div>
                                    </div>
                                )}
                                {this.state.singleBlog?.updated_at && (
                                    <div className="items">
                                        <img src={BlogDateIcon} alt={`Blog date icon`} />
                                        {this.props.singleBlog?.updated_at && <div>{format(parseISO(this.props.singleBlog?.updated_at), "MM.dd.yyyy")}</div>}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container>
                    <Row>
                        <Col className="single-blog-content" lg={8} dangerouslySetInnerHTML={{ __html: this.props.singleBlog?.description }}></Col>
                        <Col lg={4}>
                            <div className="other-blogs-wrapper">
                                <div className="title">
                                    <img src={OtherBlogsIcon} alt="Other blogs icon" />
                                    Ostali Blogovi
                                </div>
                                <div className="other-blogs-list">
                                    {this.state.otherBlogs?.slice(0, 3)?.map((blog, key) => (
                                        <Link to={`/blog/${blog.id}`} className="item-title" key={key}>
                                            {blog?.title}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        singleBlog: state.Blog.singleBlog,
        blogs: state.Blog.blogs,
    };
};

export default connect(mapStateToProps, { getBlog, getSingleBlog })(withRouter(SingleBlog));
