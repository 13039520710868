import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import Select from "react-select";

const sortOptions = [
    { value: "popusti", label: "Popusti" },
    { value: "skuplje", label: "Od jeftinijeg" },
    { value: "jeftinije", label: "Od skupljeg" },
];

const customStylesDesktop = {
    option: (provided, state) => ({
        ...provided,
        width: "192px",
        color: state.isSelected ? "white" : "black",
        backgroundColor: state.isSelected ? "black" : "white",
    }),
    control: (provided) => ({
        ...provided,
        width: "192px",
        border: "none",
        borderRadius: "9px",
        backgroundColor: "white",
    }),
    indicatorSeparator: (provided) => ({
        display: "none",
    }),
    indicatorsContainer: (provided) => ({
        svg: {
            stroke: "black",
            fill: "black",
        },
    }),
};

const customStylesMobile = {
    option: (provided, state) => ({
        ...provided,
        width: "192px",
        color: state.isSelected ? "white" : "black",
        backgroundColor: state.isSelected ? "black" : "white",
    }),
    control: (provided) => ({
        ...provided,
        width: "192px",
        border: "none",
        borderRadius: "9px",
        backgroundColor: "white",
    }),
    indicatorSeparator: (provided) => ({
        display: "none",
    }),
    indicatorsContainer: (provided) => ({
        svg: {
            stroke: "black",
            fill: "black",
        },
    }),
};

function SortingSelect(props) {
    // let location = useLocation();
    const [selected, setSelected] = useState(
        props.filters?.sort && sortOptions.find((item) => item.value === props.filters?.sort)
            ? sortOptions.find((item) => item.value === props.filters?.sort)
            : sortOptions[0],
    );

    // useEffect(() => {
    //     // if (props.filters?.sort) {
    //     const queryParams = new URLSearchParams(location?.search);

    //     for (const [key, value] of queryParams) {
    //         if (key === "sort") {
    //             props.changeFilters("sort", value);
    //         }
    //     }
    //     // }
    // }, []);

    useEffect(() => {
        if (props.filters?.sort && sortOptions.find((item) => item.value === props.filters?.sort)) {
            setSelected(sortOptions.find((item) => item.value === props.filters?.sort));
        }
    }, [props.filters]);

    return (
        <Select
            className={`sort_select ${props.className || ""}`}
            options={sortOptions}
            value={selected}
            onChange={({ value }) => {
                props.changeFilters("sort", value);
            }}
            isSearchable={false}
            styles={props.mobile === false ? customStylesDesktop : customStylesMobile}
        />
    );
}

export default SortingSelect;
