import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import "../styles/Components/LoginDropdownItem.scss";

import { withRouter } from "../helpers/utils/globalUtils";

import { logout } from "../store/actions";

const defaultDropdownStyle = {
    transition: `opacity ${800}ms ease-in-out`,
    opacity: 0,
};

const transitionDropdownStyles = {
    entering: { opacity: 1, zIndex: 1 },
    entered: { opacity: 1, zIndex: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0, zIndex: -1 },
};

class LoginDropdownItems extends Component {
    render() {
        return (
            <div
                className="login_dropdown_item_wrapper"
                style={{
                    ...defaultDropdownStyle,
                    ...transitionDropdownStyles[this.props.state],
                }}
            >
                <div className="dropdown_items">
                    <Link to={`/my-reservations`} className="item_title">
                        Moje rezervacije
                    </Link>
                    <div className="item_separator"></div>
                </div>
                <div className="dropdown_items">
                    <Link to={`/my-profile`} className="item_title">
                        Moj profil
                    </Link>
                    <div className="item_separator"></div>
                </div>
                <div className="dropdown_items">
                    <Link to="/favorites-accommodations" className="item_title">
                        Omiljeno
                    </Link>
                    <div className="item_separator"></div>
                </div>
                {this.props.userType === "agent" && (
                    <>
                        <div className="dropdown_items">
                            <Link to="/page/collaboration" className="item_title">
                                Saradnja
                            </Link>
                            <div className="item_separator"></div>
                        </div>
                        <div className="dropdown_items">
                            <Link to="/page/informations" className="item_title">
                                Informacije
                            </Link>
                            <div className="item_separator"></div>
                        </div>
                    </>
                )}
                <div className="dropdown_items" onClick={() => this.props.logout({ navigate: this.props.router.navigate })}>
                    <div className="item_title">Odjava</div>
                </div>
            </div>
        );
    }
}

export default connect(null, { logout })(withRouter(LoginDropdownItems));
