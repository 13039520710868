import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { GET_MY_PROFILE } from "./actionTypes";
import { getMyProfileSuccess, getMyProfileError } from "./actions";

import { getMyProfile } from "../../helpers/api/myProfile";

function* getMyProfileSaga() {
    try {
        const response = yield call(getMyProfile);
        if (response && response.data.status === 200) {
            yield put(getMyProfileSuccess(response.data.data));
        } else {
            yield put(getMyProfileError(response.error));
        }
    } catch (error) {
        yield put(getMyProfileError(error));
    }
}

export function* watchGetMyProfileSaga() {
    yield takeEvery(GET_MY_PROFILE, getMyProfileSaga);
}

function* myProfileSaga() {
    yield all([fork(watchGetMyProfileSaga)]);
}

export default myProfileSaga;
