import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AboutCountryInformations from "../../Reusable/AboutCountryInformations";
import Gallery from "../../Reusable/Gallery";

import AboutGreeceImage1 from "../../../static/images/About_Country/About_Greece/History/about_greece_history_1.png";
import AboutGreeceImage2 from "../../../static/images/About_Country/About_Greece/History/about_greece_history_2.png";

class AboutGreeceHistory extends Component {
    render() {
        return (
            <div className="about_greece_history">
                <AboutCountryInformations
                    image={require("../../../static/images/About_Country/About_Greece/History/about_greece_history_1.png")}
                    title={"KRATKA ISTORIJA GRČKE"}
                    description={`Grčka istorija može se podeliti na više etapa. U tzv. bronzanom dobu postojale su Egejske civilizacije: Mikenska, Minojska, Kikladska i Trojanska koje su razrušene dolaskom Doraca sa severa u periodu od 1100. do 1000. godine p. n. e. Period od 17. Do 13. Veka grčke istorije je I nazvan po jednoj od najviših kultura u istoriji – Mikeni. Ova civilizacija uništena je oko 1100 do. p.n.e, ubrzo nakon Trojanskog rata.`}
                />
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="description">
                                Zahvaljujući geografskoj razdrobljenosti dolazi do nastanka mnogobrojnih nezavisnih gradova -država – polisa poput Atine I
                                Sparte koja je do sredine šestog veka p.n.e. nametnula svoju hegemoniju čitavim Peloponezom. Ovaj period zove se Ahajskim i
                                završava se padom atinskog tiranina Hipije sa vlasti (510. godina p. n. Nakon toga je usledio je period Klasične Grčke obeležen
                                grčko-persijskim i peloponeskim ratom.
                            </div>
                        </div>
                        <Row className="content_wrapper">
                            <Col lg={6} className="description">
                                Kada je tokom 6 veka p.n.e. pokorilo većinu naroda od Nila do Inda, Persijsko carstvo se namerilo na Grčku sa kojom je vodilo
                                rat punih 50 godina. Persija je nakon Termopila prodrla u Atinu ali je ubrzo potom Atina uzvratila udarac i nakon bitaka kod
                                Mikale I Plateje Persijanci su isterani iz Evrope. Nedugo nakon toga Atina i Sparta stupile su u rat za prevlast Helade 431.
                                godine. p.n.e. koji se završio prevratom u opsednutoj Atini – poslednji pristalica demokratije biva ubijen I Atina se pokorava
                                spartanskoj hegemoniji. Naredni period obeležilo je uzdizanje pokrajine Makedonije pod Filipom II, i njena hegemonija a doba
                                nakon smrti Aleksandra Velikog do rimskog osvajanja grčke 146. godine p.n.e. poznat je kao Helenistička Grčka.
                            </Col>
                            <Col lg={6}>
                                <img src={AboutGreeceImage1} alt="Greece history 1" />
                            </Col>
                        </Row>
                        <img src={AboutGreeceImage2} alt="Greece history 2" />
                        <div className="content_wrapper">
                            <div className="description">
                                Do 1834. Godine Grčka je bila pod vlašću Rimske imperije, Vizantije i Otomanskog carstva. Najviše mesto zauzimala je u Rimskom
                                carstvu gde je predstavljala kulturno sedište a grčki jezik je smatran ravnopravnim latinskom. Podelom Rismkog Carstva, Grčka
                                ulazi u sastav Vizantije sve do 1461. Godine a nakon toga pada pod Osmansku vlast I biva podeljena u 6 sandžaka. Godine 1821.
                                Grci podižu ustanak i uz pomoć evropskih sila uspevaju da se izbore za prevlast i proglase Kraljevinu Grčku. Grčka je u Prvom
                                svetskom ratu učestvovala protiv sila Antante, a u Drugom svetskom ratu na strani Saveznika. Nakon završetka rata izbija Grčki
                                građanski rat između Grčke vlade i Komunističke partije. Rat je završen pobedom nacionalne armije ali je 1967. izvršen državni
                                udar. Sledećih sedam godina grčke istorije poznato je pod nazivom "Grčka vojna hunta"ili ’’Režim pukovnika’’. Nakon pada hunte,
                                referendumom je izglasano ukidanje monarhije i uvođenje republikanskog državnog uređenja. 1980. godine Grčka je postala članica
                                NATO pakta a naredne godine i Evropske uni
                            </div>
                        </div>
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <Container>
                    <Gallery />
                </Container>
            </div>
        );
    }
}

export default AboutGreeceHistory;
