import axios from "axios";

export const getNotifications = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/getAllNotification`).catch((error) => {
        return error;
    });
};

export const getSingleNotifications = async ({ id }) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/getSingleNotification/${id}`).catch((error) => {
        return error;
    });
};
