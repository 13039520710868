import { all } from "redux-saga/effects";

import AuthSaga from "./auth/saga";
import HomeSaga from "./home/saga";
import BlogSaga from "./blog/saga";
import BookingSaga from "./booking/saga";
import NotificationsSaga from "./notifications/saga";
import CustomPageSaga from "./customPage/saga";
import MyProfileSaga from "./myProfile/saga";

import GeneralSaga from "./general/saga";
import SpecialOffersSaga from "./specialOffers/saga";

export default function* rootSaga() {
    yield all([AuthSaga(), HomeSaga(), BlogSaga(), BookingSaga(), NotificationsSaga(), CustomPageSaga(), GeneralSaga(), MyProfileSaga(), SpecialOffersSaga()]);
}
