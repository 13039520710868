import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import AboutCountryInformations from "../Reusable/AboutCountryInformations";

import UAE1 from "../../static/images/About_Country/About_UAE/uae_1.png";
import UAE2 from "../../static/images/About_Country/About_UAE/uae_2.png";

class AboutUAE extends Component {
    render() {
        return (
            <div>
                <AboutCountryInformations
                    image={require("../../static/images/About_Country/About_UAE/general_info_uae.png")}
                    title={"ОPŠTE INFORMACIJE"}
                    description={`Ujedinjeni Arapski Emirati - savezna država na Bliskom istoku, koja se sastoji od sedam emirata, od kojih je svaki država - apsolutna monarhija: Abu Dabi, Ajman, Dubai , Ras al-Khaimah, Umm al-Kaivain, Fujairah i Sharjah Na čelu države je predsednik Ujedinjenih Arapskih Emirata, koji je emir najvećeg emirata Abu Dabija. To je bogata i prosperitetna zemlja sa luksuznim odmaralištima i nekim od najboljih svetskih hotela. Zanimljivo je pre svega jer je jedno od najrazvijenijih u arapskom svetu i u potpunosti ga karakteriše orijentalni ukus.`}
                />
                <div className="content_fake_background_wrapper">
                    <Container>
                        <ul className="country_unorder_list">
                            <li>U Dubaiju se nalazi najviša zgrada na Zemlji - Burj Khalifa, visoka 828 metara.</li>
                            <li>Emirat Abu Dabi zauzima 85% teritorije UAE.</li>
                            <li>U Emiratima u prirodi žive veliki predatori poput leoparda.</li>
                            <li>Deo teritorije zemlje zauzima pustinja Rub al-Khali, gde temperatura redovno prelazi +50 stepeni.</li>
                            <li>
                                Oštra cenzura interneta u Ujedinjenim Arapskim Emiratima blokira društvene mreže i brojne popularne web lokacije za video
                                hosting, poput YouTube-a.
                            </li>
                            <li>U UAE praktično nema zločina.</li>
                            <li>
                                Kazne za prekoračenje brzine i druge saobraćajne prekršaje su visoke, na primer, preticanje preko pune linije rezultiraće
                                novčanom kaznom do 800 dolara.
                            </li>
                            <li>U javnom prevozu Ujedinjenih Arapskih Emirata za muškarce i žene postoje zasebni vagoni u vozovima i delovi u autobusima.</li>
                            <li>U Dubaiju i Abu Dabiju mnoga autobuska stajališta su potpuno zatvorena i klimatizovana.</li>
                            <li>Jedna od najpopularnijih zabava za lokalne šeike je skakanje na kamilama, kao i takmičenja u lepoti za kamile.</li>
                            <li>U svim mestima u kojima je gužva u UAE ima mnogo policajaca u civilu.</li>
                        </ul>

                        <div className="content_wrapper">
                            <div className="title">10 razloga za posetu UAE:</div>
                            <ul className="country_unorder_list">
                                <li>
                                    Pojednostavljeni ulazak i dobijanje vize. Državljani Ruske Federacije, Ukrajine, Kazahstana imaju pravo na bezvizni ulazak u
                                    UAE sa različitim periodom boravka.
                                </li>
                                <li>Praktičan, udoban let na najboljim svetskim avio-kompanijama - Emirates Airlines, Flidubai, Aeroflot, Etihad Airlines.</li>
                                <li>Popularan sistem „all inclusive“ ugostiteljstva u mnogim hotelima.</li>
                                <li>Godišnje etape trke - „Formula 1“ na stazi svetske klase IAS MARINA.</li>
                                <li>Odlične mogućnosti kupovine: gigantski tržni centri, autentični bazari.</li>
                                <li>Trgovinski festivali održavaju se dva puta godišnje: zimi i leti.</li>
                                <li>Pevačka fontana u Dubaiju.</li>
                                <li>Kulinarski užitak u zapanjujućim gurmanskim restoranima.</li>
                                <li>Tematski parkovi, vodeni parkovi, show La Perle, Dubai Opera</li>
                                <li>Nezaboravan vatromet i vatromet za Novu godinu, koji su uvršćeni u Ginisovu knjigu rekorda.</li>
                            </ul>
                        </div>
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <Container>
                    <div className="content_wrapper">
                        <div className="title">INFORMACIJE</div>
                        <ul className="country_unorder_list">
                            <li>Glavni grad države: Abu Dabi</li>
                            <li>Službeni jezik: arapski</li>
                            <li>Valuta: dirham (AED)</li>
                            <li>Vreme u UAE: vremenska zona je GMT + 3. </li>
                            <li>Klima: suva, suptropska</li>
                            <li>Stanovništvo: 9.771.000</li>
                            <li>Državni sistem: federacija 7 emirata</li>
                            <li>Pozivni broj: međunarodni pozivni broj +971</li>
                        </ul>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">GEOGRAFIJA</div>
                        <div className="description">
                            Država se nalazi u jugozapadnoj Aziji, na severoistočnom delu Arapskog poluostrva. Površina UAE iznosi 83,6 hiljada km2. Okružene
                            vodama arapskog i omanskog zaliva. Na jugu i zapadu graniči se sa Saudijskom Arabijom, na istoku sa Omanom. Severna obala je
                            nasuprot Iranu preko Arapskog zaliva, Katar je udaljen 50 km severozapadno. Većinu teritorije zemlje zauzima pustinja Rub al-Khali
                            (područje pustinje je trećina Arapskog poluostrva, ovo je najveće područje prekriveno peskom na svetu).
                        </div>
                    </div>
                    <img src={UAE1} alt="UAE 1" />
                    <div className="content_wrapper">
                        <div className="title">POPULACIJA</div>
                        <div className="description">
                            9.771.000 ljudi, od kojih su 91% radni migranti. Domorodačko stanovništvo je 9%, uglavnom muslimana sunita. Najgušće su naseljena
                            dva emirata - Abu Dabi i Dubai, broj stanovnika 2 emirata je 85% od ukupnog stanovništva UAE. Ostatak emirata: Šardža, Ajman, Ras al
                            Hajma, Um al Kuvejn i Fujaira čini 25% stanovništva.
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">RADNO VREME</div>
                        <div className="description">
                            Zakon o radu u UAE predviđa najviše osam sati radnog dana za odrasle radnike. Istovremeno, u određenim segmentima, poput hotelskih
                            kompleksa, restorana i tržnih centara, radni dan se može produžiti za jedan sat. Radna nedelja je od nedelje do četvrtka. Većina
                            kompanija je zatvorena petkom i subotom. Radni dan vlade obično traje od 07:00 do 14:00.
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">VALUTA</div>
                        <ul className="country_unorder_list">
                            <li>Novčana jedinica UAE je dirham (označen sa AED ili DH | Dhs).</li>
                            <li>1 dirham UAE je jednak 100 filsa.</li>
                            <li>
                                Novac se razlikuje po veličini i slici na novčanicama. Novčanice apoena učestvuju u opticaju novca: 5; 10; 20; 50; 100; 200;
                                500; 1000. Novčanice prikazuju najpoznatije arhitektonske građevine, ikonične simbole koji su razumljivi lokalnom stanovništvu.
                            </li>
                            <li>
                                Arapski brojevi su naznačeni na dosijeu, tako da ih turisti mogu razlikovati po svojim slikama. sleva nadesno sledeće apoene
                                kovanica:
                                <ul className="country_unorder_list">
                                    <li>25 - 25 fil (četvrtina dirhema);</li>
                                    <li>50 - 50 fil (pola dirhama);</li>
                                    <li>1 - 1 dirham.</li>
                                </ul>
                            </li>
                            <li>Klima: suva, suptropska</li>
                            <li>Stanovništvo: 9.771.000</li>
                            <li>Državni sistem: federacija 7 emirata</li>
                            <li>Pozivni broj: međunarodni pozivni broj +971</li>
                        </ul>
                        <div className="description">
                            Banke u Ujedinjenim Arapskim Emiratima otvorene su svakodnevno od 08:00-13:00h, ručak nije obezbeđen. Slobodni dan - petak.
                            Menjačnice se nalaze u tržnim centrima, benzinskim pumpama, aerodromima, hotelima. Kurs je gotovo identičan bankarskom. Ova
                            odeljenja rade sedam dana u nedelji od 8:00 do 19:00. Bankomati su svuda, u hotelima, većina prodavnica i restorana prihvata
                            međunarodne bankarske kartice: American Express, Diners Club, Master Card i Visa.
                        </div>
                    </div>
                </Container>
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="title">JEZIK</div>
                            <div className="description">
                                Službeni jezik je arapski. Engleski se široko govori. Turističko osoblje govori mnoge druge jezike.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">TELEKOMUNIKACIJE I INTERNET</div>
                            <div className="description">
                                Međunarodni kod za UAE je +971. Mobilni operatori Etisalat i Du nude pripejd i postpejd ponude. U zemlji postoji mnogo brzih
                                pristupnih tačaka za bežični internet, posebno u hotelima i na mnogim javnim mestima postoji besplatan Wi-Fi
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">ELEKTRIČNA ENERGIJA</div>
                            <div className="description">
                                220|240 V AC, 50 Hz. Priključnice od 13 ampera sa tri pravougaone iglice (slične onima koje se koriste u Velikoj Britaniji).
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">VODA</div>
                            <div className="description">Tekuća voda u UAE i drugim gradovima je obično za piće. Većina hotela nudi flaširanu vodu.</div>
                        </div>
                        <Row>
                            <Col lg={6}>
                                <div className="content_wrapper">
                                    <div className="title">OBLAČENJE</div>
                                    <div className="description">
                                        Ne postoje striktna ograničenja za izgled u UAE, ali gosti zemlje (i muškarci i žene) treba da poštuju lokalnu kulturu i
                                        običaje i da ne nose previše otkrivenu odeću na javnim mestima. Važno je da se oblačite prikladno za tu priliku. Kupaći
                                        kostimi i odeća za plažu su prihvatljivi uz bazen na licu mesta, ali ne i na javnim mestima kao što su tržni centri,
                                        restorani i vladine kancelarije.
                                    </div>
                                </div>
                                <div className="content_wrapper">
                                    <div className="title">ZDRAVLJE</div>
                                    <div className="description">
                                        Posetioci zemlje ne zahtevaju posebne vakcinacije: nema opasnosti po zdravlje prilikom posete UAE. Zdravstvena zaštita u
                                        zemlji održava se na visokom nivou. U Emiratima ima mnogo apoteka, uključujući i one koje rade non-stop, sa širokim
                                        spektrom lekova. Osoblje apoteke govori engleski jezik. Preporučujemo vam da sa sobom ponesete lekove koje uzimate prema
                                        propisima lekara, jer možda nema analoga.
                                    </div>
                                </div>
                                <div className="content_wrapper">
                                    <div className="title">GOSTI SA INVALIDITETOM</div>
                                    <div className="description">
                                        U velikim hotelima, aerodromima, tržnim centrima, bankama obezbeđena je infrastruktura za pristup invalidskim kolicima,
                                        posebno opremljeni toaleti i privatni parking. Prilikom posete drugim mestima preporučuje se unapred proveriti
                                        dostupnost posebne infrastrukture.
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <img src={UAE2} alt="UAE 2" />
                            </Col>
                        </Row>
                        <div className="content_wrapper">
                            <div className="title">SIGURNOST</div>
                            <div className="description">
                                U velikim hotelima, aerodromima, tržnim centrima, bankama obezbeđena je infrastruktura za pristup invalidskim kolicima, posebno
                                opremljeni toaleti i privatni parking. Prilikom posete drugim mestima preporučuje se unapred proveriti dostupnost posebne
                                infrastrukture.
                            </div>
                        </div>
                    </Container>
                    <div className="fake_background blue"></div>
                </div>
                <Container>
                    <div className="content_wrapper">
                        <div className="title">KLIMA</div>
                        <div className="description">
                            UAE imaju suvu, suptropsku klimu koju karakterišu sparna leta i blage zime. UAE pere voda arapskog i omanskog zaliva. Najtoplija
                            sezona traje od juna do septembra, prosečna temperatura tokom ovog perioda je +42 ° S. Najbolji period za posetu UAE je od oktobra
                            do maja.
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">PRAZNICI</div>
                        <ul className="country_unorder_list">
                            <li>1. januara - Nova godina</li>
                            <li>2-3. Decembra - dan formiranja UAE. Ovo je glavni sekularni praznik u Ujedinjenim Arapskim Emiratima.</li>
                            <li>
                                2. decembar je slobodan dan. Na taj dan 1971. godine, emirati su potpisali sporazum o ujedinjenju. Slavi se u velikom obimu.
                                Vatromet, festivali, zabava, uređenje ulica i kvadrata. U Abu Dabiju organizuju vojnu paradu i demonstraciju vojne opreme,
                                emisiju vazduhoplovstva UAE.
                            </li>
                            <li>
                                24. aprila - 23. maja - sveti mesec Ramazan (datum varira u zavisnosti od kalendara). Početak strogog posta. Period kada je
                                prorok pronašao sveti rukopis Kuran. Vreme je za hodočašće, duhovno i telesno čišćenje.
                            </li>
                            <li>
                                24. - 26. maja 2020. - Ramazanski bajram (datum varira u zavisnosti od kalendara). Jedan od glavnih muslimanskih praznika. Dani
                                proslave: darivanje, sastanci sa rođacima i gozbe. Odeća bi trebala biti nova. Uobičajeno je biti raspoložen. Po običaju obilaze
                                grobove rođaka, dele priloge.
                            </li>
                            <li>
                                1. - 3. avgusta 2020. - Kurban-bajram (datum varira u zavisnosti od kalendara). Jedan od glavnih dana žrtvovanja. Baca se
                                kamenje na stubove radi zaštite od đavola.
                            </li>
                            <li>
                                29. decembra 2020 - Rođendan proroka Muhameda. Proslavlja se 12. dana u mesecu Rabi al-avval. Najavljen je slobodan dan, ali se
                                ne održavaju veličanstveni događaji. Organizujte predavanja i seminare na verske teme.
                            </li>
                        </ul>
                    </div>
                </Container>
            </div>
        );
    }
}

export default AboutUAE;
