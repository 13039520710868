import React, { Component } from "react";
import { Container } from "react-bootstrap";
import AboutCountryInformations from "../../Reusable/AboutCountryInformations";
import Gallery from "../../Reusable/Gallery";

import AboutGreeceImage1 from "../../../static/images/About_Country/About_Greece/Religion/about-greece_religion_2.png";

class AboutGreeceReligion extends Component {
    render() {
        return (
            <div className="about_greece_religion">
                <AboutCountryInformations
                    image={require("../../../static/images/About_Country/About_Greece/Religion/about-greece_religion_1.png")}
                    title={"Religija Grčke"}
                    description={`Zvanična religija u Grčkoj je Pravoslavlje, sa oko 98% vernika. Crkva nije nezavisna institucija (zemlja u kojoj je pravoslavna crkva-državna crkva). Grčka pravoslavna crkva je autokefalna i njeno sedište se nalazi u Atini. Nalazi se na 11. mestu u diptihu pravoslavnih crkava sa rangom arhiepiskopije. U isto vreme pravoslavne crkve monaške republike na Svetoj Gori Atos, crkve Dvanaest ostrva (Dodekanisou) i crkve na Kritu su u nadležnosti Vaseljenske Patrijašije sa sedištem u Carigradu. U skladu sa Konstitucijom, pravoslavno hrišćanstvo je zvanična religija države.`}
                />
                <Container>
                    <div className="content_wrapper">
                        <div className="description">
                            Pored pravoslavlja u Grčkoj su zastupljeni i drugi vidovi hrišćanstva. Najzastupljeniji posle pravoslavlja je katolicizam. Tokom
                            vladavine Mlečana, katolicizam je ostavio jak trag na meštane nekih ostrva Egejskog mora.
                        </div>
                    </div>
                    <img src={AboutGreeceImage1} alt="Greece religion 1" style={{ marginBottom: 89 }} />
                    <Gallery />
                </Container>
            </div>
        );
    }
}

export default AboutGreeceReligion;
