import { takeEvery, fork, put, all, call } from "redux-saga/effects"; // remove comment when you start write functions

import { GET_SLIDER, GET_MAIN_DESTINATIONS } from "./actionTypes";
import { getSliderSuccess, getSliderError, getHomeMainDestinationsSuccess, getHomeMainDestinationsError } from "./actions";
import { getSlider, getMainDestinations } from "../../helpers/api/home";

function* getSliderSaga() {
    try {
        const response = yield call(getSlider);
        if (response && response.data.status === 200) {
            yield put(getSliderSuccess(response.data.data));
        } else {
            yield put(getSliderError(response.error));
        }
    } catch (error) {
        yield put(getSliderError(error));
    }
}

function* getMainDestinationsSaga() {
    try {
        const response = yield call(getMainDestinations);
        if (response && response.data.statusCode === 200) {
            yield put(getHomeMainDestinationsSuccess(response.data.results));
        } else {
            yield put(getHomeMainDestinationsError(response.error));
        }
    } catch (error) {
        yield put(getHomeMainDestinationsError(error));
    }
}

export function* watchSliderSaga() {
    yield takeEvery(GET_SLIDER, getSliderSaga);
}

export function* watchGetMainDestinationsSaga() {
    yield takeEvery(GET_MAIN_DESTINATIONS, getMainDestinationsSaga);
}

function* homeSaga() {
    yield all([fork(watchSliderSaga), fork(watchGetMainDestinationsSaga)]);
}

export default homeSaga;
