import Greece1 from "../images/Big_Thumbnail/Greece_big_thumbnail.png";
import Greece2 from "../images/Big_Thumbnail/Greece2_big_thumbnail.png";

// import Montenegro1 from "../images/Big_Thumbnail/Montenegro_big_thumbnail.png";
// import Egypt1 from "../images/Big_Thumbnail/Egypt_big_thumbnail.png";
// import Turkey1 from "../images/Big_Thumbnail/Turkey_big_thumbnail.png";
// import Albania1 from "../images/Big_Thumbnail/Albania_big_thumbnail.png";
// import Bulgaria1 from "../images/Big_Thumbnail/Bulgaria_big_thumbnail.png";

import GreeceStay1 from "../images/Stay_Images/Greece_Stay1.png";
import GreeceStay2 from "../images/Stay_Images/Greece_Stay1.png";
import GreeceStay3 from "../images/Stay_Images/Greece_Stay1.png";

export const countriesFakeData = [
    {
        id: 0,
        color: "#EDFAFD",
        cutTop: 60,
        types: [
            {
                type: "hotels",
                image: Greece1,
                country: "Grčka",
                typeName: "Hotelski smeštaj",
                locations: ["Halkidiki", "Olimpijska regija", "Peloponez", "Krit", "Rados", "Zakintos", "Dalje 1", "Dalje 2", "Dalje 3"],
                id: 0,
                promoOffers: [
                    { id: 0, name: "Aqua Bay Hotel", image: GreeceStay1, discount: 22, oldPrice: 800, newPrice: 500, stars: 5 },
                    { id: 1, name: "Canaves Oia Hotel", image: GreeceStay2, discount: 20, oldPrice: 800, newPrice: 500, stars: 4.6 },
                    { id: 2, name: "Aqua Bay Hotel 2", image: GreeceStay3, discount: 30, oldPrice: 800, newPrice: 500, stars: 4 },
                    { id: 3, name: "Aqua Bay Hotel 3 ", image: GreeceStay1, discount: 40, oldPrice: 800, newPrice: 500, stars: 3.6 },
                    { id: 4, name: "Aqua Bay Hotel 4", image: GreeceStay1, discount: 15, oldPrice: 800, newPrice: 500, stars: 3 },
                ],
            },
            {
                type: "apartments",
                image: Greece2,
                country: "Grčka",
                typeName: "Apartmani",
                locations: ["Halkidiki", "Olimpijska regija", "Tasos", "Krf", "Zakintos"],
                id: 0,
                promoOffers: [
                    { id: 0, name: "Aqua Bay Hotel", image: GreeceStay1, discount: 22, oldPrice: 800, newPrice: 500, stars: 5 },
                    { id: 1, name: "Canaves Oia Hotel", image: GreeceStay2, discount: 20, oldPrice: 800, newPrice: 500, stars: 4.3 },
                    { id: 2, name: "Aqua Bay Hotel 2", image: GreeceStay3, discount: 30, oldPrice: 800, newPrice: 500, stars: 4 },
                    { id: 3, name: "Aqua Bay Hotel 3 ", image: GreeceStay1, discount: 40, oldPrice: 800, newPrice: 500, stars: 3 },
                    { id: 4, name: "Aqua Bay Hotel 4", image: GreeceStay1, discount: 15, oldPrice: 800, newPrice: 500, stars: 3.8 },
                ],
            },
        ],
    },
    // {
    //     id: 1,
    //     color: "#FFF5F5",
    //     cutTop: 108,
    //     types: [
    //         {
    //             image: Montenegro1,
    //             country: "Crna Gora",
    //             typeName: "Hotelski i Apartmanski smeštaj",
    //             locations: ["Budva", "Kotor", "Petrovac", "Tivat"],
    //             id: 0,
    //             promoOffers: [
    //                 { id: 0, name: "Aqua Bay Hotel", image: GreeceStay1, discount: 22, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 1, name: "Canaves Oia Hotel", image: GreeceStay2, discount: 20, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 2, name: "Aqua Bay Hotel 2", image: GreeceStay3, discount: 30, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 3, name: "Aqua Bay Hotel 3 ", image: GreeceStay1, discount: 40, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 4, name: "Aqua Bay Hotel 4", image: GreeceStay1, discount: 15, oldPrice: 800, newPrice: 500, stars: 5 },
    //             ],
    //         },
    //         {
    //             image: Egypt1,
    //             country: "Egipat",
    //             typeName: "Hotelski Smeštaj",
    //             locations: ["Aleksandria", "Cairo", "El-Gouana", "Hurgada", "Marsa-Alam", "Sharm El Sheik"],
    //             id: 0,
    //             promoOffers: [
    //                 { id: 0, name: "Aqua Bay Hotel", image: GreeceStay1, discount: 22, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 1, name: "Canaves Oia Hotel", image: GreeceStay2, discount: 20, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 2, name: "Aqua Bay Hotel 2", image: GreeceStay3, discount: 30, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 3, name: "Aqua Bay Hotel 3 ", image: GreeceStay1, discount: 40, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 4, name: "Aqua Bay Hotel 4", image: GreeceStay1, discount: 15, oldPrice: 800, newPrice: 500, stars: 5 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: 2,
    //     color: "#FBF2E2",
    //     cutTop: 132,
    //     types: [
    //         {
    //             image: Turkey1,
    //             country: "Turska",
    //             typeName: "Hotelski Smeštaj",
    //             locations: ["Bodrum", "Istanbul", "Izmir", "Kusadasi", "Marmaris"],
    //             id: 0,
    //             promoOffers: [
    //                 { id: 0, name: "Aqua Bay Hotel", image: GreeceStay1, discount: 22, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 1, name: "Canaves Oia Hotel", image: GreeceStay2, discount: 20, oldPrice: 800, newPrice: 500, stars: 4.3 },
    //                 { id: 2, name: "Aqua Bay Hotel 2", image: GreeceStay3, discount: 30, oldPrice: 800, newPrice: 500, stars: 4 },
    //                 { id: 3, name: "Aqua Bay Hotel 3 ", image: GreeceStay1, discount: 40, oldPrice: 800, newPrice: 500, stars: 3 },
    //                 { id: 4, name: "Aqua Bay Hotel 4", image: GreeceStay1, discount: 15, oldPrice: 800, newPrice: 500, stars: 3.8 },
    //             ],
    //         },
    //         {
    //             image: Albania1,
    //             country: "Albania",
    //             typeName: "Hotelski Smeštaj",
    //             locations: ["Drač", "Saranda", "Valona"],
    //             id: 0,
    //             promoOffers: [
    //                 { id: 0, name: "Aqua Bay Hotel", image: GreeceStay1, discount: 22, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 1, name: "Canaves Oia Hotel", image: GreeceStay2, discount: 20, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 2, name: "Aqua Bay Hotel 2", image: GreeceStay3, discount: 30, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 3, name: "Aqua Bay Hotel 3 ", image: GreeceStay1, discount: 40, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 4, name: "Aqua Bay Hotel 4", image: GreeceStay1, discount: 15, oldPrice: 800, newPrice: 500, stars: 5 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: 3,
    //     color: "#EAF2F7",
    //     cutTop: 123,
    //     types: [
    //         {
    //             image: Bulgaria1,
    //             country: "Bugarska",
    //             typeName: "Hotelski Smeštaj",
    //             locations: ["Burgas", "Varna"],
    //             id: 0,
    //             promoOffers: [
    //                 { id: 0, name: "Aqua Bay Hotel", image: GreeceStay1, discount: 22, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 1, name: "Canaves Oia Hotel", image: GreeceStay2, discount: 20, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 2, name: "Aqua Bay Hotel 2", image: GreeceStay3, discount: 30, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 3, name: "Aqua Bay Hotel 3 ", image: GreeceStay1, discount: 40, oldPrice: 800, newPrice: 500, stars: 5 },
    //                 { id: 4, name: "Aqua Bay Hotel 4", image: GreeceStay1, discount: 15, oldPrice: 800, newPrice: 500, stars: 5 },
    //             ],
    //         },
    //     ],
    // },
];
