import React, { Component } from "react";
import "../../../styles/Header.scss";
import { Container, Nav, NavbarBrand, NavLink, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import MenuDropdownList from "../../Reusable/MenuDropdownList";
import Login from "../../Login";

import { destinations } from "../../../static/data/destinations";

import mail_icon from "../../../static/images/icons/mail_icon.png";
import phone_icon from "../../../static/images/icons/phone_icon.png";
import flag_icon from "../../../static/images/icons/flag_icon.png";
import logo from "../../../static/images/unlimited_travel_logo.png";

/**
 * Ovo nam je ceo header gore. Sastavljen je iz 3 dela.
 */

const transitionDuration = 200;

class HeaderDesktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            destinationOffersOpen: -1,
        };
    }

    toggleDropdownMenu = (destination, index) => {
        if (destination && destination?.offers) {
            let destinationOffersOpen = index;
            if (this.state.destinationOffersOpen === destinationOffersOpen) {
                destinationOffersOpen = -1;
            }
            setTimeout(() => {
                this.setState({ destinationOffersOpen });
            }, transitionDuration);
        }
    };

    render() {
        return (
            <header className={`${this.props.shouldBeAbsolute ? "header-absolute" : ""}`}>
                <div className="top-header">
                    <Container>
                        <div className="left-side font-marck-script">For the Best Moments of Your Life</div>
                        <div className="right-side font-poppins">
                            {this.props.exchangeRate ? <div className="top-menu-item">{`Kurs 1 € = ${this.props.exchangeRate} RSD`}</div> : <></>}
                            <Link to="/contact" className="top-menu-item">
                                <img src={mail_icon} alt="Mail icon" />
                                <span>Pošalji upit</span>
                            </Link>
                            <a href="tel:0604145130" className="top-menu-item">
                                <img src={phone_icon} alt="Phone icon" />
                                <span>060/4145130</span>
                            </a>
                        </div>
                    </Container>
                </div>
                <div className="center-header font-poppins">
                    <Container>
                        <Nav>
                            <Link to="/">
                                <NavbarBrand>
                                    <img src={logo} width="30" height="30" alt="Unlimited travel logo" />
                                </NavbarBrand>
                            </Link>
                            <div className="right-nav-wrapper">
                                <NavLink href="/special-offers/first-last-minutes">LAST/FIRST MINUTE</NavLink>
                                <NavLink href="/cooming-soon">LETO 2022.</NavLink>
                                <NavLink href="/cooming-soon">EVROPSKI GRADOVI</NavLink>
                                <NavLink href="/cooming-soon">SRBIJA</NavLink>
                                <NavLink href="/cooming-soon">WELLNES</NavLink>
                                <NavLink href="/cooming-soon">DALEKA PUTOVANJA</NavLink>
                                <NavDropdown title="VESTI">
                                    <NavDropdown.Item href="/blog">BLOG</NavDropdown.Item>
                                    <NavDropdown.Item href="/notifications">OBAVEŠTENJA</NavDropdown.Item>
                                </NavDropdown>
                                <Login />
                            </div>
                        </Nav>
                    </Container>
                </div>
                <div className="bottom-header font-poppins">
                    <Container>
                        <div className="country-list">
                            {destinations.map((destination, key) => (
                                <div className="position-relative" key={key} onClick={() => this.toggleDropdownMenu(destination, key)}>
                                    <div className="country-item">
                                        {destination?.title}
                                        <img src={flag_icon} alt="Flag icon" />
                                    </div>

                                    <MenuDropdownList
                                        offers={destination?.offers}
                                        isIn={this.state.destinationOffersOpen === key}
                                        className="menu-offers-wrapper"
                                    />
                                </div>
                            ))}
                        </div>
                    </Container>
                </div>
            </header>
        );
    }
}

export default HeaderDesktop;
