import React, { Component } from "react";

import "../../../styles/Registration.scss";
import "../../../styles/Mobile/Registration.scss";

import RegistrationProfiles from "../../Reusable/RegistrationProfiles";
import UsersForm from "../../Reusable/UsersForm";

class RegistrationMobile extends Component {
    render() {
        return (
            <div className="registration_content_mobile_wrapper">
                <RegistrationProfiles active={this.props.activeProfile} setActiveProfile={this.props.setActiveProfile} />
                {this.props.registrationStatus ? (
                    <div className="status_message" style={{ color: this.props.registrationStatus?.status === "success" ? "green" : "red" }}>
                        {this.props.registrationStatus?.message}
                    </div>
                ) : (
                    <></>
                )}
                <UsersForm
                    formType={"registration"}
                    activeProfile={this.props.activeProfile}
                    getUserData={this.props.getUserData}
                    getAgentData={this.props.getAgentData}
                    submitButton={"Registruj se"}
                />
            </div>
        );
    }
}

export default RegistrationMobile;
