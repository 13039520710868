import React, { Component } from "react";
import { Container } from "react-bootstrap";
import AboutCountryInformations from "../../Reusable/AboutCountryInformations";
import Gallery from "../../Reusable/Gallery";

import AboutGreeceImage1 from "../../../static/images/About_Country/About_Greece/GeneralInformations/about_greece_img_1.png";
import AboutGreeceImage2 from "../../../static/images/About_Country/About_Greece/GeneralInformations/about_greece_img_2.png";

class AboutGreeceGeneralInformations extends Component {
    render() {
        return (
            <div className="about_greece_general_informations">
                <AboutCountryInformations
                    image={require("../../../static/images/About_Country/About_Greece/GeneralInformations/about_greece_informations.png")}
                    title={"ОPŠTE INFORMACIJE"}
                    description={`Kolevka Zapadne civilizacije i misli Grčka, uživa reputaciju turističke Meke. Današnji Grci svoj temperament ispoljavaju kroz
                            strastvenu muziku, maštovitu kuhinju kao i kroz mnogobrojne ekstremne sportove .Usled širokog dijapazona odredišta koja
                            zadovoljavaju čak i najzahtevnije turiste, a, preko netaknute prirode do danonoćnih žurki, maštovite i strastvene kuhinje i
                            luksuznih hotela, a sve to po povoljnim cenama, Grčka je godinama u nazad najpopularnija turistička. Kao posledica specifičnog
                            geografskog položaja jeste činjenica da Grčkih 3000 ostrva zapljuskuju čak tri mora!`}
                />
                <Container>
                    <div className="content_wrapper">
                        <div className="title">GRČKA UKRATKO</div>
                        <div className="description">
                            Pored toga što je zapljuskuju tri mora, Grčka se nalazi na raskrsnici 3 kontinenta: Azije, Afrike i Evrope. Grčka je jedna od
                            etnički najčistijih država na svetu. Prema popisu iz 2000.god. u Grčkoj živi oko 11 miliona stanovnika, od kojih su 95% Grci a
                            ostale etničke manjine mahom čine Turci. Fascinanatan je podatak da je ovo jedina zemlja koju svake godine poseti dva puta više
                            turista od ukupnog broja stanovnika
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">ZEMLJA FILOZOFA I BOGOVA</div>
                        <div className="description">
                            Podatak da Grčka ima više arheoloških muzeja od bilo koje druge države na svetu nije ni malo začuđujući jer je gotovo čitava Grčka
                            jedna ogromna arheološka lokacija. A da bi se osetila atmosfera drevne kulture, nije neophodno posetiti neki od muzeja jer svaki
                            delić grčke zemlje sadrži ostatke antičke civilizacije! Inače je opštepoznato da je Grčka kulturološki raj za turiste, a bogatstvo,
                            značaj, i uticaj starih Grka na svetsku baštinu teško da reči mogu opisati.
                        </div>
                    </div>
                    <img src={AboutGreeceImage1} alt="Greece 1" />
                </Container>
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="title">SAVREMENI OBIČAJI</div>
                            <div className="description">
                                Savremeni Grci se po mnogo čemu razlikuju od svojih antičkih predaka. Većina današnjih Grka dobija ime po nekom svecu. Vrlo
                                važna grčka tradicija jeste da svako ko je ime dobio po svecu, proslavlja njegovo ime na dati dan u godini. Dan krštenja je
                                najvažniji dan u životu grčkog pravoslavca i obavlja se prve godine nakon rođenja deteta. Često se izbegava davanje imena
                                novorođenčetu pre krštenja. U ovoj zemlji se veruje da pljuvanje tera nesreću i zle duhe. Zato kada Grk čuje neku lošu vest, on
                                obično pljune tri puta zaredom. Takođe, kada vam upute kompliment, oni će pljunuti jedanput kako bi vas zaštitili od uroka. To
                                je neka vrsta popularnog sujeverja. Takođe, gest mahanja koji Grci nazivaju moutza, smatra se najvećom uvredom! Poznato je da su
                                Grci ekspresivni, da se ne stide da otvoreno pokažu emocije, pa govor tela ima važnu ulogu u izražavanju osećanja. Zbog toga oni
                                vole da plešu ali nažalost, poznajemo samo njihov mali deo. Zanimljiv je podatak da u Grčkoj postoji više od 4.000
                                tradicionalnih plesova!!!
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">NAJSTARIJI PISANI JEZIK KOJI JE I DALJE ŽIV</div>
                            <div className="description">
                                Iako se smatra da je kineski jezik stariji, arheološki nalazi pokazuju da je grčki oko 5 000 godina u neprestanoj upotrebi i
                                smatra se jednim od najuticajnijih i najbogatijih jezika, a latinski, engleski i španski su direktno ili indirektno povezani sa
                                grčkim. Iz tog razloga hiljade engleskih reči potiču upravo iz Grčkog: akademija, izvini, maraton, sirena, pismo i dr.
                            </div>
                        </div>
                        <img src={AboutGreeceImage2} alt="Greece 2" />
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <Container>
                    <div className="content_wrapper">
                        <div className="title">NAJSUNČANIJA ZEMLJA EVROPE</div>
                        <div className="description">
                            Prema knjizi "Grčka: Svetska čarolija", koju je napisala En Najnriks, Grčka je obasjana sunčevim zracima prosečno 250 dana godišnje.
                            Leti skoro da i nema padavina dok je zimski period praćen pljuskovima i vetrovima. Ovde po verovanju zima u Grčkoj nastupa kada se
                            temperatura spusti ispod 6 ºC, mada ta temperatura ne traje duže od 2 nedelje.
                        </div>
                    </div>
                    <Gallery />
                </Container>
            </div>
        );
    }
}

export default AboutGreeceGeneralInformations;
