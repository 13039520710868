import { takeEvery, fork, put, all, call } from "redux-saga/effects"; // remove comment when you start write functions

import { GET_BLOG, GET_SINGLE_BLOG } from "./actionTypes";
import { getBlogSuccess, getBlogError, getSingleBlogSuccess, getSingleBlogError } from "./actions";

import { getBlog, getSingleBlog } from "../../helpers/api/blog";

function* getBlogSaga() {
    try {
        const response = yield call(getBlog);
        if (response && response.data.status === 200) {
            yield put(getBlogSuccess(response.data.data));
        } else {
            yield put(getBlogError(response.error));
        }
    } catch (error) {
        yield put(getBlogError(error));
    }
}

function* getSingleBlogSaga({ payload }) {
    try {
        const response = yield call(getSingleBlog, { id: payload });
        if (response && response.data.status === 200) {
            yield put(getSingleBlogSuccess(response.data.data));
        } else {
            yield put(getSingleBlogError(response.error));
        }
    } catch (error) {
        yield put(getSingleBlogError(error));
    }
}

export function* watchGetBlogSaga() {
    yield takeEvery(GET_BLOG, getBlogSaga);
}

export function* watchGetSingleBlogSaga() {
    yield takeEvery(GET_SINGLE_BLOG, getSingleBlogSaga);
}

function* blogSaga() {
    yield all([fork(watchGetBlogSaga), fork(watchGetSingleBlogSaga)]);
}

export default blogSaga;
