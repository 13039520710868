import React, { Component } from "react";
import { Transition } from "react-transition-group";
import { connect } from "react-redux";

import "../../styles/Components/ProgramDropdown.scss";

import ArrowDownIcon from "../../static/images/icons/arrow_down_icon.png";
import PDFIcon from "../../static/images/icons/PDF_icon.png";

import { getProgramList } from "../../store/actions";
import { openPDFdocument } from "../../helpers/utils/pdfUtils";

const defaultCountryArrowStyle = {
    transition: `transform ${800}ms ease`,
    transform: "rotate(0deg)",
};
const transitionCountryArrowStyles = {
    entering: { transform: "rotate(180deg)" },
    entered: { transform: "rotate(180deg)" },
    exiting: { transform: "rotate(0deg)" },
    exited: { transform: "rotate(0deg)" },
};

const defaultSidebarStyle = {
    transition: `opacity ${500}ms ease-in-out`,
    opacity: 0,
};
const transitionSidebarStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0, zIndex: -1 },
};

class ProgramDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        this.props.getProgramList();
    }

    render() {
        return (
            <div className="position-relative">
                <Transition in={this.state.isOpen} timeout={500}>
                    {(state) => (
                        <>
                            <div className="program_dropdown_header" onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                                Program
                                <img
                                    src={ArrowDownIcon}
                                    alt={"Arrow"}
                                    style={{
                                        ...defaultCountryArrowStyle,
                                        ...transitionCountryArrowStyles[state],
                                    }}
                                />
                            </div>
                            <div
                                className="program_dropdown_list scrollbar"
                                style={{
                                    ...defaultSidebarStyle,
                                    ...transitionSidebarStyles[state],
                                }}
                            >
                                {this.props.programs?.map((item, key) => (
                                    <div className="program_dropdown_item_wrapper" key={key}>
                                        <div onClick={() => openPDFdocument(item.pdf_file)} className="program_dropdown_item_content">
                                            <img src={PDFIcon} alt={`Pdf icon ${key}`} />
                                            {item?.title}
                                        </div>
                                        {this.props.programs?.length - 1 !== key && <div className="program_dropdown_item_separator"></div>}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </Transition>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        programs: state.General.programs,
    };
};

export default connect(mapStateToProps, { getProgramList })(ProgramDropdown);
