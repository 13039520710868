import { takeEvery, fork, put, all, call } from "redux-saga/effects"; // remove comment when you start write functions

import { GET_NOTIFICATIONS, GET_SINGLE_NOTIFICATIONS } from "./actionTypes";
import { getNotificationsSuccess, getNotificationsError, getSingleNotificationsSuccess, getSingleNotificationsError } from "./actions";

import { getNotifications, getSingleNotifications } from "../../helpers/api/notifications";

function* getNotificationsSaga() {
    try {
        const response = yield call(getNotifications);
        if (response && response.data.status === 200) {
            yield put(getNotificationsSuccess(response.data.data));
        } else {
            yield put(getNotificationsError(response.error));
        }
    } catch (error) {
        yield put(getNotificationsError(error));
    }
}

function* getSingleNotificationsSaga({ payload }) {
    try {
        const response = yield call(getSingleNotifications, { id: payload });
        if (response && response.data.status === 200) {
            yield put(getSingleNotificationsSuccess(response.data.data));
        } else {
            yield put(getSingleNotificationsError(response.error));
        }
    } catch (error) {
        yield put(getSingleNotificationsError(error));
    }
}

export function* watchGetNotificationsSaga() {
    yield takeEvery(GET_NOTIFICATIONS, getNotificationsSaga);
}

export function* watchGetSingleNotificationsSaga() {
    yield takeEvery(GET_SINGLE_NOTIFICATIONS, getSingleNotificationsSaga);
}

function* notificationsSaga() {
    yield all([fork(watchGetNotificationsSaga), fork(watchGetSingleNotificationsSaga)]);
}

export default notificationsSaga;
