import React, { Component } from "react";
import MediaQuery from "react-responsive";

import LoggedUserDesktop from "./Desktop";
import LoggedUserMobile from "./Mobile";

class LoggedUser extends Component {
    render() {
        return (
            <>
                <MediaQuery minWidth={991}>
                    <LoggedUserDesktop {...this.props} />
                </MediaQuery>
                <MediaQuery maxWidth={990}>
                    <LoggedUserMobile {...this.props} />
                </MediaQuery>
            </>
        );
    }
}

export default LoggedUser;
