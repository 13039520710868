import React, { Component } from "react";
import MediaQuery from "react-responsive";

import BookingLoadingSkeletonMobile from "./Mobile";
import BookingLoadingSkeletonDesktop from "./Desktop";

class BookingLoadingSkeleton extends Component {
    render() {
        return (
            <>
                <MediaQuery minWidth={1200}>
                    <BookingLoadingSkeletonDesktop />
                </MediaQuery>
                <MediaQuery maxWidth={1199}>
                    <BookingLoadingSkeletonMobile />
                </MediaQuery>
            </>
        );
    }
}

export default BookingLoadingSkeleton;
