export const getFavoritesHotels = () => {
    let hotels = JSON.parse(localStorage.getItem("favorites-hotels"));
    return hotels;
};

export const editFavoritesHotels = (data) => {
    let hotels = JSON.parse(localStorage.getItem("favorites-hotels"));
    let arr = [];

    if (hotels?.some((hotel) => hotel?.id === data.id)) {
        arr = hotels?.filter((hotel) => hotel?.id !== data?.id);
    } else {
        if (hotels && hotels?.length) {
            arr = [...hotels, data];
        } else {
            arr = [data];
        }
    }

    localStorage.setItem("favorites-hotels", JSON.stringify(arr));
};

export const checkFavoritesHotels = (data) => {
    let hotels = JSON.parse(localStorage.getItem("favorites-hotels"));
    return hotels?.some((hotel) => hotel?.id === data.id);
};
