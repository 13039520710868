import React, { Component } from "react";

class Maps extends Component {
    render() {
        return (
            <iframe
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_API_MAPS}&q=${this.props.location?.latitude},${this.props.location?.longitude}`}
                width="100%"
                height="202"
                style={{ border: 0 }}
                allowFullScreen={false}
                loading="lazy"
                title="maps"
            ></iframe>
        );
    }
}

export default Maps;
//AIzaSyCHLS3mfegVY3L_7XrQ_alecj5tLQZDfvI
