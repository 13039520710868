import {
    GET_SPECIAL_OFFERS_HOTELS,
    GET_SPECIAL_OFFERS_HOTELS_SUCCESS,
    GET_SPECIAL_OFFERS_HOTELS_ERROR,
    GET_SPECIAL_OFFERS_APARTMENTS,
    GET_SPECIAL_OFFERS_APARTMENTS_SUCCESS,
    GET_SPECIAL_OFFERS_APARTMENTS_ERROR,
    GET_FIRST_LAST_MINUTES_OFFERS,
    GET_FIRST_LAST_MINUTES_OFFERS_SUCCESS,
    GET_FIRST_LAST_MINUTES_OFFERS_ERROR,
} from "./actionTypes";

export const getSpecialOffersHotels = () => {
    return {
        type: GET_SPECIAL_OFFERS_HOTELS,
    };
};
export const getSpecialOffersHotelsSuccess = (payload) => {
    return {
        type: GET_SPECIAL_OFFERS_HOTELS_SUCCESS,
        payload,
    };
};
export const getSpecialOffersHotelsError = (payload) => {
    return {
        type: GET_SPECIAL_OFFERS_HOTELS_ERROR,
        payload,
    };
};

export const getSpecialOffersApartments = () => {
    return {
        type: GET_SPECIAL_OFFERS_APARTMENTS,
    };
};
export const getSpecialOffersApartmentsSuccess = (payload) => {
    return {
        type: GET_SPECIAL_OFFERS_APARTMENTS_SUCCESS,
        payload,
    };
};
export const getSpecialOffersApartmentsError = (payload) => {
    return {
        type: GET_SPECIAL_OFFERS_APARTMENTS_ERROR,
        payload,
    };
};

export const getFirstLastMinutesOffers = () => {
    return {
        type: GET_FIRST_LAST_MINUTES_OFFERS,
    };
};
export const getFirstLastMinutesOffersSuccess = (payload) => {
    return {
        type: GET_FIRST_LAST_MINUTES_OFFERS_SUCCESS,
        payload,
    };
};
export const getFirstLastMinutesOffersError = (payload) => {
    return {
        type: GET_FIRST_LAST_MINUTES_OFFERS_ERROR,
        payload,
    };
};
