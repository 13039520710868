import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";

import BlogItem from "../Reusable/BlogItem";

import "../../styles/Components/HomeNotificationsBlog.scss";

class HomeNotificationsBlog extends Component {
    render() {
        return (
            <>
                {(this.props.notifications || this.props.blogs) && (
                    <div className="home-notifications-blog-wrapper">
                        <Container className="home-notifications-blog-content-wrapper font-poppins">
                            {this.props.notifications && (
                                <div className="content-wrapper">
                                    <div className="title">Obaveštenja</div>
                                    <div className="content-items">
                                        {this.props.notifications?.map((item, key) => (
                                            <div className="notifications-item-wrapper" key={key}>
                                                <img src={`data:image/png;base64,${item.image_file}`} alt={`notifications-images-${key}`} />
                                                <div className="notifications-content-item-wrapper font-poppins">
                                                    <div className="date-title-wrapper">
                                                        <div className="date">{format(parseISO(item.updated_at), "dd.MM.yyyy")}</div>
                                                        <div className="title">{item.title}</div>
                                                    </div>
                                                    <div className="description" dangerouslySetInnerHTML={{ __html: this.props.item?.description }}></div>
                                                    <Link to={`/notifications/${item.id}`} className="read-more">
                                                        Pročitaj
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {this.props.blogs && (
                                <div className="content-wrapper">
                                    <div className="title">Naš Blog</div>
                                    <div className="content-items">
                                        {this.props.blogs?.slice(0, 3)?.map((item, key) => (
                                            <BlogItem item={item} index={key} key={key} />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Container>
                        <div className="fake_background"></div>
                    </div>
                )}
            </>
        );
    }
}

export default HomeNotificationsBlog;
