import React, { Component } from "react";
import { Container } from "react-bootstrap";
import AboutCountryInformations from "../../Reusable/AboutCountryInformations";

import AboutGreeceImage1 from "../../../static/images/About_Country/About_Greece/Vacation/about_greece_vacation_2.png";
import AboutGreeceImage2 from "../../../static/images/About_Country/About_Greece/Vacation/about_greece_vacation_3.png";

class AboutGreeceVacation extends Component {
    render() {
        return (
            <div className="about_greece_vacation">
                <AboutCountryInformations
                    image={require("../../../static/images/About_Country/About_Greece/Vacation/about_greece_vacation_1.png")}
                    title={"Odmor u Grčkoj"}
                    description={`Da li znate koja je tajna uživanja u Grčkoj? Bez sumnje, jedan od odgovora je sposobnost da se opustite na grčki nacin: od srca, iskreno i očaravajuce.  Turizam se  konstantno razvija, o tome svi vode računa, posebno stalna briga lokalne samouprave, ulaganje u infrastukturu i očuvanje prirodnih lepota.Temperament grčkog naroda, posebno se ogleda u originalnoj muzici i plesu, ispunjava životnom energijom sve koji posete ovu zemlju. Svake godine Grčka postaje sve popularnija turistička destinacija kako za letovanje tako i za ostale vidove turizma. To nikog ne iznenađuje zato što je grčki narod veoma gostoljubiv a Grčka obiluje prirodnim lepotama, odličnom klimom, kao i bogatim istorijskim i kulturnim nasleđem.`}
                />
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="title">Grčka - Ekskurzije</div>
                            <div className="description">
                                Leto u Grčkoj hrani dušu, okrepljuje telo i daje snagu i samopouzdanje dostojno herojima grčke mitologije. Letovanje u Grčkoj je
                                oduvek privlačilo turiste zato sto imaju priliku da uzivaju ali i da obiđu neprocenljivo blago koje svedoči o temeljima evropske
                                kulture. Tokom uzbudljivih ekskurzija postajete svedoci procvata drevnih gradova-drzava, bićete fascinirani brojnim spomenicima,
                                gradjevinama i arheoloskim nalazistima.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Letovanje u Grckoj</div>
                            <div className="description">
                                Grčka - zemlja u kojoj su plaže iz godine u godinu odlikovane "Plavom Zastavicom" EU za čistoću obale i mora. Bilo da je u
                                pitanju Halkidiki ili neko od ostrva: Krit, Rodos, Krf, Santorini, Mikonos…uživacete u prelepim plažama i okruženju.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Letovanje sa decom</div>
                            <div className="description">
                                Nema sumnje da je Grčka odličan izbor za Vaše nezaboravno i bezbrižno letovanje! Skoro svaki grčki hotel brine o najmlađim
                                gostima nudeći bezbedan i zabavan sadržaj za najmlađe, edukativne igre u svojim "mini klubova" što i roditeljima pruža mogućnost
                                da se malo vremena posvete sebi.
                            </div>
                        </div>
                        <img src={AboutGreeceImage1} alt="Greece Vacation 1" />
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="title">Specijalne ponude </div>
                            <div className="description">
                                Jedan od najvećih turoperatora u Grčkoj - UnlimitedTravel, sa mnogo godina iskustva u ovom sektoru, uvek pruža samo najbolje
                                ponude za letovanje u Grčkoj, sa cenama koje su u stanju da prijatno iznenade svakog od naših klijenata. Mi smo tu da se
                                pobrinemo za Vaš dobar provod a isto tako mislimo i na Vaš budžet, u ponudi su i luksuzna i ekskluzivna odmarališta po jako
                                pristupačnim cenama. Takođe, uvek nudimo specijalne popuste i akcije, kako za rane uplate "early booking" tako i za one nekoliko
                                dana pred polazak "last minute". Pored toga, posebno za one koji ne žele da se zamaraju bilo čime tokom odmora sistem "All
                                Inclusive" je idealan da Vaše letovanje u Grčkoj bude potpuno uživanje.
                            </div>
                        </div>
                        <img src={AboutGreeceImage2} alt="Greece Vacation 2" />
                    </Container>
                    <div className="fake_background blue"></div>
                </div>
            </div>
        );
    }
}

export default AboutGreeceVacation;
