import React, { Component } from "react";
import { Container } from "react-bootstrap";
import MediaQuery from "react-responsive";
import Carousel from "../Reusable/Carousel";

import "../../styles/Components/AboutCountryCarousel.scss";

class AboutCountryCarousel extends Component {
    render() {
        return (
            <div className="carousel_wrapper">
                <MediaQuery maxWidth={767}>
                    <Carousel data={this.props.images} indicators={true} className={"mobile-carousel"} />
                </MediaQuery>
                <MediaQuery minWidth={767}>
                    <Carousel data={this.props.images} indicators={false} arrows={true} />
                </MediaQuery>
                <div className="country_carousel_name_description_wrapper">
                    <Container>
                        <div className="title font-proza-libre">{this.props.country?.title}</div>
                        <div className="description">{this.props.country?.description}</div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default AboutCountryCarousel;
