import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";

import "../../../../styles/Mobile/MainFooter.scss";

import sun_icon from "../../../../static/images/icons/sun_icon.png";
import logo_white from "../../../../static/images/unlimited_travel_logo_white.png";

const schema = yup.object().shape({
    email: yup.string().email("E-mail adresa nije validna").required("Polje mora biti popunjeno"),
});

class MainFooterMobile extends Component {
    render() {
        return (
            <div className="footer-main-mobile w-100 m-0">
                <Col className="footer-main-logo">
                    <div className="footer-main-sun-icon">
                        <img src={sun_icon} alt="Sun footer logo" />
                    </div>
                    <img src={logo_white} alt="Unlimited logo white" />
                </Col>

                <div className="footer-main-send-email font-poppins">
                    <div className="footer-main-send-email-description">
                        <div id="title">Unesite svoj e-mail</div>
                        <div id="text">Pretplatite se za ekskluzivne bonuse, besplatne izlete i vesti</div>
                    </div>
                    <div className="footer-main-send-email-input ">
                        <Formik
                            validationSchema={schema}
                            initialValues={{
                                email: "",
                            }}
                            onSubmit={(values, actions) => {
                                this.props.subscribeNewsletters(values);
                                actions.resetForm();
                            }}
                        >
                            {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                                <>
                                    <Form.Group>
                                        <Form.Control
                                            type={"email"}
                                            name="email"
                                            placeholder="Vaš e-mail"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.email && errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                    <div id="button" onClick={handleSubmit}>
                                        Pretplata
                                    </div>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
                <div className="footer-main-quick-links font-poppins">
                    <div className="footer-main-quick-links-item">
                        <span>Ponuda - leto 2022.</span>
                        <div className="footer-main-quick-links-separator"></div>
                        <div className="footer-main-quick-links-item-links">
                            <Link to="/cooming-soon">Grčka</Link>
                            <Link to="/cooming-soon">Crna Gora</Link>
                            <Link to="/cooming-soon">Egipat</Link>
                            <Link to="/cooming-soon">Turska</Link>
                            <Link to="/cooming-soon">Albanija</Link>
                            <Link to="/cooming-soon">Bugarska</Link>
                            <Link to="/cooming-soon">Srbija - Pešterska visoravan</Link>
                            <Link to="/cooming-soon">Daleke destinacije</Link>
                        </div>
                    </div>
                    <div className="footer-main-quick-links-item">
                        <span>GRČKA - leto 2022.</span>
                        <div className="footer-main-quick-links-separator"></div>
                        <div className="footer-main-quick-links-item-links">
                            <Link to="/booking?selectedPlace=OT-LOC-GEO-400665">Hotelski smeštaj</Link>
                            <Link to="/booking?selectedPlace=OT-LOC-GEO-400665&aparments=true">Apartmanski smeštaj</Link>
                            <Link to="/booking?selectedPlace=OT-LOC-GEO-400665">Sopsteveni prevoz</Link>
                            <Link to="/booking?selectedPlace=OT-LOC-GEO-400665&transport=bus">Autobuski prevoz</Link>
                            <Link to="/special-offers/hotels">Special Offer - hoteli</Link>
                            <Link to="/special-offers/apartments">Special Offer - apartmani</Link>
                        </div>
                    </div>
                    <div className="footer-main-quick-links-item">
                        <span>IZDVAJAMO IZ PONUDE</span>
                        <div className="footer-main-quick-links-separator"></div>
                        <div className="footer-main-quick-links-item-links">
                            <Link to="/special-offers/first-last-minutes">Grčka - last / first minute</Link>
                            <Link to="/cooming-soon">leto 2022.</Link>
                        </div>
                    </div>
                    <div className="footer-main-quick-links-item">
                        <span>INFORMACIJE</span>
                        <div className="footer-main-quick-links-separator"></div>
                        <div className="footer-main-quick-links-item-links">
                            <Link to="/contact">Kontakt</Link>
                            <Link to="/cooming-soon">Garancija putovanja</Link>
                            <Link to="/cooming-soon">Licenca</Link>
                            <Link to="/cooming-soon">Opšti uslovi putovanja</Link>
                            <Link to="/cooming-soon">Pravilnik o žaštiti podataka o ličnosti</Link>
                            <Link to="/cooming-soon">Obaveštenje o predugovnim informacijama</Link>
                            <Link to="/cooming-soon">Putno zdrastveno osiguranje</Link>
                            <Link to="/cooming-soon">Podaci o identifikaciji firme</Link>
                            <Link to="/cooming-soon">Instrukcija o deviznom plaćanju</Link>
                            <Link to="/cooming-soon">Uslovi ulaska za odabrane destinacije i važeći protokoli</Link>
                            <Link to="/cooming-soon">Online rezervacije</Link>
                            <Link to="/cooming-soon">Saradnja</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainFooterMobile;
