export const COUNTRIES = {
    GREECE: {
        carousel: {
            images: [
                require("../../static/images/About_Country/About_Greece/grcka-1.webp"),
                require("../../static/images/About_Country/About_Greece/grcka-2.webp"),
                require("../../static/images/About_Country/About_Greece/grcka-3.webp"),
            ],
            country: {
                title: "Upoznajte Grčku",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae enim urna. Nunc elementum cursus diam.",
            },
        },
        filters: {
            informations: { title: "Opšte informacije" },
            cities: { title: "Gradovi i regije", link: "/destinations", params: "GREECE" },
            history: { title: "Istorija" },
            religion: { title: "Pravoslavlje u Grčkoj" },
            holidays: { title: "Praznici u Grčkoj" },
            food: { title: "Kuhinja" },
            people: { title: "Grci i Grkinje" },
            vacation: { title: "Odmor u Grčkoj" },
            dictionary: { title: "Rečnik" },
        },
        dictionary: [
            {
                title: "Najčešće korišćene reči",
                icon: require("../images/icons/notebook_icon.png"),
                words: [
                    {
                        language1: "Potreban mi je prevodilac",
                        language2: "HRIZOMAI DERMINEA",
                    },
                    {
                        language1: "Dobro jutro",
                        language2: "Kalimera",
                    },
                    {
                        language1: "Dobro veče",
                        language2: "Kalispera",
                    },
                    {
                        language1: "Do vidjenja",
                        language2: "Yasu",
                    },
                    {
                        language1: "Sve najbolje",
                        language2: "Sto kalo",
                    },
                    {
                        language1: "Laku noć",
                        language2: "Kalinihta",
                    },
                    {
                        language1: "Gospodin",
                        language2: "Kirios",
                    },
                    {
                        language1: "Gospođa",
                        language2: "Kiria",
                    },
                    {
                        language1: "Kako si?",
                        language2: "Ti kanis? Pos ise?",
                    },
                    {
                        language1: "Kako ste?",
                        language2: "Тi kanete? Pos iste?",
                    },
                    {
                        language1: "Ok",
                        language2: "Kala",
                    },
                    {
                        language1: "Vrlo dobro",
                        language2: "Тi kanete? Pos iste?",
                    },
                    {
                        language1: "Kako ste?",
                        language2: "Poli kala",
                    },
                    {
                        language1: "Loše",
                        language2: "Ashima",
                    },
                    {
                        language1: "Hvala",
                        language2: "Efharisto",
                    },
                    {
                        language1: "Molim vas",
                        language2: "Oriste",
                    },
                    {
                        language1: "Izvinite",
                        language2: "Signomi",
                    },
                    {
                        language1: "Mnogo",
                        language2: "Poli",
                    },
                    {
                        language1: "Malo",
                        language2: "Ligo",
                    },
                    {
                        language1: "Ja ne govorim grčki",
                        language2: "Den milao elenika",
                    },
                    {
                        language1: "Ne",
                        language2: "Ohi",
                    },
                    {
                        language1: "Da",
                        language2: "Ne",
                    },
                    {
                        language1: "Kako se zoveš",
                        language2: "Pos se lene",
                    },
                    {
                        language1: "Zovem se",
                        language2: "Me lene",
                    },
                    {
                        language1: "Koliko imaš godina",
                        language2: "Poso horonon ise",
                    },
                    {
                        language1: "Odakle si",
                        language2: "Apo pu ise",
                    },
                ],
            },
            {
                title: "U prodavnici",
                icon: require("../images/icons/shop_icon.png"),
                words: [
                    {
                        language1: "Prodavnica",
                        language2: "MАGAZI, KATASTIMA",
                    },
                    {
                        language1: "Kiosk",
                        language2: "periptero",
                    },
                    {
                        language1: "Pijaca",
                        language2: "Аgora, pazari",
                    },
                    {
                        language1: "Cena",
                        language2: "Тimi",
                    },
                    {
                        language1: "Račun",
                        language2: "Аpodiksi",
                    },
                    {
                        language1: "Novac",
                        language2: "Lefta, hrimata",
                    },
                    {
                        language1: "Veličina",
                        language2: "Numero",
                    },
                    {
                        language1: "Kilogram",
                        language2: "Kilo",
                    },
                    {
                        language1: "Gde je ... ?",
                        language2: "Pu ine...?",
                    },
                    {
                        language1: "Treba mi ...",
                        language2: "Telо...",
                    },
                    {
                        language1: "Koliko košta?",
                        language2: "Poso kani?",
                    },
                    {
                        language1: "Poklon",
                        language2: "Doro",
                    },
                    {
                        language1: "Kaput",
                        language2: "Palto",
                    },
                    {
                        language1: "Ogrtač",
                        language2: "mandýas",
                    },
                    {
                        language1: "Odelo",
                        language2: "Kustumi",
                    },
                    {
                        language1: "Pantalone",
                        language2: "Pandeloni",
                    },
                    {
                        language1: "Sako",
                        language2: "Sakaki",
                    },
                    {
                        language1: "Jakna",
                        language2: "Bufan",
                    },
                    {
                        language1: "Košulja",
                        language2: "Pukamiso",
                    },
                    {
                        language1: "Haljina",
                        language2: "Forema",
                    },
                    {
                        language1: "Bluza",
                        language2: "Bluza",
                    },
                    {
                        language1: "Suknja",
                        language2: "Fusta",
                    },
                    {
                        language1: "Donji veš",
                        language2: "Esoruha",
                    },
                    {
                        language1: "Kupaci",
                        language2: "Маjo",
                    },
                    {
                        language1: "Bade mantil",
                        language2: "Roba",
                    },
                    {
                        language1: "Cipele",
                        language2: "Papucia",
                    },
                    {
                        language1: "Papuče",
                        language2: "Pedila",
                    },
                    {
                        language1: "Papuče za kuću",
                        language2: "Pantofles",
                    },
                    {
                        language1: "Torba",
                        language2: "Canda",
                    },
                    {
                        language1: "Kaiš",
                        language2: "Zoni",
                    },
                ],
            },
            {
                title: "U hotelu",
                icon: require("../images/icons/hotel_icon.png"),
                words: [
                    {
                        language1: "Hotel",
                        language2: "Ksenodohio",
                    },
                    {
                        language1: "Recepcija",
                        language2: "Resepsion",
                    },
                    {
                        language1: "Salon",
                        language2: "Saloni",
                    },
                    {
                        language1: "Prtljag",
                        language2: "Aposkaves",
                    },
                    {
                        language1: "Kofer",
                        language2: "Valica",
                    },
                    {
                        language1: "Stepenica",
                        language2: "Skala",
                    },
                    {
                        language1: `Hodnik`,
                        language2: `Diadromos`,
                    },
                    {
                        language1: `Sprat`,
                        language2: `Orofos
                        `,
                    },
                    {
                        language1: `Galerija`,
                        language2: `Isio
                        `,
                    },
                    {
                        language1: `Prvi sprat`,
                        language2: `Protos orofos
                        `,
                    },
                    {
                        language1: `Soba`,
                        language2: `Domatio`,
                    },
                    {
                        language1: `Ključ`,
                        language2: `Klidi`,
                    },
                    {
                        language1: `Vrata`,
                        language2: `Porta`,
                    },
                    {
                        language1: `Prozor`,
                        language2: `Paratiro`,
                    },
                    {
                        language1: `Balkon`,
                        language2: `Balkoni`,
                    },
                    {
                        language1: `Klima`,
                        language2: `Erkondision`,
                    },
                    {
                        language1: `Račun`,
                        language2: `Logariazmos`,
                    },
                    {
                        language1: `Gde`,
                        language2: `Pu`,
                    },
                    {
                        language1: `Gde mogu da uzmem`,
                        language2: `Pu boro na paro`,
                    },
                ],
            },
            {
                title: "U restoranu",
                icon: require("../images/icons/restaurant_icon.png"),
                words: [
                    {
                        language1: `Restoran`,
                        language2: `Estiatorio`,
                    },
                    {
                        language1: `Kafe bar`,
                        language2: `Kafeteria`,
                    },
                    {
                        language1: `Konobar`,
                        language2: `Servitoros
                        `,
                    },
                    {
                        language1: `Meni`,
                        language2: `Katalogos`,
                    },
                    {
                        language1: `Porcija`,
                        language2: `Merida`,
                    },
                    {
                        language1: `Tanjir`,
                        language2: `Piato`,
                    },
                    {
                        language1: `Kašika`,
                        language2: `Kutali`,
                    },
                    {
                        language1: `Viljuška`,
                        language2: `Piruni`,
                    },
                    {
                        language1: `Nož`,
                        language2: `Maheri`,
                    },
                    {
                        language1: `Salveta`,
                        language2: `Hartopeceta`,
                    },
                    {
                        language1: `Želim da naručim`,
                        language2: `Ta itela na parangilo`,
                    },
                    {
                        language1: `Donesite mi račun`,
                        language2: `Mu fenete to logariazmo`,
                    },
                ],
            },
            {
                title: "Pića",
                icon: require("../images/icons/drinks_icon.png"),
                words: [
                    {
                        language1: `Voda`,
                        language2: `Nero`,
                    },
                    {
                        language1: `Gazirana pića`,
                        language2: `Anapsitiko`,
                    },
                    {
                        language1: `Mineralna voda`,
                        language2: `Metaliko nero`,
                    },
                    {
                        language1: `Soda voda`,
                        language2: `Soda`,
                    },
                    {
                        language1: `Sok`,
                        language2: `Himo`,
                    },
                    {
                        language1: `Od narandže`,
                        language2: `Portokali`,
                    },
                    {
                        language1: `Od breskve`,
                        language2: `Verikoko`,
                    },
                    {
                        language1: `Od višnje`,
                        language2: `Visino`,
                    },
                    {
                        language1: `Kafa`,
                        language2: `Kafes`,
                    },
                    {
                        language1: `Sa mlekom`,
                        language2: `Me gala`,
                    },
                    {
                        language1: `Slatka`,
                        language2: `Gliko`,
                    },
                    {
                        language1: `Srednja`,
                        language2: `Metrio`,
                    },
                    {
                        language1: `Bez šećera`,
                        language2: `Horis zahari`,
                    },
                    {
                        language1: `Grčka kafa`,
                        language2: `Elinikos kafes`,
                    },
                    {
                        language1: `Nes kafa`,
                        language2: `Neskafe`,
                    },
                    {
                        language1: `Čaj`,
                        language2: `Cai`,
                    },
                    {
                        language1: `Sa limunom`,
                        language2: `Me lemoni`,
                    },
                    {
                        language1: `Kakao`,
                        language2: `Kakao`,
                    },
                    {
                        language1: `Topla čokolada`,
                        language2: `Sokolata`,
                    },
                ],
            },
            {
                title: "Kod doktora",
                icon: require("../images/icons/ambulance_icon.png"),
                words: [
                    {
                        language1: `Doktor`,
                        language2: `Jatros`,
                    },
                    {
                        language1: `Sestra`,
                        language2: `Nosokoma`,
                    },
                    {
                        language1: `Bolnica`,
                        language2: `Nosokomio`,
                    },
                    {
                        language1: `Prva pomoć`,
                        language2: `Protes voites`,
                    },
                    {
                        language1: `Bolest`,
                        language2: `Arostia`,
                    },
                    {
                        language1: `Terapija`,
                        language2: `Terapia`,
                    },
                    {
                        language1: `Lek`,
                        language2: `Farmako`,
                    },
                    {
                        language1: `Rana`,
                        language2: `Pligi`,
                    },
                    {
                        language1: `Trauma`,
                        language2: `Travma`,
                    },
                    {
                        language1: `Pregled`,
                        language2: `Eksetasi`,
                    },
                    {
                        language1: `Rendgen`,
                        language2: `Aktionografia`,
                    },
                    {
                        language1: `Temperatura`,
                        language2: `Pireto`,
                    },
                    {
                        language1: `Bol`,
                        language2: `По́нос`,
                    },
                    {
                        language1: `Glavobolja`,
                        language2: `Ponokefalos`,
                    },
                    {
                        language1: `Vrtoglavica`,
                        language2: `Zalades`,
                    },
                    {
                        language1: `Nesanica`,
                        language2: `Lipnies`,
                    },
                    {
                        language1: `Pospanost`,
                        language2: `Nista`,
                    },
                    {
                        language1: `Kasalj`,
                        language2: `Vihas`,
                    },
                    {
                        language1: `Kijavica`,
                        language2: `Sinahi`,
                    },
                    {
                        language1: `Diarea`,
                        language2: `Diarea`,
                    },
                ],
            },
            {
                title: "Glavno jelo",
                icon: require("../images/icons/meal_icon.png"),
                words: [
                    {
                        language1: `Meso`,
                        language2: `Kreas`,
                    },
                    {
                        language1: `Govedina`,
                        language2: `Vodino`,
                    },
                    {
                        language1: `Teletina`,
                        language2: `Мoshariso`,
                    },
                    {
                        language1: `Jagnjetina`,
                        language2: `Аrniso`,
                    },
                    {
                        language1: `Svinjetina`,
                        language2: `Hirino`,
                    },
                    {
                        language1: `Piletina`,
                        language2: `Kotopulo`,
                    },
                    {
                        language1: `Jezik`,
                        language2: `Glosa`,
                    },
                    {
                        language1: `Ražnjić`,
                        language2: `Suvlaki`,
                    },
                    {
                        language1: `Ćevap`,
                        language2: `Sudzukakia`,
                    },
                    {
                        language1: `Kotlet`,
                        language2: `Kaftes`,
                    },
                    {
                        language1: `Riba na žaru`,
                        language2: `Tiganito psari`,
                    },
                ],
            },
            {
                title: "Brojevi",
                icon: require("../images/icons/numbers_icon.png"),
                words: [
                    {
                        language1: `Jedan`,
                        language2: `ena`,
                    },
                    {
                        language1: `Dva`,
                        language2: `Dio`,
                    },
                    {
                        language1: `Tri`,
                        language2: `Tria`,
                    },
                    {
                        language1: `Četiri`,
                        language2: `Tesera`,
                    },
                    {
                        language1: `Pet`,
                        language2: `Pende`,
                    },
                    {
                        language1: `Šest`,
                        language2: `Eksi`,
                    },
                    {
                        language1: `Sedam`,
                        language2: `Efta`,
                    },
                    {
                        language1: `Osam`,
                        language2: `Ohto`,
                    },
                    {
                        language1: `Devet`,
                        language2: `Enea`,
                    },
                    {
                        language1: `Deset`,
                        language2: `Deka`,
                    },
                    {
                        language1: `Jedanaest`,
                        language2: `Endeka`,
                    },
                ],
            },
            {
                title: "Alkoholna pića",
                icon: require("../images/icons/alcohol_icon.png"),
                words: [
                    {
                        language1: `Vino`,
                        language2: `Krasi`,
                    },
                    {
                        language1: `Belo`,
                        language2: `lefko`,
                    },
                    {
                        language1: `Crno`,
                        language2: `Kokino`,
                    },
                    {
                        language1: `Roze`,
                        language2: `Roze`,
                    },
                    {
                        language1: `Suvo`,
                        language2: `Ksiro`,
                    },
                    {
                        language1: `Slatko`,
                        language2: `Gliko`,
                    },
                    {
                        language1: `Vodka`,
                        language2: `Vodka`,
                    },
                    {
                        language1: `Viski`,
                        language2: `Uiski`,
                    },
                    {
                        language1: `Tonik`,
                        language2: `Tonotikó`,
                    },
                    {
                        language1: `Brendi`,
                        language2: `Brandi`,
                    },
                    {
                        language1: `Konjak`,
                        language2: `Koniak`,
                    },
                    {
                        language1: `Šampanjac`,
                        language2: `Sambanja`,
                    },
                    {
                        language1: `Liker`,
                        language2: `Liker`,
                    },
                    {
                        language1: `Pivo`,
                        language2: `Bira`,
                    },
                ],
            },
        ],
    },
    CYPRUS: {
        carousel: {
            images: [
                require("../../static/images/About_Country/About_Cyprus/kipar-1.webp"),
                require("../../static/images/About_Country/About_Cyprus/kipar-2.webp"),
                require("../../static/images/About_Country/About_Cyprus/kipar-3.webp"),
            ],
            country: {
                title: "Upoznajte Kipar",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae enim urna. Nunc elementum cursus diam.",
            },
        },
    },
    MONTENEGRO: {
        carousel: {
            images: [
                require("../../static/images/About_Country/About_Montenegro/CG-1.webp"),
                require("../../static/images/About_Country/About_Montenegro/CG-2.webp"),
                require("../../static/images/About_Country/About_Montenegro/CG-3.webp"),
            ],
            country: {
                title: "Upoznajte Crnu Goru",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae enim urna. Nunc elementum cursus diam.",
            },
        },
    },
    EGYPT: {
        carousel: {
            images: [
                require("../../static/images/About_Country/About_Egypt/egipat-1.webp"),
                require("../../static/images/About_Country/About_Egypt/egipat-2.webp"),
                require("../../static/images/About_Country/About_Egypt/egipat-3.webp"),
            ],
            country: {
                title: "Upoznajte Egipat",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae enim urna. Nunc elementum cursus diam.",
            },
        },
    },
    ALBANIA: {
        carousel: {
            images: [
                require("../../static/images/About_Country/About_Albania/albanija-1.webp"),
                require("../../static/images/About_Country/About_Albania/albanija-2.webp"),
                require("../../static/images/About_Country/About_Albania/albanija-3.webp"),
            ],
            country: {
                title: "Upoznajte Albaniju",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae enim urna. Nunc elementum cursus diam.",
            },
        },
    },
    BULGARIA: {
        carousel: {
            images: [
                require("../../static/images/About_Country/About_Bulgaria/bugarska-1.webp"),
                require("../../static/images/About_Country/About_Bulgaria/bugarska-2.webp"),
                require("../../static/images/About_Country/About_Bulgaria/bugarska-3.webp"),
            ],
            country: {
                title: "Upoznajte Bugarsku",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae enim urna. Nunc elementum cursus diam.",
            },
        },
    },
    CROATIA: {
        carousel: {
            images: [
                require("../../static/images/About_Country/About_Croatia/cro-1.webp"),
                require("../../static/images/About_Country/About_Croatia/cro-2.webp"),
                require("../../static/images/About_Country/About_Croatia/cro-3.webp"),
            ],
            country: {
                title: "Upoznajte Hrvatsku",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae enim urna. Nunc elementum cursus diam.",
            },
        },
    },
    UAE: {
        carousel: {
            images: [
                require("../../static/images/About_Country/About_UAE/UAE-1.webp"),
                require("../../static/images/About_Country/About_UAE/UAE-2.webp"),
                require("../../static/images/About_Country/About_UAE/UAE-3.webp"),
            ],
            country: {
                title: "Upoznajte UAE",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae enim urna. Nunc elementum cursus diam.",
            },
        },
    },
    TURKEY: {
        carousel: {
            images: [
                require("../../static/images/About_Country/About_Turkey/turska-1.webp"),
                require("../../static/images/About_Country/About_Turkey/turska-2.webp"),
                require("../../static/images/About_Country/About_Turkey/turska-3.webp"),
            ],
        },
    },
};
