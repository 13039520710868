import React, { useState, useEffect } from "react";
import { Carousel as CarouselBootstrap, CarouselItem } from "react-bootstrap";

import "../../styles/Mobile/Carousel.scss";

function Carousel(props) {
    const [CarouselItems, setCarouselItems] = useState(0); //PROMENI OVO KADA MENJAS BROJ SLIDE-OVA
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (props.data && props.data?.length) {
            setCarouselItems(props.data.length);
        }
    }, [props.data]);

    const changeSlide = (forward = false) => {
        // debugger;
        if (forward) {
            if (index + 1 > CarouselItems - 1) {
                setIndex(0);
            } else {
                setIndex(index + 1);
            }
        } else {
            if (index - 1 < 0) {
                setIndex(CarouselItems - 1);
            } else {
                setIndex(index - 1);
            }
        }
    };

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <>
            <CarouselBootstrap
                activeIndex={index}
                onSelect={handleSelect}
                indicators={props.indicators}
                controls={false}
                interval={5000}
                pause={false}
                className={props.className ? props.className : ""}
            >
                {props.data &&
                    props.data?.map((item, key) => (
                        <CarouselItem key={key}>
                            <img src={item && item?.image_file ? `data:image/png;base64,${item?.image_file}` : item} alt={key} />
                        </CarouselItem>
                    ))}
            </CarouselBootstrap>
            {props.arrows && props.data?.length > 1 && (
                <>
                    <div className="carousel-arrow" id="carousel-arrow-left" onClick={() => changeSlide()}></div>
                    <div className="carousel-arrow" id="carousel-arrow-right" onClick={() => changeSlide(true)}></div>
                </>
            )}
        </>
    );
}

export default Carousel;
