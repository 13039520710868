import { GET_CUSTOM_PAGE, GET_CUSTOM_PAGE_SUCCESS, GET_CUSTOM_PAGE_ERROR } from "./actionTypes";

export const getCustomPage = (slug, history) => {
    return {
        type: GET_CUSTOM_PAGE,
        payload: { slug, history },
    };
};

export const getCustomPageSuccess = (payload) => {
    return {
        type: GET_CUSTOM_PAGE_SUCCESS,
        payload,
    };
};

export const getCustomPageError = (payload) => {
    return {
        type: GET_CUSTOM_PAGE_ERROR,
        payload,
    };
};
