import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";
import { format, startOfTomorrow, addDays } from "date-fns";

import BookingDesktop from "../components/Booking/BookingDesktop";
import BookingMobile from "../components/Booking/BookingMobile";

import { withRouter } from "../helpers/utils/globalUtils";

import { getDestinations, searchAccommodation, sortAccommodation } from "../store/actions";

var _ = require("lodash");

class Booking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTypeFilters: [],
            filters: {},
        };

        this.changeActiveFilters = this.changeActiveFilters.bind(this);
        this.changeFilters = this.changeFilters.bind(this);
    }

    componentDidMount() {
        this.props.getDestinations();
        this.fetchAccommodation();
    }

    componentDidUpdate(prevProps) {
        if (this.props.router.searchParams.toString() !== prevProps.router.searchParams.toString()) {
            this.fetchAccommodation();
        }
    }

    fetchAccommodation = () => {
        const queryParams = new URLSearchParams(this.props.router.location.search);
        let filters = {};
        const filtersKey = ["mealPlan", "kategorija", "childrenAges"];
        for (const [key, value] of queryParams) {
            let val = value;
            if (filtersKey.includes(key)) {
                val = value?.split(",");
            }
            filters = { ...filters, [key]: val };
        }

        let data = {},
            sort = "",
            destination,
            start_date = startOfTomorrow(),
            end_date = addDays(startOfTomorrow(), 1),
            rooms = {
                adults: 2,
                children: 0,
                childrenAges: [0],
                quantity: 1,
            };

        if (filters?.selectedPlace) {
            destination = filters?.selectedPlace;
        } else if (this.props.destinations && this.props.destinations?.length) {
            destination = this.props.destinations[0];
            filters = { ...filters, selectedPlace: this.props.destinations[0] };
            queryParams.set("selectedPlace", this.props.destinations[0]);
        }

        if (filters?.dateFrom && filters?.dateTo) {
            start_date = new Date(filters?.dateFrom);
            end_date = new Date(filters?.dateTo);
        } else {
            filters = { ...filters, dateFrom: start_date, dateTo: end_date };
            queryParams.set("dateFrom", start_date);
            queryParams.set("dateTo", end_date);
        }

        if (filters?.adults) {
            rooms = { ...rooms, adults: parseInt(filters?.adults) };
        } else {
            filters = { ...filters, adults: rooms?.adults };
            queryParams.set("adults", rooms?.adults);
        }

        if (filters?.children) {
            rooms = { ...rooms, children: parseInt(filters?.children) };
        } else {
            filters = { ...filters, children: rooms?.children };
            queryParams.set("children", rooms?.children);
        }

        if (filters?.childrenAges) {
            rooms = { ...rooms, childrenAges: filters?.childrenAges?.map((v) => parseInt(v)) };
        } else {
            filters = { ...filters, childrenAges: rooms?.childrenAges };
            queryParams.set("childrenAges", rooms?.childrenAges);
        }

        if (filters?.cena) {
            data = { ...data, toTotalPrice: parseFloat(filters?.cena) };
        }

        if (filters?.mealPlan) {
            data = { ...data, mealPlan: filters?.mealPlan };
        }

        if (filters?.aparments) {
            data = { ...data, aparments: Boolean(filters?.aparments) };
        }

        if (filters?.kategorija) {
            let min, max;
            min = Math.min(...filters?.kategorija);
            max = Math.max(...filters?.kategorija);
            data = { ...data, stars: { min, max } };
        }

        if (filters?.sort) {
            sort = filters?.sort;
        }

        if (filters?.transport) {
            data = { ...data, transport: filters?.transport };
        }

        data = {
            ...data,
            destination,
            start_date: format(new Date(start_date), "yyyy-MM-dd"),
            end_date: format(new Date(end_date), "yyyy-MM-dd"),
            rooms: [rooms],
        };

        this.props.searchAccommodation({ data, sort, navigate: this.props.router.navigate });
        this.setState({ filters });
        setTimeout(() => {
            this.props.router.navigate(`/booking?${queryParams.toString()}`, {
                replace: true,
            });
        }, 100);
    };

    searchByFilters = () => {
        let updatedSearchParams = new URLSearchParams(this.props.router.searchParams.toString());

        for (const [key, value] of Object.entries(this.state.filters)) {
            if (value === "") {
                updatedSearchParams.delete(key);
            } else {
                updatedSearchParams.set(key, value);
            }
        }

        this.props.router.setSearchParams(updatedSearchParams.toString());

        setTimeout(() => {
            this.fetchAccommodation();
        }, 500);
    };

    changeActiveFilters(filter) {
        if (this.state.activeTypeFilters.includes(filter)) {
            this.setState({
                activeTypeFilters: _.pull(this.state.activeTypeFilters, filter),
            });
        } else {
            this.setState({
                activeTypeFilters: _.concat(this.state.activeTypeFilters, filter),
            });
        }
    }

    changeFilters(filterName, value, isArray = false) {
        let filter = { [filterName]: value };
        if (isArray) {
            let newFilter;
            if (this.state.filters[filterName]?.find((filter) => filter === value)) {
                newFilter = this.state.filters[filterName]?.filter((filter) => filter !== value);
            } else {
                newFilter = this.state.filters[filterName] ? [...this.state.filters[filterName], value] : [value];
            }

            if (newFilter.length > 0) {
                filter = { [filterName]: newFilter };
            } else {
                delete filter[filterName];
                delete this.state.filters[filterName];
            }
        }
        this.setState({ filters: { ...this.state.filters, ...filter } });
    }

    deleteFilters = () => {
        let filters = this.state.filters;
        for (const [key] of Object.entries(this.state.filters)) {
            if (key !== "selectedPlace" && key !== "dateFrom" && key !== "dateTo" && key !== "adults" && key !== "children" && key !== "childrenAges") {
                delete filters[key];
                this.props.router.searchParams.delete(key);
            }
        }
        this.props.router.setSearchParams(this.props.router.searchParams);
        this.setState({ filters }, () => this.fetchAccommodation());
    };

    sortAccommodationPrice = (filterName, value, isArray = false) => {
        this.changeFilters(filterName, value, isArray);

        let updatedSearchParams = new URLSearchParams(this.props.router.searchParams.toString());
        updatedSearchParams.set(filterName, value);
        this.props.router.setSearchParams(updatedSearchParams.toString());

        this.props.sortAccommodation(value);
    };

    render() {
        return (
            <>
                <MediaQuery maxWidth={990}>
                    <BookingMobile
                        activeTypeFilters={this.state.activeTypeFilters}
                        filters={this.state.filters}
                        route={"booking"}
                        changeActiveFilters={this.changeActiveFilters}
                        changeFilters={this.changeFilters}
                        deleteFilters={this.deleteFilters}
                        searchByFilters={this.searchByFilters}
                        sortAccommodationPrice={this.sortAccommodationPrice}
                        destinationName={this.props.destinations?.find((destination) => destination.destId === this.state.filters?.selectedPlace)?.name || ""}
                    />
                </MediaQuery>
                <MediaQuery minWidth={991}>
                    <BookingDesktop
                        activeTypeFilters={this.state.activeTypeFilters}
                        filters={this.state.filters}
                        route={"booking"}
                        changeActiveFilters={this.changeActiveFilters}
                        changeFilters={this.changeFilters}
                        deleteFilters={this.deleteFilters}
                        searchByFilters={this.searchByFilters}
                        sortAccommodationPrice={this.sortAccommodationPrice}
                        destinationName={this.props.destinations?.find((destination) => destination.destId === this.state.filters?.selectedPlace)?.name || ""}
                    />
                </MediaQuery>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        destinations: state.Booking.destinations,
    };
};

export default connect(mapStateToProps, { getDestinations, searchAccommodation, sortAccommodation })(withRouter(Booking));
