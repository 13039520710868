import React, { Component } from "react";

import "../../styles/Components/AboutHotelInformations.scss";
import { checkFavoritesHotels, editFavoritesHotels } from "../../helpers/utils/favoritesUtils";

import { getCookie } from "../../helpers/utils/globalUtils";

import LocationIcon from "../../static/images/About_Hotel/location_icon.svg";
import WebsiteIcon from "../../static/images/About_Hotel/website_icon.svg";
// import FromSeaIcon from "../../static/images/About_Hotel/from_sea_icon.svg";
import RoomNumbersIcon from "../../static/images/About_Hotel/room_numbers_icon.svg";

import FullHeartIcon from "../../static/images/icons/heart_full_icon.png";
import NotFullHeartIcon from "../../static/images/icons/heart_not_full_icon.png";

class AboutHotelInformations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFavorite: checkFavoritesHotels(props.data),
            isLogged: getCookie("userId"),
        };
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({ isFavorite: checkFavoritesHotels(this.props.data) });
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
            this.setState({ isFavorite: checkFavoritesHotels(this.props.data) });
        }
    }

    editFavoritesHotels = () => {
        editFavoritesHotels(this.props.data);
        this.setState({ isFavorite: checkFavoritesHotels(this.props.data) });
    };

    render() {
        return (
            <div className="about_hotel_informations_wrapper font-poppins">
                <div className="hotel_name_stars_wrapper">
                    <div className="hotel_name_favorite">
                        {this.state.isLogged ? (
                            <img
                                src={this.state.isFavorite ? FullHeartIcon : NotFullHeartIcon}
                                alt="Favorite icon"
                                onClick={() => this.editFavoritesHotels()}
                            />
                        ) : (
                            <></>
                        )}

                        <div className="hotel_name">{this.props.data?.name}</div>
                    </div>
                    <div className="hotel_stars">
                        {Array.from({ length: this.props.data?.rating }, (v) => v)?.map((star, key) => (
                            <span className="fa fa-star checked" key={key}></span>
                        ))}
                    </div>
                </div>
                <div className="hotel_details_wrapper">
                    {this.props.data && this.props.data?.address && (
                        <div className="item_wrapper">
                            <div className="item_icon">
                                <img src={LocationIcon} alt="Hotel location icon" />
                            </div>
                            <div className="item_text">{this.props.data?.address}</div>
                        </div>
                    )}
                    {this.props.data && this.props.data?.website && (
                        <div className="item_wrapper">
                            <div className="item_icon">
                                <img src={WebsiteIcon} alt="Hotel website icon" />
                            </div>
                            <a href={`${this.props.hotel?.website}`} className="item_text item_website_text">
                                {this.props.data?.website}
                            </a>
                        </div>
                    )}
                    {this.props.data && this.props.data?.roomNumber && (
                        <div className="item_wrapper">
                            <div className="item_icon">
                                <img src={RoomNumbersIcon} alt="Hotel room numbers icon" />
                            </div>
                            <div className="item_text">{`${this.props.data?.roomNumber} soba`}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default AboutHotelInformations;
