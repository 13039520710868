import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { Transition } from "react-transition-group";
import { Formik } from "formik";
import * as yup from "yup";
import { Form } from "react-bootstrap";

import { connect } from "react-redux";

import "../styles/Contact.scss";
import { phoneRegExp } from "../helpers/utils/globalUtils";
import { contactUs } from "../store/actions";

import ContactFormBack from "../static/images/contact_form_back.png";

// import arrow_down from "../static/images/icons/arrow_down_icon.png";

const transitionDuration = 500;
// const defaultListStyle = {
//     transition: `opacity ${800}ms ease-in-out`,
//     opacity: 0,
// };
// const transitionListStyles = {
//     entering: { opacity: 1 },
//     entered: { opacity: 1 },
//     exiting: { opacity: 1 },
//     exited: { opacity: 0, zIndex: -1 },
// };

// const defaultCountryArrowStyle = {
//     transition: `transform ${800}ms ease`,
//     transform: "rotate(0deg)",
// };
// const transitionCountryArrowStyles = {
//     entering: { transform: "rotate(180deg)" },
//     entered: { transform: "rotate(180deg)" },
//     exiting: { transform: "rotate(0deg)" },
//     exited: { transform: "rotate(0deg)" },
// };

const schema = yup.object().shape({
    name: yup.string().required("Polje mora da bude popunjeno"),
    email: yup.string().email().required("Polje mora da bude popunjeno"),
    phone: yup.string().matches(phoneRegExp, "Broj telefona nije validan").notRequired(),
    message: yup.string().required("Morate uneti poruku"),
});

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryList: Array.from({ length: 1 }, () => false),
        };
    }

    toggleMenu = (index) => {
        let countryList = this.state.countryList;
        countryList[index] = !countryList[index];
        setTimeout(() => {
            this.setState({
                countryList,
            });
        }, transitionDuration);
    };

    render() {
        return (
            <Container className="contact_wrapper font-poppins">
                <Row className="contact_row_wrapper">
                    <Col lg={6}>
                        <div className="big_title">Beograd - Kneza Miloša</div>
                        <ul className="general_info_list">
                            <li>Kneza Miloša 84, 11000 Beograd, SRBIJA</li>
                            <li>
                                <a href="tel:0604145130">060/4145130</a>
                            </li>
                            <li>
                                <a href="mailto:info@unlimitedtravel.rs">info@unlimitedtravel.rs</a>
                            </li>
                            <li>radnim danima 09:00-19:00, subotom od 10:00-15:00</li>
                        </ul>
                        <div className="employee_list">
                            <Row className="employee_list_header">
                                <Col className="header_title">Ime</Col>
                                <Col className="header_title">Dužnost</Col>
                                <Col className="header_title">Odeljenje</Col>
                            </Row>
                            <div className="employee_list_data">
                                <div className="employee_list_item">
                                    <Col className="data_text">Svetlana Nikolić</Col>
                                    <Col className="data_text">Tamara Katić</Col>
                                </div>
                                <div className="employee_list_separator"></div>
                                <div className="employee_list_item">
                                    <Col className="data_text">Office director</Col>
                                    <Col className="data_text">Director</Col>
                                </div>
                                <div className="employee_list_separator"></div>
                                <div className="employee_list_item">
                                    <Col className="data_text">Manager</Col>
                                    <Col className="data_text">Sales</Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} className="contact_form_wrapper">
                        <div className="contact_form_title">Kontaktirajte nas</div>
                        <div className="contact_form">
                            {this.props.success ? <div></div> : <></>}
                            {this.props.error ? <div></div> : <></>}
                            {this.props.success ? (
                                <div className="status_message" style={{ color: "green" }}>
                                    Uspešno ste nas kontaktirali!
                                </div>
                            ) : (
                                <></>
                            )}
                            {this.props.error ? (
                                <div className="status_message" style={{ color: "red" }}>
                                    Nažalost niste uspeli da nas kontaktirate. Pokušajte ponovo.
                                </div>
                            ) : (
                                <></>
                            )}
                            <img src={ContactFormBack} alt="Contact form back" className="contact_background" />
                            <Formik
                                validationSchema={schema}
                                initialValues={{
                                    name: "",
                                    phone: "",
                                    email: "",
                                    message: "",
                                }}
                                onSubmit={(values, actions) => {
                                    this.props.contactUs(values);
                                    // actions.resetForm();
                                }}
                            >
                                {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                                    <Form noValidate autoComplete="off" name="contact_form" className="contact_form_form">
                                        <Form.Group className="mb-3 position-relative">
                                            <Form.Control
                                                type="text"
                                                placeholder="Ime i prezime"
                                                name="name"
                                                className="contact_form_control"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.name && errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3 position-relative">
                                            <Form.Control
                                                type="text"
                                                placeholder="Broj telefona"
                                                name="phone"
                                                className="contact_form_control"
                                                value={values.phone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.phone && errors.phone}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3 position-relative">
                                            <Form.Control
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                                className="contact_form_control"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.email && errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3 position-relative">
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                placeholder="Vaša poruka"
                                                name="message"
                                                className="contact_form_control"
                                                value={values.message}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.message && errors.message}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                                        </Form.Group>

                                        <div className="contact_form_submit_btn" onClick={handleSubmit}>
                                            Pošalji poruku
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Col>
                    {/* <Col lg={6}>
                        <div className="small_title">{`Kancelarije Unlimited Travel: ${41}`}</div>
                        <div className="country_list">
                            {[{ countryName: "Grčka", count: 9 }].map((item, key) => (
                                <Transition in={this.state.countryList[key]} timeout={transitionDuration} key={key}>
                                    {(state) => (
                                        <div className="country_item_wrapper">
                                            <div className="country_item_header" onClick={() => this.toggleMenu(key)}>
                                                <div className="header_title">
                                                    {item?.countryName} {item?.count}
                                                </div>
                                                <img
                                                    src={arrow_down}
                                                    alt={`Arrow icon ${key}`}
                                                    className="arrow_icon"
                                                    style={{
                                                        ...defaultCountryArrowStyle,
                                                        ...transitionCountryArrowStyles[state],
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="country_item_list"
                                                style={{
                                                    ...defaultListStyle,
                                                    ...transitionListStyles[state],
                                                }}
                                            ></div>
                                        </div>
                                    )}
                                </Transition>
                            ))}
                        </div>
                    </Col> */}
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        success: state.General.contactUs.success,
        error: state.General.contactUs.error,
    };
};

export default connect(mapStateToProps, { contactUs })(Contact);
