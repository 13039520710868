// export const NAME_NAME = 'NAME_NAME';
// export const NAME_NAME_SUCCESS = 'NAME_NAME_SUCCESS';
// export const NAME_NAME_ERROR = 'NAME_NAME_ERROR';

export const POST_REGISTRATION_USER = "POST_REGISTRATION_USER";
export const POST_REGISTRATION_USER_SUCCESS = "POST_REGISTRATION_USER_SUCCESS";
export const POST_REGISTRATION_USER_ERROR = "POST_REGISTRATION_USER_ERROR";

export const POST_REGISTRATION_AGENT = "POST_REGISTRATION_AGENT";
export const POST_REGISTRATION_AGENT_SUCCESS = "POST_REGISTRATION_AGENT_SUCCESS";
export const POST_REGISTRATION_AGENT_ERROR = "POST_REGISTRATION_AGENT_ERROR";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
