import { GET_MY_PROFILE, GET_MY_PROFILE_SUCCESS, GET_MY_PROFILE_ERROR } from "./actionTypes";

export const getMyProfile = () => {
    return {
        type: GET_MY_PROFILE,
    };
};

export const getMyProfileSuccess = (payload) => {
    return {
        type: GET_MY_PROFILE_SUCCESS,
        payload,
    };
};

export const getMyProfileError = (payload) => {
    return {
        type: GET_MY_PROFILE_ERROR,
        payload,
    };
};
