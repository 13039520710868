import React, { Component } from "react";
import { connect } from "react-redux";

// import { getCookie } from "../helpers/utils/globalUtils";

import HomeCountries from "../components/Home/HomeCountries";
import HomeTop from "../components/Home/HomeTop";
import HomeNotificationsBlog from "../components/Home/HomeNotificationsBlog";

import { countriesFakeData } from "../static/data/countriesFakeData";

import { getSlider, getBlog, getDestinations, getNotifications, getHomeMainDestinations, setActiveDestination } from "../store/actions";

class Home extends Component {
    componentDidMount() {
        this.props.getSlider();
        this.props.getHomeMainDestinations();
        this.props.getBlog();
        this.props.getNotifications();
    }

    render() {
        return (
            <>
                <HomeTop slider={this.props.slider} />
                <HomeCountries
                    data={countriesFakeData}
                    hotels={this.props.hotels}
                    apartments={this.props.apartments}
                    activeDestinations={this.props.activeDestinations}
                    setActiveDestination={this.props.setActiveDestination}
                />
                <HomeNotificationsBlog blogs={this.props.blogs} notifications={this.props.notifications} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        slider: state.Home.slider?.sort((a, b) => a?.slider - b?.slider),
        accommodations: state.Home.accommodations,
        hotels: state.Home.accommodations?.hotels,
        apartments: state.Home.accommodations?.apartments,
        activeDestinations: state.Home.activeDestinations,
        blogs: state.Blog.blogs,
        notifications: state.Notifications.notifications,
    };
};

export default connect(mapStateToProps, { getSlider, getBlog, getDestinations, getNotifications, getHomeMainDestinations, setActiveDestination })(Home);
