import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";

import HeaderDesktop from "./Desktop";
import HeaderMobile from "./Mobile";

import { getExchangeRate } from "../../store/actions";

function Header(props) {
    let location = useLocation();
    let shouldBeAbsolute = location.pathname === "/" ? true : false;
    const fetchExchangeRate = props.getExchangeRate;

    useEffect(() => {
        fetchExchangeRate();
    }, [fetchExchangeRate]);

    return (
        <>
            <MediaQuery minWidth={991}>
                <HeaderDesktop shouldBeAbsolute={shouldBeAbsolute} exchangeRate={props.exchangeRate} />
            </MediaQuery>
            <MediaQuery maxWidth={990}>
                <HeaderMobile exchangeRate={props.exchangeRate} />
            </MediaQuery>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        exchangeRate: state.General.exchangeRate,
    };
};

export default connect(mapStateToProps, { getExchangeRate })(Header);
