import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { Row, Col } from "react-bootstrap";

import PalmTree1 from "../../static/images/Registration/palm_image.png";
import PalmTree2 from "../../static/images/Registration/palm_2_image.png";
import PalmImage from "../../static/images/Registration/palm_mobile.png";

import RegistrationFormAgents from "./RegistrationFormAgents";
import RegistrationFormTurists from "./RegistrationFormTurists";

import MyProfileFormTurists from "./MyProfileFormTurists";
import MyProfileFormAgents from "./MyProfileFormAgents";

class UsersForm extends Component {
    getForm = () => {
        let ComponentItems;
        switch (this.props.activeProfile) {
            case 0:
                ComponentItems = {
                    Component: this.props.formType === "registration" ? RegistrationFormTurists : MyProfileFormTurists,
                    getFormData: this.props.getUserData,
                };
                break;
            case 1:
                ComponentItems = {
                    Component: this.props.formType === "registration" ? RegistrationFormAgents : MyProfileFormAgents,
                    getFormData: this.props.getAgentData,
                };
                break;
            default:
                ComponentItems = { Component: <></>, getFormData: this.props.getUserData };
                break;
        }

        return <ComponentItems.Component {...this.props} getUserData={ComponentItems.getFormData} submitButton={this.props.submitButton} />;
    };

    render() {
        const reg = this.getForm();
        return (
            <>
                <MediaQuery maxWidth={990}>
                    {reg}
                    <img src={PalmImage} alt="Palm mobile" className="bottom_image" />
                </MediaQuery>
                <MediaQuery minWidth={991}>
                    <Row className={this.props.className}>
                        <Col lg={4}>{reg}</Col>
                        <Col className="align-self-end">
                            <img src={this.props.activeProfile === 0 ? PalmTree1 : PalmTree2} alt="Palm all" className="w-100" />
                        </Col>
                    </Row>
                </MediaQuery>
            </>
        );
    }
}

export default UsersForm;
