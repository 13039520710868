import React, { Component } from "react";
import { Transition } from "react-transition-group";
import { startOfTomorrow, addDays } from "date-fns";
import { connect } from "react-redux";

import { withRouter } from "../../helpers/utils/globalUtils";

import location_icon_gray from "../../static/images/icons/location_gray_icon.png";
import location_icon from "../../static/images/icons/location_icon.png";
import calendar_icon from "../../static/images/icons/calendar_icon.png";
import people_icon from "../../static/images/icons/people_icon.png";

import "../../styles/Components/SearchBar.scss";

import SearchList from "../Reusable/SearchList";
import SearchCalendar from "../Reusable/SearchCalendar";
import SearchPeople from "../Reusable/SearchPeople";

// import { fakeData } from "../../static/data/fakeSearchData";

import { getDestinations, searchAccommodation } from "../../store/actions";

const transitionDuration = 300;
const defaultStyle = {
    transition: `opacity ${transitionDuration}ms ease-in-out`,
    opacity: 0,
};
const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1, zIndex: 999 },
    exiting: { opacity: 0 },
    exited: { opacity: 0, zIndex: -1 },
};

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false,
            calendarOpen: false,
            personOpen: false,
            selectedPlaceIndex: null,
            selectedPlaceName: null,
            selectedDates: {
                from: null,
                to: null,
            },
            currentPeople: {
                adults: null,
                children: null,
                childrenAges: [],
            },
            destinationError: false,
        };

        this.changeSelectedLocation = this.changeSelectedLocation.bind(this);
        this.changeSelectedDates = this.changeSelectedDates.bind(this);
        this.returnReadableFromTo = this.returnReadableFromTo.bind(this);
        this.changeCurrentPeople = this.changeCurrentPeople.bind(this);
        this.returnReadableFromPeople = this.returnReadableFromPeople.bind(this);
        this.changeChildrenAges = this.changeChildrenAges.bind(this);
    }

    componentDidMount() {
        this.props.getDestinations();
        this.checkSearchFilters();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.filters) !== JSON.stringify(prevProps.filters)) {
            this.props.getDestinations();
            this.checkSearchFilters();
        }

        if (JSON.stringify(this.props.destinations) !== JSON.stringify(prevProps.destinations)) {
            this.checkSearchFilters();
        }
    }

    checkSearchFilters = () => {
        if (this.props.filters?.selectedPlace) {
            const destination = this.props.destinations?.find((destination) => destination.destId === this.props.filters?.selectedPlace);
            if (destination) {
                this.setState({ selectedPlaceIndex: destination.destId, selectedPlaceName: destination.name });
            }
        }
        if (this.props.filters?.dateFrom || this.props.filters?.dateTo) {
            this.setState({ selectedDates: { from: new Date(this.props.filters?.dateFrom), to: new Date(this.props.filters?.dateTo) } });
        }
        if (this.props.filters?.adults || this.props.filters?.children || (this.props.filters?.childrenAges && this.props.filters?.childrenAges?.length)) {
            this.setState({
                currentPeople: {
                    adults: parseInt(this.props.filters?.adults),
                    children: parseInt(this.props.filters?.children),
                    childrenAges: this.props.filters?.childrenAges?.map((v) => parseInt(v)),
                },
            });
        }
    };

    changeSelectedDates(newDates) {
        // Ukoliko je korisnik izabrao range (od - do)
        if (newDates.length === 2) {
            if (newDates[0].isValid && newDates[1].isValid) {
                let fromYear = newDates[0].year;
                let fromMonth = newDates[0].monthIndex;
                let fromDay = newDates[0].day;

                let from = new Date(fromYear, fromMonth, fromDay);

                let toYear = newDates[1].year;
                let toMonth = newDates[1].monthIndex;
                let toDay = newDates[1].day;

                let to = new Date(toYear, toMonth, toDay);
                this.setState({
                    selectedDates: { from, to },
                    calendarOpen: false,
                });
            }
        }
    }

    changeSelectedLocation(newLocation) {
        this.setState({
            selectedPlaceIndex: newLocation,
            selectedPlaceName: this.props.destinations?.find((destination) => destination.destId === newLocation)?.name,
            searchOpen: false,
            destinationError: false,
        });
    }

    changeCurrentPeople(newPeople) {
        this.setState({
            currentPeople: newPeople,
        });
    }

    changeChildrenAges(childrenAges) {
        this.setState({
            childrenAges,
        });
    }

    returnReadableFromTo() {
        let date = this.state.selectedDates;
        let fromString = `${date.from.getDate()}.${date.from.getMonth() + 1}.${date.from.getYear() + 1900}.`; // prettier-ignore
        let toString = `${date.to.getDate()}.${date.to.getMonth() + 1}.${date.to.getYear() + 1900}.`; // prettier-ignore

        return `${fromString} - ${toString}`; // prettier-ignore
    }

    returnReadableFromPeople() {
        let people = this.state.currentPeople;
        return `Odrasli: ${people.adults}, Deca: ${people.children}`;
    }

    searchWithFilters = () => {
        let updatedSearchParams = new URLSearchParams(this.props.router.searchParams.toString());

        if (this.state.selectedPlaceIndex || this.props.hotelName) {
            if (this.props.filters) {
                Object.entries(this.props.filters).forEach(([key, value]) => {
                    updatedSearchParams.set(key, value);
                });
            }

            if (this.state.selectedPlaceIndex) {
                updatedSearchParams.set("selectedPlace", this.state.selectedPlaceIndex);
            }
            if (this.state.selectedDates) {
                let from = startOfTomorrow();
                let to = addDays(startOfTomorrow(), 1);
                if (this.state.selectedDates?.from) {
                    from = this.state.selectedDates?.from;
                }
                if (this.state.selectedDates?.to) {
                    to = this.state.selectedDates?.to;
                }
                updatedSearchParams.set("dateFrom", from);
                updatedSearchParams.set("dateTo", to);
            }

            let adults = 2,
                children = 0,
                childrenAges = [0];
            if (this.state.currentPeople?.adults) {
                adults = this.state.currentPeople?.adults;
            }
            if (this.state.currentPeople?.children) {
                children = this.state.currentPeople?.children;
            }
            if (this.state.currentPeople?.childrenAges && this.state.currentPeople?.childrenAges?.length) {
                childrenAges = this.state.currentPeople?.childrenAges;
            }
            updatedSearchParams.set("adults", adults);
            updatedSearchParams.set("children", children);
            updatedSearchParams.set("childrenAges", childrenAges);

            if ((this.state.selectedPlaceIndex || this.props.hotelName) && updatedSearchParams.toString()) {
                this.props.router.navigate(`/${this.props.route ?? "booking"}?${updatedSearchParams.toString()}`);
            }
        } else {
            this.setState({ destinationError: true });
        }
    };

    render() {
        return (
            <div className={`search-bar font-poppins ${this.props.width === 100 ? "full-width" : ""} ${this.props.className || ""}`}>
                <div className="control-part ">
                    {this.props.hotelName ? (
                        <div className="control-item hotel_name no-select">
                            <img src={location_icon_gray} width={26} alt="Number of rooms icon" />
                            {this.props.hotelName}
                        </div>
                    ) : (
                        <div
                            className="control-item no-select position-relative"
                            onClick={() => {
                                this.setState({
                                    calendarOpen: false,
                                    personOpen: false,
                                });
                                setTimeout(() => {
                                    this.setState({
                                        searchOpen: !this.state.searchOpen,
                                    });
                                }, transitionDuration);
                            }}
                        >
                            <img src={location_icon} alt="Location icon" />
                            <span>{this.state.selectedPlaceIndex === null ? "traži regiju, hotele i apartmane" : this.state.selectedPlaceName}</span>
                            {this.state.destinationError ? <div className="error-message">Morate odabrati destinaciju</div> : <></>}
                        </div>
                    )}
                    <div
                        className="control-item no-select"
                        onClick={() => {
                            this.setState({
                                searchOpen: false,
                                personOpen: false,
                            });
                            setTimeout(() => {
                                this.setState({
                                    calendarOpen: !this.state.calendarOpen,
                                });
                            }, transitionDuration);
                        }}
                    >
                        <img src={calendar_icon} alt="Calendar icon" />
                        <span>
                            {this.state.selectedDates.from === null || this.state.selectedDates.to === null
                                ? "datum od - datum do"
                                : this.returnReadableFromTo()}
                        </span>
                    </div>
                    <div
                        className="control-item no-select"
                        onClick={() => {
                            this.setState({
                                searchOpen: false,
                                calendarOpen: false,
                            });
                            setTimeout(() => {
                                this.setState({
                                    personOpen: !this.state.personOpen,
                                });
                            }, transitionDuration);
                        }}
                    >
                        <img src={people_icon} alt="People icon" />
                        <span>
                            {this.state.currentPeople.adults === null || this.state.currentPeople.children === null
                                ? "broj osoba"
                                : this.returnReadableFromPeople()}
                        </span>
                    </div>
                </div>
                <div className="search-part" onClick={() => this.searchWithFilters()}>
                    <div className="search-button"></div>
                </div>
                <Transition in={this.state.searchOpen} timeout={transitionDuration}>
                    {(state) => (
                        <SearchList
                            data={this.props.destinations}
                            style={{
                                ...defaultStyle,
                                ...transitionStyles[state],
                            }}
                            currentLocationIndex={this.state.selectedPlaceIndex}
                            onLocationChange={this.changeSelectedLocation}
                        />
                    )}
                </Transition>
                <Transition in={this.state.calendarOpen} timeout={transitionDuration}>
                    {(state) => (
                        <SearchCalendar
                            style={{
                                ...defaultStyle,
                                ...transitionStyles[state],
                            }}
                            selectedDates={this.state.selectedDates}
                            changeSelectedDates={this.changeSelectedDates}
                        />
                    )}
                </Transition>
                <Transition in={this.state.personOpen} timeout={transitionDuration}>
                    {(state) => (
                        <SearchPeople
                            style={{
                                ...defaultStyle,
                                ...transitionStyles[state],
                            }}
                            currentPeople={this.state.currentPeople}
                            changeCurrentPeople={this.changeCurrentPeople}
                        />
                    )}
                </Transition>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        destinations: state.Booking.destinations,
    };
};

export default connect(mapStateToProps, { getDestinations, searchAccommodation })(withRouter(SearchBar));
