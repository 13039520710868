import {
    POST_REGISTRATION_USER,
    POST_REGISTRATION_USER_SUCCESS,
    POST_REGISTRATION_USER_ERROR,
    POST_REGISTRATION_AGENT,
    POST_REGISTRATION_AGENT_SUCCESS,
    POST_REGISTRATION_AGENT_ERROR,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
} from "./actionTypes";

// import { setCookie, removeCookie } from "../../helpers/utils/globalUtils";

const initialState = {
    // variable: value
    success: false,
    loading: false,
    error: undefined,
    forgotPassword: undefined,
    changePassword: undefined,
    registration: undefined,
    login: undefined,
};

const Auth = (state = initialState, action) => {
    switch (action.type) {
        case POST_REGISTRATION_USER:
        case POST_REGISTRATION_AGENT:
        case LOGIN:
        case LOGOUT:
        case FORGOT_PASSWORD:
        case CHANGE_PASSWORD:
            state = { ...state, loading: true };
            break;
        case FORGOT_PASSWORD_SUCCESS: {
            state = {
                ...state,
                loading: false,
                forgotPassword: {
                    status: "success",
                    message: action.payload,
                },
            };
            break;
        }
        case FORGOT_PASSWORD_ERROR: {
            state = {
                ...state,
                loading: false,
                forgotPassword: {
                    status: "error",
                    message: action.payload,
                },
            };
            break;
        }
        case CHANGE_PASSWORD_SUCCESS: {
            state = {
                ...state,
                loading: false,
                changePassword: {
                    status: "success",
                    message: action.payload,
                },
            };
            break;
        }
        case CHANGE_PASSWORD_ERROR: {
            state = {
                ...state,
                loading: false,
                changePassword: {
                    status: "error",
                    message: action.payload,
                },
            };
            break;
        }
        case POST_REGISTRATION_USER_SUCCESS:
        case POST_REGISTRATION_AGENT_SUCCESS:
            state = {
                ...state,
                loading: false,
                success: true,
                registration: {
                    status: "success",
                    message: action.payload,
                },
            };
            break;
        case LOGOUT_SUCCESS: {
            // removeCookie("name");
            // removeCookie("type");
            // removeCookie("userId");
            window.location.reload();
            state = { ...state, loading: false, success: true };
            break;
        }
        case LOGIN_SUCCESS: {
            // setCookie("name", "First name");
            // setCookie("type", "user");
            // setCookie("userId", 43);
            window.location.reload();
            state = { ...state, loading: false, success: true };
            break;
        }
        case LOGIN_ERROR: {
            state = {
                ...state,
                loading: false,
                login: {
                    status: "error",
                    message: action.payload,
                },
            };
            break;
        }
        case POST_REGISTRATION_USER_ERROR:
        case POST_REGISTRATION_AGENT_ERROR:
        case LOGOUT_ERROR:
            state = {
                ...state,
                loading: false,
                registration: {
                    status: "error",
                    message: action.payload,
                },
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default Auth;
