import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { withRouter } from "../helpers/utils/globalUtils";

import "../styles/CustomPage.scss";

import DownloadIcon from "../static/images/icons/download_white_icon.png";

import { getCustomPage } from "../store/actions";

class CustomPage extends Component {
    componentDidMount() {
        if (this.props.router?.params?.page) {
            this.props.getCustomPage(this.props.router?.params?.page, this.props.router.navigate);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.router?.params?.page !== prevProps.router?.params?.page) {
            this.props.getCustomPage(this.props.router?.params?.page, this.props.router.navigate);
        }
    }

    render() {
        if (this.props.loading) {
            return (
                <Container>
                    <Skeleton count={1} />
                    <Skeleton count={3} />
                    <Skeleton count={20} />
                </Container>
            );
        }

        return (
            <Container className="information_for_agents font-poppins">
                <div className="title">{this.props.title}</div>
                <div className="description" dangerouslySetInnerHTML={{ __html: this.props.description }}></div>
                <div className="items_wrapper">
                    {this.props.documents?.map((item, key) => (
                        <a href={item.url} download className="item_wrapper" key={key}>
                            <div className="item_title">{item.name}</div>
                            <img src={DownloadIcon} alt="Download icon" />
                        </a>
                    ))}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.CustomPage.loading,
        title: state.CustomPage.data?.title,
        description: state.CustomPage.data?.description,
        documents: state.CustomPage.data?.pdf_list,
    };
};

export default connect(mapStateToProps, { getCustomPage })(withRouter(CustomPage));
