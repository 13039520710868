import axios from "axios";

export const getSpecialOffersHotels = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/special-offer-hotels`).catch((error) => {
        return error;
    });
};

export const getSpecialOffersApartments = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/special-offer-apartments`).catch((error) => {
        return error;
    });
};

export const getFirstLastMinutesOffers = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/first-last-offers`).catch((error) => {
        return error;
    });
};
