export const cities = [
    "Beograd",
    "Novi Sad",
    "Niš",
    "Kragujevac",
    "Subotica",
    "Valjevo",
    "Loznica",
    "Zrenjanin",
    "Pančevo",
    "Čačak",
    "Kraljevo",
    "Novi Pazar",
    "Leskovac",
    "Užice",
    "Kruševac",
    "Vranje",
    "Sombor",
    "Kikinda",
    "Prijepolje",
    "Vršac",
    "Bor",
    "Bačka Palanka",
    "Priboj",
    "Bajina Bašta",
    "Vrbas",
    "Ćuprija",
    "Senta",
    "Trstenik",
    "Bačka Topola",
    "Sjenica",
    "Bela Crkva",
    "Ada",
    "Vrnjačka Banja",
    "Kanjiža",
    "Žabalj",
    "Odžaci",
    "Sremski Karlovci",
    "Beočin",
    "Sokobanja",
    "Lapovo",
    "Melenci",
    "Bački Petrovac",
    "Bogatić",
    "Horgoš",
    "Bač",
    "Banatski Karlovac",
    "Debeljača",
    "Stara Moravica",
    "Irig",
    "Čoka",
    "Elemir",
    "Ečka",
    "Markovac",
    "Novi Slankamen",
    "Vladimirovac",
    "Perlez",
    "Kamenica",
    "Bašaid",
    "Aradac",
    "Alibunar",
    "Vreoci",
    "Čenta",
    "Klek",
    "Deliblato",
    "Padej",
    "Lazarevo",
    "Novi Žednik",
    "Crna Trava",
    "Jagodina",
    "Šabac",
    "Gornji Milanovac",
    "Požega",
    "Krupanj",
    "Lebane",
    "Vladičin Han",
    "Knjaževac",
    "Ljubovija",
    "Smederevska Palanka",
    "Mali Zvornik",
    "Surdulica",
    "Požarevac",
    "Zaječar",
    "Paraćin",
    "Lučani",
    "Smederevo",
    "Aleksinac",
    "Despotovac",
    "Kladovo",
    "Pirot",
    "Vlasotince",
    "Prokuplje",
    "Batočina",
    "Dimitrovgrad",
    "Doljevac",
    "Velika Plana",
    "Svilajnac",
    "Svrljig",
    "Šid",
    "Negotin",
    "Bojnik",
    "Topola",
    "Blace",
    "Apatin",
    "Arilje",
    "Kovin",
    "Boljevac",
    "Kosjerić",
    "Ćićevac",
    "Plandište",
    "Varvarin",
    "Ražanj",
    "Mionica",
    "Aranđelovac",
    "Kula",
    "Novi Bečej",
    "Bečej",
    "Žitorađa",
    "Titel",
    "Merošina",
    "Stara Pazova",
    "Bosilegrad",
    "Knić",
    "Sečanj",
    "Kovačica",
    "Medveđa",
    "Žitište",
    "Čajetina",
    "Sremska Mitrovica",
    "Ivanjica",
    "Inđija",
    "Žabari",
    "Petrovac na Mlavi",
    "Mali Iđoš",
    "Ub",
    "Rača",
    "Srbobran",
    "Ljig",
    "Rekovac",
    "Novi Kneževac",
    "Lajkovac",
    "Aleksandrovac",
    "Malo Crniće",
    "Kuršumlija",
    "Raška",
    "Golubac",
    "Pećinci",
    "Nova Crnja",
    "Majdanpek",
    "Ruma",
    "Vladimirci",
    "Tutin",
    "Babušnica",
    "Brus",
    "Osečina",
    "Veliko Gradište",
    "Kučevo",
    "Koceljeva",
    "Temerin",
    "Gadžin Han",
    "Opovo",
    "Žagubica",
    "Trgovište",
    "Nova Varoš",
    "Bela Palanka",
    "Preševo",
    "Bujanovac",
    "Mladenovac",
    "Petrovaradin",
    "Obrenovac",
    "Grocka",
    "Surčin",
    "Barajevo",
    "Niška Banja",
    "Lazarevac",
    "Sopot",
    "Kostolac",
    "Van Srbije",
];
