import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { connect } from "react-redux";

import "../styles/Login.scss";

import { forgotPassword } from "../store/actions";

import Logo from "../static/images/unlimited_travel_logo_big.png";
import palm_leaf_1_icon from "../static/images/Login/login_leaf_1.png";
import palm_leaf_2_icon from "../static/images/Login/login_leaf_2.png";

const schema = yup.object().shape({
    email: yup.string().email("E-mail adresa nije validna").required("Polje mora biti popunjeno"),
});

class ForgotPassword extends Component {
    render() {
        return (
            <Container fluid className="login_background">
                <div className="login_content_wrapper">
                    <img src={palm_leaf_1_icon} alt="Palm leaf 1" id="palm_leaf_1" />
                    <img src={palm_leaf_2_icon} alt="Palm leaf 2" id="palm_leaf_2" />
                    <div className="login_logo_form font-poppins">
                        <img src={Logo} alt="Logo big" className="login_logo_image" />
                        {this.props.forgotPasswordStatus ? (
                            <div className="status_message" style={{ color: this.props.forgotPasswordStatus?.status === "success" ? "green" : "red" }}>
                                {this.props.forgotPasswordStatus?.message}
                            </div>
                        ) : (
                            <></>
                        )}
                        <Formik
                            validationSchema={schema}
                            initialValues={{
                                email: "",
                            }}
                            onSubmit={(values) => {
                                if (!this.props.loading) {
                                    this.props.forgotPassword(values);
                                }
                            }}
                        >
                            {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                                <Form className="login_form_wrapper">
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            className="login_input"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.email && errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>

                                    <div className="login_button" style={{ cursor: "pointer" }} onClick={handleSubmit}>
                                        Resetuj šifru
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        forgotPasswordStatus: state.Auth.forgotPassword,
        loading: state.Auth.loading,
    };
};

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
