import React, { Component } from "react";
import propTypes from "prop-types";

import "../../styles/Components/SearchCalendar.scss";

import { Calendar } from "react-multi-date-picker";

import { srpski_mala_slova } from "../../static/localization/calendar";

class SearchCalendar extends Component {
    render() {
        return (
            <div className="calendar-display-box" style={this.props.style}>
                <Calendar
                    className="calendar-picker"
                    range={true}
                    numberOfMonths={1}
                    shadow={false}
                    weekStartDayIndex={1}
                    locale={srpski_mala_slova}
                    onChange={this.props.changeSelectedDates}
                />
            </div>
        );
    }
}

SearchCalendar.propTypes = {
    style: propTypes.object,
    customClasses: propTypes.string,
    selectedDate: propTypes.object,
    changeSelectedDates: propTypes.func,
};

export default SearchCalendar;
