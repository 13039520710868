import {
    GET_SLIDER,
    GET_SLIDER_SUCCESS,
    GET_SLIDER_ERROR,
    GET_MAIN_DESTINATIONS,
    GET_MAIN_DESTINATIONS_SUCCESS,
    GET_MAIN_DESTINATIONS_ERROR,
    SET_ACTIVE_DESTINATION,
} from "./actionTypes";

export const getSlider = () => {
    return {
        type: GET_SLIDER,
    };
};

export const getSliderSuccess = (payload) => {
    return {
        type: GET_SLIDER_SUCCESS,
        payload,
    };
};

export const getSliderError = (payload) => {
    return {
        type: GET_SLIDER_ERROR,
        payload,
    };
};

export const getHomeMainDestinations = () => {
    return {
        type: GET_MAIN_DESTINATIONS,
    };
};

export const getHomeMainDestinationsSuccess = (payload) => {
    return {
        type: GET_MAIN_DESTINATIONS_SUCCESS,
        payload,
    };
};

export const getHomeMainDestinationsError = (payload) => {
    return {
        type: GET_MAIN_DESTINATIONS_ERROR,
        payload,
    };
};

export const setActiveDestination = (accommodation, destination) => {
    return {
        type: SET_ACTIVE_DESTINATION,
        payload: { accommodation, destination },
    };
};
