import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { connect } from "react-redux";

import "../styles/Login.scss";
import { changePassword } from "../store/actions";

import Logo from "../static/images/unlimited_travel_logo_big.png";
import palm_leaf_1_icon from "../static/images/Login/login_leaf_1.png";
import palm_leaf_2_icon from "../static/images/Login/login_leaf_2.png";
import { getCookie, withRouter } from "../helpers/utils/globalUtils";

const schema = yup.object().shape({
    password: yup.string().required("Morate upisati lozinku"),
    confirm_password: yup
        .string()
        .min(8, "Minimalni broj karaktera je 8")
        .oneOf([yup.ref("password"), null], "Lozinke se moraju podudarati")
        .required("Lozinke se moraju podudarati"),
});

class ResetPassword extends Component {
    render() {
        return (
            <Container fluid className="login_background">
                <div className="login_content_wrapper">
                    <img src={palm_leaf_1_icon} alt="Palm leaf 1" id="palm_leaf_1" />
                    <img src={palm_leaf_2_icon} alt="Palm leaf 2" id="palm_leaf_2" />
                    <div className="login_logo_form font-poppins">
                        <img src={Logo} alt="Logo big" className="login_logo_image" />
                        {this.props.changePasswordStatus ? (
                            <div className="status_message" style={{ color: this.props.changePasswordStatus?.status === "success" ? "green" : "red" }}>
                                {this.props.changePasswordStatus?.message}
                            </div>
                        ) : (
                            <></>
                        )}
                        <Formik
                            validationSchema={schema}
                            initialValues={{
                                password: "",
                                confirm_password: "",
                            }}
                            onSubmit={(values, actions) => {
                                if (this.props.router.params?.token && this.props.router.params?.email) {
                                    this.props.changePassword({ ...values, token: this.props.router.params?.token, email: this.props.router.params?.email });
                                } else if (getCookie("userId")) {
                                    this.props.changePassword(values);
                                }
                                actions.resetForm();
                            }}
                        >
                            {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                                <Form className="login_form_wrapper">
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="password"
                                            placeholder="Nova Šifra"
                                            name="password"
                                            className="login_input"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.password && errors.password}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="password"
                                            placeholder="Potvrdi šifru"
                                            name="confirm_password"
                                            className="login_input"
                                            value={values.confirm_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.confirm_password && errors.confirm_password}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.confirm_password}</Form.Control.Feedback>
                                    </Form.Group>

                                    <div className="login_button" onClick={handleSubmit}>
                                        Sačuvaj
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        changePasswordStatus: state.Auth.changePassword,
    };
};

export default connect(mapStateToProps, { changePassword })(withRouter(ResetPassword));
