import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import "../styles/Login.scss";

import { withRouter } from "../helpers/utils/globalUtils";

import Logo from "../static/images/unlimited_travel_logo_big.png";
import palm_leaf_1_icon from "../static/images/Login/login_leaf_1.png";
import palm_leaf_2_icon from "../static/images/Login/login_leaf_2.png";

import { login } from "../store/actions";

const schema = yup.object().shape({
    email: yup.string().required("Upišite email"),
    password: yup.string().required("Morate upisati lozinku"),
});

class Login extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.success !== prevProps.success && this.props.success) {
            this.props.router.navigate("/");
        }
    }

    render() {
        return (
            <Container fluid className="login_background">
                <div className="login_content_wrapper">
                    <img src={palm_leaf_1_icon} alt="Palm leaf 1" id="palm_leaf_1" />
                    <img src={palm_leaf_2_icon} alt="Palm leaf 2" id="palm_leaf_2" />
                    <div className="login_logo_form font-poppins">
                        <img src={Logo} alt="Logo big" className="login_logo_image" />
                        {this.props.loginStatus ? (
                            <div className="status_message" style={{ color: this.props.loginStatus?.status === "success" ? "green" : "red" }}>
                                {this.props.loginStatus?.message}
                            </div>
                        ) : (
                            <></>
                        )}
                        <Formik
                            validationSchema={schema}
                            initialValues={{
                                email: "",
                                password: "",
                            }}
                            onSubmit={(values) => {
                                this.props.login({ values, navigate: this.props.router.navigate });
                            }}
                        >
                            {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                                <Form className="login_form_wrapper" onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control
                                            type="email"
                                            placeholder="Vaš e-mail"
                                            name="email"
                                            className="login_input"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.email && errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            name="password"
                                            className="login_input"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.password && errors.password}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Link to="/forgot-password" className="login_forgot_password">
                                        Zaboravio si lozinku?
                                    </Link>

                                    <button type="submit" className="login_button">
                                        Uloguj se
                                    </button>
                                    <Link to="/registration" className="login_redirect_registration">
                                        Napravi novi nalog
                                    </Link>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        success: state.Auth.success,
        loginStatus: state.Auth.login,
    };
};

export default connect(mapStateToProps, { login })(withRouter(Login));
