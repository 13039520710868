import React, { Component } from "react";
import { Form } from "react-bootstrap";

import "../../styles/Components/MyReservationSearch.scss";

// import SearchReservationIcon from "../../static/images/icons/search_reservation_icon.png";

class MyReservationSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searched: "",
        };
    }

    render() {
        return (
            <Form className="search_reservation_wrapper">
                {/* <img src={SearchReservationIcon} alt="Search reservation icon" style={{ paddingLeft: 25 }} /> */}

                <Form.Control
                    type="text"
                    placeholder="Pretraži rezervaciju"
                    className="search_reservation_input"
                    value={this.state.searched}
                    onChange={(e) => this.setState({ searched: e.target.value })}
                />

                <div className="search_button" onClick={() => this.props.onSearch(this.state.searched)}></div>
            </Form>
        );
    }
}

export default MyReservationSearch;
