import React, { Component } from "react";
import propTypes from "prop-types";

import "../../styles/Components/SearchPeople.scss";

class SearchPeople extends Component {
    changeAdults(add = false) {
        let newPeople = this.props.currentPeople;
        if (newPeople.adults === null) {
            newPeople.adults = 1;
        }
        if (newPeople.children === null) {
            newPeople.children = 0;
        }
        // if (newPeople.children === 0 && !newPeople.childrenAges?.length) {
        //     newPeople.childrenAges = [0];
        // }

        if (add) {
            if (newPeople.adults < 8) {
                newPeople.adults++;
            }
        } else {
            if (newPeople.adults === 1) return;
            newPeople.adults--;
        }
        this.props.changeCurrentPeople(newPeople);
    }

    changeChildren(add = false) {
        let newPeople = this.props.currentPeople;
        if (newPeople.adults === null) {
            newPeople.adults = 1;
        }
        if (newPeople.children === null) {
            newPeople.children = 0;
        }
        // if (newPeople.children === 0 && !newPeople.childrenAges?.length) {
        //     newPeople.childrenAges = [0];
        // }

        if (add) {
            if (newPeople.children < 13) {
                newPeople.children++;
                newPeople.childrenAges.push(0);
            }
        } else {
            if (newPeople.children === 0) return;
            newPeople.children--;
            newPeople.childrenAges.pop();
        }
        this.props.changeCurrentPeople(newPeople);
    }

    changeChildrenAges(index, add = false) {
        let newPeople = this.props.currentPeople;
        if (newPeople.adults === null) {
            newPeople.adults = 1;
        }
        if (newPeople.children === null) {
            newPeople.children = 0;
        }
        // if (newPeople.children === 0 && !newPeople.childrenAges?.length) {
        //     newPeople.childrenAges = [0];
        // }

        if (add) {
            if (newPeople.childrenAges?.length - 1 >= index) {
                newPeople.childrenAges[index]++;
            } else {
                newPeople.childrenAges.push(0);
            }
        } else {
            if (newPeople.childrenAges[index] > 0) {
                newPeople.childrenAges[index]--;
            }
        }

        this.props.changeCurrentPeople(newPeople);
    }

    render() {
        return (
            <div className="people-display-box font-poppins" style={this.props.style}>
                <div className="age-wrapper">
                    <div className="age-title">Odrasli</div>
                    <div className="age-picker no-select">
                        <div
                            className="change-button"
                            onClick={() => {
                                this.changeAdults(true);
                            }}
                        >
                            +
                        </div>
                        <div className="change-number">{this.props.currentPeople.adults ? this.props.currentPeople.adults : 1}</div>
                        <div
                            className="change-button"
                            onClick={() => {
                                this.changeAdults();
                            }}
                        >
                            -
                        </div>
                    </div>
                </div>
                <div className="age-wrapper">
                    <div className="age-title">Deca</div>
                    <div className="age-picker no-select">
                        <div
                            className="change-button"
                            onClick={() => {
                                this.changeChildren(true);
                            }}
                        >
                            +
                        </div>
                        <div className="change-number">{this.props.currentPeople.children ? this.props.currentPeople.children : 0}</div>
                        <div
                            className="change-button"
                            onClick={() => {
                                this.changeChildren();
                            }}
                        >
                            -
                        </div>
                    </div>
                </div>
                {this.props.currentPeople?.children ? (
                    <div className="age-wrapper children-ages-wrapper">
                        <div className="children-ages-label">Godine dece</div>
                        {Array.from({ length: this.props.currentPeople?.children }, (v) => v)?.map((child, key) => {
                            return (
                                <div className="age-wrapper" key={key}>
                                    <div className="age-title">{`Dete ${key + 1}`}</div>
                                    <div className="age-picker no-select">
                                        <div
                                            className="change-button"
                                            onClick={() => {
                                                this.changeChildrenAges(key, true);
                                            }}
                                        >
                                            +
                                        </div>
                                        <div className="change-number">
                                            {this.props.currentPeople.childrenAges?.length && this.props.currentPeople.childrenAges[key]
                                                ? this.props.currentPeople.childrenAges[key]
                                                : 0}
                                        </div>
                                        <div
                                            className="change-button"
                                            onClick={() => {
                                                this.changeChildrenAges(key);
                                            }}
                                        >
                                            -
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    }
}
SearchPeople.propTypes = {
    style: propTypes.object,
    customClasses: propTypes.string,
    currentPeople: propTypes.object,
    changeCurrentPeople: propTypes.func,
};

export default SearchPeople;
