import React, { Component } from "react";
import { Link } from "react-router-dom";

import CoomingSoonImage from "../static/images/cooming_soon_image.png";

import "../styles/CoomingSoon.scss";

class CoomingSoon extends Component {
    render() {
        return (
            <div className="cooming_soon font-poppins">
                <img src={CoomingSoonImage} alt="Cooming soon" />
                <Link to="/">
                    <div className="cooming_soon_btn">Početna</div>
                </Link>
            </div>
        );
    }
}

export default CoomingSoon;
