import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import NotFoundLogo from "../static/images/not_found_logo.svg";

import "../styles/NotFound.scss";

class NotFound extends Component {
    render() {
        return (
            <Container className="not_found_wrapper font-poppins">
                <img src={NotFoundLogo} className="not_found_logo" alt="Not found" />
                <div className="not_found_text">Na pogrešnoj ste destinaciji</div>
                <Link to="/" className="not_found_button">
                    Početna
                </Link>
            </Container>
        );
    }
}

export default NotFound;
