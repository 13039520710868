import { takeEvery, fork, put, all, call } from "redux-saga/effects"; // remove comment when you start write functions

import { GET_DESTINATIONS, SEARCH_ACCOMMODATION, GET_ABOUT_HOTEL, MAKE_RESERVATION, MY_RESERVATIONS, CANCEL_RESERVATION } from "./actionTypes";
import {
    getDestinationsSuccess,
    getDestinationsError,
    searchAccommodationSuccess,
    // searchAccommodationError,
    getAboutHotelSuccess,
    getAboutHotelError,
    makeReservationSuccess,
    makeReservationError,
    myReservationsSuccess,
    myReservationsError,
    cancelReservationSuccess,
    cancelReservationError,
} from "./actions";

import {
    getDestinations,
    searchAccommodation,
    getAboutHotel,
    getHotelInfo,
    makeReservation,
    myReservation,
    cancelReservation,
} from "../../helpers/api/booking";

function* getDestinationsSaga() {
    try {
        const response = yield call(getDestinations);
        if (response && response.status === 200) {
            response.data.destinations.sort(function (a, b) {
                return b.hotels + b.apartments - (a.hotels + a.apartments);
            });

            yield put(getDestinationsSuccess(response.data));
        } else {
            yield put(getDestinationsError(response.error));
        }
    } catch (error) {
        yield put(getDestinationsError(error));
    }
}

function* searchAccommodationSaga({ payload: { data, sort, navigate } }) {
    try {
        const response = yield call(searchAccommodation, data);
        if (response && response.status === 200) {
            yield put(searchAccommodationSuccess({ data: response.data.hotels, sort }));
        } else {
            // navigate("/404");
            yield put(searchAccommodationSuccess({ data: [], sort }));
        }
    } catch (error) {
        // navigate("/404");
        yield put(searchAccommodationSuccess({ data: [], sort }));
    }
}

function* getAboutHotelSaga({ payload: { data, navigate } }) {
    try {
        let response = yield call(getAboutHotel, data);
        if (response && response?.data?.results) {
            response = yield call(getHotelInfo, { hotelId: data.hotelId, destId: data.destId });
        }
        if (response && response.status === 200) {
            yield put(getAboutHotelSuccess(response.data));
        } else {
            navigate("/404");
            yield put(getAboutHotelError(response.error));
        }
    } catch (error) {
        navigate("/404");
        yield put(getAboutHotelError(error));
    }
}

function* makeReservationSaga({ payload }) {
    try {
        const response = yield call(makeReservation, payload);
        if (response && response.status === 200) {
            yield put(makeReservationSuccess(response.data));
        } else {
            yield put(makeReservationError(response.error));
        }
    } catch (error) {
        yield put(makeReservationError(error));
    }
}

function* myReservationsSaga() {
    try {
        const response = yield call(myReservation);
        if (response && response.data?.status === 200) {
            yield put(myReservationsSuccess(response.data?.data));
        } else {
            yield put(myReservationsError(response.error));
        }
    } catch (error) {
        yield put(myReservationsError(error));
    }
}

function* cancelReservationSaga({ payload }) {
    try {
        const response = yield call(cancelReservation, payload);
        if (response && response.data?.status === 201) {
            yield put(cancelReservationSuccess(payload));
        } else {
            yield put(cancelReservationError(response.error));
        }
    } catch (error) {
        yield put(cancelReservationError(error));
    }
}

export function* watchGetDestinationsSaga() {
    yield takeEvery(GET_DESTINATIONS, getDestinationsSaga);
}

export function* watchSearchAccommodationSaga() {
    yield takeEvery(SEARCH_ACCOMMODATION, searchAccommodationSaga);
}

export function* watchGetAboutHotelSaga() {
    yield takeEvery(GET_ABOUT_HOTEL, getAboutHotelSaga);
}

export function* watchMakeReservationSaga() {
    yield takeEvery(MAKE_RESERVATION, makeReservationSaga);
}

export function* watchMyReservationsSaga() {
    yield takeEvery(MY_RESERVATIONS, myReservationsSaga);
}

export function* watchCancelReservationSaga() {
    yield takeEvery(CANCEL_RESERVATION, cancelReservationSaga);
}

function* bookingSaga() {
    yield all([
        fork(watchGetDestinationsSaga),
        fork(watchSearchAccommodationSaga),
        fork(watchGetAboutHotelSaga),
        fork(watchMakeReservationSaga),
        fork(watchMyReservationsSaga),
        fork(watchCancelReservationSaga),
    ]);
}

export default bookingSaga;
