import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

class BookingLoadingSkeletonMobile extends Component {
    render() {
        return (
            <div className="item_loading_skeleton">
                <div className="picture_holder">
                    <Skeleton height={192} containerClassName="picture_skeleton" />
                </div>
                <div className="info_part">
                    <Skeleton count={8} containerClassName="inside_description" />
                </div>
                <div className="price_part">
                    <Skeleton count={6} containerClassName="inside_price_part" />
                </div>
            </div>
        );
    }
}

export default BookingLoadingSkeletonMobile;
