import React, { Component } from "react";
import pdf_icon from "../../static/images/icons/PDF_icon.png";

import { openPDFdocument } from "../../helpers/utils/pdfUtils";

class AboutHotelFilters extends Component {
    render() {
        return (
            <div className="about_hotel_filters_wrapper">
                {this.props.hotelDescriptionPDF ? (
                    <div onClick={() => openPDFdocument(this.props.hotelDescriptionPDF)} className="filter-item download_item">
                        <img src={pdf_icon} alt="Download description icon" />
                        Opis hotela na srpskom
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    }
}

export default AboutHotelFilters;
