import React, { Component } from "react";
import MediaQuery from "react-responsive";

import SpecialOffersAccommodationItemDesktop from "./Desktop";
import SpecialOffersAccommodationItemMobile from "./Mobile";

class SpecialOffersAccommodationItem extends Component {
    render() {
        return (
            <>
                <MediaQuery minWidth={1200}>
                    <SpecialOffersAccommodationItemDesktop {...this.props} />
                </MediaQuery>
                <MediaQuery maxWidth={1199}>
                    <SpecialOffersAccommodationItemMobile {...this.props} />
                </MediaQuery>
            </>
        );
    }
}

export default SpecialOffersAccommodationItem;
