import React, { Component } from "react";

import "../../styles/Components/AboutHotelDescription.scss";

class AboutHotelDescription extends Component {
    render() {
        if (this.props.description) {
            return <div className="about_hotel_description_wrapper" dangerouslySetInnerHTML={{ __html: this.props.description }}></div>;
        }

        return <></>;
    }
}

export default AboutHotelDescription;
