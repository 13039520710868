import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";

import SearchBar from "../Reusable/SearchBar";
import BookingQuickFilters from "../BookingQuickFilters";

import "../../styles/Booking.scss";
import SortingSelect from "../Reusable/SortingSelect";
import BookingFilters from "../Reusable/BookingFilters";
import BookingList from "../Reusable/BookingList";
import Spinner from "../../components/Loading/Spinner";

class BookingDesktop extends Component {
    render() {
        return (
            <Container fluid className="booking_main_wrapper_desktop font-poppins">
                <Container className="booking_inner_wrapper">
                    {/* prettier-ignore */}
                    <BookingQuickFilters activeTypeFilters={this.props.activeTypeFilters} changeActiveFilters={this.props.changeActiveFilters} />
                    <SearchBar
                        width={100}
                        className="search_bar_external_class"
                        route={this.props.route}
                        filters={this.props.filters}
                        changeFilters={this.props.changeFilters}
                    />
                    <div className="sort_wrapper">
                        <div className="sort_text">Sortiraj: </div>
                        <SortingSelect mobile={false} filters={this.props.filters} changeFilters={this.props.sortAccommodationPrice} />
                    </div>
                    <div className="listings_area">
                        <div>
                            <BookingFilters
                                className="bookings_filter_desktop"
                                filters={this.props.filters}
                                changeFilters={this.props.changeFilters}
                                deleteFilters={this.props.deleteFilters}
                                searchByFilters={this.props.searchByFilters}
                            />
                        </div>
                        <div className="bookings_items">
                            <BookingList
                                loading={this.props.loading}
                                destination={this.props.filters?.selectedPlace}
                                params={new URLSearchParams(this.props.filters).toString()}
                            />
                        </div>
                    </div>
                    {this.props.loading && <Spinner destinationName={this.props.destinationName} filters={this.props.filters} />}
                </Container>
            </Container>
        );
    }
}

BookingDesktop.propTypes = {
    activeTypeFilters: PropTypes.arrayOf(PropTypes.string),
    changeActiveFilters: PropTypes.func,
    destinationName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        loading: state.Booking.loading || state.Booking.loadingSearch,
    };
};

export default connect(mapStateToProps, {})(BookingDesktop);
