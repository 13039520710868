import React, { Component } from "react";
import PropTypes from "prop-types";

import "../../styles/Components/BookingFilters.scss";

import CurrencySelect from "./CurrencySelect";
import ProgramDropdown from "./ProgramDropdown";

import SearchIcon from "../../static/images/icons/search_icon.png";
import InactiveSearchIcon from "../../static/images/icons/inactive_search_icon.png";

class BookingFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSearchButton: false,
            activeFavoriteFilter: false,
        };
    }

    checkActiveFilters = (filterName, value) => {
        if (this.props.filters && this.props.filters[filterName] && this.props.filters[filterName].find((filter) => filter === value)) {
            return true;
        }

        return false;
    };

    checkFilters = () => {
        let arrFilters = ["transport", "kategorija", "aparments", "mealPlan", "cena"];
        let test = false;
        arrFilters.forEach((v) => {
            if (Object.keys(this.props.filters).includes(v)) {
                test = true;
            }
        });

        return test;
    };

    render() {
        return (
            <>
                <div className={`filters_outer_wrapper ${this.props.className || ""}`}>
                    <div className="filters_area">
                        <div className="filters_title">Transport</div>
                        <div className="filters_radio">
                            {[
                                {
                                    value: "sopstveni_prevoz",
                                    label: "Sopstveni prevoz",
                                },
                                {
                                    value: "bus",
                                    label: "Autobus",
                                },
                                {
                                    value: "airplane",
                                    label: "Avion",
                                },
                            ].map((item, key) => (
                                <label className="radio_row" key={key}>
                                    <input
                                        type="radio"
                                        value={item.value}
                                        name="transport"
                                        checked={this.props.filters && this.props.filters["transport"] ? this.props.filters["transport"] === item.value : false}
                                        onChange={(e) => this.props.changeFilters("transport", item.value)}
                                    />{" "}
                                    {item.label}
                                </label>
                            ))}
                        </div>
                    </div>

                    <div className="filters_area">
                        <div className="filters_title">Kategorija</div>
                        <div className="filters_radio filter_stars">
                            {["5", "4", "3", "2", "1"].map((item, key) => (
                                <label className="radio_row" key={key}>
                                    <input
                                        type="checkbox"
                                        value={item}
                                        name="kategorija"
                                        checked={this.props.filters["kategorija"] ? this.props.filters["kategorija"].includes(item) : false}
                                        onChange={() => this.props.changeFilters("kategorija", item, true)}
                                    />{" "}
                                    {Array.from({ length: parseInt(item) }, (v) => v)?.map((star, index) => (
                                        <span className="fa fa-star checked" key={index}></span>
                                    ))}
                                </label>
                            ))}
                            <label className="radio_row apartman_filter">
                                <input
                                    type="checkbox"
                                    value="apartman"
                                    name="aparments"
                                    checked={this.props.filters["aparments"] ? Boolean(this.props.filters["aparments"]) : false}
                                    onChange={() => this.props.changeFilters("aparments", !Boolean(this.props.filters["aparments"]))}
                                />{" "}
                                APP / Studio
                            </label>
                        </div>

                        <div className="filters_title meal_title">Tip obroka</div>
                        <div className="filters_radio">
                            {[
                                {
                                    value: "ultra_all_inclusive",
                                    label: "UAL - Ultra all inclusive",
                                },
                                {
                                    value: "all_inclusive",
                                    label: "AL - ALL Inclusive",
                                },
                                {
                                    value: "bed_breakfast",
                                    label: "BB - Bed breakfast",
                                },
                                {
                                    value: "full_board",
                                    label: "FB - FULL Board",
                                },
                                {
                                    value: "half_board",
                                    label: "HB - HALF Board",
                                },
                                {
                                    value: "half_board_modified_american",
                                    label: "HBM - Half board American",
                                },
                                {
                                    value: "room_only",
                                    label: "RO - Room only",
                                },
                            ].map((item, key) => (
                                <label className="radio_row" key={key}>
                                    <input
                                        type="checkbox"
                                        value={item.value}
                                        name="mealPlan"
                                        checked={
                                            this.props.filters && this.props.filters["mealPlan"] ? this.props.filters["mealPlan"].includes(item.value) : false
                                        }
                                        onChange={() => this.props.changeFilters("mealPlan", item.value, true)}
                                    />{" "}
                                    {item.label}
                                </label>
                            ))}
                        </div>

                        <div className="price_element_outer_wrapper">
                            <div className="price_element">
                                <input
                                    type="number"
                                    name="cena"
                                    maxLength={5}
                                    placeholder="Cena do"
                                    value={this.props.filters && this.props.filters["cena"] ? this.props.filters["cena"] : ""}
                                    onChange={(e) => this.props.changeFilters("cena", e.target.value)}
                                />
                                <CurrencySelect mobile={false} />
                            </div>
                        </div>
                    </div>

                    <div className="butttons_wrapper no-select">
                        <div className="reset_button" onClick={() => this.props.deleteFilters()}>
                            Obriši sve
                        </div>
                        <div
                            className={`search_button ${this.checkFilters() && Object.keys(this.props.filters).length ? "active" : "inactive"}`}
                            onClick={() => this.props.searchByFilters()}
                        >
                            <img src={this.checkFilters() && Object.keys(this.props.filters).length ? SearchIcon : InactiveSearchIcon} alt="Search icon" />{" "}
                            Pretraga
                        </div>
                    </div>
                </div>
                <ProgramDropdown />
            </>
        );
    }
}

BookingFilters.propTypes = {
    className: PropTypes.string,
};

export default BookingFilters;
