import React from "react";
import { useParams } from "react-router-dom";

import AboutCountryDesktopMobile from "../components/AboutCountry/AboutCountryDesktopMobile";

function AboutCountry() {
    const { country } = useParams();

    return <AboutCountryDesktopMobile country={country?.toUpperCase()} />;
}

export default AboutCountry;
