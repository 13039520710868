import React, { Component } from "react";
import ImageGallery from "react-image-gallery";

class Gallery extends Component {
    render() {
        return (
            <>
                <ImageGalleryDesktop photos={this.props.photos} />
            </>
        );
    }
}

export default Gallery;

class ImageGalleryDesktop extends Component {
    getImages = () => {
        return this.props.photos?.map((photo) => ({
            original: photo,
            thumbnail: photo,
        }));
    };

    render() {
        const images = this.getImages();
        if (images) {
            return <ImageGallery items={images} />;
        }

        return <></>;
    }
}
