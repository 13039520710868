import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AboutCountryInformations from "../../Reusable/AboutCountryInformations";
import Gallery from "../../Reusable/Gallery";

import AboutGreeceImage1 from "../../../static/images/About_Country/About_Greece/Food/about_greece_food_2.png";
import AboutGreeceImage2 from "../../../static/images/About_Country/About_Greece/Food/about_greece_food_3.png";

class AboutGreeceFood extends Component {
    render() {
        return (
            <div className="about_greece_history">
                <AboutCountryInformations
                    image={require("../../../static/images/About_Country/About_Greece/Food/about_greece_food_1.png")}
                    title={"Grčka kuhinja"}
                    description={`Grci su oduvek pridavali veliki značaj hrani. Još u antičko doba postojali su glavni I pomoćni kuvari ali i robovi posebno obučeni za pečenja, poslastice, soseve.Prvi kuvar u istoriji napisan je 350 godine pre nove ere a napisao ga je filozof, pesnik i veliki gurman po imenu Archestratus. On je prvi upotrebio termin– gastronomija, koja u prevodu znači – pravila stomaka. Zahvaljujući brojnim spoljašnjim uticajima tokom vekova Grčka kuhinja se postepeno menjala: Persijanaci su najzaslužniji što grci koriste pirinač, jogurt, kolače sa medom,orašaste plodove i susam. Stari Rimljani doneli su pastu, Arapi niz začina i dodataka širi miris cimeta, kima a Turci kafu.`}
                />
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="description">
                                Naravno, posebno mesto u Grkoj tradiciji zauzimaju masline. One predstavljaju mnogo više od obične namirnice. One su hrana i lek
                                a nekada su na Mediteranu bile smatrane svetim drvetom. Skoro sva hrana se priprema na maslinovom ulju. Što se ostalog voća tiče
                                (a masline su zaista voće) Grci su poznati po uzgajanju lubenice, grožđa, breskvi. Glavni deo mesne ishrane je riba. Jagnjetina
                                i piletina kao i suvlaki su čest izbor dok se svinjetnina i govedina zaista retko konzumiraju. Takođe, supu nećete često naći na
                                meniju (efekat vruće klime). Grci vole ražnjiće: meso mekano, marinirano i začinjeno začinskim travama, maslinovim uljem, lukom,
                                limunovim sokom, biberom i solju. Kao i u svakoj primorskoj državi, popularna su i jela od morskih plodova ili "talassina":
                                lignje, hobotnice, rakovi, školjke i raznovrsna riba koja se služi sa sosem od maslinovog ulja i limunovog soka.
                            </div>
                        </div>
                        <Row className="content_wrapper">
                            <Col lg={6}>
                                <div className="description">
                                    Sir se u staroj grčkoj smatrao hranom Bogova a današnji Grci zauzimaju drugo mesto u Evropi po potrošnji sireva (iza
                                    Francuza naravno). Postoji nekoliko sireva karakterističnih za Grčku: Feta, polutvrd, beli sir koji se pravi od kravljeg
                                    mleka, Kasseri-tvrdi, žuti sir, Mizithra-mek, beli sir koji se pravi od kozijeg ili ovčijeg mleka. Popularna grčka salata je
                                    nezamisliva bez Feta sira! To je ujedno i najpoznatiji grčki proizvod izvan granica Grčke
                                </div>
                                <img src={AboutGreeceImage1} alt="Greece food 1" />
                            </Col>
                            <Col lg={6}>
                                <img src={AboutGreeceImage2} alt="Greece food 2" />
                                <div className="description">
                                    Takođe, Grčka je domovina vina. Stari Grci su slavivši boga Dionisa pijanstvo doživljavali kao duhovno uzdizanje. Najstarije
                                    vino je Retsina - vino sa smolom borova koje se 3000 godina sprema po istom receptu. Pored vina, Metaxa je najpoznatije
                                    grčko alkoholno piće: mešavina konjaka, likera i prirodnih aroma. naravno kafa je nezaobilazan pojam, i to "grčka kafa"
                                    (naručivanje ''turske kafe'' obično mami smrknute poglede Grka). Radno vreme restorana je od 12:00- 16:00 i od 20:00 –
                                    00:00, jer Grci više od svega vole popodnevnu pauzu za ručak i dremku.
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <Container>
                    <Gallery />
                </Container>
            </div>
        );
    }
}

export default AboutGreeceFood;
