import React, { Component } from "react";
import { Container } from "react-bootstrap";
import MediaQuery from "react-responsive";
import "../../styles/Footer.scss";

import MainFooterDesktop from "./MainFooter/Desktop";
import MainFooterMobile from "./MainFooter/Mobile";

import facebook_icon from "../../static/images/icons/facebook_icon.png";
import instagram_icon from "../../static/images/icons/instagram_icon.png";
import twitter_icon from "../../static/images/icons/twitter_icon.png";

class Footer extends Component {
    render() {
        return (
            <footer className="font-poppins">
                <Container fluid>
                    <MediaQuery maxWidth={990}>
                        <MainFooterMobile />
                    </MediaQuery>
                    <MediaQuery minWidth={991}>
                        <MainFooterDesktop />
                    </MediaQuery>

                    <div className="bottom-part-wrapper ">
                        <div className="bottom-part-inside">
                            <a href="google.com">Kneza Miloša 84, 11000 Beograd, SRBIJA</a>
                            <a href="mailto:info@unlimitedtravel.rs">info@unlimitedtravel.rs</a>
                            <a href="tel:+381604145131">060/4145131</a>
                            <div className="bottom-part-social-media">
                                <a href="google.com" className="icon-item">
                                    <img src={facebook_icon} alt="Facebook icon" />
                                </a>
                                <a href="google.com" className="icon-item">
                                    <img src={instagram_icon} alt="Instagram icon" />
                                </a>
                                <a href="google.com" className="icon-item">
                                    <img src={twitter_icon} alt="Twitter icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </Container>
            </footer>
        );
    }
}

export default Footer;
