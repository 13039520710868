import React, { Component } from "react";

import "../../styles/Components/DiscountRibbon.scss";

import { discountColor } from "../../helpers/utils/globalUtils";

class DiscountRibbon extends Component {
    render() {
        const el = this.props.data;
        return (
            <div className="discount_ribbon_wrapper">
                <div className="discount_ribbon" style={{ borderLeftColor: discountColor(el?.discount) }}></div>
                <div className="discount_text font-poppins" id="discount_text_1">
                    {`${el.discount}%`}
                </div>
                <div className="discount_text font-poppins" id="discount_text_2">
                    popust
                </div>
            </div>
        );
    }
}

export default DiscountRibbon;
