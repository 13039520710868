import { GET_BLOG, GET_BLOG_SUCCESS, GET_BLOG_ERROR, GET_SINGLE_BLOG, GET_SINGLE_BLOG_SUCCESS, GET_SINGLE_BLOG_ERROR } from "./actionTypes";

const initialState = {
    // variable: value
    blogs: undefined,
    singleBlog: undefined,
    loading: false,
    error: undefined,
};

const Blog = (state = initialState, action) => {
    switch (action.type) {
        case GET_BLOG:
            state = { ...state, loading: true };
            break;
        case GET_BLOG_SUCCESS:
            state = { ...state, loading: false, blogs: action.payload };
            break;
        case GET_BLOG_ERROR:
            state = { ...state, loading: false };
            break;
        case GET_SINGLE_BLOG:
            state = { ...state, loading: true };
            break;
        case GET_SINGLE_BLOG_SUCCESS:
            state = { ...state, loading: false, singleBlog: action.payload };
            break;
        case GET_SINGLE_BLOG_ERROR:
            state = { ...state, loading: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default Blog;
