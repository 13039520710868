import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";

import { withRouter } from "../helpers/utils/globalUtils";

import Gallery from "../components/Reusable/Gallery";

import "../styles/DestinationDetails.scss";

import destination_icon from "../static/images/Destination_Details/destination_icon.png";
import gallery_icon from "../static/images/Destination_Details/gallery_icon.png";
import accomodation_icon from "../static/images/Destination_Details/accomodation_icon.png";
import BeachImage from "../static/images/Destination_Details/beach.png";

import { getSingleRegion } from "../store/actions";

class DestinationDetails extends Component {
    componentDidMount() {
        if (this.props.router?.params?.region) {
            this.props.getSingleRegion(this.props.router?.params?.region, this.props.router.navigate);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.router?.params?.region !== prevProps.router?.params?.region) {
            this.props.getSingleRegion(this.props.router?.params?.region, this.props.router.navigate);
        }
    }

    render() {
        const { singleRegion } = this.props;
        return (
            <div className="destination_details_wrapper">
                <div
                    className="destination_details_cover_image"
                    style={{ backgroundImage: `url('${require("../static/images/Destination_Details/destination.png")}')` }}
                >
                    <div className="overlay"></div>
                    <Container className="destination_country_names">
                        <div className="destination_name font-proza-libre">{singleRegion?.title}</div>
                        <div className="country_name font-proza-libre">{singleRegion?.country}</div>
                    </Container>
                </div>
                <Container className="quick_links_wrapper font-poppins">
                    <Row className="quick_links_items">
                        <Col lg={4}>
                            <a href="#destinations" className="quick_links_item">
                                <img src={destination_icon} alt="Destination icon" />
                                Destinacije
                            </a>
                        </Col>
                        <Col lg={4}>
                            <a href={"#gallery"} className="quick_links_item">
                                <img src={gallery_icon} alt="Gallery icon" />
                                Foto galerija
                            </a>
                        </Col>
                        <Col lg={4}>
                            <div className="quick_links_item">
                                <img src={accomodation_icon} alt="Accomodation icon" />
                                Izaberite smeštaj
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="content_fake_background_wrapper font-poppins" id="destinations">
                    <Container>
                        <Col>
                            <div className="place_title">{singleRegion?.title}</div>
                            <div className="place_description" dangerouslySetInnerHTML={{ __html: singleRegion?.description }}>
                                {/* Regija Halkidiki se nalazi u severnoj Grčkoj i prostire od grada Soluna prema jugu formirajući tri rta, takozvana prsta:
                                Kasandru, Sitoniju i Atos. Bogata priroda, gde se borove šume smenjuju sa dugim peščanim plažama i morskim površinama neobičnih
                                nijansi plave boje, a prevashodno lokacija, ključni su razlozi zašto je ovo najposećenija regija turista iz Srbije, ali i samih
                                Grka sa severa. Odlični prirodni uslovi su doprineli da se izgradi jako dobra turistička infrastruktura. Na Halkidikiju nijedna
                                tačka nije udaljena više od 137 kilometara od vode (što je oko sat i po vremena vožnje). Tako da je najveći broj hotelskih
                                kapaciteta i resort-ova skoncentrisan blizu ili na samim plažama. */}
                            </div>
                        </Col>

                        <Row className="about_destination_item">
                            <Col lg={6}>
                                <div className="place_title">KASANDRA</div>
                                <div className="place_description">
                                    Kasandra je omiljena destinacija za letovanje naših turista. Razlog tome je najpre blizina (prvo mesto udaljeno je oko 60km
                                    od Soluna), prelepe plaže, ali i pristupačne cene. Sam ulazak na početak Kasandre je specifičan zato što se mora preći most
                                    kod mesta Nea Potidea, koji povezuje Kasandru sa Halkidikijem i zaliv Termaikos na zapadu sa zalivom Toroneos na istoku.
                                    Kasandra je turistički razvijenija u odnosu na Sitoniju i Atos, pa samim tim i urbanija, što je prednost za one koji žele
                                    raznovstan odmor. Ukoliko volite popularna i vrlo posećena mesta sa lepim gradskim plažama, šetalištima s mnoštovom kafica,
                                    restorana, prodavnica, svakako treba odabrati Pefkohori, Hanioti, Polihrono i Kaliteu. Sva ova mesta imaju lepe plaže,
                                    naročito Kalitea čija se plaža ubraja u najlepše na čitavom Halkidikiju. Za ljubitelje mirnijeg letovanja pravi izbor su
                                    mesta na zapadnoj obali: Siviri, Furka, Nea Skioni, Posidi, Loutra. Najlepše plaže Kasandre: Egeopelagitika kod Posidija,
                                    Ksenija i čitav potez od Pefkohorija ka Paljuriju sa fantastičnim beach barovima, plaža u Kalitei i potez od Kalitee prema
                                    Kriopigiju, Sani kod čuvenog Sani Resorta, kao i veliki peščani rt u Posidiju
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="position-relative beach_name_image_wrapper">
                                    <div className="beach_name_wrapper">
                                        <div className="beach_name">Egeopelagitika</div>
                                        <div>plaža kod Posidija</div>
                                    </div>
                                    <img src={BeachImage} alt="Destination details" />
                                </div>
                            </Col>
                        </Row>
                        <Row className="about_destination_item">
                            <Col lg={6}>
                                <div className="place_title">Sitonija</div>
                                <div className="place_description">
                                    Prva asocijacija za Sitoniju je nezaboravni krajolik, mir i tišina, kvalitetne plaže. Sitonija je smeštena između Kasandre i
                                    Atosa. Iako je 300 km duga obala Sitonije raznovrsnija u odnosu na Kasandru, turizam na Sitoniji je slabije razvijen. To je
                                    upravo prednost ovog prsta, jer kuda god da krenete bićete okruženi brdima pod borovom šumom, vinogradima, voćnjacima u
                                    unitrašnjosti i plantažama maslina. Na obali se smenjuju živopisna ribarska sela, prelepe uvale, borove šume, turistički
                                    kompleksi, kamp naselja i duge peščane plaže. Zapadna obala Sitonije je komercijalizovanija od istočne obale. Odlikuju je
                                    duge, široke plaže sa kvalitetnim peskom. Prvo poznatije letovalište je Gerakini, zatim Psakoudia, južnije Metamorfozi i
                                    Nikiti. Najurbanije mesto zapadne obale je Neos Marmaras. Ovde možete naći bezbroj taverni, restorana, kafića, barova,
                                    marketa, prodavnica suvenira... Plaže oko Neos Marmarasa su male sa mekim peskom i postepenim ulaskom u vodu. Obilazak
                                    zapadne obale Sitonije završava se luksuzim odmaralištem Porto Carras i mestašcem Toroni sa čuvenom plažom sa zlatnim
                                    peskom. Mesto Vouvourou je pravo mesto za porodičan odmor, ukoliko se odlučite za istočnu obalu Sitonije. Posebna atrakcija
                                    je ostrvo Diaporos, koje je nalazi preko puta mesta Vouvourou. Do ostrva se lako stiže čamcem, vode su mirne, a ostvro je
                                    odlično za istaživanje laguna i izolovanih plaža. Teško je izdvojiti najbolju plažu, ali svakako preporuka je ne izostaviti
                                    egzotične plaže sa neverovatnom bojom vode i neobičnom konfiguracijom terena, poput: Orange beach (Kavourotripes),
                                    Kalamitsi, Karidi, Trani Ammouda, Lagomandra, Kalogria, Koviou plaža i Platanitsi.
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="position-relative beach_name_image_wrapper">
                                    <div className="beach_name_wrapper">
                                        <div className="beach_name">Egeopelagitika</div>
                                        <div>plaža kod Posidija</div>
                                    </div>
                                    <img src={BeachImage} alt="Destination details" />
                                </div>
                            </Col>
                        </Row>
                        <Row className="about_destination_item">
                            <Col>
                                <div className="place_title">ATOS</div>
                                <div className="place_description">
                                    Treći i najistočniji prst predstavlja Atos. Čitavom dužinom poluostrva proteže se planinski greben, koji se izdiže nad morem
                                    i doseže visinu do 2000m - Sveta Gora, najznačajniji centar pravoslavlja. Tako da ostrvo najviše posećuju hodočasnici. U
                                    podnožju planine Atos, nalazi se Uranopolis - Nebeski grad. Simbol Uranopolisa jeste vizantijski toranj Prosforio, danas
                                    jedinstveni muzej hrišćanskih antikviteta. Uranopolis je takođe jezgro većine hotela i plaža na Atosu. Najveći broj lepih
                                    plaža na Atosu nalazi se u okolini mesta Nea Roda, u okolini mesta Olimpijada i na ostrvu Amuliani. Preporuka, bilo da ste
                                    ljubitelji divljih manjih ili uređenih prostranih plaža, su: Babilon, Cuba (Proti Ammoudia), Zugla, Kakoudia, Legend plaža,
                                    Vulitsa i Komitsa i najpopularnija plaža na zapadu Ksiropotami.
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="fake_background blue"></div>
                </div>
                <Container id="gallery">
                    <Gallery />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        singleRegion: state.General.singleRegion,
    };
};

export default connect(mapStateToProps, { getSingleRegion })(withRouter(DestinationDetails));
