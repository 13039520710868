import React, { useState } from "react";
import Select, { components } from "react-select";
import { Transition } from "react-transition-group";

import ArrowDownIcon from "../../static/images/icons/arrow_down_icon.png";

const currencyOptions = [
    { value: "EUR", label: "EUR" },
    { value: "RSD", label: "RSD" },
];

const customStylesDesktop = {
    option: (provided, state) => ({
        ...provided,
        width: "100px",
        height: "46px",
        color: state.isSelected ? "white" : "black",
        backgroundColor: state.isSelected ? "black" : "white",
    }),
    control: (provided) => ({
        ...provided,
        width: "100px",
        height: "46px",
        minHeight: "46px",
        border: "1px solid #166FA9",
        borderRadius: "9px",
        backgroundColor: "white",
    }),
    indicatorSeparator: (provided) => ({
        display: "none",
    }),
    indicatorsContainer: (provided) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }),
    input: (provided, state) => ({
        ...provided,
        margin: "0px",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: "46px",
        padding: "0 8px",
    }),
};

const customStylesMobile = {
    option: (provided, state) => ({
        ...provided,
        width: "100px",
        height: "46px",
        color: state.isSelected ? "white" : "black",
        backgroundColor: state.isSelected ? "black" : "white",
    }),
    control: (provided) => ({
        ...provided,
        width: "100px",
        height: "46px",
        minHeight: "46px",
        border: "1px solid #166FA9",
        borderRadius: "9px",
        backgroundColor: "white",
    }),
    indicatorSeparator: (provided) => ({
        display: "none",
    }),
    indicatorsContainer: (provided) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }),
    input: (provided, state) => ({
        ...provided,
        margin: "0px",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: "46px",
        padding: "0 8px",
    }),
};

const defaultCountryArrowStyle = {
    transition: `transform ${800}ms ease`,
    transform: "rotate(0deg)",
};
const transitionCountryArrowStyles = {
    entering: { transform: "rotate(180deg)" },
    entered: { transform: "rotate(180deg)" },
    exiting: { transform: "rotate(0deg)" },
    exited: { transform: "rotate(0deg)" },
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Transition in={props.isFocused} timeout={500}>
                {(state) => (
                    <img
                        src={ArrowDownIcon}
                        alt="Arrow icon"
                        width={19}
                        style={{
                            ...defaultCountryArrowStyle,
                            ...transitionCountryArrowStyles[state],
                        }}
                    />
                )}
            </Transition>
        </components.DropdownIndicator>
    );
};

function CurrencySelect(props) {
    const [selected, setSelected] = useState(currencyOptions[0]);

    return (
        <Select
            className={`sort_select currency_selector ${props.className || ""}`}
            options={currencyOptions}
            components={{ DropdownIndicator }}
            value={selected}
            onChange={setSelected}
            isSearchable={false}
            styles={props.mobile === false ? customStylesDesktop : customStylesMobile}
        />
    );
}

export default CurrencySelect;
