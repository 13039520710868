import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// import "../../styles/AboutHotel.scss";
import "../../styles/Mobile/AboutHotel.scss";

import AboutHotelFilters from "../Reusable/AboutHotelFilters";
import AboutHotelInformations from "../Reusable/AboutHotelInformations";
import AboutHotelDescription from "../Reusable/AboutHotelDescription";
import AboutHotelServices from "../Reusable/AboutHotelServices";
import AboutHotelRooms from "../Reusable/AboutHotelRooms";
import Gallery from "../Reusable/Gallery";
import Maps from "../Reusable/Maps";

// import { aboutHotelFakeData } from "../../static/data/aboutHotelFakeData";

class AboutHotelMobile extends Component {
    render() {
        return (
            <Container fluid className="about_hotel_wrapper_desktop about_hotel_wrapper_mobile font-poppins">
                <Container>
                    <AboutHotelFilters />
                    {this.props.loading && (
                        <div>
                            <div style={{ marginBottom: 15 }}>
                                <Skeleton count={8} />
                            </div>
                            <div style={{ height: 350, marginBottom: 15 }}>
                                <Skeleton height={"100%"} />
                            </div>
                            <div style={{ marginBottom: 15 }}>
                                <Skeleton count={8} />
                            </div>
                            <div style={{ marginBottom: 15 }}>
                                <Skeleton count={8} />
                            </div>
                            <div style={{ height: 150 }}>
                                <Skeleton height={"100%"} />
                            </div>
                        </div>
                    )}
                    {!this.props.loading && (
                        <>
                            <AboutHotelInformations data={{ ...this.props.informations, destId: this.props.filters?.destId }} />
                            <Gallery photos={this.props.photos} />
                            <AboutHotelDescription description={this.props.description} />
                            <AboutHotelServices data={this.props.services} />
                            <Maps location={this.props.location} />
                        </>
                    )}
                    <AboutHotelRooms
                        rooms={this.props.rooms}
                        informations={this.props.informations}
                        filters={this.props.filters}
                        changeFilters={this.props.changeFilters}
                    />
                </Container>
            </Container>
        );
    }
}

export default AboutHotelMobile;
