export const quickFilters = [
    {
        label: "Hoteli",
        value: "hoteli",
    },
    {
        label: "Apartmani",
        value: "apartmani",
    },
    {
        label: "Paket aranžman",
        value: "paket_aranzman",
    },
    {
        label: "Avio karte",
        value: "avio_karte",
    },
    {
        label: "Izleti",
        value: "izleti",
    },
];

const RESERVATIONS_STATUS = {
    approved: {
        value: 0,
        status: {
            icon: require("../../static/images/icons/confirmed_reservation_icon.png"),
            text: "Potvrdjeno",
        },
    },
    onwait: {
        value: 1,
        status: {
            icon: require("../../static/images/icons/pending_reservation_icon.png"),
            text: "Čeka se",
        },
    },
    cancelled: {
        value: 2,
        status: {
            icon: require("../../static/images/icons/canceled_reservation_icon.png"),
            text: "Otkazano",
        },
    },
};

export const statusIconText = (status) => {
    if (status) {
        return RESERVATIONS_STATUS[status]?.status;
    }

    return;
};

export const checkIfCancelled = (status) => {
    if (RESERVATIONS_STATUS[status]?.value === 2) {
        return true;
    }

    return false;
};
