import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import AboutCountryInformations from "../Reusable/AboutCountryInformations";

import CyprusImage1 from "../../static/images/About_Country/About_Cyprus/cyprus_1.png";
import CyprusImage2 from "../../static/images/About_Country/About_Cyprus/cyprus_2.png";
import CyprusImage3 from "../../static/images/About_Country/About_Cyprus/cyprus_3.png";

class AboutCyprus extends Component {
    render() {
        return (
            <div>
                <AboutCountryInformations
                    image={require("../../static/images/About_Country/About_Cyprus/general_info_cyprus.png")}
                    title={"ОPŠTE INFORMACIJE"}
                    description={`Kipar je ostrvo u istočnom delu Mediterana. Možete iskoristiti celodnevne aktivnosti za rekreaciju i turističke izlete: u okolini modernih hotela u dobro održavanim turističkim centrima možete uživati u atmosferi istorije i antike, posetiti tradicionalna sela i zanimljive spomenike kulture.`}
                />
                <Container>
                    <div className="description">
                        Ostrvo nudi turistima širok spektar mogućnosti za različite vrste rekreacije: odmor na plaži ili porodični odmor, obilaske, posete
                        manastirima, izlete do antičkih spomenika. Roditelji sa decom mogu uživati u čarima vodenih parkova i delfinarijuma; Obožavatelji
                        aktivnog odmora mogu provesti vreme u obilascima, ići na safari turu ili na krstarenje morem, ići na jedrenje, ronjenje, morski ribolov.
                        Poznate kritske specijalitete od morskih plodova možete degustirati u brojnim konobama, restoranima, probati lokalna vina, uživati u
                        mediteranskim plodovima i odličnim poslastičarnicama. U večernjim satima, turistički centri, hotelski kompleksi i hoteli nude zabavni
                        program. Svake sezone na Kipru ima mnogo šarenih lokalnih festivala.
                    </div>
                </Container>
                <div className="content_fake_background_wrapper" style={{ marginTop: 85 }}>
                    <Container>
                        <div className="content_wrapper">
                            <div className="title">Populacija</div>
                            <div className="description">
                                Prema popisu iz 2011. broj stanovnika na Kipru je 839 hiljada. Etnički sastav: Grci - 80%, Turci - 18%, druge nacionalnosti -
                                2%. Mnogi stranci biraju Kipar kao mesto stalnog prebivališta, što se objašnjava relativno jeftinom životnim standardom,
                                povoljnom klimom, visokim stepenom sigurnosti i politikom podsticanja offshore poslovanja.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Jezik</div>
                            <div className="description">
                                Zvanični jezici su grčki i turski. Engleski se široko koristi, kao i francuski i nemački. Neki od osoblja u mnogim hotelima i
                                sektoru usluga govore ruski.
                            </div>
                        </div>
                        <img src={CyprusImage1} alt="Cyprus 1" />
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <Container>
                    <div className="content_wrapper">
                        <div className="title">GEOGRAFIJA</div>
                        <div className="description">
                            Ostrvo Kipar nalazi se između 34-35 stepena Severne geografske širine i 32-34 stepena Istočne geografske dužine. U severoistočnom
                            delu mediteranskog basena, NA 380 km ot Egipta, 105 km ot Sirije, 800 km ot kopnene Grčke. To je treće po veličini ostrvo
                            Sredozemnog mora i ima površinu od 9,251 kvadratnih metara. Maksimalna dužina 240 km od istoka ka zapadu, maksimalna širina 100 km
                            od severa do juga. Najbliža ostrva Grčke - Rodos i Karpasos - nalaze se 380 km zapadno od Kipra. Kiparska obala na jugu je dosta
                            dugačka i stenovita je na severu. U jugozapadnom delu nalazi se planinski raspon Troodos (najviša tačka je 1952 metara nadmorske
                            visine) sa borom, hrastom, čempresom i kedrovim šumama (gotovo 19% ukupne teritorije Kipra). Ostrvo ima 2 slana jezera, od kojih je
                            jedno solarni rudnik.
                        </div>
                    </div>
                    <img src={CyprusImage2} alt="Cyprus 2" />
                </Container>
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="title">KLIMA</div>
                            <div className="description">
                                Kipar je poznat po povoljnim klimatskim uslovima. Izražena mediteranska klima na ostrvu se je ubraja u jednu od najzdravijih na
                                svetu. Pored toga, Kipar je jedno od najsunčanijih ostrva Mediterana (oko 340 sunčanih dana godišnje) i sa veoma toplom morskom
                                vodom (od 16 stepeni u januaru do 32 u avgustu). Leto na ostrvu je toplo i sunčano, sa oblačnim nebom, traje od sredine maja do
                                sredine septembra. Zima je obeležena promenljivim vremenom od novembra do marta. Relativna vlažnost vazduha je u proseku 60-80%
                                u zimskim periodima i 40-60% leti. Vetrovi na ostrvu su uglavnom lagani do umereni.Zapadna obala u regionu Paphosa smatra se
                                najkvalitetnijim mestom na ostrvu, a letnje temperature su u proseku nekoliko stepeni niže nego u Limassolu i Larnaki. U periodu
                                od januara do februara, ostrvo dobija do 60% godišnjih padavina, sneg se pojavljuje u planinama od decembra i može doći do 1000
                                metara nadmorske visine, do aprila. Plitak, ali stabilan snežni pokrivač tokom hladne sezone privlači skijaše nizbrdo prema
                                padinama Troodos i snovboardere.
                            </div>
                        </div>
                    </Container>
                    <div className="fake_background"></div>
                </div>

                <div className="content_fake_background_wrapper">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <div className="content_wrapper">
                                    <div className="title">Valuta</div>
                                    <div className="description">
                                        Monetarna jedinica je evro. U velikim trgovačkim centrima, hotelima i restoranima prihvaćene su plastične kartice
                                        najvećih međunarodnih platnih sistema.
                                    </div>
                                </div>
                                <div className="content_wrapper">
                                    <div className="title">CARINSKA PRAVILA</div>
                                    <div className="description">
                                        Kada uvozite više od $ 1,000 po osobi, novac mora biti deklarisan. Možete unositi bez kamereU, sportsku opremu, robe
                                        vredne ne više od 85 eura, sa izuzetkom nakita. Dozvoljeno je uvoziti ne više od 50 cigara, 200 cigareta ili 250 g
                                        duvana, 1 litar alkoholnih pića, 0,75 litara vina, 0,3 litra parfema ili toaletne vode. Zabranjen je izvoz arheološke
                                        svojine bez posebnog odobrenja. Kada se uvoze kućni ljubimci na Kipar, obezbeđuje se šest meseci karantina.
                                    </div>
                                </div>
                                <div className="content_wrapper">
                                    <div className="title">ELEKTRIČNA MREŽA</div>
                                    <div className="description">
                                        240 volti, 50 hertza, trofazne utičnice; turistima iz Rusije će biti potrebni adapteri. U većini hotela, adapter možete
                                        uzeti uz kauciju (oko 5 evra) ili kupiti adapter u prodavnici ili turističkim radnjama za 1-2 evra.
                                    </div>
                                </div>
                                <div className="content_wrapper">
                                    <div className="title">MEDICINSKA POMOĆ I OSIGURANJE</div>
                                    <div className="description">
                                        Medicinske usluge na Kipru se plaćaju, putovanje zahteva zdravstveno osiguranje. Apoteke rade u redovnoj prodavnici
                                        (leti od 8:00h do 19:00h s pauzom za ručak od 13:00h do 16:00h, zimi od 08:00h do 17:30h, uz pauzu za ručak od 13:00h do
                                        14:30h, sredom i subotom do 13:00h), postoje dežurne službe. Ako treba da idete kod doktora, potrebno je da registrujete
                                        osigurani događaj, obavestite osiguravajuće društvo i da od lekara uzimate račun o uplati za posetu i propisane lekove.
                                        U toku leta na Kipru posebno je potrebna zaštita od sunca - koristiti kreme za sunčanje sa zaštitnim faktorom, naočare,
                                        šešir. Sigurno vreme za sunčanje je pre 10:00h, a zatim posle 16:00h.
                                    </div>
                                </div>
                                <div className="content_wrapper">
                                    <div className="title">MERE PREDOSTROŽNOSTI</div>
                                    <div className="description">Nije preporučljivo piti vodu iz vodovoda, bolje je kupiti flaširanu u supermarketu.</div>
                                </div>
                                <div className="content_wrapper">
                                    <div className="title">RUSKA AMBASADA</div>
                                    <div className="description">
                                        Ambasada Rusije u Nikoziji: kutak Agiosa Prokopija i nadbiskupa Makariosa III, 2406 Engomi, Nikozija, Kipar; POB 21845,
                                        1514 Nikozija, Kipa Тel.: (22)-46-46-22, 47-21-41, 47-21-42 Fax: (22)-46-48-54. E-mail: russia1@cytanet.com.cy Vreme
                                        prijema: ponedeljak-petak od 08:00h do 14:00h Konzularno odeljenje: tel. (22)-77-68-32 Prijemno vreme: ponedeljak-petak
                                        od 10:00 до 13:00 Web: www.cyprus.mid.ru
                                    </div>
                                </div>
                                <div className="content_wrapper">
                                    <div className="title">Policija</div>
                                    <div className="description">
                                        Policiju možete pozvati u bilo kom gradu na Kipru, pozivajući 199. Policija govori engleski.
                                    </div>
                                </div>
                                <div className="content_wrapper">
                                    <div className="title">TELEFONSKI KOD</div>
                                    <div className="description">Кod Kipra – 357</div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <img src={CyprusImage3} alt="Cyprus 3" />
                            </Col>
                        </Row>
                    </Container>
                    <div className="fake_background blue"></div>
                </div>
            </div>
        );
    }
}

export default AboutCyprus;
