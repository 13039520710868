import React, { Component } from "react";
import "../../../styles/Mobile/Header.scss";
import { Container, Row, Col, NavbarBrand } from "react-bootstrap";
import { Transition } from "react-transition-group";
import { Link } from "react-router-dom";

import MenuDropdownList from "../../Reusable/MenuDropdownList";
import Login from "../../Login";

import { destinations } from "../../../static/data/destinations";

import logo from "../../../static/images/unlimited_travel_logo.png";
import menu from "../../../static/images/icons/mobile_menu_icon.png";
import mail_icon from "../../../static/images/icons/mail_black_icon.png";
import phone_icon from "../../../static/images/icons/phone_black_icon.png";

import close from "../../../static/images/icons/close_icon.png";
import flag_icon from "../../../static/images/icons/flag_icon.png";
import arrow_down from "../../../static/images/icons/white_arrow_down_icon.png";

const transitionDuration = 200;
const defaultStyle = {
    transition: `left  ${transitionDuration}ms ease-in-out`,
    left: 0,
};
const transitionStyles = {
    entering: { left: "-50%" },
    entered: { left: 0 },
    exiting: { left: "-100%" },
    exited: { left: "-100%", zIndex: -1 },
};

const defaultSidebarStyle = {
    transition: `opacity ${500}ms ease-in-out`,
    opacity: 0,
};
const transitionSidebarStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0, zIndex: -1 },
};

const defaultOffersArrowStyle = {
    transition: `transform ${500}ms ease`,
    transform: "rotate(0deg)",
};

const transitionOffersArrowStyles = {
    entering: { transform: "rotate(180deg)" },
    entered: { transform: "rotate(180deg)" },
    exiting: { transform: "rotate(0deg)" },
    exited: { transform: "rotate(0deg)" },
};

class HeaderMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchOpen: false,
            destinationOffersOpen: -1,
        };
    }

    render() {
        return (
            <>
                <Transition in={this.state.searchOpen} timeout={transitionDuration}>
                    {(state) => (
                        <>
                            <div
                                className="sidebar-menu-overlay"
                                style={{
                                    ...defaultSidebarStyle,
                                    ...transitionSidebarStyles[state],
                                }}
                                onClick={() => {
                                    setTimeout(() => {
                                        this.setState({
                                            searchOpen: !this.state.searchOpen,
                                        });
                                    }, transitionDuration);
                                }}
                            ></div>
                            <div
                                style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state],
                                }}
                                className="sidebar-menu"
                            >
                                <Row className="sidebar-menu-logo w-100 mx-0">
                                    <img src={logo} alt="Unlimited logo sidebar" />
                                    <div
                                        className="sidebar-menu-close"
                                        onClick={() => {
                                            setTimeout(() => {
                                                this.setState({
                                                    searchOpen: !this.state.searchOpen,
                                                });
                                            }, transitionDuration);
                                        }}
                                    >
                                        <img src={close} alt="Close sidebar icon" />
                                    </div>
                                </Row>
                                <div className="sidebar-menu-content">
                                    <div className="sidebar-menu-country-list">
                                        {destinations?.map((item, key) => (
                                            <div className="sidebar-menu-country-list-item-space" key={key}>
                                                <div
                                                    className="sidebar-menu-country-list-item font-poppins"
                                                    onClick={() => {
                                                        if (item && item?.offers) {
                                                            let destinationOffersOpen = key;
                                                            if (this.state.destinationOffersOpen === destinationOffersOpen) {
                                                                destinationOffersOpen = -1;
                                                            }
                                                            setTimeout(() => {
                                                                this.setState({ destinationOffersOpen });
                                                            }, transitionDuration);
                                                        }
                                                    }}
                                                >
                                                    <div className="sidebar-menu-country-list-item-title">
                                                        <img src={flag_icon} alt="Flag icon" />
                                                        {item?.title}
                                                    </div>
                                                    {item?.offers && (
                                                        <Transition in={this.state.destinationOffersOpen[key]} timeout={transitionDuration}>
                                                            {(state2) => (
                                                                <img
                                                                    src={arrow_down}
                                                                    alt="Arrow down"
                                                                    style={{ ...defaultOffersArrowStyle, ...transitionOffersArrowStyles[state2] }}
                                                                />
                                                            )}
                                                        </Transition>
                                                    )}
                                                </div>
                                                <MenuDropdownList offers={item?.offers} isIn={this.state.destinationOffersOpen === key} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="info-menu-items font-poppins">
                                        <a href="tel:0604145130" className="info-menu-item">
                                            <img src={phone_icon} width={18} alt="Phone icon" />
                                            <span>060/4145130</span>
                                        </a>
                                        <Link to="/contact" className="info-menu-item">
                                            <img src={mail_icon} width={19} alt="Mail icon" />
                                            <span>Pošalji upit</span>
                                        </Link>
                                        {this.props.exchangeRate ? <div className="info-menu-item">{`Kurs 1 € = ${this.props.exchangeRate} RSD`}</div> : <></>}
                                    </div>
                                    <div className="font-poppins menu-info-description">For the Best Moments of Your Life</div>
                                </div>
                            </div>
                        </>
                    )}
                </Transition>
                <header>
                    <div className="center-header font-poppins">
                        <Container>
                            <Row>
                                <Col
                                    xs={2}
                                    className="d-flex align-items-center"
                                    onClick={() => {
                                        setTimeout(() => {
                                            this.setState({
                                                searchOpen: !this.state.searchOpen,
                                            });
                                        }, transitionDuration);
                                    }}
                                >
                                    <img src={menu} alt="Mobile menu icon" />
                                </Col>
                                <Col className="text-center">
                                    <Link to="/">
                                        <NavbarBrand className="m-0">
                                            <img src={logo} width="30" height="30" alt="Unlimited travel logo" />
                                        </NavbarBrand>
                                    </Link>
                                </Col>
                                <Col xs={2} className="d-flex align-items-center justify-content-end">
                                    <Login />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </header>
            </>
        );
    }
}

export default HeaderMobile;
