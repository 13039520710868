import DryCleaningIcon from "../../static/images/About_Hotel/hotel_services/dry_cleaning_icon.svg";
import WifiIcon from "../../static/images/About_Hotel/hotel_services/wifi_icon.svg";
import DoctorIcon from "../../static/images/About_Hotel/hotel_services/doctor_icon.svg";
import PoolIcon from "../../static/images/About_Hotel/hotel_services/pool_icon.svg";
import BusStopIcon from "../../static/images/About_Hotel/hotel_services/bus_station_icon.svg";
import GymIcon from "../../static/images/About_Hotel/hotel_services/gym_icon.svg";
import ParkingIcon from "../../static/images/About_Hotel/hotel_services/parking_icon.svg";
import SpaIcon from "../../static/images/About_Hotel/hotel_services/spa_icon.svg";
import RestaurantIcon from "../../static/images/About_Hotel/hotel_services/restaurant_icon.svg";

import SandyBeachIcon from "../../static/images/About_Hotel/beaches/sandy_beach_icon.svg";
import UmbrellaIcon from "../../static/images/About_Hotel/beaches/umbrella_icon.svg";
import SunbedIcon from "../../static/images/About_Hotel/beaches/sunbed_icon.svg";

const hotelServicesData = {
    dry_cleaning: {
        icon: DryCleaningIcon,
        text: "Hemijsko čišćenje",
    },
    wifi: {
        icon: WifiIcon,
        text: "Wifi",
    },
    doctor: {
        icon: DoctorIcon,
        text: "Lekar",
    },
    pool: {
        icon: PoolIcon,
        text: "Bazen",
    },
    bus_station: {
        icon: BusStopIcon,
        text: "Autobuska stanica",
    },
    gym: {
        icon: GymIcon,
        text: "Teretana",
    },
    parking: {
        icon: ParkingIcon,
        text: "Parking",
    },
    spa: {
        icon: SpaIcon,
        text: "Spa",
    },
    restaurant: {
        icon: RestaurantIcon,
        text: "Restoran/bar",
    },
};

const beachServicesData = {
    beach: {
        icon: SandyBeachIcon,
        text: "Šljunkovito-peščana plaža",
    },
    umbrella: {
        icon: UmbrellaIcon,
        text: "Suncobrani",
    },
    sunbed: {
        icon: SunbedIcon,
        text: "Ležaljke",
    },
};

const roomIncludesData = {
    coffee: "Aparat za kafu/čaj",
    fridge: "Frižider",
    terrace: "Terasa",
    shower: "Tuš/kada",
    safe: "Sef",
    air_conditioning: "Klima",
    tv: "Satelitska televizija",
    phone: "Telefon",
    hairdryer: "Fen",
};

export const roomType = {
    ultra_all_inclusive: "UAL - Ultra all inclusive",
    all_inclusive: "AL - ALL Inclusive",
    bed_breakfast: "BB - Bed breakfast",
    full_board: "FB - FULL Board",
    half_board: "HB - HALF Board",
    half_board_modified_american: "HBM - Half board American",
    room_only: "RO - Room only",
};

const roomTypeIcons = {
    ultra_all_inclusive: require("../../static/images/icons/ultra_all_inclusive.png"),
    all_inclusive: require("../../static/images/icons/all_inlusive.png"),
    bed_breakfast: require("../../static/images/icons/breakfast.png"),
    full_board: require("../../static/images/icons/full_board.png"),
    half_board: require("../../static/images/icons/half_board.png"),
    half_board_modified_american: require("../../static/images/icons/half_board_american.png"),
    room_only: require("../../static/images/icons/room_only.png"),
};

export const hotelServicesIcon = (data) => {
    return hotelServicesData[data]?.icon;
};

export const hotelServicesName = (data) => {
    return hotelServicesData[data]?.text;
};

export const beachServicesIcon = (data) => {
    return beachServicesData[data]?.icon;
};

export const beachServicesName = (data) => {
    return beachServicesData[data]?.text;
};
export const roomIncludesName = (data) => {
    return roomIncludesData[data];
};
export const roomTypeName = (data) => {
    if (roomType[data]) {
        return roomType[data];
    }

    return data?.replace("_", " ");
};

export const roomTypeIcon = (data) => {
    if (roomTypeIcons[data]) {
        return roomTypeIcons[data];
    }

    return "";
};
