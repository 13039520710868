import React, { Component } from "react";
import { Container } from "react-bootstrap";

import "../../styles/AboutCountry.scss";

import AboutCountryCarousel from "../Reusable/AboutCountryCarousel";

import AboutGreece from "./AboutGreece";
import AboutCyprus from "./AboutCyprus";
import AboutMontenegro from "./AboutMontenegro";
import AboutEgypt from "./AboutEgypt";
import AboutAlbania from "./AboutAlbania";
import AboutBulgaria from "./AboutBulgaria";
import AboutCroatia from "./AboutCroatia";
import AboutUAE from "./AboutUAE";
import AboutTurkey from "./AboutTurkey";
// import AboutCountryTemplate from "./AboutCountryTemplate";

import { COUNTRIES } from "../../static/data/aboutCountry";

class AboutCountryDesktopMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountry: COUNTRIES[props.country],
        };
    }

    componentDidMount() {
        this.setState({ selectedCountry: COUNTRIES[this.props.country] });
    }

    componentDidUpdate(prevProps) {
        if (this.props.country !== prevProps.country) {
            this.setState({ selectedCountry: COUNTRIES[this.props.country] });
        }
    }

    getCountryPage = () => {
        const country = this.props.country.toUpperCase();
        let component = <></>;
        switch (country) {
            case "GREECE":
                component = <AboutGreece selectedCountry={this.state.selectedCountry} />;
                break;
            case "CYPRUS":
                component = <AboutCyprus selectedCountry={this.state.selectedCountry} />;
                break;
            case "MONTENEGRO":
                component = <AboutMontenegro selectedCountry={this.state.selectedCountry} />;
                break;
            case "EGYPT":
                component = <AboutEgypt selectedCountry={this.state.selectedCountry} />;
                break;
            case "ALBANIA":
                component = <AboutAlbania selectedCountry={this.state.selectedCountry} />;
                break;
            case "BULGARIA":
                component = <AboutBulgaria selectedCountry={this.state.selectedCountry} />;
                break;
            case "CROATIA":
                component = <AboutCroatia selectedCountry={this.state.selectedCountry} />;
                break;
            case "UAE":
                component = <AboutUAE selectedCountry={this.state.selectedCountry} />;
                break;
            case "TURKEY":
                component = <AboutTurkey />;
                break;
            default:
                component = <></>;
                break;
        }

        return component;
    };

    render() {
        return (
            <Container fluid className="about_country_wrapper font-poppins">
                <AboutCountryCarousel {...this.state.selectedCountry?.carousel} />

                {this.getCountryPage()}
            </Container>
        );
    }
}

export default AboutCountryDesktopMobile;
