import React, { Component } from "react";
import { Transition } from "react-transition-group";

import "../../../styles/Components/LoggedUser.scss";

import LoginDropdownItems from "../../../routes/LoginDropdownItems";
import LoginProfileCircle from "../../../routes/LoginProfileCircle";

class LoggedUserMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };
    }

    render() {
        return (
            <div className="position-relative logged_user_wrapper">
                <Transition in={this.state.isMenuOpen} timeout={200}>
                    {(state) => (
                        <>
                            <LoginProfileCircle userName={this.props.userName} onClick={() => this.setState({ isMenuOpen: !this.state.isMenuOpen })} />
                            <LoginDropdownItems state={state} userId={this.props.userId} userType={this.props.userType} />
                        </>
                    )}
                </Transition>
            </div>
        );
    }
}

export default LoggedUserMobile;
