import axios from "axios";

export const getSlider = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/slider`).catch((error) => {
        return error;
    });
};

export const getMainDestinations = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/main-destinations`).catch((error) => {
        return error;
    });
};
