import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";

import RegistrationDesktop from "../components/Registration/Desktop";
import RegistrationMobile from "../components/Registration/Mobile";

import { postRegistrationUser, postRegistrationAgent } from "../store/actions";

class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeProfile: 0,
        };
    }

    setActiveProfile = (index) => {
        this.setState({ activeProfile: index });
    };

    registerNewUser = (values) => {
        this.props.postRegistrationUser(values);
    };

    registerNewAgent = (values) => {
        this.props.postRegistrationAgent(values);
    };

    render() {
        return (
            <>
                <MediaQuery maxWidth={990}>
                    <RegistrationMobile
                        registrationStatus={this.props.registrationStatus}
                        activeProfile={this.state.activeProfile}
                        setActiveProfile={this.setActiveProfile}
                        getUserData={this.registerNewUser}
                        getAgentData={this.registerNewAgent}
                    />
                </MediaQuery>
                <MediaQuery minWidth={991}>
                    <RegistrationDesktop
                        registrationStatus={this.props.registrationStatus}
                        activeProfile={this.state.activeProfile}
                        setActiveProfile={this.setActiveProfile}
                        getUserData={this.registerNewUser}
                        getAgentData={this.registerNewAgent}
                    />
                </MediaQuery>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        registrationStatus: state.Auth.registration,
    };
};

export default connect(mapStateToProps, { postRegistrationUser, postRegistrationAgent })(Registration);
