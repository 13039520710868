import React, { Component } from "react";
import { Container } from "react-bootstrap";

import AboutCountryInformations from "../Reusable/AboutCountryInformations";

import Albania1 from "../../static/images/About_Country/About_Albania/albania_1.png";

class AboutAlbania extends Component {
    render() {
        return (
            <div>
                <AboutCountryInformations
                    image={require("../../static/images/About_Country/About_Albania/general_informations_albania.png")}
                    title={"ОPŠTE INFORMACIJE"}
                    description={`Albanija se nalazi na Jadranskom i Jonskom moru, graniči se sa Grčkom i Crnom Gorom. Turisti su dugo vremena nezasluženo zobilazili Albaniju, zbog čega je tako malo proučena. A ona ima šta da ponudi i oduševi čak i sofisticirane putnike - čiste plaže, neverovatnu prirodu i bogato kulturno nasleđe.`}
                />
                <Container>
                    <ul className="country_unorder_list">
                        <li>Glavni grad države: Tirana</li>
                        <li>Službeni jezik: albanski</li>
                        <li>Valuta: Lek</li>
                        <li>Klima: kontinentalni (zapdni) deo zemlje — mediteranski, na istoku prelazi u kontinentalnu klimu.</li>
                        <li>Stanovništvo: 2 876 591 ljudi</li>
                        <li>Državni sistem: unitarna država, parlamentarna republika</li>
                        <li>Pozivni broj: +355</li>
                    </ul>

                    <img src={Albania1} alt="Albania 1" />
                </Container>
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="title">ZNAMENITOSTI</div>
                            <div className="description">
                                U istoriji Albanije postoji mnogo dramatičnih stranica - na njenoj teritoriji su vođeni brojni ratovi i revolucije, što je
                                ostavilo primetni trag na kulturi i arhitekturi. Preživeli su mnogi spomenici - od starih do modernih vremena. Svaki spomenik
                                ima svoju istoriju.
                            </div>
                            <ul className="country_unorder_list">
                                <li>
                                    Spomenik albanskom nacionalnom heroju Skanderbegu nalazi se na centralnom trgu u Tirani, I turisti ga pri dolasku u Tiranu
                                    obavezno posećuju.
                                </li>
                                <li>Džamija Efem bej simbol je slobode veroispovesti.</li>
                                <li>Sahat-kula sa osmatračnicom.</li>
                                <li>"Piramida" je zgrada iz vremena diktatora Khoja.</li>
                                <li>Planina Daiti - nalazi se na 26 km od prestonice sa koje se pruža predivan panoramski pogled.</li>
                                <li>Tvrđava Justinijan (XIV век).</li>
                                <li>Kameni tabački most sa 3 luka (XVII-XVIII veka).</li>
                                <li>Nacionalni i arheološki muzej.</li>
                            </ul>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">GRADOVI</div>
                            <div className="description">
                                Skadar je poznat po svojoj tvrđavi Rozafa, koja se nalazi na neupadljivoj stijeni. Tu je i jedinstvena Olovna džamija bez
                                minareta, tokom čijeg izgradnje je korišćen tečni olovo, i Crkva Rođenja Hristovog. Treba napomenuti da je u Albaniji 60%
                                muslimana, a oko 30% hrišćanina. Zbog toga ovde postoji toliko različitih verskih institucija. U Vlori se možete diviti džamiji
                                Muradiie koja je stilski slična džamijama u Istanbulu, a šetajući okolinom možete videti luku Apolonija. U Podgarecu je nova
                                vizantijska crkva uzašašća sa blistavo belim zidovima i ruševinama citadele. U Gjirokastri je dobro očuvana tvrđava, gde deluje
                                Muzej oružja. U Korči, u zgradi prve albanske škole, funkcioniše Muzej obrazovanja. U gradu Sarandi vredi posetiti drevni grad
                                Butrint, koji se danas smatra muzejem-rezervatom. Sagradili su ga stari Grci pre više od 2.500 godina na obali jezera Butrint.
                                Nekad je bila kolonija koju su Grci aktivno razvijali pre rimskog napada. U 5. veku grad je uništen, a u 15. veku je postao deo
                                Turske. Dvadesetih godina 20. veka započinju iskopavanja grada i obnovljen je. U Draču možete da posetite najstariji balkanski
                                amfiteatar - nekada se koristio za predstave i borbe.
                            </div>
                        </div>
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <Container>
                    <div className="content_wrapper">
                        <div className="title">PRAZNICI</div>
                        <div className="description">
                            Albanci slave katolički i pravoslavni Božić. A pored standardnih novogodišnjih svečanosti početkom januara, obeležava se i Kalendar
                            - ovo je zimski „prekretnički“ praznik. Ovih dana se u ritualnim ognjištima peku posebni okrugli hleb, tako da će "sve nesreće biti
                            spaljene". Važan državni praznik pada 11. januara - Dan Republike. Datum je postao značajan nakon povlačenja nemačkih okupatora od
                            strane snaga Narodnooslobodilačke vojske. U Albaniji se, takođe, 19. oktobra obeležava Dan majke Tereze. Malo je poznata činjenica,
                            ali bila je Albanka. Albanci imaju originalnu zastavu - krvavo crveno platno na kojem je prikazan crni dvoglavi orao. Dan zastave u
                            zemlji se obilježava 28. novembra. 5-6. Decembra održavaju se narodne svečanosti - to su dani Svetog Nikole Zimskog. Pred njima se
                            primećuje post, a zatim su tokom dva dana Albanci postavili veličanstven sto i plesali do jutra. Albanci takođe slave muslimanske
                            praznike: Veliki bajram (5. jula), Kurban bajram (12. septembra) i Navruz (22. marta). Kada idete u Albaniju, morate imati na umu da
                            su stanovnici zemlje prilično konzervativni. Ne preporučuje se komplimentiranje tuđih žena. Najbolji kompliment za vlasnika biće
                            pohvale njegovom detetu. Takođe je vredno zapamtiti mali detalj - kimanje glave znači poricanje, a pomicanje sa strane na stranu
                            znači slaganje.
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default AboutAlbania;
