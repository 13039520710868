import React, { Component, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import ReactStars from "react-stars";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import DiscountRibbon from "../Reusable/DiscountRibbon";

import { areObjectsEqual } from "../../helpers/utils/globalUtils";

import "../../styles/Components/HomeCountries.scss";

class HomeCountries extends Component {
    render() {
        return (
            <Container fluid className="countries_wrapper">
                {this.props.data?.map((el, index) => (
                    <CountryBlock
                        areaData={el}
                        key={index}
                        hotels={this.props.hotels}
                        apartments={this.props.apartments}
                        activeDestinations={this.props.activeDestinations}
                        setActiveDestination={this.props.setActiveDestination}
                    />
                ))}
            </Container>
        );
    }
}

HomeCountries.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    hotels: PropTypes.arrayOf(PropTypes.object),
    apartment: PropTypes.arrayOf(PropTypes.object),
    activeDestinations: PropTypes.object,
    setActiveDestination: PropTypes.func,
};

export default HomeCountries;

function CountryBlock(props) {
    return (
        <div className="country_block">
            <Container>
                {props.areaData?.types?.map((el, index) => (
                    <CountryArea
                        countryData={el}
                        locations={props[el.type]}
                        activeDestination={props.activeDestinations[el.type]}
                        setActiveDestination={(location) => props.setActiveDestination(el.type, location)}
                        key={index}
                    />
                ))}
            </Container>
            <div className="fake_background" style={{ backgroundColor: props.areaData.color, height: `calc(100% - ${props.areaData.cutTop}px)` }}></div>
        </div>
    );
}

function CountryArea(props) {
    var sliderSettings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
        ],
    };

    const sliderRef = useRef(null);

    //Hack for iOS and other mobile devices with problematic browsers
    useEffect(() => {
        if (props.locations) {
            sliderRef.current.slickGoTo(1);
            setTimeout(() => {
                sliderRef.current.slickGoTo(0);
            }, 500);
        }
    }, [props.locations]);
    return (
        <div className="country_area">
            <div className="top_area">
                <img src={props.countryData?.image} alt="Country" />
                <div className="region_title_subtitle_wrapper">
                    <div className="title font-proza-libre">{props.countryData?.country}</div>
                    <div className="subtitle font-marck-script">{props.countryData?.typeName}</div>
                </div>
                <div className="region_list_wrapper">
                    <div className="region_list">
                        {props.locations?.map((el, index) => (
                            <div
                                className={`location_item ${el?.destId === props.activeDestination ? "location_item_active" : ""} font-poppins`}
                                onClick={() => {
                                    props.setActiveDestination(el?.destId);
                                }}
                                key={index}
                            >
                                {el?.destName}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="bottom_area">
                <Slider {...sliderSettings} className="carousel_wrapper" ref={sliderRef}>
                    {props.locations
                        ?.find((location) => location?.destId === props.activeDestination)
                        ?.[props.countryData.type]?.map((el, index) => (
                            <div data-index={index} key={index} className="carousel_item">
                                <img src={el.hotelPhotos[0]} alt="Hotel or apartment" />
                                {el?.discount ? <DiscountRibbon data={el} /> : <></>}
                                <div className="bottom_of_item">
                                    <div className="hotel_name font-poppins">{el?.hotelName}</div>
                                    {el?.discount ? (
                                        <div className="old_price font-poppins">{`€ ${(el?.hotelPrice * (1 + el?.discount / 100)).toFixed(0)}`}</div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="new_price font-poppins">{`€ ${Math.round(el?.hotelPrice)}`}</div>
                                    <ReactStars className="stars" count={5} edit={false} size={24} value={el.hotelRating} color2={"#FDB72C"} />
                                    <Link to={`/about-hotel/${props.activeDestination}/${el.hotelId}`}>
                                        <div className="find_more font-poppins">saznaj više</div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                </Slider>
            </div>
        </div>
    );
}

function PreviousArrow(props) {
    const { onClick } = props;
    return <div className="carousel-arrow" id="carousel-arrow-left" onClick={onClick} />;
}

function NextArrow(props) {
    const { onClick } = props;
    return <div className="carousel-arrow" id="carousel-arrow-right" onClick={onClick} />;
}
