import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import AboutCountryInformations from "../Reusable/AboutCountryInformations";

import Croatia1 from "../../static/images/About_Country/About_Croatia/croatia_1.png";
import Croatia2 from "../../static/images/About_Country/About_Croatia/croatia_2.png";

class AboutCroatia extends Component {
    render() {
        return (
            <div>
                <AboutCountryInformations
                    image={require("../../static/images/About_Country/About_Croatia/general_info_croatia.png")}
                    title={"ОPŠTE INFORMACIJE"}
                    description={`Hrvatska je mala slikovita zemlja na obalama Jadranskog mora. Hiljade turista iz svih krajeva sveta svake godine dođe ovde i svima se svide: ekološka čista priroda, slikovite obale, guste šume, kristalna jezera, mineralni izvori, karakteristična kuhinja i drevni spomenici. U Hrvatskoj se možete opustiti što je moguće bliže prirodi bez da se odreknete dobrobiti civilizacije.`}
                />
                <Container>
                    <ul className="country_unorder_list">
                        <li>Glavni grad države: Zagreb</li>
                        <li>Službeni jezik: Hrvatski</li>
                        <li>Valuta: kuna</li>
                        <li>Klima: kontinentalna klima preovlađuje u severnoj Hrvatskoj, planinska u središnjoj Hrvatskoj, mediteranska klima blizu obale</li>
                        <li>Stanovništvo: 4.800.000 stanovnika</li>
                        <li>Politički sistem: višestranačka parlamentarna republika</li>
                        <li>Pozivni broj: +385</li>
                    </ul>
                </Container>
                <div className="content_fake_background_wrapper">
                    <Container>
                        <div className="content_wrapper">
                            <div className="title">Znamenitosti</div>
                            <div className="description">
                                Hrvatska je poznata po svojim prelepim drevnim gradovima uz more. Gledajući ih, može se na trenutak činiti da ste ušli u
                                vremeplov i prevezli se pre par vekova
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Rovinj</div>
                            <div className="description">
                                To je drevni grad sa tri kapije. Ranije su bili deo tvrđave - zida koji je okruživao grad. Njegova odlika je svetla boja
                                spoljnih zidova.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Trogir</div>
                            <div className="description">
                                Ovde se prepliću drevni grad koji ima mozaik stilova - stilovi baroka, renesanse i gotike. Najstarija katedrala nalazi se u
                                Trogiru - katedrala Svetog Lovre izgrađena je u XIII veku.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Korčula</div>
                            <div className="description">
                                Ostrvski grad, koji se naziva i „Venecijom“ na jadranskoj obali. Lokalne kuće su od posebnog interesa - ukrašene su porodičnim
                                simbolima, balkonima i pešačkim stazama.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Dubrovnik</div>
                            <div className="description">
                                Grad osnovan u 7. veku. Zidovi se protežu na 2 km, visina im je 25 metara, a širina 6 metara. Zidovi su izgrađeni na takav način
                                da izgleda kao da rastu iz stena - kao da se mnogo vekova grad naprosto uzdizao iz morskih dubina
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Plitvička jezera</div>
                            <div className="description">
                                Ovo su 16 jezera i 92 vodopada koja je stvorila priroda - pravi biser Hrvatske. Jezera su upečatljiva po svojoj lepoti i
                                čistoći. Sliku upotpunjuju drevne bukove i četinarske šume, planinske pećine i stotine uskih staza.
                            </div>
                        </div>
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <div className="content_fake_background_wrapper">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <div className="content_wrapper">
                                    <div className="title">Arhitektura</div>
                                    <div className="description">
                                        Vredno je uključiti se u popis poseta Trgu bana Jelačića, Zagrebačkoj katedrali, Crkvi svetog Marka i Hrvatskom narodnom
                                        kazalištu u Zagrebu, a zatim posetiti Eufrazijevu baziliku u Poreču, katedrale i palate u Splitu. Ne može se samo Rim
                                        pohvaliti Koloseumom. Hrvatski grad Pula ima svoj. Ovo je drevni rimski amfiteatar, koji je u jednom trenutku mogao da
                                        primi čitav grad - 23.000 ljudi. Sve do 5. veka, tamo su se vodile bitke. U podzemnim dvoranama danas postoji
                                        funkcionalan muzej, u kojem se mogu videti arheološka nalazišta ovih mesta. Hrvatska je prelepa jer ne morate daleko ići
                                        da biste otkrili znamenitosti, one su gotovo na svakom koraku - čak i u malenim selima možete pronaći drevnu crkvu,
                                        ostatke tvrđave ili muzeja.
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <img src={Croatia1} alt="Croatia 1" />
                            </Col>
                        </Row>
                    </Container>
                    <div className="fake_background blue"></div>
                </div>
                <Container>
                    <div className="content_wrapper">
                        <div className="title">PRAZNICI</div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">Karneval u Rijeci</div>
                        <div className="description">
                            Odvija se u januaru. Ovo masovno slavlje pretvara se u marš po ulicama i traje čitav mesec. Za to vreme možete gledati jedinstveni
                            program zvonara, koji se smatraju vlasnicima drevnih tradicija i braniocima svog rodnog kraja.
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">Noć žaba</div>
                        <div className="description">
                            30. aprila možete doći na praznik koji se održava u gradu Lokva u čast svog simbola - žabe. U noći između 29. do 30. aprila u gradu
                            se održavaju takmičenja - čija će žaba skakati dalje. Pobednik dobija titulu "Kraljice žabe".
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">Festival Šparoge</div>
                        <div className="description">
                            Ljubitelji festivala hrane posetiće festival šparoga. Održava se svake godine u Lovranu krajem aprila. Simbol festivala je omlet od
                            hiljadu jaja i 30 kg šparoga, koji se priprema za sve goste. Nakon jela omleta, učesnici nastavljaju gozbu trbuha - festival traje 2
                            nedelje.
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">Festival kestena</div>
                        <div className="description">
                            U Hrvatskoj se od oktobra može uživati u kestenima. Tri dana u Istri svi se priušte raznim jelima od kestena: pecivima, slatkišima,
                            slanim grickalicama. Jela od kestena najčešće se služe sa mladim domaćim vinom.
                        </div>
                    </div>
                    <img src={Croatia2} alt="Croatia 2" />
                </Container>
            </div>
        );
    }
}

export default AboutCroatia;
