import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "../../../../styles/MainFooter.scss";

import { subscribeNewsletters } from "../../../../store/actions";

import sun_icon from "../../../../static/images/icons/sun_icon.png";
import logo_white from "../../../../static/images/unlimited_travel_logo_white.png";

const schema = yup.object().shape({
    email: yup.string().email("E-mail adresa nije validna").required("Polje mora biti popunjeno"),
});

class MainFooterDesktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newslattersMail: "",
        };
    }

    render() {
        return (
            <Row className="footer-main w-100 m-0">
                <Col className="footer-main-sun-icon" lg={2}>
                    <img src={sun_icon} alt="Sun footer logo" />
                </Col>
                <Col lg={10}>
                    <Row>
                        <Col lg={4} xl={5} className="footer-main-logo">
                            <img src={logo_white} alt="Unlimited logo white" />
                        </Col>
                        <Col lg={8} xl={7} className="footer-main-send-email font-poppins">
                            <div className="footer-main-send-email-description">
                                <div id="title">Unesite svoj e-mail</div>
                                <div id="text">Pretplatite se za ekskluzivne bonuse, besplatne izlete i vesti</div>
                            </div>
                            <div className="footer-main-send-email-input ">
                                <Formik
                                    enableReinitialize
                                    validationSchema={schema}
                                    initialValues={{
                                        email: "",
                                    }}
                                    onSubmit={(values, actions) => {
                                        this.props.subscribeNewsletters(values);
                                    }}
                                >
                                    {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                                        <>
                                            <Form.Group style={{ maxWidth: 227, width: "100%" }}>
                                                <Form.Control
                                                    type={"email"}
                                                    name="email"
                                                    placeholder="Vaš e-mail"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.email && errors.email}
                                                    className={`${this.props.newslettersStatus === "success" ? "success-input" : ""} ${
                                                        this.props.newslettersStatus === "sending" ? "sending-input" : ""
                                                    } ${this.props.newslettersStatus === "error" ? "error-input" : ""}`}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                            </Form.Group>
                                            <div id="button" onClick={handleSubmit}>
                                                Pretplata
                                            </div>
                                        </>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>

                    <div className="footer-main-quick-links font-poppins">
                        <Row>
                            <Col lg={3}>
                                <span>Ponuda - leto 2022.</span>
                            </Col>
                            <Col lg={3}>
                                <span>GRČKA - leto 2022.</span>
                            </Col>
                            <Col lg={3}>
                                <span>IZDVAJAMO IZ PONUDE</span>
                            </Col>
                            <Col lg={3}>
                                <span>Informacije</span>
                            </Col>
                        </Row>
                        <div className="footer-main-quick-links-separator"></div>
                        <Row>
                            <Col lg={3} className="footer-main-quick-links-wrapper">
                                <Link to="/cooming-soon">Grčka</Link>
                                <Link to="/cooming-soon">Crna Gora</Link>
                                <Link to="/cooming-soon">Egipat</Link>
                                <Link to="/cooming-soon">Turska</Link>
                                <Link to="/cooming-soon">Albanija</Link>
                                <Link to="/cooming-soon">Bugarska</Link>
                                <Link to="/cooming-soon">Srbija - Pešterska visoravan</Link>
                                <Link to="/cooming-soon">Daleke destinacije</Link>
                            </Col>
                            <Col lg={3} className="footer-main-quick-links-wrapper">
                                <Link to="/booking?selectedPlace=OT-LOC-GEO-400665">Hotelski smeštaj</Link>
                                <Link to="/booking?selectedPlace=OT-LOC-GEO-400665&aparments=true">Apartmanski smeštaj</Link>
                                <Link to="/booking?selectedPlace=OT-LOC-GEO-400665">Sopsteveni prevoz</Link>
                                <Link to="/booking?selectedPlace=OT-LOC-GEO-400665&transport=bus">Autobuski prevoz</Link>
                                <Link to="/special-offers/hotels">Special Offer - hoteli</Link>
                                <Link to="/special-offers/apartments">Special Offer - apartmani</Link>
                            </Col>
                            <Col lg={3} className="footer-main-quick-links-wrapper">
                                <Link to="/special-offers/first-last-minutes">Grčka - last / first minute</Link>
                                <Link to="/cooming-soon">leto 2022.</Link>
                                {/* <div>Crna Gora - Hotel SPLENDIDO 4*</div>
                                <div>Srbija - Hotel PEŠTER 4*</div>
                                <div>Egipat - first minute</div>
                                <div>Turska - special offer</div> */}
                            </Col>
                            <Col lg={3} className="footer-main-quick-links-wrapper">
                                <Link to="/contact">Kontakt</Link>
                                <Link to="/cooming-soon">Garancija putovanja</Link>
                                <Link to="/cooming-soon">Licenca</Link>
                                <Link to="/cooming-soon">Opšti uslovi putovanja</Link>
                                <Link to="/cooming-soon">Pravilnik o zaštiti podataka o ličnosti</Link>
                                <Link to="/cooming-soon">Obaveštenje o predugovornim</Link>
                                <Link to="/cooming-soon">Informacijama</Link>
                                <Link to="/cooming-soon">Putno zdravstveno osiguranje</Link>
                                <Link to="/cooming-soon">Podaci o identifikaciji firme</Link>
                                <Link to="/cooming-soon">Instrukcija o deviznom plaćanju</Link>
                                <Link to="/cooming-soon">Uslovi ulaska za odabrane </Link>
                                <Link to="/cooming-soon">Destinacije i važeći protokoli</Link>
                                <Link to="/cooming-soon">Online rezervacija</Link>
                                <Link to="/cooming-soon">Saradnja</Link>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        newslettersStatus: state.General.newsletters,
    };
};

export default connect(mapStateToProps, { subscribeNewsletters })(MainFooterDesktop);
