// export const NAME_NAME = 'NAME_NAME';
// export const NAME_NAME_SUCCESS = 'NAME_NAME_SUCCESS';
// export const NAME_NAME_ERROR = 'NAME_NAME_ERROR';

export const GET_SLIDER = "GET_SLIDER";
export const GET_SLIDER_SUCCESS = "GET_SLIDER_SUCCESS";
export const GET_SLIDER_ERROR = "GET_SLIDER_ERROR";

export const GET_MAIN_DESTINATIONS = "GET_MAIN_DESTINATIONS";
export const GET_MAIN_DESTINATIONS_SUCCESS = "GET_MAIN_DESTINATIONS_SUCCESS";
export const GET_MAIN_DESTINATIONS_ERROR = "GET_MAIN_DESTINATIONS_ERROR";

export const SET_ACTIVE_DESTINATION = "SET_ACTIVE_DESTINATION";
