import React, { Component } from "react";
import propTypes from "prop-types";

import location_icon from "../../static/images/icons/location_icon.png";

import "../../styles/Components/SearchList.scss";

// Ovoj klasi mozes da ubacis custom klase preko customClasses propa
class SearchList extends Component {
    render() {
        return (
            <div style={this.props.style} className={`listings-display-box font-poppins ${this.props.customClasses ? this.props.customClasses : ""}`}>
                <div className="inner-wrapper">
                    {this.props.data?.map((el, i) => (
                        <div
                            className={`search-item ${el.destId === this.props.currentLocationIndex ? "active" : ""}`}
                            key={i}
                            onClick={this.props.onLocationChange.bind(this, el.destId)}
                        >
                            <img src={location_icon} alt="Location icon" />
                            <span className="location-text">{el?.name}</span>
                            <div className="location-anchor">
                                <span className="amount-text">{el.hotels + el.apartments}</span>
                                <span className="type-text">{`${el.hotels + el.apartments === 1 ? "smeštaj" : "smeštaja"}`}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
SearchList.propTypes = {
    style: propTypes.object,
    customClasses: propTypes.string,
    data: propTypes.array,
    onLocationChange: propTypes.func,
    currentLocationIndex: propTypes.string,
};
export default SearchList;
