import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";

import { getCookie } from "../../helpers/utils/globalUtils";

import LoginDesktop from "./Desktop";
import LoginMobile from "./Mobile";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: getCookie("userId") ? true : false,
            userFirstName: getCookie("name") ?? "",
            userType: getCookie("type") ?? "",
            userId: getCookie("userId") ?? "",
        };
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={991}>
                    <LoginDesktop
                        isLogged={this.state.isLogged}
                        userId={this.state.userId}
                        userFirstName={this.state.userFirstName}
                        userType={this.state.userType}
                    />
                </MediaQuery>
                <MediaQuery maxWidth={990}>
                    <LoginMobile
                        isLogged={this.state.isLogged}
                        userId={this.state.userId}
                        userFirstName={this.state.userFirstName}
                        userType={this.state.userType}
                    />
                </MediaQuery>
            </>
        );
    }
}
export default connect(null, {})(Login);
