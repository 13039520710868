import { combineReducers } from "redux";

import Auth from "./auth/reducer";
import Home from "./home/reducer";
import Blog from "./blog/reducer";
import Booking from "./booking/reducer";
import Notifications from "./notifications/reducer";
import CustomPage from "./customPage/reducer";
import MyProfile from "./myProfile/reducer";

import General from "./general/reducer";
import SpecialOffers from "./specialOffers/reducer";

const rootReducer = combineReducers({ Auth, Home, Blog, Booking, Notifications, CustomPage, General, MyProfile, SpecialOffers });

export default rootReducer;
