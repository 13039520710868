import React, { Component } from "react";
import AboutGreeceGeneralInformations from "../Reusable/AboutGreeceTemplates/AboutGreeceGeneralInformations";
import AboutGreeceHistory from "../Reusable/AboutGreeceTemplates/AboutGreeceHistory";
import AboutGreeceReligion from "../Reusable/AboutGreeceTemplates/AboutGreeceReligion";
import AboutGreeceVacation from "../Reusable/AboutGreeceTemplates/AboutGreeceVacation";
import AboutGreeceDictionary from "../Reusable/AboutGreeceTemplates/AboutGreeceDictionary";
import AboutGreeceHolidays from "../Reusable/AboutGreeceTemplates/AboutGreeceHolidays";
import AboutGreeceFood from "../Reusable/AboutGreeceTemplates/AboutGreeceFood";
import AboutGreecePeople from "../Reusable/AboutGreeceTemplates/AboutGreecePeople";
import AboutCountryFilters from "../Reusable/AboutCountryFilters";

class AboutGreece extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFilter: props.selectedCountry && props.selectedCountry?.filters ? Object.keys(props.selectedCountry?.filters)[0] : "",
        };
    }

    componentDidMount() {
        this.setState({
            selectedFilter: this.props.selectedCountry && this.props.selectedCountry?.filters ? Object.keys(this.props.selectedCountry?.filters)[0] : "",
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedCountry !== prevProps.selectedCountry) {
            this.setState({
                selectedFilter: this.props.selectedCountry && this.props.selectedCountry?.filters ? Object.keys(this.props.selectedCountry?.filters)[0] : "",
            });
        }
    }

    renderBody = () => {
        switch (this.state.selectedFilter) {
            case "informations":
                return <AboutGreeceGeneralInformations />;
            case "history":
                return <AboutGreeceHistory />;
            case "religion":
                return <AboutGreeceReligion />;
            case "vacation":
                return <AboutGreeceVacation />;
            case "dictionary":
                return <AboutGreeceDictionary dictionary={this.props.selectedCountry?.dictionary} />;
            case "holidays":
                return <AboutGreeceHolidays />;
            case "food":
                return <AboutGreeceFood />;
            case "people":
                return <AboutGreecePeople />;
            default:
                return <></>;
        }
    };

    render() {
        return (
            <div>
                {this.props.selectedCountry && this.props.selectedCountry?.filters && (
                    <AboutCountryFilters
                        data={this.props.selectedCountry?.filters}
                        selectedFilter={this.state.selectedFilter}
                        onSetSelectedFilter={(value) => this.setState({ selectedFilter: value })}
                    />
                )}
                {this.renderBody()}
            </div>
        );
    }
}

export default AboutGreece;
