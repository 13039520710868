import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { GET_ALL_REGIONS, GET_SINGLE_REGION, GET_PROGRAM_LIST, SUBSCRIBE_NEWSLETTERS, CONTACT_US, GET_EXCHANGE_RATE } from "./actionTypes";
import {
    getAllRegionsSuccess,
    getAllRegionsError,
    getSingleRegionSuccess,
    getSingleRegionError,
    getProgramListSuccess,
    getProgramListError,
    contactUsSuccess,
    contactUsError,
    getExchangeRateSuccess,
    getExchangeRateError,
    subscribeNewslettersSuccess,
    subscribeNewslettersError,
} from "./actions";

import { getAllRegions, getSingleRegion, getProgramList, subscribeNewsletters, contactUs, getExchangeRate } from "../../helpers/api/general";

function* getAllRegionsSaga() {
    try {
        const response = yield call(getAllRegions);
        if (response && response.data.status === 200) {
            yield put(getAllRegionsSuccess(response.data.data));
        } else {
            yield put(getAllRegionsError(response.error));
        }
    } catch (error) {
        yield put(getAllRegionsError(error));
    }
}

function* getSingleRegionSaga({ payload: { slug, history } }) {
    try {
        const response = yield call(getSingleRegion, slug);
        if (response && response.data.status === 200) {
            yield put(getSingleRegionSuccess(response.data.data));
        } else {
            history("/404");
            yield put(getSingleRegionError(response.error));
        }
    } catch (error) {
        history("/404");
        yield put(getSingleRegionError(error));
    }
}

function* getProgramListSaga() {
    try {
        const response = yield call(getProgramList);
        if (response && response.data.status === 200) {
            yield put(getProgramListSuccess(response.data.data));
        } else {
            yield put(getProgramListError(response.error));
        }
    } catch (error) {
        yield put(getProgramListError(error));
    }
}

function* contactUsSaga({ payload }) {
    try {
        const response = yield call(contactUs, payload);
        if (response && response.status === 200) {
            yield put(contactUsSuccess());
        } else {
            yield put(contactUsError(response.error));
        }
    } catch (error) {
        yield put(contactUsError(error));
    }
}

function* subscribeNewslettersSaga({ payload }) {
    try {
        const response = yield call(subscribeNewsletters, payload);
        if (response && response.status === 200) {
            yield put(subscribeNewslettersSuccess());
        } else {
            yield put(subscribeNewslettersError());
        }
    } catch (error) {
        yield put(subscribeNewslettersError());
    }
}

function* getExchangeRateSaga() {
    try {
        const response = yield call(getExchangeRate);
        if (response && response.data.statusCode === 200) {
            yield put(getExchangeRateSuccess(response.data.results));
        } else {
            yield put(getExchangeRateError(response.error));
        }
    } catch (error) {
        yield put(getExchangeRateError(error));
    }
}

export function* watchGetAllRegionsSaga() {
    yield takeEvery(GET_ALL_REGIONS, getAllRegionsSaga);
}

export function* watchGetSingleRegionSaga() {
    yield takeEvery(GET_SINGLE_REGION, getSingleRegionSaga);
}

export function* watchGetProgramListSaga() {
    yield takeEvery(GET_PROGRAM_LIST, getProgramListSaga);
}

export function* watchSubscribeNewslettersSaga() {
    yield takeEvery(SUBSCRIBE_NEWSLETTERS, subscribeNewslettersSaga);
}

export function* watchContactUsSaga() {
    yield takeEvery(CONTACT_US, contactUsSaga);
}

export function* watchGetExchangeRateSaga() {
    yield takeEvery(GET_EXCHANGE_RATE, getExchangeRateSaga);
}

function* generalSaga() {
    yield all([
        fork(watchGetAllRegionsSaga),
        fork(watchGetSingleRegionSaga),
        fork(watchGetProgramListSaga),
        fork(watchSubscribeNewslettersSaga),
        fork(watchContactUsSaga),
        fork(watchGetExchangeRateSaga),
    ]);
}

export default generalSaga;
