import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import "../../styles/Components/AboutHotelServices.scss";

import { hotelServicesIcon, hotelServicesName, beachServicesIcon, beachServicesName } from "../../helpers/utils/aboutHotelUtils";

class AboutHotelServices extends Component {
    render() {
        return (
            <div className="about_hotel_services_wrapper font-poppins">
                {this.props.data && this.props.data?.hotelServices && this.props.data?.hotelServices?.length ? (
                    <div className="about_hotel_single_service">
                        <div className="title">Hotelske usluge</div>
                        <Row className="align-items-center">
                            {this.props.data?.hotelServices?.map((item, key) => {
                                let icon = hotelServicesIcon(item);
                                let text = hotelServicesName(item);
                                return (
                                    <Col xs={6} sm={4} key={key}>
                                        <div className="services_item_wrapper">
                                            {icon && <img src={icon} alt={`Hotel services icon ${key + 1}`} />}
                                            {text}
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                ) : (
                    <></>
                )}

                {this.props.data && this.props.data?.beachServices && this.props.data?.beachServices?.length ? (
                    <div className="about_hotel_single_service">
                        <div className="title">Plaža</div>
                        <Row className="align-items-center">
                            {this.props.data?.beachServices?.map((item, key) => {
                                let icon = beachServicesIcon(item);
                                let text = beachServicesName(item);
                                return (
                                    <Col xs={6} sm={4} key={key}>
                                        <div className="services_item_wrapper">
                                            {icon && <img src={icon} alt={`Beach services icon ${key + 1}`} />}
                                            {text}
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    }
}

export default AboutHotelServices;
