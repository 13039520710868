import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./spinner.scss";

class Spinner extends Component {
    constructor() {
        super();

        this.returnReadableDate = this.returnReadableDate.bind(this);
    }
    returnReadableDate(date) {
        let fromString = `${date.getDate()}.${date.getMonth() + 1}.${date.getYear() + 1900}.`; // prettier-ignore
        return fromString; // prettier-ignore
    }

    render() {
        return (
            <Modal show={true} onHide={() => console.log("hide")} aria-labelledby="contained-modal-title-vcenter" centered className="spinner_wrapper">
                <Modal.Body className="spinner_inner font-poppins">
                    <div className="spinner"></div>
                    <div className="location">{this.props.destinationName}</div>
                    <div className="description" id="description1">
                        From {this.returnReadableDate(new Date(this.props.filters.dateFrom))} to {this.returnReadableDate(new Date(this.props.filters.dateTo))}
                    </div>
                    <div className="description" id="description2">
                        1 Room, {parseInt(this.props.filters?.adults || 0) + parseInt(this.props.filters?.children || 0)} Guests
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default Spinner;
