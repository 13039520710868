import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import "../../styles/AboutHotel.scss";

import AboutHotelFilters from "../Reusable/AboutHotelFilters";
import AboutHotelInformations from "../Reusable/AboutHotelInformations";
import AboutHotelDescription from "../Reusable/AboutHotelDescription";
import AboutHotelServices from "../Reusable/AboutHotelServices";
import Gallery from "../Reusable/Gallery";
import Maps from "../Reusable/Maps";
import AboutHotelRooms from "../Reusable/AboutHotelRooms";

class AboutHotelDesktop extends Component {
    render() {
        return (
            <Container fluid className="about_hotel_wrapper_desktop font-poppins">
                <Container>
                    <AboutHotelFilters hotelDescriptionPDF={this.props.hotelDescriptionPDF} />
                    {this.props.loading && (
                        <div className="item_loading_skeleton_details">
                            <Row>
                                <Col xs={6}>
                                    <div className="informations">
                                        <Skeleton count={6} />
                                    </div>
                                    <div className="informations">
                                        <Skeleton count={10} />
                                    </div>
                                    <div className="informations">
                                        <Skeleton count={6} />
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div style={{ height: 350, marginBottom: 15 }}>
                                        <Skeleton height={"100%"} />
                                    </div>
                                    <div style={{ height: 150 }}>
                                        <Skeleton height={"100%"} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
                    {!this.props.loading && (
                        <Row className="about_hotel_content_wrapper">
                            <Col xs={6}>
                                <AboutHotelInformations data={{ ...this.props.informations, destId: this.props.filters?.destId }} />
                                <AboutHotelDescription description={this.props.description} />
                                <AboutHotelServices data={this.props.services} />
                            </Col>
                            <Col xs={6}>
                                <Gallery photos={this.props.photos} />
                                <Maps location={this.props.location} />
                            </Col>
                        </Row>
                    )}
                    <AboutHotelRooms
                        rooms={this.props.rooms}
                        informations={this.props.informations}
                        filters={this.props.filters}
                        changeFilters={this.props.changeFilters}
                    />
                </Container>
            </Container>
        );
    }
}

export default AboutHotelDesktop;
