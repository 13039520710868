import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import "../../styles/Components/MyReservationItem.scss";

import { statusIconText, checkIfCancelled } from "../../helpers/utils/bookingUtils";

import RedCrossIcon from "../../static/images/icons/red_cross_icon.png";
import GrayCrossIcon from "../../static/images/icons/gray_cross_icon.png";

class MyReservationItem extends Component {
    render() {
        const isCancelled = checkIfCancelled(this.props.data?.status);
        return (
            <Row className="reservation_item">
                <Col lg={3} className="reservation_number_wrapper">
                    <div className="reservation_num">{this.props.data?.bookID}</div>
                    <div className="reservation_label">Broj rezervacije</div>
                </Col>
                <Col lg={3} className="price_nights_wrapper">
                    <div className="price_text">{`${this.props.data?.totalPrice} €`}</div>
                    <div className="nights_text">{`Cena za ${this.props.data?.days} ${this.props.data?.days > 1 ? "noći" : "noć"}`}</div>
                </Col>
                <Col lg={3}>
                    <StatusColumn status={this.props.data?.status} />
                </Col>
                <Col lg={3}>
                    <div
                        className={`cancel_button ${isCancelled ? "disabled_cancel_button" : ""}`}
                        onClick={() => this.props.cancelReservation(this.props.data?.bookID)}
                    >
                        <img src={isCancelled ? GrayCrossIcon : RedCrossIcon} width={13} height={13} alt="Cancel button" />
                        Otkaži
                    </div>
                </Col>
            </Row>
        );
    }
}

export default MyReservationItem;

class StatusColumn extends Component {
    render() {
        const { icon, text } = statusIconText(this.props.status);
        return (
            <div className="status_column_wrapper">
                <img src={icon} alt="Status column" />
                <div className="status_column_text">{text}</div>
            </div>
        );
    }
}
