import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";
import { startOfTomorrow, addDays, format } from "date-fns";

import { withRouter } from "../helpers/utils/globalUtils";
import { roomType } from "../helpers/utils/aboutHotelUtils";

import AboutHotelDesktop from "../components/AboutHotel/AboutHotelDesktop";
import AboutHotelMobile from "../components/AboutHotel/AboutHotelMobile";

import { getAboutHotel } from "../store/actions";

class AboutHotel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
        };

        this.changeFilters = this.changeFilters.bind(this);
    }

    componentDidMount() {
        this.fetchAccommodation();
    }

    componentDidUpdate(prevProps) {
        if (this.props.router.searchParams.toString() !== prevProps.router.searchParams.toString()) {
            this.fetchAccommodation();
        }
    }

    fetchAccommodation = () => {
        const queryParams = new URLSearchParams(this.props.router.searchParams.toString());
        let filters = {};
        const filtersKeys = ["childrenAges", "mealPlan"];
        for (const [key, value] of queryParams) {
            let val = value;
            if (filtersKeys.includes(key)) {
                val = value?.split(",");
            }
            filters = { ...filters, [key]: val };
        }

        let data = {},
            start_date = startOfTomorrow(),
            end_date = addDays(startOfTomorrow(), 1),
            rooms = {
                adults: 2,
                children: 0,
                childrenAges: [0],
                quantity: 1,
            },
            mealPlan = Object.keys(roomType),
            isRefundable = false,
            transport = "sopstveni_prevoz";

        if (filters?.dateFrom && filters?.dateTo) {
            start_date = new Date(filters?.dateFrom);
            end_date = new Date(filters?.dateTo);
            queryParams.set("dateFrom", filters.dateFrom);
            queryParams.set("dateTo", filters.dateTo);
        } else {
            queryParams.set("dateFrom", start_date);
            queryParams.set("dateTo", end_date);
        }

        if (filters?.adults) {
            rooms = { ...rooms, adults: parseInt(filters?.adults) };
            queryParams.set("adults", filters?.adults);
        } else {
            queryParams.set("adults", rooms?.adults);
        }

        if (filters?.children) {
            rooms = { ...rooms, children: parseInt(filters?.children) };
            queryParams.set("children", filters.children);
        } else {
            queryParams.set("children", rooms?.children);
        }

        if (filters?.childrenAges) {
            rooms = { ...rooms, childrenAges: filters?.childrenAges?.map((v) => parseInt(v)) };
            queryParams.set("childrenAges", filters?.childrenAges);
        } else {
            queryParams.set("childrenAges", rooms?.childrenAges);
        }

        if (filters?.mealPlan) {
            mealPlan = filters?.mealPlan;
            queryParams.set("mealPlan", filters?.mealPlan);
        } else {
            queryParams.set("mealPlan", mealPlan);
        }

        if (filters?.isRefundable) {
            if (isRefundable === "true") {
                isRefundable = true;
            } else {
                isRefundable = false;
            }
            queryParams.set("isRefundable", filters?.isRefundable);
        } else {
            queryParams.set("isRefundable", isRefundable);
        }

        if (filters?.transport) {
            transport = filters?.transport;
            queryParams.set("transport", filters?.transport);
        } else {
            queryParams.set("transport", transport);
        }
        data = {
            start_date: format(new Date(start_date), "yyyy-MM-dd"),
            end_date: format(new Date(end_date), "yyyy-MM-dd"),
            rooms: [rooms],
            mealPlan,
            isRefundable,
            hotelId: this.props.router.params?.id,
            destId: this.props.router.params?.destId,
        };

        this.props.getAboutHotel({ data, navigate: this.props.router.navigate });

        this.setState({
            filters: {
                ...filters,
                dateFrom: start_date,
                dateTo: end_date,
                mealPlan,
                isRefundable,
                adults: rooms.adults,
                children: rooms.children,
                childrenAges: rooms.childrenAges,
                hotelId: this.props.router.params?.id,
                destId: this.props.router.params?.destId,
                transport,
            },
        });
        setTimeout(() => {
            this.props.router.navigate(`/about-hotel/${this.props.router.params?.destId}/${this.props.router.params?.id}?${queryParams.toString()}`, {
                replace: true,
            });
        }, 100);
    };

    changeFilters(filterName, value, isArray = false) {
        let filter = { [filterName]: value };
        if (isArray) {
            let newFilter;
            if (this.state.filters[filterName]?.find((filter) => filter === value)) {
                newFilter = this.state.filters[filterName]?.filter((filter) => filter !== value);
            } else {
                newFilter = this.state.filters[filterName] ? [...this.state.filters[filterName], value] : [value];
            }

            if (newFilter.length > 0) {
                filter = { [filterName]: newFilter };
            } else {
                delete filter[filterName];
                delete this.state.filters[filterName];
            }
        }
        this.setState({ filters: { ...this.state.filters, ...filter } });
    }

    render() {
        return (
            <>
                <MediaQuery maxWidth={990}>
                    <AboutHotelMobile
                        loading={this.props.loading}
                        informations={this.props.informations}
                        description={this.props.description}
                        services={this.props.services}
                        photos={this.props.photos}
                        location={this.props.location}
                        rooms={this.props.rooms}
                        hotelDescriptionPDF={this.props.hotelDescriptionPDF}
                        filters={this.state.filters}
                        changeFilters={this.changeFilters}
                    />
                </MediaQuery>
                <MediaQuery minWidth={991}>
                    <AboutHotelDesktop
                        loading={this.props.loading}
                        informations={this.props.informations}
                        description={this.props.description}
                        services={this.props.services}
                        photos={this.props.photos}
                        location={this.props.location}
                        rooms={this.props.rooms}
                        hotelDescriptionPDF={this.props.hotelDescriptionPDF}
                        filters={this.state.filters}
                        changeFilters={this.changeFilters}
                    />
                </MediaQuery>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        aboutHotel: state.Booking.aboutHotel,
        informations: {
            photo: state.Booking.aboutHotel?.photos && state.Booking.aboutHotel?.photos?.length ? state.Booking.aboutHotel?.photos[0] : "",
            id: state.Booking.aboutHotel?.id ?? "",
            name: state.Booking.aboutHotel?.name ?? "",
            rating: state.Booking.aboutHotel?.rating ?? 0,
            address: state.Booking.aboutHotel?.location?.address,
        },
        description: state.Booking.aboutHotel?.description ?? "",
        services: state.Booking.aboutHotel?.services ?? {},
        photos: state.Booking.aboutHotel?.photos ?? [],
        location: {
            latitude: state.Booking.aboutHotel?.location?.latitude ?? 0,
            longitude: state.Booking.aboutHotel?.location?.longitude ?? 0,
        },
        rooms: state.Booking.aboutHotel?.rooms ?? [],
        hotelDescriptionPDF: state.Booking.aboutHotel?.descriptionPdf,
        loading: state.Booking.loading,
    };
};

export default connect(mapStateToProps, { getAboutHotel })(withRouter(AboutHotel));
