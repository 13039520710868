import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "../styles/FavoritesAccommodations.scss";

import { getFavoritesHotels } from "../helpers/utils/favoritesUtils";

// import AccommodationFakeImage from "../static/images/destinations_fake_image.png";
import BedIcon from "../static/images/icons/bed_blue_icon.png";

class FavoritesAccommodations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeAccommodation: 0,
            accommodations: getFavoritesHotels(),
        };
    }

    render() {
        return (
            <Container className="favorites_accommodations_wrapper font-poppins">
                <div className="favorites_accommodations_header">
                    <div className="title">Omiljeni smeštaj</div>
                </div>

                <div className="favorites_accommodations_pagination_wrapper">
                    <div className="favorites_accommodations_list">
                        {this.state.accommodations && this.state.accommodations?.length ? (
                            this.state.accommodations?.map((accommodation, key) => (
                                <div className="favorites_accommodation_item" key={key}>
                                    <Row className="favorites_accommodation_item_row">
                                        <Col md={6}>
                                            <img src={accommodation.photo} alt={`Accommodation ${key}`} className="w-100" style={{ borderRadius: 12 }} />
                                        </Col>
                                        <Col md={6}>
                                            <div className="accommodation_content_wrapper">
                                                <div className="hotel_name_stars">
                                                    <div className="hotel_name">{accommodation?.name}</div>
                                                    <div className="hotel_stars">
                                                        {Array.from({ length: parseInt(accommodation?.rating) }, (v, index) => index).map((item, key) => (
                                                            <span className="fa fa-star checked" key={key}></span>
                                                        ))}
                                                    </div>
                                                </div>

                                                <Link to={`/about-hotel/${accommodation?.destId}/${accommodation?.id}`} className="about_hotel_button">
                                                    <img src={BedIcon} alt="Bed icon" />O smeštaju
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))
                        ) : (
                            <div>Nemate omiljeni smeštaj</div>
                        )}
                    </div>
                </div>
            </Container>
        );
    }
}

export default FavoritesAccommodations;
