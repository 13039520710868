import React, { Component } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { getCookie } from "./helpers/utils/globalUtils";

class AuthRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logged: getCookie("userId") ? true : false,
        };
    }

    render() {
        if (this.state.logged) {
            return <Outlet />;
        }

        return <Navigate to={"/login"} />;
    }
}

export default AuthRoute;
