import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import "../styles/MyReservation.scss";

import MyReservationItem from "../components/Reusable/MyReservationItem";
import MyReservationSearch from "../components/Reusable/MyReservationSearch";

import { myReservations, cancelReservation, searchReservation } from "../store/actions";

class MyReservation extends Component {
    componentDidMount() {
        this.props.myReservations();
    }

    render() {
        return (
            <Container className="my_reservation_wrapper font-poppins">
                <Row className="my_reservation_header_wrapper">
                    <Col className="title">Moje rezervacija</Col>
                    <Col lg={5} xl={4}>
                        <MyReservationSearch onSearch={this.props.searchReservation} />
                    </Col>
                </Row>
                <div className="my_reservation_list_pagination">
                    <div className="my_reservation_list">
                        {this.props.searchedReservationsList && this.props.searchedReservationsList?.length
                            ? this.props.searchedReservationsList?.map((item, key) => (
                                  <MyReservationItem data={item} cancelReservation={this.props.cancelReservation} key={key} />
                              ))
                            : this.props.myReservationsList?.map((item, key) => (
                                  <MyReservationItem data={item} cancelReservation={this.props.cancelReservation} key={key} />
                              ))}
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        myReservationsList: state.Booking.myReservations,
        searchedReservationsList: state.Booking.searchedReservations,
    };
};

export default connect(mapStateToProps, { myReservations, cancelReservation, searchReservation })(MyReservation);
