import React, { Component } from "react";
import { Link } from "react-router-dom";

import DiscountRibbon from "../../Reusable/DiscountRibbon";

import { roomTypeName } from "../../../helpers/utils/aboutHotelUtils";
import { discountColor } from "../../../helpers/utils/globalUtils";

import "../../../styles/Components/BookingAccommodationItem.scss";

import LocationIcon from "../../../static/images/About_Hotel/hotel_services/location_icon.svg";
import RoomTypeIcon from "../../../static/images/icons/room_type_icon.png";
import MealPlanDescriptionIcon from "../../../static/images/icons/meal_plan_description_icon.png";

class BookingAccommodationItemMobile extends Component {
    render() {
        const { accommodation } = this.props;

        return (
            <div className="booking_accommodation_item_wrapper font-poppins">
                <div style={{ background: "#fff", borderRadius: 9 }}>
                    <div className="image_wrapper">
                        {accommodation?.photos.length ? <img src={accommodation?.photos[0]} alt={`Hotel ${accommodation?.name}`} /> : <></>}
                    </div>

                    <div className="accomodation_box">
                        <div className="hotel_name">{accommodation?.name}</div>
                        <div className="stars_wrapper">
                            {Array.from({ length: accommodation?.rating?.value }, (v, index) => index).map((item, key) => (
                                <span className="fa fa-star checked" key={key}></span>
                            ))}
                        </div>
                        <div className="hotel_description" dangerouslySetInnerHTML={{ __html: accommodation?.description }}></div>
                    </div>
                </div>

                <div className="accomodation_box price_box position-relative">
                    <div className={`price_per_room ${accommodation?.discount ? "price_left" : ""}`} style={{ color: discountColor(accommodation?.discount) }}>
                        <div className="price_text">{`${Math.round(parseFloat(accommodation?.roomData?.finalPrice))} €`}</div>
                        <div className="price_label">Po sobi</div>
                        {accommodation && accommodation?.discount ? <DiscountRibbon data={accommodation} /> : <></>}
                    </div>
                    {/* <div className="price_per_room_per_night" style={{ color: this.discountColor(this.props.data?.discount) }}>
                        <div className="price">34.89 €</div>
                        <div className="label">per room,per night</div>
                    </div> */}
                    <div className="top_3_services">
                        <div className="top_services">
                            <img src={LocationIcon} alt="Top 3 services 1" />
                            {`${accommodation?.location?.address} ${accommodation?.location?.postalCode}, ${accommodation?.location?.countryCode}`}
                        </div>

                        {accommodation?.roomData?.roomType && (
                            <div className="top_services">
                                <img src={RoomTypeIcon} alt="Top 3 services 1" width={28} height={27} />
                                {accommodation?.roomData?.roomType}
                            </div>
                        )}
                        {accommodation?.roomData?.mealPlanDescription && (
                            <div className="top_services">
                                <img src={MealPlanDescriptionIcon} alt="Top 3 services 2" />
                                {roomTypeName(accommodation?.roomData?.mealPlanDescription)}
                            </div>
                        )}
                    </div>

                    <Link to={`/about-hotel/${accommodation?.destination}/${accommodation?.id}?${this.props.params}`} className="reservation_button_link">
                        <div className="reservation_button">Rezerviši</div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default BookingAccommodationItemMobile;
