import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import AboutCountryInformations from "../Reusable/AboutCountryInformations";

import Egypt1 from "../../static/images/About_Country/About_Egypt/egypt_1.png";
import Egypt2 from "../../static/images/About_Country/About_Egypt/egypt_2.png";
import Egypt3 from "../../static/images/About_Country/About_Egypt/egypt_3.png";

class AboutEgypt extends Component {
    render() {
        return (
            <div>
                <AboutCountryInformations
                    image={require("../../static/images/About_Country/About_Egypt/general_informations_egypt.png")}
                    title={"ОPŠTE INFORMACIJE"}
                    description={`Egipat, zvanično ime je Arapska Republika Egipat. Egipat je država smeštena u 
                    severnoj Africi i na Sinajskom poluostrvu u Aziji. Egipat je zemlja koju je stvorio bog „Ra“, na čijoj teritoriji je nastala jedna od najdrevnijih i najtajanstvenijih civilizacija na svetu. Ovo je zemlja dva kontinenta i „Kapija Istoka“. Zemlja prelepe arhitekture, faraona, sfinga i piramida. Zemlja neverovatno lepog i bogatog Crvenog mora. Egipat je obavijen magijom, mitovima i misterijama koji se pokušavaju razotkriti do danas.`}
                />
                <Container>
                    <div className="description">
                        Egipat je jedinstvena prilika da saznate sve o kolevki ljudske civilizacije. Hiljade turista ovde dolaze svake godine da lično vide
                        istorijske spomenike nepokolebljive milenijumima i pronađu odgovore na misterije drevnih civilizacija.
                    </div>
                    <ul className="country_unorder_list">
                        <li>Glavni grad — Кairo</li>
                        <li>Veliki gradovi — Aleksandrija, Luksor, Asuan, Port Said, Giza</li>
                        <li>Zvanični jezik – аrapski</li>
                        <li>Religija – većinski deo je islam, suniti, i manjinski kopti hrišćani.</li>
                        <li>Valuta – еgipatska funta</li>
                        <li>Vremenska zona – UTC + 2:00</li>
                    </ul>
                    <img src={Egypt1} alt="Egypt 1" />
                </Container>
                <div className="content_fake_background_wrapper" style={{ marginTop: 65 }}>
                    <Container>
                        <div className="content_wrapper">
                            <div className="title">Geografski položaj</div>
                            <div className="description">
                                Egipat se graniči na zapadu sa Libijom, na jugu sa Sudanom, na istoku sa Palestinskom vlašću, Izraelom, a ima i pomorsku granicu
                                sa Saudijskom Arabijom i Jordanom. Na severu se graniči sa Sredozemnim morem, na istoku sa Crvenim morem. Egipat poseduje jedn
                                od najvećih veštačkih kanala - Suecki kanal, koji povezuje Sredozemno i Crveno more, čime se otvara kratka ruta od Atlantika do
                                Indijskog okeana. Kroz Egipat od juga prema severu protiče jedna od dve najduže reke na svetu, Nil.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Populacija</div>
                            <div className="description">
                                Crnogorci imaju mnogo praznika. Većina festivala se održava tokom leta. Krajem jula možete posetiti festival borovnica koji
                                traje nedelju dana i održava se u Plavu. Početkom avgusta u Tivtu se održava Sajam maslina, a krajem meseca možete uživati u
                                ribama i domaćim vinima u Petrovcu. Malo severnije, u mestu Berane, možete posetiti Festival meda, a u selu Njeguši možete
                                probati sir, pršut i ostale lokalne specijalitete. Leti u Crnoj Gori možete doći na Narodne izložbe, izložbu informacionih
                                tehnologija, Ribarski dan, muzičke festivale i druge nezaboravne događaje.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Jezik</div>
                            <div className="description">
                                Službeni jezik u Egiptu je književni arapski i koristi se u većini štampanih medija. Egipatski arapski jezik je jezik koji se
                                svuda koristi u svakodnevnom životu, najčešće pisan arapskim pismom, ponekad latinskim (na primer, u čet sobama). Arapski jezik
                                se pojavio u Egiptu u VII veku i postao savremeni govorni jezik Egipćana. Od svih varijeteta arapskog, egipatski arapski je
                                najrazumljiviji dijalekt na Bliskom Istoku i u Severnoj Africi, verovatno zbog uticaja egipatske kinematografije u zemljama
                                arapskog govornog područja. Otprilike trećina egipatskog stanovništva govori gornjegipatski (Said) dijalekt arapskog jezika.
                                Beduini, koji su u manjini, govore razne oblike beduinskih dijalekata na arapskom, uglavnom na Sinajskom poluostrvu. Pored toga,
                                sudanski arapski je uobičajen među ljudima iz Sudana.
                            </div>
                        </div>
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <Container>
                    <img src={Egypt2} alt="Egypt 2" />
                </Container>
                <div className="content_fake_background_wrapper" style={{ marginTop: 65 }}>
                    <Container>
                        <div className="content_wrapper">
                            <div className="title">Religija</div>
                            <div className="description">
                                Većinski deo stanovništva govori arapski i ispoveda sunitski islam, ima i hrišćana Kopta. Koptski hrišćani su minimalno
                                zastupljeni u sprovođenju zakona, državnoj bezbednosti i javnoj službi i diskriminirani su na tržištu rada na osnovu svoje
                                religije. Stavovi zvanično zabranjenih radikalnih islamskih organizacija prilično su jaki u Egiptu. Mnogi ideolozi vehabizma i
                                radikalnog islama studirali su na Univerzitetu u Kairu.
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">Valuta</div>
                            <div className="description">
                                Novčana jedinica zemlje je egipatska funta (EGP), 1. egipatska funta 100 pijastera. Lako možete menjati valutu u menjačnicama,
                                bankama (obično radnim danom od 8:30h do 14:00h, neke su zatvorene od ponedeljka do utorka-srede) ili hotelima. Mnogi veliki
                                hoteli iskazuju cene u dolarima i evrima i prihvataju ovu valutu. U upotrebi su i MasterCard, Visa, American Ekpress i Diners
                                Club kartice, a danas su prihvaćene ne samo u hotelima već i u restoranima i nekim prodavnicama
                            </div>
                        </div>
                        <div className="content_wrapper">
                            <div className="title">KLIMA</div>
                            <div className="description">
                                Klima Egipta je tropska i suptropsko-kontinentalna (mediteranska obala). Zimi (od decembra do februara) temperatura vazduha je
                                +20 ... + 25 ° C, leti (od maja do septembra) +35 ... + 40 ° C. Zimi i na proleće često duvaju suvi vrući vetrovi „khamsin“.
                            </div>
                        </div>
                        <Row>
                            <Col lg={4}>
                                <div className="content_wrapper">
                                    <div className="title">Znamenitosti</div>
                                    <ul className="country_unorder_list">
                                        <li>Kompleks piramida u Gizi</li>
                                        <li>Dolina kraljeva</li>
                                        <li>Velika sfinga</li>
                                        <li>Egipatski hram</li>
                                        <li>Hram Karnak</li>
                                        <li>Hram i muzej u Luksoru</li>
                                        <li>Abu - Simbel</li>
                                        <li>Khan el-Khalili</li>
                                        <li>Sahara</li>
                                        <li>Sinaj</li>
                                        <li>Manastir Sv. Katarine</li>
                                        <li>Citadela u Kairu</li>
                                        <li>Edfuov Hram</li>
                                        <li>Hram Hačepsuta</li>
                                        <li>Muhamed Alijeva Džamija</li>
                                        <li>Tvrđava Kite Bai</li>
                                        <li>Crkva Sv. Marija</li>
                                        <li>Nubijski muzej</li>
                                        <li>Velike egipatski muzej</li>
                                        <li>Šareni kanjon</li>
                                        <li>Ostrvo faraona</li>
                                        <li>Grad mrtvih</li>
                                        <li>Мuzej mumija</li>
                                        <li>Tutankamonova grobnica</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <img src={Egypt3} alt="Egypt 3" />
                            </Col>
                        </Row>
                    </Container>
                    <div className="fake_background blue"></div>
                </div>
            </div>
        );
    }
}

export default AboutEgypt;
