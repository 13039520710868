import {
    GET_SPECIAL_OFFERS_HOTELS,
    GET_SPECIAL_OFFERS_HOTELS_SUCCESS,
    GET_SPECIAL_OFFERS_HOTELS_ERROR,
    GET_SPECIAL_OFFERS_APARTMENTS,
    GET_SPECIAL_OFFERS_APARTMENTS_SUCCESS,
    GET_SPECIAL_OFFERS_APARTMENTS_ERROR,
    GET_FIRST_LAST_MINUTES_OFFERS,
    GET_FIRST_LAST_MINUTES_OFFERS_SUCCESS,
    GET_FIRST_LAST_MINUTES_OFFERS_ERROR,
} from "./actionTypes";

const initialState = {
    // variable: value
    hotels: undefined,
    apartments: undefined,
    firstLastMinutes: undefined,
    loading: false,
    error: undefined,
};

const SpecialOffers = (state = initialState, action) => {
    switch (action.type) {
        case GET_SPECIAL_OFFERS_HOTELS:
        case GET_SPECIAL_OFFERS_APARTMENTS:
        case GET_FIRST_LAST_MINUTES_OFFERS:
            state = { ...state, loading: true };
            break;
        case GET_SPECIAL_OFFERS_HOTELS_SUCCESS:
            state = { ...state, loading: false, hotels: action.payload };
            break;
        case GET_SPECIAL_OFFERS_APARTMENTS_SUCCESS:
            state = { ...state, loading: false, apartments: action.payload };
            break;
        case GET_FIRST_LAST_MINUTES_OFFERS_SUCCESS:
            state = { ...state, loading: false, firstLastMinutes: action.payload };
            break;
        case GET_SPECIAL_OFFERS_HOTELS_ERROR:
        case GET_SPECIAL_OFFERS_APARTMENTS_ERROR:
        case GET_FIRST_LAST_MINUTES_OFFERS_ERROR:
            state = { ...state, loading: false, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default SpecialOffers;
