import { GET_BLOG, GET_BLOG_SUCCESS, GET_BLOG_ERROR, GET_SINGLE_BLOG, GET_SINGLE_BLOG_SUCCESS, GET_SINGLE_BLOG_ERROR } from "./actionTypes";

export const getBlog = () => {
    return {
        type: GET_BLOG,
    };
};
export const getBlogSuccess = (payload) => {
    return {
        type: GET_BLOG_SUCCESS,
        payload,
    };
};
export const getBlogError = (payload) => {
    return {
        type: GET_BLOG_ERROR,
        payload,
    };
};

export const getSingleBlog = (id) => {
    return {
        type: GET_SINGLE_BLOG,
        payload: id,
    };
};
export const getSingleBlogSuccess = (payload) => {
    return {
        type: GET_SINGLE_BLOG_SUCCESS,
        payload,
    };
};
export const getSingleBlogError = (payload) => {
    return {
        type: GET_SINGLE_BLOG_ERROR,
        payload,
    };
};
