import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import AboutCountryInformations from "../Reusable/AboutCountryInformations";

import Bulgaria1 from "../../static/images/About_Country/About_Bulgaria/bulgaria_1.png";
import Bulgaria2 from "../../static/images/About_Country/About_Bulgaria/bulgaria_2.png";

class AboutBulgaria extends Component {
    render() {
        return (
            <div>
                <AboutCountryInformations
                    image={require("../../static/images/About_Country/About_Bulgaria/general_informations_bulgaria.png")}
                    title={"ОPŠTE INFORMACIJE"}
                    description={`područje Balkanskog poluostrva, sa predivnom klimom, živopisnim planinama, šarmantnim primorskim odmaralištima, lekovitim termalnim izvorima, bogatim kulturnim nasleđem, pravoslavnim svetinjama, jasno izraženim nacionalnim tradicijama i mnogo više.
                    Mouzenidis Travel Vas poziva, da otkrijete sa nama sve aspekte odmora u Bugarskoj i uživate u atmosferi ove divne zemlje, divnim planinama, toplom suncu, šarenim pejzažima i bezgraničnom gostoprimstvu!`}
                />
                <Container>
                    <ul className="country_unorder_list">
                        <li>Glavni grad: Sofija</li>
                        <li>Službeni jezik: Bugarski</li>
                        <li>Valuta: Bugarski lev (BGN)</li>
                        <li>Klima: umereno kontinentalna, mediteranska</li>
                        <li>Stanovništvo: 7.364.570 (prema popisu 2011. godine)</li>
                        <li>Državni sistem: parlamentarna republika</li>
                        <li>Pozivni broj: međunarodni kod +359</li>
                    </ul>
                    <img src={Bulgaria1} alt="Bulgaria 1" />
                    <div className="content_wrapper">
                        <div className="title">Geografksi položaj</div>
                        <div className="description">
                            Republika Bugarska se nalazi u jugoistočnoj Evropi, na živopisnom Balkanskom poluostrvu, u njegovom istočnom delu. Svoje ime
                            Bugarska duguje narodima koji je naseljavaju, od pamtiveka. Bugarska se graniči sa 5 zemalja: na jugu - sa Grčkom i Turskom, na
                            zapadu - sa Srbijom i Crnom Gorom, na jugozapadu - sa Makedonijom, na severu - sa Rumunijom i na istoku se graniči sa Crnim morem.
                            Uprkos svojoj maloj veličini, samo 110.550 km2, zemlja ima iznenađujuće raznovrstan pejzaž. Ovde ćete naći slikovite doline, divlje
                            reke, snežne vrhove, nizije, misteriozne klisure i visoravni. Tri planinska venca Bugarske su, Pirin, Rila i Rodopi prostiru se od
                            istoka ka zapadu. Istočna regija je više brdovita, dok zapadom dominiraju visoke planine, a visina pojedinih vrhova, dostiže 2900
                            metara. Bugarska prestonica i najveći grad je Sofija, koja se nalazi u podnožju Vitoša i Lulin. Sofija je politički, ekonomski i
                            kulturni centar zemlje, ali i grad sa velikom koncentracijom jedinstvenog arhitektonskog nasleđa tri kulture - Antičke (Serdica
                            tvrđava), vizantijske (Crkva Svete Sofije, rotonde Svetog Đorđa) i otomanske (džamija Bania Bashi). Među ostalim gradovima su
                            najveći, Plovdiv, Varna, Burgas, Ruse, Stara Zagora, i drugi. Bugarska je podeljena u 28 provincija i 264 regiona, od kojih je
                            svaki, čuvar vekovne nacionalne tradicije. 1. januara 2007. godine, Bugarska se pridružila Evropskoj uniji.
                        </div>
                    </div>
                </Container>
                <div className="content_fake_background_wrapper">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <div className="content_wrapper">
                                    <div className="title">Zimski turizam u Bugarskoj</div>
                                    <div className="description">
                                        Predivna priroda, kao iz zimske bajke, prelepo bugarsko sunce, nove staze, savremena oprema odmarališta, prijatna
                                        atmosfera i udobni hoteli doveli su do toga da skijališta Bugarske (Bansko, Borovec, Pamporovo) postaju sve popularniji,
                                        iz godine u godinu. Ova mesta privlače turiste iz cele Evrope, postoje međunarodna sportska takmičenja, ne prestaju da
                                        razvijaju infrastrukturu, grade se novi hoteli, različitih kategorija od 2* do 5*, obezbeđujući visok kvalitet usluge i
                                        udoban boravak za sve goste. Jedan od najpopularnijih skijališta u Bugarskoj je Bansko, šarmantno mesto, koje leži na
                                        visinama od 990m do 2600m nadmorske visine. Mesto se nalazi u susedstvu jedinstvenog nacionalnog rezervata, koji je na
                                        listi svetske baštine UNESCO. Do 2 metra snega i duga sezona, koja traje od novembra do aprila, omogućava ljubiteljima
                                        skijanja i snouborda da uživaju u svojim omiljenim sportovima 6 meseci. Spektakularni pogled, povoljna klima i udobnost
                                        modernih hotela čekaju goste ovog divnog skijališta.
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <img src={Bulgaria2} alt="Bulgaria 2" />
                            </Col>
                        </Row>
                    </Container>
                    <div className="fake_background"></div>
                </div>
                <Container>
                    <div className="content_wrapper">
                        <div className="title">VALUTA</div>
                        <div className="description">
                            Zvanična valuta Bugarske - lev, jednak 100 stotinki. U opticaju možete naći apoene od 2, 5, 10, 20, 50, 100 leva, kao i kovanice od
                            1, 2, 5, 10, 20, 50 stotinki i 1 Lev.
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">KLIMA</div>
                        <div className="description">
                            Klima, kao i priroda Bugarske je prilično raznovrsna. Klimatske uslove svakog regiona određuje jedinstvenost njegovog pejzaža.
                            Dakle, zimi kontinentalna klima sa niskim temperaturama i teškim snežnim padavinama, a leti mediteranska klima, koja obezbeđuje
                            toplo vreme tokom letnjih meseci. Vreme u Bugarskoj po mesecima:
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">SLOBODNO VREME</div>
                        <div className="description">
                            U Bugarskoj možete naći najrazličitiju zabavu, ovde svako može pronaći nešto za sebe. Možete poći tradicionalnim putevima, kroz
                            živopisne gradove (Dobarsko, Ognianovo, Melnik), upoznati znamenitosti Sofije, obići svetilišta pravoslavlja (manastir Rila,
                            manastir Rožen), jedinstvene eko-parkove, otići na Wellness tretmane u lekovitim vodama toplih izvora (Sandanski), jahati, otkriti
                            vina i gastronomske specijalitete i još mnogo toga. Svaki dan će vam biti ispunjen novim i nezaboravnim iskustvima!
                        </div>
                    </div>
                    <div className="content_wrapper">
                        <div className="title">SHOPING U BUGARSKOJ</div>
                        <div className="description">
                            Sve prodavnice, u gradovima, su otvorene od ponedeljka do petka od 10:00 do 20:00, a subotom od 10:00 do 18:00. Brojne prodavnice
                            hrane, su otvorene 24 sata. Šta kupiti u Bugarskoj? Izbor jedinstvenih poklona, koje će vas podsećati na Bugarsku, je veoma širok:
                            eterično ulje, ručno rađena čipke, ikone i razna vina itd.
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default AboutBulgaria;
