import {
    GET_SLIDER,
    GET_SLIDER_SUCCESS,
    GET_SLIDER_ERROR,
    GET_MAIN_DESTINATIONS,
    GET_MAIN_DESTINATIONS_SUCCESS,
    GET_MAIN_DESTINATIONS_ERROR,
    SET_ACTIVE_DESTINATION,
} from "./actionTypes";

const initialState = {
    // variable: value
    slider: undefined,
    accommodations: undefined,
    activeDestinations: {
        hotel: 0,
        apartments: 0,
    },
    loading: false,
    error: undefined,
};

const Home = (state = initialState, action) => {
    switch (action.type) {
        case GET_SLIDER:
            state = { ...state, loading: true };
            break;
        case GET_SLIDER_SUCCESS:
            state = { ...state, loading: false, slider: action.payload };
            break;
        case GET_SLIDER_ERROR:
            state = { ...state, loading: false };
            break;
        case GET_MAIN_DESTINATIONS:
            state = { ...state, loading: true };
            break;
        case GET_MAIN_DESTINATIONS_SUCCESS: {
            let activeDestinations, accommodations;
            if (action.payload?.mainGreekHotelDest && action.payload?.mainGreekHotelDest?.length) {
                activeDestinations = { ...activeDestinations, hotels: action.payload?.mainGreekHotelDest[0]?.destId };
                accommodations = { ...accommodations, hotels: action.payload?.mainGreekHotelDest };
            }

            if (action.payload?.mainGreekApartmentDest && action.payload?.mainGreekApartmentDest?.length) {
                activeDestinations = { ...activeDestinations, apartments: action.payload?.mainGreekApartmentDest[0]?.destId };
                accommodations = { ...accommodations, apartments: action.payload?.mainGreekApartmentDest };
            }

            state = { ...state, loading: false, accommodations, activeDestinations };
            break;
        }
        case GET_MAIN_DESTINATIONS_ERROR:
            state = { ...state, loading: false, error: action.payload };
            break;
        case SET_ACTIVE_DESTINATION:
            state = {
                ...state,
                activeDestinations: { ...state.activeDestinations, [action.payload.accommodation]: action.payload.destination },
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default Home;
