import { takeEvery, fork, put, all, call } from "redux-saga/effects"; // remove comment when you start write functions

import { GET_SPECIAL_OFFERS_HOTELS, GET_SPECIAL_OFFERS_APARTMENTS, GET_FIRST_LAST_MINUTES_OFFERS } from "./actionTypes";
import {
    getSpecialOffersHotelsSuccess,
    getSpecialOffersHotelsError,
    getSpecialOffersApartmentsSuccess,
    getSpecialOffersApartmentsError,
    getFirstLastMinutesOffersSuccess,
    getFirstLastMinutesOffersError,
} from "./actions";

import { getSpecialOffersHotels, getSpecialOffersApartments, getFirstLastMinutesOffers } from "../../helpers/api/specialOffers";

function* getSpecialOffersHotelsSaga() {
    try {
        const response = yield call(getSpecialOffersHotels);
        if (response && response.status === 200) {
            yield put(getSpecialOffersHotelsSuccess(response.data.discountedHotels));
        } else {
            yield put(getSpecialOffersHotelsError(response.error));
        }
    } catch (error) {
        yield put(getSpecialOffersHotelsError(error));
    }
}

function* getSpecialOffersApartmentsSaga() {
    try {
        const response = yield call(getSpecialOffersApartments);
        if (response && response.status === 200) {
            yield put(getSpecialOffersApartmentsSuccess(response.data.discountedApartments));
        } else {
            yield put(getSpecialOffersApartmentsError(response.error));
        }
    } catch (error) {
        yield put(getSpecialOffersApartmentsError(error));
    }
}

function* getFirstLastMinutesOffersSaga() {
    try {
        const response = yield call(getFirstLastMinutesOffers);
        if (response && response.status === 200) {
            yield put(getFirstLastMinutesOffersSuccess(response.data["First/Last minut offers"]));
        } else {
            yield put(getFirstLastMinutesOffersError(response.error));
        }
    } catch (error) {
        yield put(getFirstLastMinutesOffersError(error));
    }
}

export function* watchGetSpecialOffersHotelsSaga() {
    yield takeEvery(GET_SPECIAL_OFFERS_HOTELS, getSpecialOffersHotelsSaga);
}

export function* watchGetSpecialOffersApartmentsSaga() {
    yield takeEvery(GET_SPECIAL_OFFERS_APARTMENTS, getSpecialOffersApartmentsSaga);
}

export function* watchGetFirstLastMinutesOffersSaga() {
    yield takeEvery(GET_FIRST_LAST_MINUTES_OFFERS, getFirstLastMinutesOffersSaga);
}

function* specialOffersSaga() {
    yield all([fork(watchGetSpecialOffersHotelsSaga), fork(watchGetSpecialOffersApartmentsSaga), fork(watchGetFirstLastMinutesOffersSaga)]);
}

export default specialOffersSaga;
