import React, { Component } from "react";
import MediaQuery from "react-responsive";

import BookingAccommodationItemMobile from "./Mobile";
import BookingAccommodationItemDesktop from "./Desktop";

class BookingAccommodationItem extends Component {
    render() {
        return (
            <>
                <MediaQuery minWidth={1200}>
                    <BookingAccommodationItemDesktop {...this.props} />
                </MediaQuery>
                <MediaQuery maxWidth={1199}>
                    <BookingAccommodationItemMobile {...this.props} />
                </MediaQuery>
            </>
        );
    }
}

export default BookingAccommodationItem;
