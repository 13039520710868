import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";

import BookingAccommodationItem from "../BookingAccommodationItem";
import BookingLoadingSkeleton from "../BookingLoadingSkeleton";

import "../../styles/Components/BookingList.scss";
import "../../styles/Components/Pagination.scss";

class BookingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentItems: null,
            pageCount: 0,
            itemsOffset: 0,
        };
    }

    doPaginate = () => {
        const endOffset = this.state.itemsOffset + 4;
        this.setState({
            currentItems: this.props.accommodation?.slice(this.state.itemsOffset, endOffset),
            pageCount: Math.ceil(this.props.accommodation?.length / 4),
        });
    };

    componentDidMount() {
        this.doPaginate();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.itemsOffset !== prevState.itemsOffset || JSON.stringify(this.props.accommodation) !== JSON.stringify(prevProps.accommodation)) {
            this.doPaginate();
        }
    }

    handlePageClick = (event) => {
        const newOffset = (event.selected * 4) % this.props.accommodation?.length;
        this.setState({ itemsOffset: newOffset });
    };

    render() {
        return (
            <div className={`bookings_list_wrapper ${this.props.loading ? "loading" : "done_loading"}`}>
                {this.props.loading && [1, 2, 3].map((e, index) => <BookingLoadingSkeleton key={index} />)}
                {!this.props.loading &&
                    (this.props.accommodation?.length ? (
                        <>
                            {this.state.currentItems?.map((e, index) => (
                                <BookingAccommodationItem
                                    key={index}
                                    accommodation={{ ...e, destination: this.props.destination }}
                                    params={this.props.params}
                                />
                            ))}
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={this.state.pageCount}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                                className="pagination"
                            />
                        </>
                    ) : (
                        <div className="not-available-text">Nema rezultata</div>
                    ))}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accommodation: state.Booking.accommodation,
        loading: state.Booking.loading || state.Booking.loadingSearch,
    };
};

export default connect(mapStateToProps, {})(BookingList);
