import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import "../styles/SingleBlog.scss";

import { shuffle, withRouter } from "../helpers/utils/globalUtils";

import BlogDateIcon from "../static/images/icons/date_blog_icon.png";
import OtherBlogsIcon from "../static/images/icons/other_blogs_icon.png";

import { getNotifications, getSingleNotifications } from "../store/actions";

class SingleNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            singleBlog: undefined,
            otherNotifications: undefined,
        };
    }

    shuffleNotifications = () => {
        this.setState({
            otherNotifications: shuffle(this.props.notifications?.filter((value) => this.props.router?.params?.id !== value?.id)),
        });
    };

    componentDidMount() {
        if (this.props.router?.params?.id) {
            this.props.getSingleNotifications(this.props.router?.params?.id);
            this.props.getNotifications();
            this.shuffleNotifications();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.router?.params && this.props.router?.params?.id !== prevProps.router?.params?.id) {
            this.props.getSingleNotifications(this.props.router?.params?.id);
            this.props.getNotifications();
            this.shuffleNotifications();
        }

        if (
            JSON.stringify(this.props.notifications) !== JSON.stringify(prevProps.notifications) ||
            this.props.singleNotification !== prevProps.singleNotification
        ) {
            this.shuffleNotifications();
        }
    }

    render() {
        return (
            <div className="single-blog-wrapper font-poppins">
                <div
                    className="single-blog-image-cover"
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_API_URL}${this.props.singleNotification?.image})`,
                    }}
                >
                    <div className="image-overlay"></div>

                    <Container className="single-blog-header-wrapper">
                        <Row className="single-blog-header-row">
                            <Col className="title font-marck-script">{this.props.singleNotification?.title}</Col>
                            <Col className="single-blog-writer-time-wrapper ">
                                {this.props.singleNotification?.updated_at && (
                                    <div className="items">
                                        <img src={BlogDateIcon} alt={`Blog date icon`} />
                                        {this.props.singleNotification?.updated_at && (
                                            <div>{format(parseISO(this.props.singleNotification?.updated_at), "MM.dd.yyyy")}</div>
                                        )}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container>
                    <Row>
                        <Col className="single-blog-content" lg={8} dangerouslySetInnerHTML={{ __html: this.props.singleNotification?.description }}></Col>
                        {this.state.otherNotifications && this.state.otherNotifications?.length && (
                            <Col lg={4}>
                                <div className="other-blogs-wrapper">
                                    <div className="title">
                                        <img src={OtherBlogsIcon} alt="Other blogs icon" />
                                        Ostala Obaveštenja
                                    </div>
                                    <div className="other-blogs-list">
                                        {this.state.otherNotifications?.slice(0, 3)?.map((notification, key) => (
                                            <Link to={`/notifications/${notification.id}`} className="item-title" key={key}>
                                                {notification?.title}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        singleNotification: state.Notifications.singleNotification,
        notifications: state.Notifications.notifications,
    };
};

export default connect(mapStateToProps, { getSingleNotifications, getNotifications })(withRouter(SingleNotifications));
