export const destinations = [
    {
        title: "Grčka",
        offers: [
            { label: "Hotelski smeštaj", link: "/booking?selectedPlace=OT-LOC-GEO-400665" },
            { label: "Apartmanski smeštaj", link: "/booking?selectedPlace=OT-LOC-GEO-400665&aparments=true" },
            { label: "Sopstveni prevoz", link: "/booking?selectedPlace=OT-LOC-GEO-400665" },
            { label: "Autobuski/kombi prevoz", link: "/booking?selectedPlace=OT-LOC-GEO-400665&transport=bus" },
            { label: "O destinaciji", link: "/about-country/greece" },
            { label: "Gradovi i letovališta", link: "/destinations" },
            { label: "Opisi hotelskog smeštaja" },
            { label: "Opisi apartmanskog smeštaja" },
            // { label: "Opšte informacije" },
        ],
    },
    {
        title: "Crna gora",
        offers: [{ label: "O destinaciji", link: "/about-country/montenegro" }],
    },
    {
        title: "Egipat",
        offers: [{ label: "O destinaciji", link: "/about-country/egypt" }],
    },
    {
        title: "Turska",
        offers: [{ label: "O destinaciji", link: "/about-country/turkey" }],
    },
    {
        title: "Albanija",
        offers: [{ label: "O destinaciji", link: "/about-country/albania" }],
    },
    {
        title: "Bugarska",
        offers: [{ label: "O destinaciji", link: "/about-country/bulgaria" }],
    },
    {
        title: "Hrvatska",
        offers: [{ label: "O destinaciji", link: "/about-country/croatia" }],
    },
    {
        title: "Kipar",
        offers: [{ label: "O destinaciji", link: "/about-country/cyprus" }],
    },
    {
        title: "UAE",
        offers: [{ label: "O destinaciji", link: "/about-country/uae" }],
    },
];

export const regions = {
    GREECE: [
        {
            title: "Halkidiki",
        },
        {
            title: "Halkidiki",
        },
        {
            title: "Halkidiki",
        },
        {
            title: "Halkidiki",
        },
        {
            title: "Halkidiki",
        },
    ],
};
