import React from "react";

import HomeCarousel from "./HomeCarousel";
import SearchBar from "../Reusable/SearchBar";

import "../../styles/Home.scss";

function HomeTop(props) {
    return (
        <div className="home-top">
            <HomeCarousel slider={props.slider} />
            <SearchBar />
        </div>
    );
}

export default HomeTop;
