import React, { Component } from "react";

import "../styles/Components/LoginProfileCircle.scss";

class LoginProfileCircle extends Component {
    render() {
        return (
            <div
                className="login_profile_circle_wrapper"
                onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick();
                    }
                }}
            >
                {this.props.userName.charAt(0).toUpperCase()}
            </div>
        );
    }
}

export default LoginProfileCircle;
