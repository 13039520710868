import axios from "axios";

import { getCookie } from "../utils/globalUtils";

export const getMyProfile = async () => {
    const userId = getCookie("userId");
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/myProfile/${userId}`).catch((error) => {
        return error;
    });
};
