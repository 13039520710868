import axios from "axios";
import { getCookie } from "../utils/globalUtils";

export const getDestinations = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/destinations`).catch((error) => {
        return error;
    });
};

export const searchAccommodation = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/search`, data).catch((error) => {
        return error;
    });
};

export const getAboutHotel = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/searchhotel`, data).catch((error) => {
        return error;
    });
};

export const getHotelInfo = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/hotelinfo`, data).catch((error) => {
        return error;
    });
};

export const makeReservation = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/book`, data).catch((error) => {
        return error;
    });
};

export const myReservation = async () => {
    const userID = getCookie("userId");

    return await axios.get(`${process.env.REACT_APP_API_URL}/api/getUserReservatoin/${userID}`).catch((error) => {
        return error;
    });
};

export const cancelReservation = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/cancelBooking/${data}`).catch((error) => {
        return error;
    });
};
