import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

export const shuffle = (array) => {
    let currentIndex = array?.length ?? 0,
        randomIndex;

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

export function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        const [searchParams, setSearchParams] = useSearchParams();

        return <Component {...props} router={{ location, navigate, params, searchParams, setSearchParams }} />;
    }

    return ComponentWithRouterProp;
}

export const setCookie = (name, value) => {
    var expires = "";

    var date = new Date();
    date.setTime(date.getTime() + 1 * 8 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();

    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const areObjectsEqual = (...objects) => objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]));

export const getCookie = (_key) => {
    let name = _key + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const removeCookie = (name) => {
    // remove cookie2
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
};

export const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const discountColor = (popust) => {
    if (popust >= 25) {
        return "#FF3E3E";
    } else if (popust >= 10) {
        return "#FDB72C";
    } else {
        return "#166FA9";
    }
};
