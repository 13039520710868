import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Form } from "react-bootstrap";

import { cities } from "../../static/data/cities";
import { phoneRegExp } from "../../helpers/utils/globalUtils";

const schemaUser = yup.object().shape({
    first_name: yup.string().required("Polje mora da bude popunjeno"),
    last_name: yup.string().required("Polje mora da bude popunjeno"),
    city: yup.string().required("Polje mora da bude popunjeno"),
    address: yup.string().required("Polje mora da bude popunjeno"),
    email: yup.string().email().required("Polje mora da bude popunjeno"),
    username: yup.string().required("Polje mora da bude popunjeno"),
    phone_number: yup.string().matches(phoneRegExp, "Broj telefona nije validan").required("Polje mora biti popunjeno"),
    password: yup.string().min(8, "Minimalni broj karaktera je 8").required("Morate upisati lozinku"),
    confirm_password: yup
        .string()
        .min(8, "Minimalni broj karaktera je 8")
        .oneOf([yup.ref("password"), null], "Lozinke se moraju podudarati")
        .required("Lozinke se moraju podudarati"),
});

class RegistrationFormTurists extends Component {
    render() {
        return (
            <Formik
                validationSchema={schemaUser}
                initialValues={{
                    first_name: this.props.values?.first_name ?? "",
                    last_name: this.props.values?.last_name ?? "",
                    phone_number: this.props.values?.phone_number ?? "",
                    email: this.props.values?.email ?? "",
                    city: this.props.values?.city ?? "",
                    address: this.props.values?.address ?? "",
                    username: this.props.values?.username ?? "",
                    password: this.props.values?.password ?? "",
                    confirm_password: this.props.values?.confirm_password ?? "",
                }}
                onSubmit={(values, actions) => {
                    this.props.getUserData(values);
                    actions.resetForm();
                }}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                    <Form noValidate autoComplete="off" name="registration_turist_form" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 position-relative">
                            <Form.Control
                                type="text"
                                placeholder="Ime"
                                name="first_name"
                                value={values.first_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.first_name && errors.first_name}
                            />
                            <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Prezime"
                                name="last_name"
                                value={values.last_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.last_name && errors.last_name}
                            />
                            <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Mobilni"
                                name="phone_number"
                                value={values.phone_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.phone_number && errors.phone_number}
                            />
                            <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="E-mail"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.email && errors.email}
                            />
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Select
                                className="mb-3"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.city && errors.city}
                            >
                                <option hidden>Grad</option>
                                {cities.map((city, key) => (
                                    <option value={`${city}`} key={key}>
                                        {city}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Adresa"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.address && errors.address}
                            />
                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Korisničko ime"
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.username && errors.username}
                            />
                            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="password"
                                placeholder="Šifra"
                                name="password"
                                value={values.password}
                                isInvalid={touched.password && errors.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="password"
                                placeholder="Potvrdi Šifru"
                                name="confirm_password"
                                value={values.confirm_password}
                                isInvalid={touched.confirm_password && errors.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">{errors.confirm_password}</Form.Control.Feedback>
                        </Form.Group>
                        <button className="send_user_button" type="submit">
                            {this.props.submitButton}
                        </button>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default RegistrationFormTurists;
