import React from "react";
import MediaQuery from "react-responsive";
import Carousel from "../Reusable/Carousel";

// import HomepageBigBanner from "../../static/images/Big_Thumbnail/Homepage_big_thumbnail.png";
// import MobileHomepageBigBanner from "../../static/images/Big_Thumbnail/mobile-big-thumnail.png";

function HomeCarousel(props) {
    return (
        <>
            <MediaQuery minWidth={768}>
                {/* <Carousel data={[HomepageBigBanner, HomepageBigBanner, HomepageBigBanner]} indicators={false} arrows={true} /> */}
                <Carousel data={props.slider} indicators={false} arrows={true} />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
                <div className="mobile-carousel-links">
                    <div className="mobile-links-wrapper">
                        <div className="mobile-links-title-wrapper font-poppins">
                            {[
                                { title: "Last/First minute" },
                                { title: "Leto 2022." },
                                { title: "Evropski gradovi" },
                                { title: "Srbija" },
                                { title: "Wellnes" },
                                { title: "Daleka putovanja" },
                                { title: "Blog" },
                            ].map((item, key) => (
                                <div key={key}>{item.title}</div>
                            ))}
                        </div>
                    </div>
                    {/* <Carousel
                        data={[MobileHomepageBigBanner, MobileHomepageBigBanner, MobileHomepageBigBanner]}
                        indicators={true}
                        className={"mobile-carousel"}
                    /> */}
                    <Carousel data={props.slider} indicators={true} className={"mobile-carousel"} />
                </div>
            </MediaQuery>
        </>
    );
}

export default HomeCarousel;
