export const GET_SPECIAL_OFFERS_HOTELS = "GET_SPECIAL_OFFERS_HOTELS";
export const GET_SPECIAL_OFFERS_HOTELS_SUCCESS = "GET_SPECIAL_OFFERS_HOTELS_SUCCESS";
export const GET_SPECIAL_OFFERS_HOTELS_ERROR = "GET_SPECIAL_OFFERS_HOTELS_ERROR";

export const GET_SPECIAL_OFFERS_APARTMENTS = "GET_SPECIAL_OFFERS_APARTMENTS";
export const GET_SPECIAL_OFFERS_APARTMENTS_SUCCESS = "GET_SPECIAL_OFFERS_APARTMENTS_SUCCESS";
export const GET_SPECIAL_OFFERS_APARTMENTS_ERROR = "GET_SPECIAL_OFFERS_APARTMENTS_ERROR";

export const GET_FIRST_LAST_MINUTES_OFFERS = "GET_FIRST_LAST_MINUTES_OFFERS";
export const GET_FIRST_LAST_MINUTES_OFFERS_SUCCESS = "GET_FIRST_LAST_MINUTES_OFFERS_SUCCESS";
export const GET_FIRST_LAST_MINUTES_OFFERS_ERROR = "GET_FIRST_LAST_MINUTES_OFFERS_ERROR";
