import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../styles/Registration.scss";

import RegistrationProfiles from "../../Reusable/RegistrationProfiles";
import UsersForm from "../../Reusable/UsersForm";

import Logo from "../../../static/images/unlimited_travel_logo_big.png";

class RegistrationDesktop extends Component {
    render() {
        return (
            <Container fluid className="login_background">
                <div className="registration_content_wrapper">
                    <Row className="align-items-center justify-content-between">
                        <Col lg={6}>
                            <img src={Logo} alt="Logo big" />
                        </Col>
                        <Col lg={6}>
                            <RegistrationProfiles active={this.props.activeProfile} setActiveProfile={this.props.setActiveProfile} />
                        </Col>
                    </Row>

                    {this.props.registrationStatus ? (
                        <div className="status_message" style={{ color: this.props.registrationStatus?.status === "success" ? "green" : "red" }}>
                            {this.props.registrationStatus?.message}
                        </div>
                    ) : (
                        <></>
                    )}
                    <UsersForm
                        formType={"registration"}
                        activeProfile={this.props.activeProfile}
                        submitButton={"Registruj se"}
                        getUserData={this.props.getUserData}
                        getAgentData={this.props.getAgentData}
                        className="registration_form_wrapper"
                    />
                </div>
            </Container>
        );
    }
}

export default RegistrationDesktop;
